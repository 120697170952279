import { Stack, Typography } from "@mui/material"
import { Buffer } from "buffer"
import { useMemo } from "react"
import { createRoot } from "react-dom/client"
import { Action, ClientContextProvider, createClient } from "react-fetching-library"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Bar } from "./components/bar/bar"
import { LeftDrawer } from "./components/drawer/drawerLeft/drawerLeft"
import { DRAWER_MAX_WIDTH, DRAWER_MIN_WIDTH, DRAWER_TRANSITION_DURATION, SERVER_HOST } from "./constants"
import { LanguageProvider } from "./containers/Languages"
import { AuthProvider, UserUpdater, useAuth } from "./containers/auth"
import { CurrencyProvider } from "./containers/currency"
import { DimensionProvider } from "./containers/dimension"
import { ImportRecordProvider } from "./containers/importRecords"
import { InnerShopProvider, InnerShopUpdater } from "./containers/inner_shop"
import { OrganisationProvider, OrganisationUpdater } from "./containers/organisations"
import { PermissionProvider } from "./containers/permissions"
import { ThemeProvider } from "./containers/theme"
import { TimeFormatProvider } from "./containers/timeFormatter"
import { UIProvider, useUI } from "./containers/ui"
import { AccountantPage } from "./pages/accountant/accountantPage"
import { PayStaffsPage } from "./pages/accountant/payStaffsPage"
import { ImportRecordCreatePage } from "./pages/importRecords/importRecordCreatePage"
import { ImportRecordPage } from "./pages/importRecords/importRecordPage"
import { InnerShopPage } from "./pages/inner_page/shops/innerShopPage"
import { ItemPage } from "./pages/item/itemPage"
import { LoginPage } from "./pages/login/loginPage"
import { RedirectPage } from "./pages/login/redirectPage"
import { PosClockInPage } from "./pages/pos/PosClockInPage"
import { ShopItemPage } from "./pages/shops/shopItemPage"
import { ShopMemberPage } from "./pages/shops/shopMemberPage"
import { ShopPage } from "./pages/shops/shopPage"
import { ShopSettingsPage } from "./pages/shops/shopSettingsPage"
import { WarehousePage } from "./pages/warehouses/warehousePage"
import { colors } from "./theme/theme"
import { ItemType } from "./types/items"

const AppInner = () => {
    const { userID, isSystemUser } = useAuth()
    const { isNavLinksDrawerOpen } = useUI()

    const routes = useMemo(() => {
        if (isSystemUser) return <Route path={"*"} element={<PosClockInPage />} />
        return (
            <>
                <Route path={"/pos"} element={<PosClockInPage />} />
                <Route path={"/item"} element={<ItemPage />} />
                <Route path={"/import"}>
                    <Route index element={<ImportRecordPage />} />
                    <Route path={"create"} element={<ImportRecordCreatePage />} />
                </Route>
                <Route path={"/warehouse"}>
                    <Route index element={<WarehousePage />} />
                </Route>
                <Route path={"/store"}>
                    <Route index element={<ShopPage />} />
                </Route>
                <Route path={"/accountant"}>
                    <Route index element={<AccountantPage />} />
                    <Route path={"pay_staffs"} element={<PayStaffsPage />}></Route>
                </Route>
                <Route path={"/inner_shop/:id"}>
                    <Route index element={<InnerShopPage />} />
                    <Route path={"members"}>
                        <Route index element={<ShopMemberPage />} />
                    </Route>
                    <Route path={"items"}>
                        <Route index element={<ShopItemPage routeType={ItemType.ITEM} />} />
                    </Route>
                    <Route path={"intermediate_products"}>
                        <Route index element={<ShopItemPage routeType={ItemType.INTERMEDIATE_PRODUCT} />} />
                    </Route>
                    <Route path={"products"}>
                        <Route index element={<ShopItemPage routeType={ItemType.PRODUCT} />} />
                    </Route>
                    <Route path={"settings"}>
                        <Route index element={<ShopSettingsPage />} />
                    </Route>
                </Route>
                <Route path={"*"} element={<Typography variant="h3">HOME PAGE</Typography>} />
            </>
        )
    }, [isSystemUser])

    if (!userID) {
        return <LoginPage />
    }

    return (
        <Stack
            sx={{
                position: "fixed",
                width: "100vw",
                height: "100%",
                backgroundColor: colors.background,
            }}
        >
            <Bar />
            <LeftDrawer />
            <Stack
                direction="row"
                sx={{
                    position: "relative",
                    flex: 1,
                    p: "1rem",
                    ml: isNavLinksDrawerOpen ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH,
                    width: `calc(100% - ${isNavLinksDrawerOpen ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH})`,
                    transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,
                    overflow: "hidden",
                    justifyContent: "space-between",
                    "& > *": {
                        flexShrink: 0,
                    },
                }}
            >
                <Routes>{routes}</Routes>
            </Stack>
        </Stack>
    )
}

window.Buffer = Buffer

const prefixURL = (prefix: string) => () => async (action: Action) => {
    return {
        ...action,
        headers: {
            ...action.headers,
        },
        endpoint: action.endpoint.startsWith("http") ? action.endpoint : `${prefix}${action.endpoint}`,
    }
}

const client = createClient({
    //None of the options is required
    requestInterceptors: [prefixURL(`${window.location.protocol}//${SERVER_HOST}/api`)],
    responseInterceptors: [],
})

const App = () => {
    return (
        <ThemeProvider>
            <ClientContextProvider client={client}>
                <AuthProvider>
                    <TimeFormatProvider>
                        <LanguageProvider>
                            <OrganisationProvider>
                                <InnerShopProvider>
                                    <InnerShopUpdater />
                                    <PermissionProvider>
                                        <ImportRecordProvider>
                                            <DimensionProvider>
                                                <UIProvider>
                                                    <CurrencyProvider>
                                                        <UserUpdater />
                                                        <OrganisationUpdater />
                                                        <BrowserRouter>
                                                            <Routes>
                                                                <Route path="login-redirect" element={<RedirectPage />} />
                                                                <Route path="*" element={<AppInner />} />
                                                            </Routes>
                                                        </BrowserRouter>
                                                    </CurrencyProvider>
                                                </UIProvider>
                                            </DimensionProvider>
                                        </ImportRecordProvider>
                                    </PermissionProvider>
                                </InnerShopProvider>
                            </OrganisationProvider>
                        </LanguageProvider>
                    </TimeFormatProvider>
                </AuthProvider>
            </ClientContextProvider>
        </ThemeProvider>
    )
}

createRoot(document.getElementById("root")!).render(<App />)
