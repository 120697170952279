import { Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { ShopSalaryPayout } from "../../../components/shop/shopSalaryPayout"

export const InnerShopPage = () => {
    const { id } = useParams()

    if (!id) return null

    return (
        <Stack
            sx={{
                flex: 1,
            }}
        >
            <Stack
                sx={{
                    height: "100%",
                    width: "50%",
                }}
            >
                <ShopSalaryPayout shopID={id} />
            </Stack>
        </Stack>
    )
}
