import AddBoxIcon from "@mui/icons-material/AddBox"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { Box, Divider, Stack } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { usePermissions } from "../../../../containers/permissions"
import { useServerSubscriptionShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { Permission } from "../../../../types/permissions"
import { ShopJobTitle } from "../../../../types/Shop"
import { PageHeader } from "../../../common/pageHeader"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { ShopJobTitleCard } from "./shopJobTitleCard"
import { ShopJobTitleCreateModal } from "./shopJobTitleCreateModal"
import { ShopJobTitlePanel } from "./shopJobTitlePanel"

export const ShopJobTitleList = () => {
    const { hasPerm } = usePermissions()
    const [deleteMode, setDeleteMode] = React.useState(false)
    const [selectedShopJobTitleID, setSelectedShopJobTittleID] = React.useState("")
    const [shopJobTitles, setShopJobTitles] = React.useState<ShopJobTitle[]>([])
    const [openJobTitleCreateModal, setOpenJobTitleCreateModal] = React.useState(false)
    useServerSubscriptionShop<ShopJobTitle[]>(
        {
            URI: `/job_titles`,
            key: WSKeys.WSKeyShopJobTitles,
            ready: hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobTitles((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => a.title.localeCompare(b.title))
                    prev = prev.map((jt) => payload.find((p) => p.id === jt.id) || jt)
                    payload.forEach((p) => (prev.some((jt) => jt.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jt) => !jt.deleted_at).sort((a, b) => a.title.localeCompare(b.title))
                })
            },
        },
    )

    const selectedShopJobTitle = React.useMemo(() => {
        if (deleteMode) return null
        return shopJobTitles.find((jt) => jt.id === selectedShopJobTitleID)
    }, [deleteMode, selectedShopJobTitleID, shopJobTitles])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {shopJobTitles.map((jt) => {
                        return (
                            <div key={`shop-member-${jt.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ShopJobTitleCard
                                    shopJobTitle={jt}
                                    onSelect={() => setSelectedShopJobTittleID(jt.id)}
                                    selected={selectedShopJobTitleID === jt.id}
                                    deleteMode={deleteMode}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [selectedShopJobTitleID, shopJobTitles, deleteMode])

    return (
        <Stack
            direction={"column"}
            flex={1}
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
            }}
        >
            <PageHeader
                title={
                    <TypographyTrans variant="h5" sx={{ fontWeight: "bolder", fontFamily: fonts.sourceSansProBlack }}>
                        JOB TITLE
                    </TypographyTrans>
                }
            />
            <Stack direction={"row"} flex={1}>
                <Stack sx={{ px: "1rem", py: ".5rem", width: "32.5rem" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={"1rem"} sx={{ pt: ".5rem", pb: ".2rem" }}>
                        <Box
                            sx={{ cursor: "pointer", opacity: !deleteMode ? 1 : 0.5 }}
                            onClick={() => {
                                setOpenJobTitleCreateModal(true)
                                setDeleteMode(false)
                            }}
                        >
                            <AddBoxIcon sx={{ color: colors.primary, fontSize: "2.5rem" }} />
                        </Box>

                        <Box
                            sx={{ cursor: "pointer", opacity: deleteMode ? 1 : 0.5 }}
                            onClick={() => {
                                setDeleteMode((prev) => !prev)
                                setSelectedShopJobTittleID("")
                            }}
                        >
                            <DeleteForeverIcon sx={{ color: colors.lightRed, fontSize: "2.5rem" }} />
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            my: "1rem",
                            flex: 1,
                            overflowY: "auto",
                            overflowX: "hidden",
                            direction: "ltr",
                        }}
                    >
                        {content}
                    </Box>
                </Stack>
                <Divider sx={{ my: "1.5rem !important", border: `${colors.primary}60 1px dashed` }} />
                <Stack flex={1} sx={{ p: "1rem" }}>
                    {selectedShopJobTitle && <ShopJobTitlePanel key={selectedShopJobTitle.id} shopJobTitle={selectedShopJobTitle} />}
                </Stack>
            </Stack>
            {openJobTitleCreateModal && <ShopJobTitleCreateModal onClose={() => setOpenJobTitleCreateModal(false)} />}
        </Stack>
    )
}
