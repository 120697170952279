import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { BiSolidFoodMenu } from "react-icons/bi"
import { BsInfoCircleFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { useInnerShop } from "../../../containers/inner_shop"
import { useItem } from "../../../containers/items"
import { calProfit, calProfitRate, roundToDecimalPlaces } from "../../../helpers"
import { useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ItemStackIDs, ItemType } from "../../../types/items"
import { TypographyTrans } from "../../common/TypographyTrans"
import IconLabelButton from "../../common/iconLabelButton"
import { PageHeader } from "../../common/pageHeader"
import { StoreTooltip } from "../../common/storeTooltip"
import { ItemCard } from "../../items/itemCard"
import RecipeModal from "./RecipeModal"
import { ProductComponent } from "./componentSelectionList"
import ProductModal from "./productModal"

interface Props {
    productID: string
}

const ProductDetail = ({ productID }: Props) => {
    const { items, intermediateProducts, products } = useItem()
    const { shopID } = useInnerShop()
    const [itemStackIDs, setItemStackIDs] = React.useState<ItemStackIDs>({})
    const [openProductModal, setOpenProductModal] = React.useState(false)
    const [openRecipeModal, setOpenRecipeModal] = React.useState(false)
    const navigate = useNavigate()

    const product = React.useMemo(() => {
        if (!products) return null
        return [...products].find((p) => p.id === productID)
    }, [products, productID])

    useServerSubscriptionShop<ItemStackIDs>(
        {
            URI: `/item_stacks/${productID}`,
            key: WSKeys.WSKeyItemStacks,
        },
        {
            callback: (payload) => {
                setItemStackIDs(payload)
            },
        },
    )

    const { first_level_items } = itemStackIDs
    const { item_stacks, intermediate_product_stacks } = first_level_items || {}

    const firstLevelItems = React.useMemo<ProductComponent[]>(() => {
        const stackedItems: ProductComponent[] = []

        if (item_stacks && item_stacks.length > 0) {
            item_stacks.forEach((is) => {
                const item = items.find((i) => i.id === is.id)
                if (!item) return

                stackedItems.push({
                    id: item.id,
                    volume: item.volume,
                    type: "item",
                    label: item.label,
                    cost: item.cost,
                    unit_type: item.unit_type,
                    setVolume: is.volume,
                    setUnitType: is.unit_type,
                })
            })
        }

        if (intermediate_product_stacks && intermediate_product_stacks.length > 0) {
            intermediate_product_stacks.forEach((is) => {
                const ip = intermediateProducts.find((i) => i.id === is.id)
                if (!ip) return

                stackedItems.push({
                    id: ip.id,
                    volume: ip.volume,
                    type: "intermediate_product",
                    label: ip.label,
                    cost: ip.cost,
                    unit_type: ip.unit_type,
                    setVolume: is.volume,
                    setUnitType: is.unit_type,
                })
            })
        }

        return stackedItems
    }, [item_stacks, items, intermediate_product_stacks, intermediateProducts])

    // included intermediate products
    const includedIntermediateProducts = React.useMemo(() => {
        if (!intermediate_product_stacks || !intermediateProducts) return null

        return [...intermediate_product_stacks].map((is) => {
            const ip = intermediateProducts.find((i) => i.id === is.id)
            if (!ip) return <React.Fragment key={is.id}></React.Fragment>
            return (
                <Box key={is.id} mb={"0.25rem"}>
                    <ItemCard
                        item={ip}
                        currentUnitType={is.unit_type}
                        currentVolume={is.volume}
                        linkTo={() => {
                            navigate(`/inner_shop/${shopID}/intermediate_products?intermediate_product_id=${ip.id}`)
                        }}
                    />
                </Box>
            )
        })
    }, [intermediate_product_stacks, intermediateProducts, navigate, shopID])

    // included items
    const includedItems = React.useMemo(() => {
        if (!item_stacks || !items) return null

        return [...item_stacks].map((is) => {
            const item = items.find((i) => i.id === is.id)
            if (!item) return <React.Fragment key={is.id}></React.Fragment>
            return (
                <Box key={is.id} mb={"0.25rem"}>
                    <ItemCard
                        item={item}
                        currentUnitType={is.unit_type}
                        currentVolume={is.volume}
                        linkTo={() => {
                            navigate(`/inner_shop/${shopID}/items?item_id=${item.id}`)
                        }}
                    />
                </Box>
            )
        })
    }, [item_stacks, items, navigate, shopID])

    const labelValue = React.useCallback((label: React.ReactNode, value: string) => {
        return (
            <Stack direction={"row"} spacing={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
                {typeof label === "string" ? (
                    <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                        {label}
                    </TypographyTrans>
                ) : (
                    label
                )}
                <Typography variant="h5" fontFamily={fonts.sourceSansProBold}>
                    {value}
                </Typography>
            </Stack>
        )
    }, [])

    if (!product) return null

    const { label, cost, price } = product

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    flex: 1,
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>DETAIL</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Update Product",
                            onClick: () => setOpenProductModal(true),
                            icon: <ConstructionRoundedIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="column" flex={1} sx={{ p: "1rem" }} spacing={"1rem"} minHeight={0}>
                    {/* Item details */}
                    <Stack direction="row" flex={1} spacing={"3rem"} minHeight={0}>
                        <Stack direction="column" sx={{ width: "60rem" }}>
                            {labelValue("Label:", label)}
                            {labelValue("Price:", `$ ${price}`)}
                            {labelValue("Cost:", `$ ${roundToDecimalPlaces(cost)}`)}
                            {labelValue(
                                <Stack direction={"row"}>
                                    <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                                        Profit:
                                    </TypographyTrans>
                                    <StoreTooltip text={"(Price x 0.9) - Cost"}>
                                        <Box pt="2px" pl="2px">
                                            <BsInfoCircleFill size={"1.5rem"} style={{ color: `${colors.primary}80` }} />
                                        </Box>
                                    </StoreTooltip>
                                </Stack>,
                                `$ ${calProfit(cost, price)}`,
                            )}
                            {labelValue(
                                <Stack direction={"row"}>
                                    <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                                        Profit Rate:
                                    </TypographyTrans>
                                    <StoreTooltip text={"Profit / Cost"}>
                                        <Box pt="2px" pl="2px">
                                            <BsInfoCircleFill size={"1.5rem"} style={{ color: `${colors.primary}80` }} />
                                        </Box>
                                    </StoreTooltip>
                                </Stack>,
                                `${calProfitRate(cost, price)} %`,
                            )}
                            <Stack direction={"row"} spacing={"1rem"} sx={{ mt: "2rem" }}>
                                <IconLabelButton
                                    icon={<BiSolidFoodMenu style={{ fontSize: "2.25rem", fontWeight: "bolder", color: colors.offWhite }} />}
                                    label={"Export Recipe"}
                                    sx={{ fontWeight: "bold", color: colors.offWhite }}
                                    buttonSx={{
                                        border: `${colors.primary}60 2px solid`,
                                        backgroundColor: `${colors.primary}AA`,
                                    }}
                                    onClick={() => setOpenRecipeModal(true)}
                                />
                            </Stack>
                        </Stack>
                        <Stack direction="column" flex={1}>
                            <Box sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", direction: "ltr" }}>
                                {includedIntermediateProducts}
                                {includedItems}
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {openProductModal && <ProductModal onClose={() => setOpenProductModal(false)} product={product} initialFirstLevelItems={firstLevelItems} />}
            {openRecipeModal && (
                <RecipeModal
                    itemType={ItemType.PRODUCT}
                    onClose={() => setOpenRecipeModal(false)}
                    shopID={shopID}
                    exportItem={product}
                    components={firstLevelItems}
                />
            )}
        </>
    )
}

export default ProductDetail
