import { Box, Stack, Typography } from "@mui/material"
import { Moment } from "moment"
import React from "react"
import FlipMove from "react-flip-move"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks"
import { useServerSubscription, useServerSubscriptionOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { Shop, ShopMember } from "../../../types/Shop"
import { Permission } from "../../../types/permissions"
import { InputBox } from "../../common/InputBox"
import { SelectBox } from "../../common/selectBox"
import { PayStaffMemberCard } from "./payStaffMemberCard"

interface PayStaffPanelMemberSelectorProps {
    selectedShopID: string
    selectedMemberID: string
    startTime: Moment
    endTime: Moment
}

export const PayStaffPanelMemberSelector = ({ selectedShopID, selectedMemberID, startTime, endTime }: PayStaffPanelMemberSelectorProps) => {
    const { hasPerm } = usePermissions()
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const [shops, setShops] = React.useState<Shop[]>([])
    useServerSubscriptionOrg<Shop[]>(
        {
            URI: "/shops",
            key: WSKeys.WSKeyShops,
            ready: hasPerm(Permission.ShopList),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShops((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at)
                    prev = prev.map((r) => payload.find((p) => p.id === r.id) || r)
                    payload.forEach((p) => (prev.some((r) => r.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((r) => !r.deleted_at)
                })
            },
        },
    )

    const [shopMembers, setShopMembers] = React.useState<ShopMember[]>([])
    useServerSubscription<ShopMember[]>(
        {
            URI: `/shop/${selectedShopID}/members`,
            key: WSKeys.WSKeyShopMembers,
            ready: !!selectedShopID && hasPerm(Permission.UserList),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMembers((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at && p.shop_id === selectedShopID)
                    // update existing members
                    prev = prev.map((m) => payload.find((p) => p.id === m.id) || m)
                    // add new data to the list
                    payload.forEach((p) => (prev.some((m) => m.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((p) => !p.deleted_at && p.shop_id === selectedShopID)
                })
            },
        },
    )

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 250)

    const [list, setList] = React.useState<ShopMember[]>([])
    React.useEffect(() => {
        let result = [...shopMembers].sort((a, b) => a.payslip_prefer_name.localeCompare(b.payslip_prefer_name))
        if (searchValue !== "") {
            result = result.filter((s) => `${s.prefer_name} ${s.payslip_prefer_name} ${s.payslip_full_name}`.toLowerCase().includes(searchValue.toLowerCase()))
        }
        setList(result)
    }, [searchValue, shopMembers])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((sm) => {
                        return (
                            <div key={`shopMember-${sm.id}`} style={{ marginBottom: ".5rem" }}>
                                <PayStaffMemberCard
                                    shopMember={sm}
                                    selectedShopID={selectedShopID}
                                    selectedMemberID={selectedMemberID}
                                    startTime={startTime}
                                    endTime={endTime}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, selectedMemberID, endTime, startTime, selectedShopID])

    return (
        <Stack spacing={"1.5rem"} sx={{ width: "32.5rem" }}>
            <Stack spacing={".5rem"}>
                <Stack spacing={".5rem"}>
                    <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        Shops
                    </Typography>
                    <SelectBox
                        options={shops.map((s) => ({ value: s.id, label: s.label }))}
                        value={selectedShopID}
                        sx={{ border: `${colors.primary}50 2px solid` }}
                        onChange={(e) => {
                            const v = e.target.value
                            if (typeof v !== "string") return

                            searchParams.set("selected_shop_id", v)
                            navigate(`${location.pathname}?${searchParams.toString()}`)
                        }}
                    />
                </Stack>

                <Stack spacing={".5rem"}>
                    <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        Search
                    </Typography>
                    <InputBox
                        variant={"outlined"}
                        value={searchValueInstant}
                        setValue={setSearchValue}
                        sx={{
                            border: `${colors.primary}50 2px solid`,
                        }}
                    />
                </Stack>
            </Stack>

            <Box
                sx={{
                    mr: ".2rem",
                    pr: ".2rem",
                    pt: "1.5rem",
                    flex: 1,
                    overflowY: "auto",
                    overflowX: "hidden",
                    direction: "ltr",
                }}
            >
                {content}
            </Box>
        </Stack>
    )
}
