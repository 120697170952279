import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { DRAWER_TRANSITION_DURATION } from "../../constants"
import { colors, fonts } from "../../theme/theme"

interface FilterBarProps {
    children: React.ReactNode
    resetFilter?: () => void
    setFilterBarExpended?: React.Dispatch<React.SetStateAction<boolean>>
}

export const FilterBar = ({ children, resetFilter, setFilterBarExpended }: FilterBarProps) => {
    const [open, setOpen] = React.useState(false)
    return (
        <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
                width: open ? "30rem" : "3rem",
                transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,
                overflowY: "auto",
                overflowX: "hidden",
            }}
        >
            <Stack
                direction="column"
                sx={{
                    height: "100%",
                    width: "100%",
                    display: open ? "flex" : "none",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing="2rem"
                    sx={{
                        backgroundColor: colors.primary,
                        borderBottom: `${colors.primary}80 1.5px solid`,
                        py: ".25rem",
                        px: "1rem",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "bolder",
                            color: colors.secondary,
                            fontFamily: fonts.sourceSansProBold,
                        }}
                    >
                        FILTER
                    </Typography>

                    {resetFilter && (
                        <IconButton size="small" onClick={resetFilter}>
                            <RotateLeftIcon
                                sx={{
                                    fontSize: "2.5rem",
                                }}
                            />
                        </IconButton>
                    )}
                </Stack>

                <Stack
                    direction="column"
                    sx={{
                        px: ".5rem",
                    }}
                >
                    {children}
                </Stack>
            </Stack>

            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: "3rem",
                    height: "100%",
                    backgroundColor: `${colors.primary}60`,
                    cursor: "pointer",
                }}
                onClick={() => {
                    setOpen((prev) => !prev)
                    if (setFilterBarExpended) setFilterBarExpended(!open)
                }}
            >
                {open ? <KeyboardDoubleArrowLeftIcon fontSize="large" /> : <KeyboardDoubleArrowRightIcon fontSize="large" />}
            </Stack>
        </Stack>
    )
}
