import { useItem } from "../containers/items"
import { usePermissions } from "../containers/permissions"
import { useServerSubscriptionOrg, useServerSubscriptionShop } from "../hooks/useServer"
import { WSKeys } from "../key"
import { IntermediateProduct } from "../types/intermediateProducts"
import { Ingredient, Item, ItemSource, Manufacturer } from "../types/items"
import { Permission } from "../types/permissions"
import { Product } from "../types/products"

const ItemUpdater = () => {
    const { setItems, setIngredients, setManufacturers, setItemSources, setIntermediateProducts, setProducts } = useItem()
    const { hasPerm } = usePermissions()

    useServerSubscriptionOrg<Item[]>(
        {
            URI: "/item_list",
            key: WSKeys.WSKeyItemList,
            ready: hasPerm(Permission.ItemView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setItems((prev) => {
                    if (prev.length === 0) {
                        return payload.filter((item) => !item.deleted_at)
                    }

                    // replace the one that already exists
                    const list = [...prev].map((item) => payload.find((p) => p.id === item.id) || item)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((item) => item.id === p.id)) return
                        list.push(p)
                    })

                    // set new item list
                    return list.filter((item) => !item.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )

    useServerSubscriptionOrg<Ingredient[]>(
        {
            URI: "/ingredient_list",
            key: WSKeys.WSKeyIngredientList,
            ready: hasPerm(Permission.ItemView),
        },
        {
            callback: (payload) => {
                if (!payload) return

                setIngredients((prev) => {
                    if (prev.length === 0) {
                        return payload
                    }

                    // replace the one that already exists
                    const list = [...prev].map((ingredient) => payload.find((p) => p.id === ingredient.id) || ingredient)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((ingredient) => ingredient.id === p.id)) return
                        list.push(p)
                    })

                    // set new item list
                    return list
                })
            },
        },
    )

    useServerSubscriptionOrg<Manufacturer[]>(
        {
            URI: "/manufacturer_list",
            key: WSKeys.WSKeyManufacturerList,
            ready: hasPerm(Permission.ManufacturerView),
        },
        {
            callback: (payload) => {
                if (!payload) return

                setManufacturers((prev) => {
                    if (prev.length === 0) {
                        return payload
                    }

                    // replace the one that already exists
                    const list = [...prev].map((manufacturer) => payload.find((p) => p.id === manufacturer.id) || manufacturer)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((manufacturer) => manufacturer.id === p.id)) return
                        list.push(p)
                    })

                    // set new item list
                    return list
                })
            },
        },
    )

    useServerSubscriptionOrg<ItemSource[]>(
        {
            URI: "/item_source_list",
            key: WSKeys.WSKeyItemSourceList,
            ready: hasPerm(Permission.ItemView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setItemSources((prev) => {
                    if (prev.length === 0) {
                        return payload.filter((item) => !item.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                    }

                    // replace the one that already exists
                    const list = [...prev].map((item) => payload.find((p) => p.id === item.id) || item)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((item) => item.id === p.id)) return
                        list.push(p)
                    })

                    // set new item list
                    return list.filter((item) => !item.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                })
            },
        },
    )

    useServerSubscriptionShop<IntermediateProduct[]>(
        {
            URI: "/intermediate_product_list",
            key: WSKeys.WSKeyIntermediateProductList,
            ready: hasPerm(Permission.ShopProductView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setIntermediateProducts((prev) => {
                    if (prev.length === 0) {
                        return payload.filter((IntermediateProduct) => !IntermediateProduct.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                    }

                    // replace the one that already exists
                    const list = [...prev].map((IntermediateProduct) => payload.find((p) => p.id === IntermediateProduct.id) || IntermediateProduct)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((IntermediateProduct) => IntermediateProduct.id === p.id)) return
                        list.push(p)
                    })

                    // set new Intermediate product list
                    return list.filter((IntermediateProduct) => !IntermediateProduct.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                })
            },
        },
    )

    useServerSubscriptionShop<Product[]>(
        {
            URI: "/product_list",
            key: WSKeys.WSKeyProductList,
            ready: hasPerm(Permission.ShopProductView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setProducts((prev) => {
                    if (prev.length === 0) {
                        return payload.filter((product) => !product.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                    }

                    const list = [...prev].map((product) => payload.find((p) => p.id === product.id) || product)

                    payload.forEach((p) => {
                        if (list.some((product) => product.id === p.id)) return
                        list.push(p)
                    })

                    return list.filter((product) => !product.deleted_at).sort((a, b) => a.label.localeCompare(b.label))
                })
            },
        },
    )

    return null
}

export default ItemUpdater
