import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Pagination, Stack, Typography } from "@mui/material"
import React, { useMemo } from "react"
import FlipMove from "react-flip-move"
import { useLocation, useNavigate } from "react-router-dom"
import { useLanguages } from "../../../containers/Languages"
import { useItem } from "../../../containers/items"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks"
import { usePagination } from "../../../hooks/usePagination"
import { colors, fonts } from "../../../theme/theme"
import { Item } from "../../../types/items"
import { Permission } from "../../../types/permissions"
import { InputBox } from "../../common/InputBox"
import { FilterBar } from "../../common/filterBar"
import { FilterBarOption, FilterOption } from "../../common/filterOption"
import { PageHeader } from "../../common/pageHeader"
import { TotalAndPageSizeOptions } from "../../common/totalAndPageSizeOptions"
import { ItemCard } from "../../items/itemCard"
import { ItemSourceModal } from "../../items/itemSourceModal"
import ShopItemDetail from "./shopItemDetail"
import { ShopItemModal } from "./shopItemModal"

export const ShopItems = () => {
    const { items, itemSources } = useItem()
    const { language } = useLanguages()
    const [list, setList] = React.useState<Item[]>([])
    const { hasPerm } = usePermissions()
    const [openAddOptionModal, setOpenAddOptionModal] = React.useState(false)
    const [openUpdateOptionModal, setOpenUpdateOptionModal] = React.useState(false)
    const [openDeleteOptionModal, setOpenDeleteOptionModal] = React.useState(false)
    const [openAddItemModal, setOpenAddItemModal] = React.useState(false)

    const navigate = useNavigate()
    const { pathname, search } = useLocation()
    const selectedItemID = useMemo(() => {
        const searchParams = new URLSearchParams(search)
        return searchParams.get("item_id")
    }, [search])

    const [selectedItemSourceKeys, setSelectedItemSourceKeys] = React.useState<string[]>([])

    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...items].sort((a, b) => a.label.localeCompare(b.label))

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => `${s.label}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        if (selectedItemSourceKeys.length > 0) {
            sorted = sorted.filter((s) => {
                if (!s.item_sources) return false

                let exists = false
                s.item_sources.forEach((source) => {
                    if (exists) return

                    exists = selectedItemSourceKeys.includes(source.id)
                })

                return exists
            })
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, items, searchValue, page, pageSize, setTotalItems, selectedItemSourceKeys, language])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((item) => {
                        return (
                            <div key={`item-${item.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ItemCard
                                    item={item}
                                    onClick={() => {
                                        navigate(`${pathname}?item_id=${item.id}`)
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, pathname, navigate])

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "80rem",
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>ITEM</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Create Items",
                            onClick: () => setOpenAddItemModal(true),
                            icon: <AddCircleIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />
                <Stack direction="row" flex={1}>
                    <FilterBar
                        resetFilter={() => {
                            setSelectedItemSourceKeys([])
                        }}
                    >
                        <FilterOption
                            title="Source"
                            options={itemSources.map((c) => ({ key: c.id, value: c.label }))}
                            selectedKeys={selectedItemSourceKeys}
                            setSelectedKeys={setSelectedItemSourceKeys}
                            onKeySelect={() => changePage(1)}
                            openAddOptionModal={hasPerm(Permission.ItemUpdate) ? openAddOptionModal : undefined}
                            setOpenAddOptionModal={setOpenAddOptionModal}
                            addOptionModalFunc={() => <ItemSourceModal onClose={() => setOpenAddOptionModal(false)} />}
                            openUpdateOptionModal={hasPerm(Permission.ItemUpdate) ? openUpdateOptionModal : undefined}
                            setOpenUpdateOptionModal={setOpenUpdateOptionModal}
                            updateOptionModalFunc={(o: FilterBarOption) => (
                                <ItemSourceModal
                                    itemSource={{
                                        id: o.key,
                                        label: o.value,
                                    }}
                                    onClose={() => setOpenUpdateOptionModal(false)}
                                />
                            )}
                            openDeleteOptionModal={hasPerm(Permission.ItemUpdate) ? openDeleteOptionModal : undefined}
                            setOpenDeleteOptionModal={setOpenDeleteOptionModal}
                            deleteOptionModalFunc={(o: FilterBarOption) => (
                                <ItemSourceModal
                                    itemSource={{
                                        id: o.key,
                                        label: o.value,
                                    }}
                                    deleteMode
                                    onClose={() => setOpenDeleteOptionModal(false)}
                                />
                            )}
                        />
                    </FilterBar>

                    <Stack direction="column" flex={1}>
                        <TotalAndPageSizeOptions
                            countItems={list.length}
                            pageSizeOptions={[10, 20, 40]}
                            pageSize={pageSize}
                            changePageSize={changePageSize}
                            changePage={changePage}
                        >
                            {/* Search */}
                            <Stack spacing="1rem" direction="row" alignItems="center">
                                <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                    <strong>SEARCH:</strong>
                                </Typography>
                                <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                            </Stack>
                        </TotalAndPageSizeOptions>

                        <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                            <Box
                                sx={{
                                    ml: "1.9rem",
                                    mr: ".5rem",
                                    pr: "1.4rem",
                                    my: "1rem",
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>

                        {totalPages > 1 && (
                            <Box
                                sx={{
                                    mt: "auto",
                                    px: "1rem",
                                    py: ".7rem",
                                    backgroundColor: colors.background,
                                }}
                            >
                                <Pagination
                                    size="medium"
                                    count={totalPages}
                                    page={page}
                                    sx={{
                                        ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                        ".Mui-selected": {
                                            color: colors.primary,
                                            backgroundColor: `${colors.background} !important`,
                                        },
                                    }}
                                    onChange={(e, p) => changePage(p)}
                                />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            {selectedItemID && <ShopItemDetail itemID={selectedItemID} />}

            {openAddItemModal && <ShopItemModal onClose={() => setOpenAddItemModal(false)} />}
        </>
    )
}
