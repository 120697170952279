import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Box, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useLocation, useNavigate } from "react-router-dom"
import { useLanguages } from "../../../containers/Languages"
import { useInnerShop } from "../../../containers/inner_shop"
import { useItem } from "../../../containers/items"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks/useDebounce"
import { usePagination } from "../../../hooks/usePagination"
import { colors, fonts } from "../../../theme/theme"
import { Permission } from "../../../types/permissions"
import { Product } from "../../../types/products"
import { InputBox } from "../../common/InputBox"
import { PageHeader } from "../../common/pageHeader"
import { TotalAndPageSizeOptions } from "../../common/totalAndPageSizeOptions"
import ProductCard from "../../items/productCard"
import ProductDetail from "./productDetail"
import ProductModal from "./productModal"

export const ShopProducts = () => {
    const { products } = useItem()
    const { language } = useLanguages()
    const { shopID } = useInnerShop()
    const { hasPerm } = usePermissions()
    const [openAddModal, setOpenAddModal] = React.useState(false)

    const navigate = useNavigate()
    const { search } = useLocation()
    const selectedProductID = React.useMemo(() => {
        const searchParams = new URLSearchParams(search)
        return searchParams.get("product_id")
    }, [search])

    const [list, setList] = React.useState<Product[]>([])
    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...products].sort((a, b) => a.label.localeCompare(b.label))

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => `${s.label}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, products, searchValue, page, pageSize, setTotalItems, language])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((product) => {
                        return (
                            <div key={`product-${product.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ProductCard
                                    product={product}
                                    onClick={() => {
                                        navigate(`/inner_shop/${shopID}/products?product_id=${product.id}`)
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, shopID, navigate])

    if (!hasPerm(Permission.ShopProductView)) return null

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "80rem",
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>PRODUCT</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Create ",
                            onClick: () => setOpenAddModal(true),
                            icon: <AddCircleIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="column" flex={1}>
                    <TotalAndPageSizeOptions
                        countItems={list.length}
                        pageSizeOptions={[10, 20, 40]}
                        pageSize={pageSize}
                        changePageSize={changePageSize}
                        changePage={changePage}
                    >
                        {/* Search */}
                        <Stack spacing="1rem" direction="row" alignItems="center">
                            <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                <strong>SEARCH:</strong>
                            </Typography>
                            <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                        </Stack>
                    </TotalAndPageSizeOptions>

                    <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                        <Box
                            sx={{
                                ml: "1.9rem",
                                mr: ".5rem",
                                pr: "1.4rem",
                                my: "1rem",
                                flex: 1,
                                overflowY: "auto",
                                overflowX: "hidden",
                                direction: "ltr",
                            }}
                        >
                            {content}
                        </Box>
                    </Stack>

                    {totalPages > 1 && (
                        <Box
                            sx={{
                                mt: "auto",
                                px: "1rem",
                                py: ".7rem",
                                backgroundColor: colors.background,
                            }}
                        >
                            <Pagination
                                size="medium"
                                count={totalPages}
                                page={page}
                                sx={{
                                    ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                    ".Mui-selected": {
                                        color: colors.primary,
                                        backgroundColor: `${colors.background} !important`,
                                    },
                                }}
                                onChange={(e, p) => changePage(p)}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>

            {selectedProductID && <ProductDetail productID={selectedProductID} />}

            {openAddModal && <ProductModal onClose={() => setOpenAddModal(false)} />}
        </>
    )
}
