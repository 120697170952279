import { Stack, Typography } from "@mui/material"
import React from "react"
import { fonts } from "../../../../theme/theme"
import { TypographyTrans } from "../../../common/TypographyTrans"

interface ShopMemberDetailFieldProps {
    trans?: boolean
    label: string
    value: string
}
export const ShopMemberDetailField = ({ trans, label, value }: ShopMemberDetailFieldProps) => {
    const titleDisplay = React.useMemo(() => {
        if (trans)
            return (
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProRegular}>
                    {label}
                </TypographyTrans>
            )

        return (
            <Typography variant={"h5"} fontFamily={fonts.sourceSansProRegular}>
                {label}
            </Typography>
        )
    }, [trans, label])
    return (
        <Stack direction="row" alignItems="center" spacing="1rem" justifyContent={"space-between"}>
            {titleDisplay}
            <Typography variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                {value}
            </Typography>
        </Stack>
    )
}
