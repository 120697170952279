import React from "react"
import { Permission } from "../types/permissions"

export interface PermissionState {
    hasPerm: (permission: Permission) => boolean
    setOrgPermissions: React.Dispatch<React.SetStateAction<Permission[]>>
    setShopPermissions: React.Dispatch<React.SetStateAction<Permission[]>>
}

const initialState: PermissionState = {
    hasPerm: () => false,
    setOrgPermissions: () => {
        return
    },
    setShopPermissions: () => {
        return
    },
}

export const PermissionContext = React.createContext<PermissionState>(initialState)

export const PermissionProvider = ({ children }: { children: React.ReactNode }) => {
    const [orgPermissions, setOrgPermissions] = React.useState<Permission[]>([])
    const [shopPermissions, setShopPermissions] = React.useState<Permission[]>([])

    const permissions = React.useMemo(() => [...orgPermissions, ...shopPermissions], [orgPermissions, shopPermissions])
    const hasPerm = React.useCallback((permission: Permission) => permissions.includes(permission), [permissions])
    return (
        <PermissionContext.Provider
            value={{
                hasPerm,
                setOrgPermissions,
                setShopPermissions,
            }}
        >
            {children}
        </PermissionContext.Provider>
    )
}

export const usePermissions = () => {
    return React.useContext<PermissionState>(PermissionContext)
}
