import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { FaMoneyBillWaveAlt } from "react-icons/fa"
import { IoIosPricetag } from "react-icons/io"
import { PiPercentFill } from "react-icons/pi"
import { roundToDecimalPlaces } from "../../helpers"
import { colors } from "../../theme/theme"
import { Product } from "../../types/products"
import TooltipLabelValue from "../common/tooltipLabelValue"

interface Props {
    product: Product
    onClick?: () => void
    linkTo?: () => void
}

const ProductCard = ({ product, onClick, linkTo }: Props) => {
    const { label, price, cost } = product
    const profitRate = React.useMemo(() => {
        const priceNum = parseFloat(price)
        const costNum = parseFloat(cost)
        return (100 * (priceNum * 0.9 - costNum)) / costNum
    }, [price, cost])

    if (linkTo)
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: colors.primary,
                    textAlign: "start",
                    border: `${colors.primary}C0 2px solid`,
                    borderRadius: 0.6,
                    height: "6.5rem",
                    pl: ".8rem",
                }}
                onClick={linkTo}
            >
                <Stack direction="row" flex={1} alignItems={"center"}>
                    <Stack direction="row" flex={1} alignItems={"center"} justifyContent="space-between">
                        <Stack direction="column">
                            <Typography variant="h5" flex={1}>
                                <strong>{label}</strong>
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={"1rem"}>
                            <Stack direction={"row"} spacing={"1rem"} alignItems={"center"}>
                                <TooltipLabelValue
                                    label={<FaMoneyBillWaveAlt size={"2.5rem"} />}
                                    value={`$ ${roundToDecimalPlaces(cost)}`}
                                    tooltipText="Cost"
                                />
                                <TooltipLabelValue label={<IoIosPricetag size={"2.5rem"} />} value={`$ ${roundToDecimalPlaces(price)}`} tooltipText="Price" />
                                <TooltipLabelValue
                                    label={<PiPercentFill size={"2.5rem"} />}
                                    value={`${roundToDecimalPlaces(profitRate)}%`}
                                    tooltipText="Profit Rate"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            p: 0,
                            height: "100%",
                            backgroundColor: `${colors.primary}C0`,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <KeyboardDoubleArrowRightRoundedIcon sx={{ color: colors.offWhite, fontSize: "4rem" }} />
                    </Box>
                </Stack>
            </Box>
        )

    return (
        <Box
            sx={{
                color: colors.primary,
                textAlign: "start",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.6,
                minHeight: "8rem",
                p: ".8rem",
            }}
            onClick={onClick}
        >
            <Stack direction="column">
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" flex={1}>
                        <strong>{label}</strong>
                    </Typography>
                    <Stack direction={"row"} spacing={"1.5rem"} alignItems={"center"}>
                        <TooltipLabelValue label={<FaMoneyBillWaveAlt size={"2.5rem"} />} value={`$ ${roundToDecimalPlaces(cost)}`} tooltipText="Cost" />
                        <TooltipLabelValue label={<IoIosPricetag size={"2.5rem"} />} value={`$ ${roundToDecimalPlaces(price)}`} tooltipText="Price" />
                        <TooltipLabelValue label={<PiPercentFill size={"2.5rem"} />} value={`${roundToDecimalPlaces(profitRate)}%`} tooltipText="Profit Rate" />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}

export default ProductCard
