import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { IconButton, Stack } from "@mui/material"
import moment from "moment"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useOrganisation } from "../../../containers/organisations"
import { getPaySession } from "../../../helpers"
import { colors, fonts } from "../../../theme/theme"
import { SalaryPayCycle } from "../../../types/organisations"
import { DateFormat } from "../../../types/types"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ConfirmModal } from "../../common/confirmModal"

interface PayStaffModalProps {
    onClose: () => void
}
export const PayStaffModal = ({ onClose }: PayStaffModalProps) => {
    const { organisation } = useOrganisation()
    const [offset, setOffset] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
    const { startDate: initStartDate, endDate: initEndDate } = getPaySession(
        organisation ? moment(organisation.salary_pay_date) : moment(),
        organisation?.salary_pay_cycle || SalaryPayCycle.Weekly,
    )

    const startDate = React.useMemo(() => initStartDate.clone().add(1 * offset, "weeks"), [offset, initStartDate])
    const endDate = React.useMemo(() => initEndDate.clone().add(1 * offset, "weeks"), [offset, initEndDate])

    const navigate = useNavigate()

    const onConfirm = React.useCallback(() => {
        setTimeout(() => {
            setIsLoading(false)
            navigate(`/accountant/pay_staffs?start_from=${startDate.format("YYYY-MM-DD")}&end_at=${endDate.format("YYYY-MM-DD")}`)
        }, 1000)
        setIsLoading(true)
    }, [endDate, navigate, startDate])

    return (
        <ConfirmModal
            title={"Please set a payment period"}
            onClose={onClose}
            confirmLabel={"START"}
            onConfirm={onConfirm}
            isLoading={isLoading}
            disableConfirm={!startDate || !endDate}
        >
            <Stack spacing={"1rem"} sx={{ pb: "2rem" }}>
                <Stack direction={"column"} spacing={"0.5rem"}>
                    <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        Payment Cycle
                    </TypographyTrans>
                    <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProBoldIt} paddingLeft={"1rem"}>
                        {organisation?.salary_pay_cycle}
                    </TypographyTrans>
                </Stack>
                <Stack direction={"column"} spacing={"0.5rem"}>
                    <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        Date Range
                    </TypographyTrans>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <IconButton size="small" onClick={() => setOffset((prev) => prev - 1)} sx={{ p: 0 }}>
                            <ArrowLeftIcon sx={{ color: colors.primary, fontSize: "4rem" }} />
                        </IconButton>
                        <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                            {startDate.format(DateFormat.DateDay)} ~ {endDate.format(DateFormat.DateDay)}
                        </TypographyTrans>
                        <IconButton size="small" onClick={() => setOffset((prev) => prev + 1)} sx={{ p: 0 }}>
                            <ArrowRightIcon sx={{ color: colors.primary, fontSize: "4rem" }} />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
