import React from "react"

/** useDebounce
 * @param  {T} value
 * @param  {number} delay
 * @returns a debounced stateful value, a function to update it and a function to update it without delay.
 */
export function useDebounce<T>(value: T, delay: number): [T, React.Dispatch<React.SetStateAction<T>>, T, React.Dispatch<React.SetStateAction<T>>] {
    const [instant, setInstant] = React.useState<T>(value)
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value)
    const latestValue = React.useRef<T>(value)

    React.useEffect(() => {
        latestValue.current = instant
        const t = setTimeout(() => {
            setDebouncedValue(latestValue.current)
        }, delay)
        return () => clearTimeout(t)
    }, [delay, instant])

    React.useEffect(() => {
        latestValue.current = debouncedValue
        setInstant(latestValue.current)
    }, [debouncedValue])

    return [debouncedValue, setInstant, instant, setDebouncedValue]
}
