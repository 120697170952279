import { Stack, Typography } from "@mui/material"
import { colors, fonts } from "../../theme/theme"

interface Props {
    title: string
}
export const CalculatingCard = ({ title }: Props) => {
    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                p: "1rem",
                backgroundColor: colors.primary,
                width: "100%",
                height: "100%",
                borderRadius: 3,
            }}
        >
            <Typography variant="h4" fontWeight={"bolder"} color={colors.offWhite}>
                {title}
            </Typography>
            <Stack
                alignItems={"center"}
                sx={{
                    mb: "3rem",
                }}
                spacing={"2rem"}
            >
                <Typography sx={{ fontFamily: fonts.sourceSansProBold, fontSize: "2.5rem", color: colors.offWhite }}>Calculating...</Typography>
            </Stack>
        </Stack>
    )
}
