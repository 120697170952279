import { Stack, TextField, Typography } from "@mui/material"
import moment from "moment/moment"
import React from "react"
import { colors, fonts } from "../../theme/theme"

export const DateSelector = ({
    date,
    setDate,
    label,
    min,
    max,
}: {
    date: moment.Moment | null
    setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>
    label?: React.ReactNode
    min?: string
    max?: string
}) => {
    const title = React.useMemo(() => {
        if (!label) return null
        if (typeof label === "string")
            return (
                <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                    {label}
                </Typography>
            )

        return label
    }, [label])
    return (
        <Stack spacing={".5rem"}>
            {title}
            <TextField
                id="time"
                hiddenLabel
                type="date"
                fullWidth
                variant="outlined"
                defaultValue={date ? date.format("YYYY-MM-DD") : undefined}
                onChange={(e) => {
                    const time = e.currentTarget.value
                    if (!time) {
                        setDate(null)
                        return
                    }
                    setDate(moment(time, "YYYY-MM-DD"))
                }}
                inputProps={{
                    min,
                    max,
                }}
                sx={{
                    width: "100%",
                    ".MuiOutlinedInput-input": {
                        p: ".37rem 1.6rem",
                        pt: ".55rem",
                        fontSize: "2.2rem",
                        height: `4.3rem`,
                        fontFamily: fonts.sourceSansProBold,
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        appearance: "textfield",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: `${colors.primary} 2px solid`,
                        borderRadius: 0.8,
                    },
                }}
            />
        </Stack>
    )
}
