import React from "react"
import { InputBox } from "../../components/common/InputBox"
import { ConfirmModal } from "../../components/common/confirmModal"
import { useAuth } from "../../containers/auth"
import { colors } from "../../theme/theme"

interface PosLoginModalProps {
    onClose: () => void
}

export const PosLoginModal = ({ onClose }: PosLoginModalProps) => {
    const { onLoginPOS } = useAuth()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [passcode, setPasscode] = React.useState("")
    const posSystemLogin = React.useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await onLoginPOS(username, passcode)
            if (!resp) return
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to login POS system.")
        } finally {
            setIsLoading(false)
        }
    }, [onLoginPOS, username, passcode])
    return (
        <ConfirmModal title={"POS LOGIN"} onClose={onClose} onConfirm={posSystemLogin} confirmLabel="LOGIN" isLoading={isLoading} error={error} omitCancel>
            <InputBox
                label="Username"
                variant={"outlined"}
                value={username}
                setValue={setUsername}
                stackSx={{ flex: 1 }}
                sx={{
                    border: `${colors.primary}99 2px solid`,
                    borderRadius: 0,
                }}
                placeholder={"Enter username"}
            />
            <InputBox
                label="Passcode"
                variant={"outlined"}
                value={passcode}
                setValue={setPasscode}
                stackSx={{ flex: 1 }}
                sx={{
                    border: `${colors.primary}99 2px solid`,
                    borderRadius: 0,
                }}
                placeholder={"Enter passcode"}
            />
        </ConfirmModal>
    )
}
