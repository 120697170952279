import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { roundedTo } from "../../../helpers"
import { typographyMaxLength } from "../../../theme/sxProps"
import { colors } from "../../../theme/theme"
import { Item } from "../../../types/items"
import { InputBox } from "../../common/InputBox"
import { LabelValue } from "../../common/labelValue"
import { ImportItemSelectField } from "./importRecordCreate"

interface ImportedItemFieldCardProps {
    item: Item
    onDelete: () => void
    itemsSelectField: React.MutableRefObject<ImportItemSelectField[]>
    audToTwdRate: string
}
export const ImportedItemFieldCard = ({ item, onDelete, itemsSelectField, audToTwdRate }: ImportedItemFieldCardProps) => {
    const { label, import_label, item_carton_variant } = item

    const [aud, setAUD] = React.useState(itemsSelectField.current.find((is) => is.item_carton_variant_id === item_carton_variant?.id)?.cost_aud || "0")
    const [twd, setTwd] = React.useState(itemsSelectField.current.find((is) => is.item_carton_variant_id === item_carton_variant?.id)?.cost_twd || "0")
    const [cartonAmount, setCartonAmount] = React.useState(
        itemsSelectField.current.find((is) => is.item_carton_variant_id === item_carton_variant?.id)?.carton_amount || "0",
    )
    const totalCBM = React.useMemo(() => {
        if (!item_carton_variant?.cbm || isNaN(parseInt(cartonAmount)) || cartonAmount === "0") return "0"

        return roundedTo(parseFloat(item_carton_variant.cbm) * parseFloat(cartonAmount), 2)
    }, [cartonAmount, item_carton_variant?.cbm])

    React.useEffect(() => {
        const twdCost = parseFloat(twd)
        const audCost = parseFloat(aud)

        if (isNaN(twdCost) || twdCost === 0 || isNaN(audCost) || audCost === 0) return

        if (!isNaN(twdCost) && twdCost > 0) {
            const audValue = twdCost / parseFloat(audToTwdRate)

            if (!isNaN(audValue)) {
                const newAud = `${roundedTo(audValue, 2)}`

                setAUD(newAud)
                itemsSelectField.current = itemsSelectField.current.map((is) => {
                    if (is.item_carton_variant_id !== item_carton_variant?.id) return is
                    return { ...is, cost_aud: newAud }
                })
            }

            return
        }

        if (!isNaN(audCost) && audCost > 0) {
            const twdValue = audCost * parseFloat(audToTwdRate)
            if (!isNaN(twdValue)) {
                const newTwd = `${roundedTo(twdValue, 2)}`
                setTwd(newTwd)
                itemsSelectField.current = itemsSelectField.current.map((is) => {
                    if (is.item_carton_variant_id !== item_carton_variant?.id) return is
                    return { ...is, cost_twd: newTwd }
                })
            }

            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aud, audToTwdRate, item_carton_variant?.id, setAUD, setTwd, twd])

    return (
        <Stack
            sx={{
                p: ".5rem",
                border: `${colors.primary}99 2px solid`,
                borderRadius: 0.8,
                height: "100%",
            }}
        >
            <Stack direction="row" sx={{ height: "100%", position: "relative" }}>
                <Stack direction="column" sx={{ width: "25rem" }}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{
                            ...typographyMaxLength,
                        }}
                    >
                        {label}
                    </Typography>
                    <Typography
                        variant="body2"
                        color={`${colors.primary}CC`}
                        sx={{
                            ...typographyMaxLength,
                        }}
                    >
                        {import_label}
                    </Typography>
                </Stack>

                {/*<Divider sx={{ mx: "2rem !important", border: `${colors.primary}50 1px dashed` }} />*/}

                <Stack
                    direction="row"
                    alignItems="end"
                    flex={1}
                    spacing={4}
                    sx={{
                        height: "100%",
                    }}
                >
                    {item_carton_variant && (
                        <>
                            <LabelValue
                                label={
                                    <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                        W
                                    </Typography>
                                }
                                value={<Typography variant="h6">{item_carton_variant.carton_width_cm} cm</Typography>}
                                spacing={1}
                            />
                            <LabelValue
                                label={
                                    <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                        D
                                    </Typography>
                                }
                                value={<Typography variant="h6">{item_carton_variant.carton_depth_cm} cm</Typography>}
                                spacing={1}
                            />
                            <LabelValue
                                label={
                                    <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                        H
                                    </Typography>
                                }
                                value={<Typography variant="h6">{item_carton_variant.carton_height_cm} cm</Typography>}
                                spacing={1}
                            />
                            <LabelValue
                                label={
                                    <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                        CBM
                                    </Typography>
                                }
                                value={<Typography variant="h6">{roundedTo(item_carton_variant.cbm, 5)}</Typography>}
                                spacing={1}
                            />
                        </>
                    )}
                </Stack>

                <IconButton
                    size="small"
                    sx={{
                        position: "absolute",
                        right: -3,
                        top: -3,
                    }}
                    onClick={onDelete}
                >
                    <DeleteForeverIcon fontSize="large" sx={{ color: colors.lightRed, fontSize: "2.5rem" }} />
                </IconButton>
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    py: ".5rem",
                    px: "1rem",
                }}
            >
                <Stack flex={1} direction="row" alignItems="center">
                    <InputBox
                        label="Carton:"
                        variant="standard"
                        value={cartonAmount}
                        setValue={setCartonAmount}
                        stackSx={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "20rem",
                        }}
                        onChange={(e) => {
                            itemsSelectField.current = itemsSelectField.current.map((is) => {
                                if (is.item_carton_variant_id !== item_carton_variant?.id) return is
                                return { ...is, carton_amount: e.target.value }
                            })
                        }}
                    />

                    <InputBox
                        label="TWD:"
                        variant="standard"
                        value={twd}
                        setValue={setTwd}
                        stackSx={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "20rem",
                        }}
                        onChange={(e) => {
                            const aud = `${roundedTo(parseFloat(e.target.value) / parseFloat(audToTwdRate), 2)}`
                            setAUD(aud)
                            itemsSelectField.current = itemsSelectField.current.map((is) => {
                                if (is.item_carton_variant_id !== item_carton_variant?.id) return is
                                return { ...is, cost_twd: e.target.value, cost_aud: aud }
                            })
                        }}
                    />
                    <InputBox
                        label="AUD:"
                        variant="standard"
                        value={aud}
                        setValue={setAUD}
                        stackSx={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "20rem",
                        }}
                        onChange={(e) => {
                            const twd = `${roundedTo(parseFloat(e.target.value) * parseFloat(audToTwdRate), 2)}`

                            setTwd(twd)
                            itemsSelectField.current = itemsSelectField.current.map((is) => {
                                if (is.item_carton_variant_id !== item_carton_variant?.id) return is
                                return { ...is, cost_aud: e.target.value, cost_twd: twd }
                            })
                        }}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1} sx={{ width: "20rem" }}>
                    <Typography variant="h6" fontWeight="bold" color={`${colors.primary}CC`}>
                        Total CBM:
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                        {totalCBM}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}
