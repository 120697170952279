import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { dateFormatter, DateFormatType, roundedTo } from "../../helpers"
import { useImportRecordPrice } from "../../hooks/useImportRecord"
import { colors } from "../../theme/theme"
import { ImportRecord } from "../../types/importRecords"
import { CurrencyLabel, DisplayCurrencyAmount } from "../common/currency/currency"

interface ImportRecordCardProps {
    importRecord: ImportRecord
}

const propsAreEqual = (prevProps: ImportRecordCardProps, nextProps: ImportRecordCardProps) => {
    return (
        prevProps.importRecord.id === nextProps.importRecord.id &&
        prevProps.importRecord.order_placed_at === nextProps.importRecord.order_placed_at &&
        prevProps.importRecord.cbm === nextProps.importRecord.cbm &&
        prevProps.importRecord.cost_twd === nextProps.importRecord.cost_twd
    )
}

export const ImportRecordCard = React.memo(function ImportRecordCard({ importRecord }: ImportRecordCardProps) {
    const { order_placed_at, aud_to_twd_rate, cbm, total_item_amount } = importRecord
    const { totalCostTWD, totalCostAUD } = useImportRecordPrice({ importRecord })

    return (
        <Box
            sx={{
                color: colors.primary,
                textAlign: "start",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.6,
                py: ".8rem",
                px: "1.5rem",
            }}
        >
            <Stack direction="column">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        minHeight: "3rem",
                    }}
                >
                    <Typography variant="h5">
                        DATE:
                        <strong style={{ marginLeft: "1rem" }}>
                            {order_placed_at ? dateFormatter(order_placed_at, DateFormatType.DateMonthYear) : "EMPTY"}
                        </strong>
                    </Typography>

                    <Stack direction="row" alignItems="center">
                        <Typography fontWeight="bolder">AUD</Typography>
                        <SwapHorizIcon
                            fontSize="large"
                            sx={{
                                mx: ".5rem",
                            }}
                        />
                        <Typography fontWeight="bolder">{`TWD : ${aud_to_twd_rate}`}</Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        minHeight: "3rem",
                    }}
                >
                    <Typography variant="h5">
                        CBM:
                        <strong style={{ marginLeft: "1rem" }}>{roundedTo(cbm)}</strong>
                    </Typography>
                    <Typography variant="h5">
                        Item Amount:
                        <strong style={{ marginLeft: "1rem" }}>{total_item_amount}</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="h5" sx={{ mr: "1rem" }}>{`Total Cost (${CurrencyLabel()}):`}</Typography>
                        <DisplayCurrencyAmount variant="h5" fontWeight={"bolder"} twd={totalCostTWD} aud={totalCostAUD} />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}, propsAreEqual)
