import { Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { secondsToDisplayTime } from "../../helpers"
import { useInterval } from "../../hooks"
import { fonts } from "../../theme/theme"

interface CountUpTimerProps {
    startedAt: Date
    sx?: SxProps
}

export const CountUpTimer = ({ startedAt, sx }: CountUpTimerProps) => {
    const [timeDisplay, setTimeDisplay] = React.useState("--:--:--")

    // count up second
    useInterval(() => setTimeDisplay(() => secondsToDisplayTime(Math.floor((new Date().getTime() - startedAt.getTime()) / 1000))), 1000)

    return <Typography sx={{ fontFamily: fonts.sourceSansProBold, fontSize: "2.5rem", ...sx }}>{timeDisplay}</Typography>
}
