import TrendingFlatIcon from "@mui/icons-material/TrendingFlat"
import { Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { useForm } from "react-hook-form"
import { usePermissions } from "../../../containers/permissions"
import { roundedTo } from "../../../helpers"
import { useServerCommandsOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { fadeInAndOutEffect } from "../../../theme/keyframes"
import { colors, fonts } from "../../../theme/theme"
import { ImportRecord, PalletType } from "../../../types/importRecords"
import { Permission } from "../../../types/permissions"
import { ConfirmModal } from "../../common/confirmModal"
import { FeeCurrencyFields } from "../../common/currency/feeCurrencyFields"
import { DateField } from "../../common/form/dateField"
import { InputField } from "../../common/form/inputField"
import { SelectField } from "../../common/form/selectField"

interface ImportRecordDetailUpdateModalProps {
    importRecord: ImportRecord
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ImportRecordUpdateForm {
    shipping_fee_twd: string
    shipping_fee_aud: string
    custom_fee_twd: string
    custom_fee_aud: string

    order_placed_at: Date | null
    payment_transfer_at: Date | null
    ship_arrived_at: Date | null
    ship_left_at: Date | null

    pallet_count: string
    pallet_type: string
    pallet_cost_twd: string
    pallet_cost_aud: string

    surcharge_twd: string
    surcharge_aud: string

    cargo_packing_fee_twd: string
    cargo_packing_fee_aud: string

    item_extra_charge_percentage: string
    aud_to_twd_rate: string
}

export interface ImportRecordRequest {
    id: string
    shipping_fee_twd: string
    shipping_fee_aud: string
    custom_fee_twd: string
    custom_fee_aud: string

    order_placed_at?: Date
    payment_transfer_at?: Date
    ship_arrived_at?: Date
    ship_left_at?: Date

    pallet_count: number
    pallet_type: string
    pallet_cost_twd: string
    pallet_cost_aud: string

    surcharge_twd: string
    surcharge_aud: string

    cargo_packing_fee_twd: string
    cargo_packing_fee_aud: string

    item_extra_charge_percentage: number
    aud_to_twd_rate: number

    imported_items: {
        item_id: string
        item_carton_variant_id: string
        carton_amount: number
        cost_twd: string
        cost_aud: string
    }[]
}

export const ImportRecordDetailUpdateModal = ({ setOpen, importRecord }: ImportRecordDetailUpdateModalProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsOrg()

    const [showSuccess, setShowSuccess] = React.useState(false)
    const { control, watch, setValue, getValues, trigger } = useForm<ImportRecordUpdateForm>({
        defaultValues: {
            shipping_fee_twd: importRecord.shipping_fee_twd,
            shipping_fee_aud: importRecord.shipping_fee_aud,
            custom_fee_twd: importRecord.custom_fee_twd,
            custom_fee_aud: importRecord.custom_fee_aud,

            order_placed_at: importRecord.order_placed_at || null,
            payment_transfer_at: importRecord.payment_transfer_at || null,
            ship_left_at: importRecord.ship_left_at || null,
            ship_arrived_at: importRecord.ship_arrived_at || null,

            pallet_count: importRecord.pallet_count,
            pallet_type: importRecord.pallet_type,
            pallet_cost_twd: importRecord.pallet_cost_twd,
            pallet_cost_aud: importRecord.pallet_cost_aud,
            surcharge_twd: importRecord.surcharge_twd,
            surcharge_aud: importRecord.surcharge_aud,
            cargo_packing_fee_twd: importRecord.cargo_packing_fee_twd,
            cargo_packing_fee_aud: importRecord.cargo_packing_fee_aud,
            item_extra_charge_percentage: importRecord.item_extra_charge_percentage,
            aud_to_twd_rate: importRecord.aud_to_twd_rate,
        },
    })
    const audToTwdRate = watch("aud_to_twd_rate")

    React.useEffect(() => {
        const formData = getValues()
        setValue("shipping_fee_aud", `${roundedTo(parseFloat(formData.shipping_fee_twd) / parseFloat(audToTwdRate))}`)
        setValue("custom_fee_aud", `${roundedTo(parseFloat(formData.custom_fee_twd) / parseFloat(audToTwdRate))}`)
        setValue("pallet_cost_aud", `${roundedTo(parseFloat(formData.pallet_cost_twd) / parseFloat(audToTwdRate))}`)
        setValue("surcharge_aud", `${roundedTo(parseFloat(formData.surcharge_twd) / parseFloat(audToTwdRate))}`)
        setValue("cargo_packing_fee_aud", `${roundedTo(parseFloat(formData.cargo_packing_fee_twd) / parseFloat(audToTwdRate))}`)
    }, [audToTwdRate, getValues, setValue])

    const onSubmit = React.useCallback(async () => {
        if (!hasPerm(Permission.ImportRecordUpdate)) return

        // valid
        if (!(await trigger())) return

        const data: ImportRecordUpdateForm = getValues()

        try {
            await send<null, ImportRecordRequest>(WSKeys.WSKeyImportRecordUpdate, {
                ...data,
                id: importRecord.id,
                order_placed_at: moment(data.order_placed_at).isValid() ? moment(data.order_placed_at).toDate() : undefined,
                payment_transfer_at: moment(data.payment_transfer_at).isValid() ? moment(data.payment_transfer_at).toDate() : undefined,
                ship_arrived_at: moment(data.ship_arrived_at).isValid() ? moment(data.ship_arrived_at).toDate() : undefined,
                ship_left_at: moment(data.ship_left_at).isValid() ? moment(data.ship_left_at).toDate() : undefined,
                pallet_count: !isNaN(parseInt(data.pallet_count)) ? parseInt(data.pallet_count) : 0,
                item_extra_charge_percentage: !isNaN(parseFloat(data.item_extra_charge_percentage)) ? parseFloat(data.item_extra_charge_percentage) : 0,
                aud_to_twd_rate: !isNaN(parseFloat(data.aud_to_twd_rate)) ? parseFloat(data.aud_to_twd_rate) : 0,
                imported_items: [],
            })
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
                setOpen(false)
            }, 2000)
        } catch (e) {
            console.log(e)
        }
    }, [getValues, hasPerm, importRecord.id, send, setOpen, trigger])

    return (
        <ConfirmModal
            title={
                <Stack direction="row" alignItems="center" sx={{ height: "3.5rem" }}>
                    <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                        Update Import Record
                    </Typography>
                    {showSuccess && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: "1rem",
                                px: "1rem",
                                borderRadius: 2,
                                backgroundColor: colors.green,
                                animation: `${fadeInAndOutEffect} 2s`,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: fonts.sourceSansProBlack,
                                    fontWeight: "bold",
                                    color: colors.offWhite,
                                }}
                            >
                                SUCCESS
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            }
            onConfirm={onSubmit}
            confirmLabel={"SAVE"}
            omitCancel
            confirmColor={colors.primary}
            onClose={() => setOpen(false)}
            width="110rem"
        >
            <form style={{ height: "70rem" }}>
                <Stack direction="row" spacing={4}>
                    <Stack direction="column" flex={1} spacing={1}>
                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Fee:
                        </Typography>
                        <Stack direction="column" sx={{ px: "1rem" }}>
                            <FeeCurrencyFields
                                title="Shipping Fee"
                                control={control}
                                audToTwdRate={audToTwdRate}
                                twdName="shipping_fee_twd"
                                audName="shipping_fee_aud"
                                setValue={setValue}
                            />
                            <FeeCurrencyFields
                                title="Custom fee"
                                control={control}
                                audToTwdRate={audToTwdRate}
                                twdName="custom_fee_twd"
                                audName="custom_fee_aud"
                                setValue={setValue}
                            />
                            <FeeCurrencyFields
                                title="Surcharge"
                                control={control}
                                audToTwdRate={audToTwdRate}
                                twdName="surcharge_twd"
                                audName="surcharge_aud"
                                setValue={setValue}
                            />
                            <FeeCurrencyFields
                                title="Cargo Packing fee"
                                control={control}
                                audToTwdRate={audToTwdRate}
                                twdName="cargo_packing_fee_twd"
                                audName="cargo_packing_fee_aud"
                                setValue={setValue}
                            />
                        </Stack>

                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Pallet:
                        </Typography>
                        <Stack direction="column" spacing={1} sx={{ px: "1rem" }}>
                            <InputField label="Count" name="pallet_count" variant="outlined" control={control} type="number" />
                            <SelectField
                                title="Type"
                                name="pallet_type"
                                control={control}
                                options={[
                                    { id: PalletType.Wooden, label: PalletType.Wooden },
                                    { id: PalletType.Plastic, label: PalletType.Plastic },
                                ]}
                            />
                            <FeeCurrencyFields
                                title="Cost"
                                control={control}
                                audToTwdRate={audToTwdRate}
                                twdName="pallet_cost_twd"
                                audName="pallet_cost_aud"
                                setValue={setValue}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1} sx={{ width: "40rem" }}>
                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Time:
                        </Typography>
                        <Stack direction="column" spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                            <DateField label="Order At:" control={control} name="order_placed_at" />
                            <DateField label="Payment transfer:" control={control} name="payment_transfer_at" />
                            <DateField label="Ship left:" control={control} name="ship_left_at" />
                            <DateField label="Ship Arrive:" control={control} name="ship_arrived_at" />
                        </Stack>

                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            General:
                        </Typography>
                        <Stack direction="column" spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                            <InputField
                                label={
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h6" fontWeight="bold">
                                            AUD
                                        </Typography>
                                        <TrendingFlatIcon fontSize="large" fontWeight="bold" sx={{ color: colors.primary }} />
                                        <Typography variant="h6" fontWeight="bold">
                                            TWD
                                        </Typography>
                                    </Stack>
                                }
                                name="aud_to_twd_rate"
                                variant="outlined"
                                control={control}
                                type="number"
                            />
                            <InputField label="Extra charge (%)" name="item_extra_charge_percentage" variant="outlined" control={control} type="number" />
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </ConfirmModal>
    )
}
