import { Stack, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { useInnerShop } from "../../containers/inner_shop"
import { colors } from "../../theme/theme"

export const Logo = React.memo(function Logo() {
    const { shopName, shopID } = useInnerShop()
    const redirectURL = React.useMemo(() => {
        if (!shopID) return "/"
        return `/inner_shop/${shopID}`
    }, [shopID])
    return (
        <Stack direction="row" alignItems="center" spacing="1.3rem" sx={{ zIndex: 1, height: "100%", pl: "1.2rem", pr: "2.2rem" }}>
            <Link to={redirectURL}>
                <Typography variant="h5" color={colors.secondary}>
                    <strong>{shopName || "Store Mate"}</strong>
                </Typography>
            </Link>
        </Stack>
    )
})
