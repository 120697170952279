import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { colors, fonts } from "../../../theme/theme"
import { filterOptions, SelectBoxOption } from "../autocompleteBox"
import { TypographyTrans } from "../TypographyTrans"

interface SelectFieldProps {
    control: Control<any, object>
    rules?: any
    name: string

    title?: React.ReactNode
    options: SelectBoxOption[]
    onChange?: (value: SelectBoxOption | string) => void
    trans?: boolean
}

export const SelectField = ({ control, rules, name, options, title, onChange, trans }: SelectFieldProps) => {
    const inputLabel = React.useMemo(() => {
        if (!title) return null
        if (typeof title === "string") {
            if (trans)
                return (
                    <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProIt}>
                        {title}
                    </TypographyTrans>
                )
            return (
                <Typography variant="h5" fontFamily={fonts.sourceSansProIt}>
                    {title}
                </Typography>
            )
        }
        return title
    }, [title, trans])
    return (
        <Stack>
            {inputLabel}
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue=""
                render={({ field, fieldState }) => (
                    <Autocomplete
                        id="combo-box-demo"
                        freeSolo
                        disableClearable
                        fullWidth
                        options={options}
                        onChange={(e: any, value: SelectBoxOption | string) => {
                            field.onChange(typeof value === "string" ? value : value.id)
                            if (onChange) onChange(value)
                        }}
                        value={options.find((o) => o.id === field.value) || ""}
                        filterOptions={filterOptions}
                        sx={{
                            "&& .MuiInputBase-hiddenLabel": {
                                p: 0,
                                backgroundColor: "unset",
                            },
                            "&& .MuiOutlinedInput-root": {
                                p: 0,
                                backgroundColor: "unset",
                            },
                            "&& .MuiAutocomplete-input": {
                                height: "4rem",
                                px: "1.5rem",
                                py: "0",
                            },
                        }}
                        componentsProps={{
                            paper: {
                                sx: {
                                    backgroundColor: colors.background,
                                    borderRadius: 0.5,
                                },
                            },
                            popper: {
                                sx: {
                                    borderBottom: `${colors.primary}DD 2px solid`,
                                    borderLeft: `${colors.primary}DD 2px solid`,
                                    borderRight: `${colors.primary}DD 2px solid`,
                                    borderRadius: 0.5,
                                },
                            },
                        }}
                        noOptionsText={
                            <Typography sx={{ opacity: 0.6 }}>
                                <i>No options</i>
                            </Typography>
                        }
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Typography variant="h5" fontFamily={fonts.sourceSansProBold}>
                                    {option.label}
                                </Typography>
                                {option.description && (
                                    <Typography variant="h5" sx={{ ml: "1rem" }}>
                                        {option.description}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                hiddenLabel
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : "invalid"}
                                sx={{
                                    ".MuiOutlinedInput-root": {
                                        p: 0,
                                    },
                                    ".MuiOutlinedInput-input": {
                                        height: "unset",
                                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                                            WebkitAppearance: "none",
                                        },
                                        fontSize: "2.2rem",
                                        fontFamily: fonts.sourceSansProBold,
                                        borderRadius: 0.5,
                                        border: `${colors.primary}50 2px solid`,
                                        ":hover, :focus, :active": { border: `${colors.primary}99 2px solid` },
                                    },
                                    ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                                    ".MuiFormHelperText-root": {
                                        display: "none",
                                    },
                                }}
                            />
                        )}
                    />
                )}
            />
        </Stack>
    )
}
