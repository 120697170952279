import { Stack, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"

interface LabelValueProps {
    label: JSX.Element | string | number
    value: JSX.Element | string | number

    sx?: SxProps
    spacing?: number
    isVertical?: boolean
    alignItems?: string
}

export const LabelValue = ({ label, value, isVertical, sx, spacing, alignItems }: LabelValueProps) => {
    const displayLabel = React.useMemo(() => {
        if (typeof label === "string" || typeof label === "number") {
            return (
                <Typography variant="h5" fontWeight="bolder">
                    {label}
                </Typography>
            )
        }
        return label
    }, [label])

    const displayValue = React.useMemo(() => {
        if (typeof value === "string" || typeof value === "number") {
            return <Typography variant="h6">{value}</Typography>
        }
        return value
    }, [value])
    return (
        <Stack
            direction={isVertical ? "column" : "row"}
            alignItems={alignItems || "center"}
            justifyContent={"space-between"}
            spacing={spacing !== undefined ? spacing : 1}
            sx={{
                ...sx,
            }}
        >
            {displayLabel}
            {displayValue}
        </Stack>
    )
}
