import { Stack } from "@mui/material"
import { useInnerShop } from "../../../../containers/inner_shop"
import { colors, fonts } from "../../../../theme/theme"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { PageHeader } from "../../../common/pageHeader"
import { ShopDetailPanel } from "./shopDetailPanel"
import { ShopDevice } from "./shopDevice"
import { ShopPosPanel } from "./shopPosPanel"

export const ShopDetail = () => {
    const { shop } = useInnerShop()

    if (!shop) return null

    return (
        <Stack
            direction={"column"}
            flex={1}
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
            }}
        >
            <PageHeader
                title={
                    <TypographyTrans variant="h5" sx={{ fontWeight: "bolder", fontFamily: fonts.sourceSansProBlack }}>
                        SHOP SETTINGS
                    </TypographyTrans>
                }
            />
            <Stack direction={"row"} flex={1} sx={{ height: "100%", width: "100%", px: "2rem", py: "1rem" }}>
                <Stack sx={{ width: "60rem" }} spacing={"2.5rem"}>
                    <ShopDetailPanel shop={shop} />
                    <ShopPosPanel shop={shop} />
                </Stack>
                <Stack sx={{ width: "60rem" }}>
                    <ShopDevice shop={shop} />
                </Stack>
            </Stack>
        </Stack>
    )
}
