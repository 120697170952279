import { MenuItem, Select, SelectProps, Typography } from "@mui/material"
import { colors, fonts } from "../../theme/theme"

interface SelectBoxProps {
    options: { label: string; value: string | number }[]
    fontSize?: string
    fontFamily?: string
}
export const SelectBox = ({ options, sx, fontSize, fontFamily, ...props }: SelectBoxProps & SelectProps) => {
    return (
        <Select
            sx={{
                width: "100%",
                borderRadius: 0.5,
                border: `${colors.primary} 2px solid`,
                height: "4rem",
                ".MuiTypography-root": {
                    px: "1rem",
                    py: ".5rem",
                },
                "& .MuiSelect-outlined": {
                    px: ".8rem",
                    pt: ".2rem",
                    pb: 0,
                    pr: "0 !important",
                },
                ".MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                },
                ...sx,
            }}
            MenuProps={{
                variant: "menu",
                sx: {
                    "&& .Mui-selected": {
                        ".MuiTypography-root": {
                            color: colors.secondary,
                        },
                        backgroundColor: colors.primary,
                        "& .hover": {
                            backgroundColor: colors.primary,
                        },
                    },
                    zIndex: 1000,
                },
                PaperProps: {
                    sx: {
                        backgroundColor: colors.background,
                        borderRadius: 0.5,
                        border: `${colors.primary} 1px solid`,
                    },
                },
            }}
            {...props}
        >
            {options.map((x, i) => {
                return (
                    <MenuItem
                        key={`${x.value}${i}`}
                        value={x.value}
                        sx={{
                            "& .hover": {
                                color: "black",
                            },
                        }}
                    >
                        <Typography variant={"h6"} sx={{ fontFamily: fontFamily || fonts.sourceSansProSemibold, fontSize }}>
                            {x.label}
                        </Typography>
                    </MenuItem>
                )
            })}
        </Select>
    )
}
