// Capitalize convert a string "example" to "Example"

import moment, { Moment } from "moment"
import { EmploymentStatus } from "../key"
import {
    LeaveType,
    ShopMember,
    UserOrdinaryPyament,
    UserShopLeaveRequest,
    UserShopSpecialPayslipRequirement,
    UserShopWorkRecord,
    UserShopYTDPayment,
} from "../types/Shop"
import { SalaryPayCycle, SalaryPayoutSessionType, TaxRateType } from "../types/organisations"

export const Capitalize = (str: string): string => str[0].toUpperCase() + str.substring(1).toLowerCase()

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const isObject = (item: any) => {
    return item && typeof item === "object" && !Array.isArray(item)
}

export const truncate = (str: string, n: number): string => {
    return str.length > n ? str.substring(0, n - 1) + "..." : str
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const areEqual = <T,>(a: T | undefined, b: T | undefined): boolean => a === b || (a === undefined && b === undefined)

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const mergeDeep = (target: any, ...sources: any): any => {
    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }

    return mergeDeep(target, ...sources)
}

export const shadeColor = (hexColor: string, factor: number) => {
    let R = parseInt(hexColor.substring(1, 3), 16)
    let G = parseInt(hexColor.substring(3, 5), 16)
    let B = parseInt(hexColor.substring(5, 7), 16)

    R = parseInt((R * (100 + factor)) / 100 + "")
    G = parseInt((G * (100 + factor)) / 100 + "")
    B = parseInt((B * (100 + factor)) / 100 + "")

    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255

    const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16)
    const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16)
    const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16)

    return "#" + RR + GG + BB
}

export const getRandomArbitrary = (min: number, max: number): number => {
    return Math.random() * (max - min) + min
}

export const numFormatter = (num: number) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M"
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K"
    }
    return num + ""
}

export const parseString = (val: string | null, defaultVal: number): number => {
    if (!val) return defaultVal

    return parseFloat(val)
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getObjectFromArrayByKey = (array: any[], idValue: string, idName = "id") => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][idName] === idValue) {
            return array[i]
        }
    }
    return undefined
}

export const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return d
}

// Converts numeric degrees to radians
export const deg2rad = (deg: number): number => {
    return deg * (Math.PI / 180)
}

// Converts numeric degrees to radians
export const rad2Deg = (rad: number): number => {
    return rad * (180 / Math.PI)
}

export const clamp = (min: number, value: number, max: number) => {
    return Math.min(Math.max(value, min), max)
}

export function acronym(s: string): string {
    const x = s.match(/\b(\w)/g)
    if (!x) {
        return ""
    }
    return x.join("").toUpperCase()
}

export const hexToRGB = (hexx: string) => {
    const hex = hexx.toUpperCase()

    const h = "0123456789ABCDEF"
    const r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2])
    const g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4])
    const b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6])
    return { r, g, b }
}

export enum DateFormatType {
    DateMonthYear = "DATE_MONTH_YEAR",
    HourMinuteSecond = "HOUR_MINUTE_SECOND",
}

export const dateFormatter = (date: Date, dateFormatType: DateFormatType): string => {
    let hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    // Check whether AM or PM
    const suffix = hours >= 12 ? "PM" : "AM"

    // Find current hour in AM-PM Format
    hours = hours % 12

    // To display "0" as "12"
    hours = hours ? hours : 12
    const minutes2 = minutes < 10 ? "0" + minutes : minutes
    const seconds2 = seconds < 10 ? "0" + seconds : seconds

    switch (dateFormatType) {
        case DateFormatType.HourMinuteSecond:
            return `${hours}:${minutes2}:${seconds2} ${suffix}`

        case DateFormatType.DateMonthYear:
            return `${day}/${month}/${year}`
    }
}

export const snakeToTitle = (str: string, lowerCase?: boolean): string => {
    if (!str.length) return ""

    if (lowerCase) return str.split("_").join(" ")

    return str.split("_").map(Capitalize).join(" ")
}

export const snakeToSlug = (str: string): string => {
    return str.split("_").join("-").toLowerCase()
}

// Calculates the difference between two dates in different units (days, hours etc.)
export const timeDiff = (
    fromDate: Date,
    toDate: Date,
): {
    total: number
    days: number
    hours: number
    minutes: number
    seconds: number
} => {
    const total = toDate.getTime() - fromDate.getTime()
    const seconds = Math.floor(total / 1000)
    const minutes = Math.floor(total / 1000 / 60)
    const hours = Math.floor(total / (1000 * 60 * 60))
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    }
}

// Calculates the time difference between two dates in days, hours minutes etc.
export const timeSince = (
    fromDate: Date,
    toDate: Date,
): {
    totalSeconds: number
    days: number
    hours: number
    minutes: number
    seconds: number
} => {
    const total = toDate.getTime() - fromDate.getTime()
    const totalSeconds = total / 1000
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
        totalSeconds,
        days,
        hours,
        minutes,
        seconds,
    }
}

export const timeSinceInWords = (fromDate: Date, toDate: Date, abbreviated = false): string => {
    const { days, hours, minutes, seconds } = timeSince(fromDate, toDate)

    let result = days > 0 ? days + (abbreviated ? "d" : " day") + (days === 1 || abbreviated ? "" : "s") : ""
    result = (result ? result + " " : "") + (hours > 0 ? hours + (abbreviated ? "h" : " hour") + (hours === 1 || abbreviated ? "" : "s") : "")

    // Return result if more than a day, else too long
    if (days > 0) return result

    result = (result ? result + " " : "") + (minutes > 0 ? minutes + (abbreviated ? "m" : " minute") + (minutes === 1 || abbreviated ? "" : "s") : "")

    // Return result if more than a day, else too long
    if (hours > 0) return result

    result = (result ? result + " " : "") + (seconds > 0 ? seconds + (abbreviated ? "s" : " second") + (seconds === 1 || abbreviated ? "" : "s") : "")
    return result
}

export const camelToTitle = (str: string) => {
    const result = str.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
}

// Returns a random chat color for non faction users
export const getRandomColor = () => {
    let color = "#"
    for (let i = 0; i < 3; i++) color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2)
    return color
}

export const numberCommaFormatter = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const calculateDutchAuctionEndPrice = ({ endAt, dropRate, startPrice }: { endAt: Date; dropRate?: number; startPrice: number }) => {
    if (!dropRate) return startPrice
    return Math.max(startPrice - dropRate * timeDiff(new Date(), endAt).minutes, 1)
}

// Converts number to alphabet letter. E.g. 0 -> "a"
export const intToLetter = (i: number) => String.fromCharCode(97 + i)

export const roundedTo = (val: string | number, decimalPoint: number | undefined = 0) => {
    const value = typeof val === "number" ? val : parseFloat(val)
    const scale = Math.pow(10, decimalPoint)
    return Math.round(value * scale) / scale
}

export const autoTextColor = (hex: string) => {
    const rgb = hexToRGB(hex)

    if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 150) {
        return "#000000"
    } else {
        return "#FFFFFF"
    }
}

const prefillZero = (n: number) => (n >= 0 && n < 10 ? `0${n}` : `${n}`)

export const secondsToDisplayTime = (totalSeconds: number, omitSecond?: boolean) => {
    if (totalSeconds <= 0) return "00:00:00"
    const hours = prefillZero(Math.floor(totalSeconds / 3600))
    const minutes = prefillZero(Math.floor((totalSeconds % 3600) / 60))
    if (omitSecond) {
        return `${hours}:${minutes}`
    }

    const seconds = prefillZero(totalSeconds % 60)

    return `${hours}:${minutes}:${seconds}`
}

export const secondsToDisplayDuration = (totalSeconds: number, omitSecond?: boolean) => {
    if (totalSeconds <= 0) return "00:00:00"
    const hours = prefillZero(Math.floor(totalSeconds / 3600))
    const minutes = prefillZero(Math.floor((totalSeconds % 3600) / 60))
    if (omitSecond) {
        return `${hours}h ${minutes}m`
    }

    const seconds = prefillZero(totalSeconds % 60)

    return `${hours}h ${minutes}m ${seconds}s`
}

export const combineDateAndTime = (date: Moment, time: Moment) =>
    moment({ year: date.year(), month: date.month(), day: date.date(), hour: time.hour(), minute: time.minute(), second: 0, millisecond: 0 })

// closedXDay return the same date day of next week
export const closedXDay = (day: number) => moment().startOf("isoWeek").isoWeekday(day)

export const getPaySession = (firstPayDate: Moment, payCycle: SalaryPayCycle): { startDate: moment.Moment; endDate: moment.Moment } => {
    const now = moment()
    const payDayOfWeek = moment(firstPayDate).day()
    const currentDayOfWeek = now.day()

    let startDate = moment()
    let endDate = moment()

    switch (payCycle) {
        case SalaryPayCycle.Monthly:
        case SalaryPayCycle.Fortnightly:
        case SalaryPayCycle.Weekly:
            if (currentDayOfWeek <= payDayOfWeek) {
                startDate = moment().day(payDayOfWeek).subtract(6, "days").startOf("day")
                endDate = moment().day(payDayOfWeek).endOf("day").startOf("second")
            } else {
                startDate = moment().day(payDayOfWeek).add(1, "day").startOf("day")
                endDate = moment().day(payDayOfWeek).add(1, "week").endOf("day").startOf("second")
            }
            break
    }

    return { startDate, endDate }
}

export const getFinancialYearDateRange = (date: moment.Moment): { startDate: moment.Moment; endDate: moment.Moment } => {
    const year = date.year()
    const july1 = moment([year, 6, 1])
    const june30 = moment([year + 1, 5, 30])

    if (date.isBefore(july1)) {
        july1.year(year - 1)
        june30.year(year)
    }

    return {
        startDate: july1,
        endDate: june30,
    }
}

export const getSalaryPayoutSessionDateRange = (
    firstSalaryPayDate: moment.Moment,
    offset: number,
    SessionType: SalaryPayoutSessionType,
): { startDate: moment.Moment; endDate: moment.Moment } => {
    const now = moment()
    const payDayOfWeek = firstSalaryPayDate.day()
    const currentDayOfWeek = now.day()

    // get first day of the month
    const firstDayOfMonth = now
        .clone()
        .month(now.month() + offset)
        .startOf("month")

    // get date in quarter
    const dateInQuarter = now.clone().month(now.month() + offset * 3)

    switch (SessionType) {
        case SalaryPayoutSessionType.Week:
            if (currentDayOfWeek <= payDayOfWeek) {
                return {
                    startDate: moment().day(payDayOfWeek).subtract(6, "days").startOf("day").add(offset, "weeks"),
                    endDate: moment().day(payDayOfWeek).endOf("day").add(offset, "weeks"),
                }
            }

            return {
                startDate: moment().day(payDayOfWeek).add(1, "day").startOf("day").add(offset, "weeks"),
                endDate: moment().day(payDayOfWeek).add(1, "week").endOf("day").add(offset, "weeks"),
            }

        case SalaryPayoutSessionType.Month:
            return {
                startDate: firstDayOfMonth,
                endDate: firstDayOfMonth.clone().endOf("month"),
            }
        case SalaryPayoutSessionType.Quarter:
            return {
                startDate: dateInQuarter.clone().startOf("quarter"),
                endDate: dateInQuarter.clone().endOf("quarter"),
            }

        case SalaryPayoutSessionType.Year:
            return getFinancialYearDateRange(now.clone().year(now.year() + offset))
    }
}

interface salaryResult {
    weekdayPayRate: number
    ordinaryHours: number
    weekdayHours: number
    saturdayHours: number
    sundayHours: number
    ordinaryHoursPay: number
    weekdayPay: number
    saturdayPay: number
    sundayPay: number
    totalEarnings: number
    netPay: number
    tax: number
    superannuation: number

    annualLeavePay: number
    annualLeaveAccrued: number
    annualLeaveUsed: number
    annualLeaveBalance: number

    personalCareLeavePay: number
    personalCareLeaveAccrued: number
    personalCareLeaveUsed: number
    personalCareLeaveBalance: number

    timeInLieuPay: number
    timeInLieuUsed: number
    timeInLieuLeaveBalance: number
}
export const salaryCalculator = (
    shopMember: ShopMember,
    workRecords: UserShopWorkRecord[],
    ordinaryPayments: UserOrdinaryPyament[],
    leaveRequest: UserShopLeaveRequest[],
    paymentCycle: SalaryPayCycle,
    taxRateType?: TaxRateType,
    superannuationRate?: string,
    userShopSpecialPayslipRequirement?: UserShopSpecialPayslipRequirement,
): salaryResult => {
    const shopMemberLeave = shopMember.user_shop_leave
    let ordinaryHours = 0
    let weekdayHours = 0
    let saturdayHours = 0
    let sundayHours = 0
    let ordinaryHoursPay = 0
    let weekdayPay = 0
    let saturdayPay = 0
    let sundayPay = 0
    let annualLeavePay = 0
    let personalCareLeavePay = 0
    let timeInLieuPay = 0
    let totalEarnings = 0
    let annualLeaveAccrued = 0
    let annualLeaveUsed = 0
    let personalCareLeaveAccrued = 0
    let personalCareLeaveUsed = 0
    let timeInLieuUsed = 0
    let annualLeaveBalance = roundedTo(shopMemberLeave?.annual_leave_hours || 0, 4)
    let personalCareLeaveBalance = roundedTo(shopMemberLeave?.personal_care_leave_hours || 0, 4)
    let timeInLieuLeaveBalance = roundedTo(shopMemberLeave?.time_in_lieu_hours || 0, 4)
    const superannuationRateNum = superannuationRate ? parseFloat(superannuationRate) : 0.105

    const sundayPayRate = roundedTo(shopMember.sunday_pay, 2)
    const saturdayPayRate = roundedTo(shopMember.saturday_pay, 2)
    const weekdayPayRate = roundedTo(shopMember.weekday_pay, 2)
    let totalWorkSeconds = 0
    workRecords.forEach((wr) => {
        let payment = 0
        totalWorkSeconds += wr.total_seconds
        switch (wr.started_at.getDay()) {
            case 0:
                sundayHours += wr.total_seconds / 3600
                payment = (wr.total_seconds * sundayPayRate) / 3600
                sundayPay += payment
                break
            case 6:
                saturdayHours += wr.total_seconds / 3600
                payment = (wr.total_seconds * saturdayPayRate) / 3600
                saturdayPay += payment
                break
            default:
                weekdayHours += wr.total_seconds / 3600
                payment = (wr.total_seconds * weekdayPayRate) / 3600
                weekdayPay += payment
                break
        }
        totalEarnings += payment
    })

    ordinaryPayments.forEach((op) => {
        const totalHours = parseInt(op.total_hours)
        ordinaryHours += totalHours
        ordinaryHoursPay += totalHours * weekdayPayRate
    })

    ordinaryHoursPay = roundedTo(ordinaryHoursPay, 0)
    totalEarnings += ordinaryHoursPay

    leaveRequest.forEach((lr) => {
        const leaveHours = roundedTo(lr.hours)
        const payment = leaveHours * weekdayPayRate
        switch (lr.leave_type) {
            case LeaveType.Annual:
                annualLeavePay += payment
                annualLeaveUsed += leaveHours
                annualLeaveBalance -= leaveHours
                break
            case LeaveType.PersonalCare:
                personalCareLeavePay += payment
                personalCareLeaveUsed += leaveHours
                personalCareLeaveBalance -= leaveHours
                break
            case LeaveType.TimeInLieu:
                timeInLieuPay += payment
                timeInLieuUsed += leaveHours
                timeInLieuLeaveBalance -= leaveHours
                break
        }
        totalEarnings += payment
    })

    // calculate annual leave get
    if ([EmploymentStatus.FullTime, EmploymentStatus.PartTime].includes(shopMember.employment_status)) {
        const maxClaimableHours = Math.min(38, totalWorkSeconds / 3600)

        // calculate accrued annual leave
        annualLeaveAccrued = maxClaimableHours * (4.0 / 52.0)

        annualLeaveBalance += annualLeaveAccrued

        // calculate accrued personal care leave
        personalCareLeaveAccrued = maxClaimableHours * (2.0 / 52.0)

        personalCareLeaveBalance += personalCareLeaveAccrued
    }
    const tax = taxCalculator(totalEarnings, paymentCycle, taxRateType)

    return {
        weekdayPayRate,
        ordinaryHours: roundedTo(ordinaryHours, 2),
        weekdayHours: roundedTo(weekdayHours, 2),
        saturdayHours: roundedTo(saturdayHours, 2),
        sundayHours: roundedTo(sundayHours, 2),
        ordinaryHoursPay: roundedTo(ordinaryHoursPay, 2),
        weekdayPay: roundedTo(weekdayPay, 2),
        saturdayPay: roundedTo(saturdayPay, 2),
        sundayPay: roundedTo(sundayPay, 2),
        totalEarnings: roundedTo(totalEarnings),
        netPay: roundedTo(totalEarnings - tax),
        tax: roundedTo(tax),
        superannuation: roundedTo(totalEarnings * superannuationRateNum),
        annualLeavePay: roundedTo(annualLeavePay),
        personalCareLeavePay: roundedTo(personalCareLeavePay),
        timeInLieuPay: roundedTo(timeInLieuPay),
        annualLeaveAccrued: roundedTo(annualLeaveAccrued, 4),
        annualLeaveUsed: roundedTo(annualLeaveUsed, 4),
        personalCareLeaveAccrued: roundedTo(personalCareLeaveAccrued, 4),
        personalCareLeaveUsed: roundedTo(personalCareLeaveUsed, 4),
        timeInLieuUsed: roundedTo(timeInLieuUsed, 4),
        annualLeaveBalance: roundedTo(annualLeaveBalance, 4),
        personalCareLeaveBalance: roundedTo(personalCareLeaveBalance, 4),
        timeInLieuLeaveBalance: roundedTo(timeInLieuLeaveBalance, 4),
    }
}

export const taxCalculator = (totalEarnings: number, paymentCycle: SalaryPayCycle, taxRateType?: TaxRateType): number => {
    if (!taxRateType) return 0

    let fraction = 1
    switch (paymentCycle) {
        case SalaryPayCycle.Monthly:
        case SalaryPayCycle.Fortnightly:
        case SalaryPayCycle.Weekly:
            fraction = 52
    }

    let tax = 0
    let lastThreshold = 0
    taxRateType.tax_rules.forEach((tr) => {
        if (totalEarnings <= lastThreshold || lastThreshold < 0) return

        const threshold = Math.floor(tr.tax_threshold / fraction)

        let maxAmount = threshold
        if (totalEarnings < maxAmount || threshold < 0) maxAmount = totalEarnings

        const taxableAmount = maxAmount - lastThreshold

        tax += taxableAmount * tr.tax_rate

        lastThreshold = threshold
    })

    return roundedTo(tax)
}

export const roundToDecimalPlaces = (num: number | string, decimalPoint: number | undefined = 2): string => {
    const roundedString = roundedTo(num, decimalPoint).toFixed(decimalPoint)
    const parts = roundedString.split(".")
    if (parts.length === 1) {
        return roundedString + ".00"
    }
    if (parts[1].length === 1) {
        return roundedString + "0"
    }
    return roundedString
}

interface UserShopYTDPaymentResult {
    weekdayPayYTD: number
    saturdayPayYTD: number
    sundayPayYTD: number
    holidayPayYTD: number
    totalEarningsYTD: number
    taxYTD: number
    superannuationYTD: number
}

export const salaryYTDCalculator = (userShopYTDPayment?: UserShopYTDPayment): UserShopYTDPaymentResult => {
    const result: UserShopYTDPaymentResult = {
        weekdayPayYTD: 0,
        saturdayPayYTD: 0,
        sundayPayYTD: 0,
        holidayPayYTD: 0,
        totalEarningsYTD: 0,
        taxYTD: 0,
        superannuationYTD: 0,
    }

    if (!userShopYTDPayment) return result

    result.weekdayPayYTD = roundedTo(userShopYTDPayment.weekday_pay)
    result.saturdayPayYTD = roundedTo(userShopYTDPayment.saturday_pay)
    result.sundayPayYTD = roundedTo(userShopYTDPayment.sunday_pay)
    result.holidayPayYTD = roundedTo(userShopYTDPayment.holiday_pay)
    result.totalEarningsYTD = result.weekdayPayYTD + result.saturdayPayYTD + result.sundayPayYTD + result.holidayPayYTD
    result.taxYTD = roundedTo(userShopYTDPayment.tax)
    result.superannuationYTD = roundedTo(userShopYTDPayment.superannuation)

    return result
}

export const calCostRatio = (fraction: number | string, base: number | string, unitType: string): string => {
    let fractionNum = typeof fraction === "string" ? parseFloat(fraction) : fraction
    const baseNum = typeof base === "string" ? parseFloat(base) : base
    let displayedUnitType = unitType.toLowerCase()

    if (isNaN(fractionNum) || isNaN(baseNum) || baseNum === 0) return "N/A"

    switch (displayedUnitType) {
        case "ml":
            fractionNum *= 1000
            displayedUnitType = "l"
            break

        case "g":
            fractionNum *= 1000
            displayedUnitType = "kg"
            break
    }

    return `$ ${roundToDecimalPlaces(fractionNum / baseNum)} /${displayedUnitType}`
}

export const calProfitRate = (cost: number | string, price: number | string): string => {
    const priceNum = typeof price === "string" ? parseFloat(price) : price
    const costNum = typeof cost === "string" ? parseFloat(cost) : cost
    return roundToDecimalPlaces((100 * (priceNum * 0.9 - costNum)) / costNum)
}

export const calProfit = (cost: number | string, price: number | string): string => {
    const priceNum = typeof price === "string" ? parseFloat(price) : price
    const costNum = typeof cost === "string" ? parseFloat(cost) : cost
    return roundToDecimalPlaces(priceNum * 0.9 - costNum)
}

export const calComponentCost = (
    baseFraction: number | string,
    baseVolume: number | string,
    baseUnitType: string,
    targetVolume: number | string,
    targetUnitType: string,
): number => {
    const baseFractionNumber = typeof baseFraction === "string" ? parseFloat(baseFraction) : baseFraction
    const baseVolumeNumber = typeof baseVolume === "string" ? parseFloat(baseVolume) : baseVolume
    const baseUnitTypeLower = baseUnitType.toLowerCase()
    let targetVolumeNumber = typeof targetVolume === "string" ? parseFloat(targetVolume) : targetVolume
    const targetUnitTypeLower = targetUnitType.toLowerCase()

    if (isNaN(baseFractionNumber) || isNaN(baseVolumeNumber) || isNaN(targetVolumeNumber)) return NaN

    // calculate the base cost per unit
    const baseCostPerUnit = baseFractionNumber / baseVolumeNumber

    // convert the base cost per unit to the target unit type
    switch (baseUnitTypeLower) {
        case "ml":
            if (targetUnitTypeLower === "l") targetVolumeNumber *= 1000
            break
        case "l":
            if (targetUnitTypeLower === "ml") targetVolumeNumber /= 1000
            break
        case "g":
            if (targetUnitTypeLower === "kg") targetVolumeNumber *= 1000
            break
        case "kg":
            if (targetUnitTypeLower === "g") targetVolumeNumber /= 1000
            break
    }

    // calculate the target cost
    return baseCostPerUnit * targetVolumeNumber
}

export const priceCalculator = ({
    cost,
    profitRate,
    price,
}: {
    cost: string | number
    profitRate?: string | number
    price?: string | number
}): { profitRate: number; price: number } => {
    if (!profitRate && !price) {
        return {
            profitRate: 0,
            price: 0,
        }
    }

    const costNum = typeof cost === "string" ? parseFloat(cost) : cost
    let profitRateNum = typeof profitRate === "string" ? parseFloat(profitRate) : profitRate
    let priceNum = typeof price === "string" ? parseFloat(price) : price

    // calculate through profit rate
    if (profitRateNum) {
        // get cost and profit rate calculate price
        priceNum = ((profitRateNum / 100) * costNum + costNum) / 0.9
    } else if (priceNum) {
        // get cost and price calculate profit rate
        profitRateNum = (0.9 * priceNum - costNum) / costNum
        profitRateNum = profitRateNum * 100
    }

    return {
        price: priceNum || 0,
        profitRate: profitRateNum || 0,
    }
}

export const getVolumeRatio = (baseVolume: number | string, baseUnitType: string, targetVolume: number | string, targetUnitType: string): number => {
    const baseVolumeNum = typeof baseVolume === "string" ? parseFloat(baseVolume) : baseVolume
    let targetVolumeNum = typeof targetVolume === "string" ? parseFloat(targetVolume) : targetVolume

    // convert the base cost per unit to the target unit type
    switch (baseUnitType.toLowerCase()) {
        case "ml":
            if (targetUnitType.toLowerCase() === "l") targetVolumeNum *= 1000
            break
        case "l":
            if (targetUnitType.toLowerCase() === "ml") targetVolumeNum /= 1000
            break
        case "g":
            if (targetUnitType.toLowerCase() === "kg") targetVolumeNum *= 1000
            break
        case "kg":
            if (targetUnitType.toLowerCase() === "g") targetVolumeNum /= 1000
            break
    }

    return targetVolumeNum / baseVolumeNum
}

export const getConvertedVolumeUnitType = (volume: number | string, unitType: string): string => {
    let volumeNum = typeof volume === "string" ? parseFloat(volume) : volume
    let unitTypeLower = unitType.toLowerCase()

    switch (unitTypeLower) {
        case "g":
            if (volumeNum >= 1000) {
                volumeNum = roundedTo(volumeNum / 1000, 3)
                unitTypeLower = "kg"
            }
            break
        case "kg":
            if (volumeNum < 1) {
                volumeNum = roundedTo(volumeNum * 1000)
                unitTypeLower = "g"
            }
            break
        case "ml":
            if (volumeNum >= 1000) {
                volumeNum = roundedTo(volumeNum / 1000, 3)
                unitTypeLower = "l"
            }
            break
        case "l":
            if (volumeNum < 1) {
                volumeNum = roundedTo(volumeNum * 1000)
                unitTypeLower = "ml"
            }
            break
        default:
            volumeNum = roundedTo(volumeNum)
            break
    }

    return `${volumeNum} ${unitTypeLower}`
}
