import { Box, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { BsFiletypeXlsx } from "react-icons/bs"
import { IoSendSharp } from "react-icons/io5"
import { SERVER_HOST } from "../../../constants"
import { getConvertedVolumeUnitType, getVolumeRatio, roundedTo } from "../../../helpers"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ItemType } from "../../../types/items"
import { User } from "../../../types/users"
import { InputBox } from "../../common/InputBox"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ConfirmModal } from "../../common/confirmModal"
import IconLabelButton from "../../common/iconLabelButton"
import { SelectBox } from "../../common/selectBox"
import { ProductComponent } from "./componentSelectionList"

interface ExportItem {
    id: string
    label: string

    cost: string

    volume?: string
    unit_type?: string

    price?: string
}

interface Props {
    shopID: string
    itemType: string
    exportItem: ExportItem
    components: ProductComponent[]
    onClose: () => void
}

const RecipeModal = ({ shopID, itemType, exportItem, components, onClose }: Props) => {
    const { id, label, cost, volume, unit_type, price } = exportItem
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [exportVolume, setExportVolume] = React.useState(volume ? parseFloat(volume) : 1)
    const [exportUnitType, setExportUnitType] = React.useState(unit_type || "")
    const [selectedLineUserIDs, setSelectedLineUserIDs] = React.useState<string[]>([])
    const { send } = useServerCommandsShop()
    const labelValue = React.useCallback((label: string, value: string | number) => {
        return (
            <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                <Typography variant="h5" sx={{ color: colors.text }}>
                    {label}:
                </Typography>
                <Typography variant="h5" fontWeight={"bold"}>
                    {value}
                </Typography>
            </Stack>
        )
    }, [])

    const [lineUsers, setLineUsers] = React.useState<User[]>([])
    useServerSubscriptionShop<User[]>(
        {
            URI: "/line_users",
            key: WSKeys.WSKeyShopLineUserList,
        },
        {
            callback: (payLoad) => {
                if (!payLoad) return
                setLineUsers(payLoad.sort((a, b) => a.prefer_name.localeCompare(b.prefer_name)))
            },
        },
    )

    const userCard = React.useCallback((lineUser: User, highlighted: boolean) => {
        const { id, prefer_name } = lineUser
        return (
            <div key={`user-card-${id}`} style={{ marginBottom: ".5rem" }}>
                <Box
                    key={id}
                    onClick={() => {
                        setSelectedLineUserIDs((prev) => {
                            if (prev.includes(id)) {
                                return prev.filter((v) => v !== id)
                            }
                            return [...prev, id]
                        })
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-between"}
                        sx={{
                            p: ".5rem",
                            border: `${colors.primary}40 1px solid`,
                            backgroundColor: highlighted ? `${colors.primary}60` : "transparent",
                        }}
                    >
                        <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                            {prefer_name}
                        </Typography>
                    </Stack>
                </Box>
            </div>
        )
    }, [])

    const lineUserContent = React.useMemo(() => {
        if (lineUsers.length === 0) return <Typography variant="h5">No LINE user</Typography>

        return (
            <Stack
                direction="column"
                spacing={".5rem"}
                sx={{
                    height: "30rem",
                    overflowY: "auto",
                    px: ".5rem",
                }}
            >
                <Box sx={{ direction: "ltr", height: 0 }}>
                    {/*@ts-ignore*/}
                    <FlipMove>
                        {lineUsers.filter((lu) => selectedLineUserIDs.includes(lu.id)).map((lineUser) => userCard(lineUser, true))}
                        {lineUsers.filter((lu) => !selectedLineUserIDs.includes(lu.id)).map((lineUser) => userCard(lineUser, false))}
                    </FlipMove>
                </Box>
            </Stack>
        )
    }, [lineUsers, selectedLineUserIDs, userCard])

    const exportVolumeRatio = React.useMemo(() => {
        if (unit_type && volume && exportUnitType) {
            return getVolumeRatio(volume, unit_type, exportVolume, exportUnitType)
        }
        return exportVolume
    }, [unit_type, volume, exportVolume, exportUnitType])

    const content = React.useMemo(() => {
        return (
            <Stack direction="column" spacing={".5rem"}>
                {components.map((component) => {
                    const { id, label, setVolume, setUnitType } = component

                    return (
                        <Stack key={id} direction="row" alignItems="center" justifyContent={"space-between"}>
                            <Typography variant="h5">{label}</Typography>
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                {getConvertedVolumeUnitType(parseFloat(setVolume) * exportVolumeRatio, setUnitType)}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
        )
    }, [components, exportVolumeRatio])

    const unitTypeOptions = React.useMemo(() => {
        if (!unit_type) return []
        switch (unit_type.toLowerCase()) {
            case "g":
            case "kg":
                return [
                    { value: "g", label: "G" },
                    { value: "kg", label: "KG" },
                ]
            case "ml":
            case "l":
                return [
                    { value: "ml", label: "ML" },
                    { value: "l", label: "L" },
                ]
            default:
                return [{ value: unit_type.toLowerCase(), label: unit_type }]
        }
    }, [unit_type])

    const downloadExcel = React.useCallback(() => {
        setIsLoading(true)
        fetch(`${window.location.protocol}//${SERVER_HOST}/api/files/recipe`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopID,
                root_item_id: id,
                item_type: itemType,
                volume: exportVolume,
                unit_type: exportUnitType,
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    response.json().then(console.log)
                    return
                }

                response
                    .blob()
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement("a")
                        a.href = url
                        a.download = `${label}_recipe.xlsx`
                        a.click()
                    })
                    .catch((e) => {
                        setError(typeof e === "string" ? e : e.message)
                    })
            })
            .catch((e) => {
                console.log(e)
                setError(typeof e === "string" ? e : e.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [exportUnitType, exportVolume, id, itemType, label, shopID, setError])

    const lineMessagingRecipe = React.useCallback(() => {
        if (lineUsers.length === 0) return

        setIsLoading(true)

        try {
            const resp = send(WSKeys.WSKeyLineMessagingRecipe, {
                to_user_ids: selectedLineUserIDs,
                root_item_id: id,
                item_type: itemType,
                volume: exportVolume,
                unit_type: exportUnitType,
            })
            if (!resp) return
            setSelectedLineUserIDs([])
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to send recipe. Please try again later.")
        } finally {
            setIsLoading(false)
        }
    }, [send, selectedLineUserIDs, id, itemType, exportVolume, exportUnitType, lineUsers])

    return (
        <ConfirmModal
            title={
                <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                    <TypographyTrans variant="h4" sx={{ lineHeight: 1, fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                        Export Recipe
                    </TypographyTrans>
                    <IconLabelButton
                        icon={<BsFiletypeXlsx style={{ fontSize: "2.25rem", fontWeight: "bolder", color: colors.offWhite }} />}
                        label={"Download Excel"}
                        sx={{ fontWeight: "bold", color: colors.offWhite }}
                        buttonSx={{
                            border: `${colors.primary}60 2px solid`,
                            backgroundColor: `${colors.primary}AA`,
                        }}
                        onClick={downloadExcel}
                    />
                </Stack>
            }
            onClose={onClose}
            omitButton
            width="95rem"
            isLoading={isLoading}
            error={error}
        >
            <Stack direction={"row"} justifyContent="space-between" sx={{ minHeight: "40rem" }}>
                {/* exported item details*/}
                <Stack direction="column" flex={1}>
                    {/* Title */}
                    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h5" fontWeight="bold" color={`${colors.primary}80`}>
                            {label}
                        </Typography>
                        <Stack direction="row" alignItems={"center"}>
                            {itemType === ItemType.PRODUCT && (
                                <Typography variant="h6" color={`${colors.primary}80`} fontFamily={fonts.sourceSansProSemibold}>
                                    Amount:
                                </Typography>
                            )}

                            <InputBox
                                value={`${exportVolume}`}
                                setValue={(v) => {
                                    setExportVolume(parseFloat(v))
                                }}
                                type="number"
                                placeholder=" "
                                stackSx={{
                                    width: "60px",
                                }}
                                innerSx={{
                                    fontSize: "2rem",
                                    border: 0,
                                }}
                            />

                            {unitTypeOptions.length > 0 && (
                                <SelectBox
                                    options={unitTypeOptions}
                                    value={exportUnitType}
                                    sx={{
                                        border: `${colors.primary}80 1px solid`,
                                        borderBottom: 0,
                                        "& .MuiSelect-outlined": {
                                            pt: ".2rem",
                                            pb: 0,
                                            px: "0 !important",
                                            width: "40px",
                                        },
                                    }}
                                    onChange={(e) => {
                                        const v = e.target.value
                                        if (typeof v !== "string") return
                                        setExportUnitType(v)
                                    }}
                                    placeholder=" "
                                />
                            )}
                        </Stack>
                    </Stack>
                    <Divider sx={{ mb: ".5rem", border: `${colors.primary}40 1px solid` }} />

                    {/* Item detail*/}
                    <Stack direction="column">
                        {price && labelValue("Price", `$ ${roundedTo(parseFloat(price) * exportVolumeRatio, 2)}`)}
                        {labelValue("Cost", `$ ${roundedTo(parseFloat(cost) * exportVolumeRatio, 2)}`)}
                    </Stack>

                    {/* components */}
                    <Stack direction={"column"} sx={{ mt: "2rem" }}>
                        <Typography variant="h4" sx={{ fontFamily: fonts.sourceSansProSemibold, color: `${colors.primary}80` }}>
                            Ingredients:
                        </Typography>
                        <Divider sx={{ mb: ".5rem", border: `${colors.primary}40 1px dashed` }} />
                        {content}
                    </Stack>
                </Stack>

                <Divider sx={{ mx: "2rem" }} />

                {/* export methods */}
                <Stack direction="column" width={"40rem"}>
                    <Typography variant="h5" fontWeight="bold" color={`${colors.primary}80`}>
                        LINE Messaging
                    </Typography>
                    <Divider sx={{ my: ".5rem", border: `${colors.primary}40 1px solid` }} />
                    {lineUserContent}

                    {lineUsers.length > 0 && (
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: "2rem" }}>
                            <IconLabelButton
                                icon={<IoSendSharp style={{ fontSize: "2.25rem", fontWeight: "bolder", color: colors.offWhite }} />}
                                label={"Send"}
                                sx={{ fontWeight: "bold", color: colors.offWhite }}
                                buttonSx={{
                                    border: `${colors.primary}60 2px solid`,
                                    backgroundColor: `${colors.primary}AA`,
                                    flexDirection: "row-reverse",
                                    opacity: selectedLineUserIDs.length === 0 ? 0.5 : 1,
                                    cursor: selectedLineUserIDs.length === 0 ? "not-allowed" : "pointer",
                                }}
                                onClick={lineMessagingRecipe}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}

export default RecipeModal
