import LogoutIcon from "@mui/icons-material/Logout"
import { Avatar, Button, Popover, Stack, Typography } from "@mui/material"
import React from "react"
import { useAuth } from "../../../containers/auth"
import { colors, fonts, siteZIndex } from "../../../theme/theme"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ShopButton } from "../../common/shopButton"

interface PopoverFunc {
    label: string
    icon: JSX.Element
    onClick: () => void
    trans?: boolean
}

export const ProfileCard = () => {
    const { user, onLogoutClick } = useAuth()
    const { prefer_name } = user

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const popoverFuncs = React.useMemo<PopoverFunc[]>(() => {
        return [
            {
                label: "Logout",
                icon: <LogoutIcon fontSize="large" sx={{ color: colors.primary }} />,
                onClick: onLogoutClick,
            },
        ]
    }, [onLogoutClick])

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing=".96rem"
            sx={{
                mx: "1.2rem",
                height: "100%",
                cursor: "pointer",
                ":hover p": { opacity: 0.7 },
                overflowX: "auto",
                overflowY: "hidden",
            }}
        >
            <Button onClick={handleClick}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing=".96rem"
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <Avatar
                        src={""}
                        alt={`${prefer_name}'s Avatar`}
                        sx={{
                            height: "2.6rem",
                            width: "2.6rem",
                            borderRadius: 0.8,
                            border: `${colors.green} 2px solid`,
                            backgroundColor: colors.green,
                        }}
                        variant="square"
                    />

                    <Typography
                        variant="body1"
                        sx={{
                            mt: ".29rem !important",
                            lineHeight: 1,
                            fontFamily: fonts.sourceSansProBlack,
                            color: colors.secondary,
                            display: "-webkit-box",
                            overflow: "hidden",
                            overflowWrap: "anywhere",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                        }}
                    >
                        <strong>{prefer_name}</strong>
                    </Typography>
                </Stack>
            </Button>

            <Popover
                id={"PageHeader-popover-modal"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    zIndex: siteZIndex.Popover,
                    ".MuiPaper-root": {
                        background: colors.background,
                    },
                }}
            >
                <Stack
                    direction="column"
                    sx={{
                        minWidth: "10rem",
                        p: ".5rem",
                    }}
                >
                    {popoverFuncs.map((pf) => (
                        <ShopButton
                            key={`PageHeader-option-${pf.label}`}
                            id={pf.label}
                            onClick={(e) => {
                                e.preventDefault()

                                pf.onClick()
                                handleClose()
                            }}
                        >
                            <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                {pf.icon}

                                {pf.trans ? (
                                    <TypographyTrans variant="body1" fontWeight="bolder" ml="1rem">
                                        {pf.label}
                                    </TypographyTrans>
                                ) : (
                                    <Typography variant="body1" fontWeight="bolder" ml="1rem">
                                        {pf.label}
                                    </Typography>
                                )}
                            </Stack>
                        </ShopButton>
                    ))}
                </Stack>
            </Popover>
        </Stack>
    )
}
