import CategoryIcon from "@mui/icons-material/Category"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import { Box, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useItem } from "../../containers/items"
import { useDebounce } from "../../hooks"
import { usePagination } from "../../hooks/usePagination"
import { colors, fonts } from "../../theme/theme"
import { Item } from "../../types/items"
import { InputBox } from "../common/InputBox"
import { FilterBar } from "../common/filterBar"
import { FilterOption } from "../common/filterOption"
import { PageHeader } from "../common/pageHeader"
import { TotalAndPageSizeOptions } from "../common/totalAndPageSizeOptions"
import { ItemCard } from "./itemCard"

export const ItemLists = () => {
    const { items, manufacturers } = useItem()
    const [list, setList] = React.useState<Item[]>([])

    // filter
    const [selectedManufacturerKeys, setSelectedManufacturerKeys] = React.useState<string[]>([])

    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...items]

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => s.label.toLowerCase().includes(searchValue.toLowerCase()))
        }

        if (selectedManufacturerKeys.length > 0) {
            sorted = sorted.filter((s) => !!s.manufacturer_id && selectedManufacturerKeys.includes(s.manufacturer_id))
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, items, searchValue, page, pageSize, setTotalItems, selectedManufacturerKeys])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((item) => {
                        return (
                            <div key={`item-${item.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ItemCard item={item} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list])

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "70rem",
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>ITEM</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Update Category",
                            onClick: () => console.log("test click"),
                            icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                        {
                            label: "Move Items",
                            onClick: () => console.log("test click"),
                            icon: <SwapHorizIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="row" flex={1}>
                    <FilterBar
                        resetFilter={() => {
                            setSelectedManufacturerKeys([])
                        }}
                    >
                        <FilterOption
                            title="Manufacturer"
                            options={manufacturers.map((c) => ({ key: c.id, value: c.label_ch }))}
                            selectedKeys={selectedManufacturerKeys}
                            setSelectedKeys={setSelectedManufacturerKeys}
                        />
                    </FilterBar>

                    <Stack direction="column" flex={1}>
                        <TotalAndPageSizeOptions
                            countItems={list.length}
                            pageSizeOptions={[10, 20, 40]}
                            pageSize={pageSize}
                            changePageSize={changePageSize}
                            changePage={changePage}
                        >
                            {/* Search */}
                            <Stack spacing="1rem" direction="row" alignItems="center">
                                <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                    <strong>SEARCH:</strong>
                                </Typography>
                                <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                            </Stack>
                        </TotalAndPageSizeOptions>

                        <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                            <Box
                                sx={{
                                    ml: "1.9rem",
                                    mr: ".5rem",
                                    pr: "1.4rem",
                                    my: "1rem",
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>

                        {totalPages > 1 && (
                            <Box
                                sx={{
                                    mt: "auto",
                                    px: "1rem",
                                    py: ".7rem",
                                    backgroundColor: colors.background,
                                }}
                            >
                                <Pagination
                                    size="medium"
                                    count={totalPages}
                                    page={page}
                                    sx={{
                                        ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                        ".Mui-selected": {
                                            color: colors.primary,
                                            backgroundColor: `${colors.background} !important`,
                                        },
                                    }}
                                    onChange={(e, p) => changePage(p)}
                                />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <Stack flex={1}></Stack>
        </>
    )
}
