import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { FaMoneyBillWaveAlt, FaTimes } from "react-icons/fa"
import { RiScales2Fill } from "react-icons/ri"
import { calComponentCost, calCostRatio, roundToDecimalPlaces } from "../../helpers"
import { colors } from "../../theme/theme"
import TooltipLabelValue from "../common/tooltipLabelValue"

interface commonItemType {
    id: string
    label: string
    volume: string
    unit_type: string
    cost: string
}

interface ItemProps {
    item: commonItemType
    currentVolume?: string
    currentUnitType?: string
    onClick?: () => void
    linkTo?: () => void
}

export const ItemCard = ({ item, onClick, linkTo, currentUnitType, currentVolume }: ItemProps) => {
    const { label, volume, unit_type, cost } = item

    const unitCost = React.useMemo(() => calCostRatio(cost, volume, unit_type), [volume, unit_type, cost])

    const unitCostElement = React.useMemo(() => {
        if (!currentUnitType || !currentVolume) {
            return (
                <Stack direction={"row"}>
                    <Typography variant="h5">
                        <strong>{unitCost}</strong>
                    </Typography>
                </Stack>
            )
        }

        return (
            <Stack direction={"row"} spacing={"1rem"}>
                <TooltipLabelValue
                    label={<RiScales2Fill size={"2.5rem"} />}
                    value={`${currentVolume} ${currentUnitType}`}
                    tooltipText="Volume"
                    minWidth={"0"}
                    pr="1rem"
                />
                <TooltipLabelValue label={<FaTimes size={"2.5rem"} opacity={0.4} />} value={unitCost} tooltipText="" minWidth={"16rem"} />
                <TooltipLabelValue
                    label={<FaMoneyBillWaveAlt size={"2.5rem"} />}
                    value={`$ ${roundToDecimalPlaces(calComponentCost(cost, volume, unit_type, currentVolume, currentUnitType))}`}
                    tooltipText="Cost"
                    minWidth={"10rem"}
                />
            </Stack>
        )
    }, [unitCost, cost, volume, unit_type, currentUnitType, currentVolume])

    if (linkTo) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    color: colors.primary,
                    textAlign: "start",
                    border: `${colors.primary}C0 2px solid`,
                    borderRadius: 0.6,
                    height: "6.5rem",
                    pl: ".8rem",
                }}
                onClick={linkTo}
            >
                <Stack direction="row" flex={1} alignItems={"center"} spacing={"1.5rem"}>
                    <Stack direction="row" flex={1} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction="column">
                            <Typography variant="h5">
                                <strong>{label}</strong>
                            </Typography>
                        </Stack>
                        {unitCostElement}
                    </Stack>
                    <Box
                        sx={{
                            p: 0,
                            height: "100%",
                            backgroundColor: `${colors.primary}C0`,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <KeyboardDoubleArrowRightRoundedIcon sx={{ color: colors.offWhite, fontSize: "4rem" }} />
                    </Box>
                </Stack>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                color: colors.primary,
                textAlign: "start",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.6,
                px: ".8rem",
                py: "1rem",
            }}
            onClick={onClick}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">
                    <strong>{label}</strong>
                </Typography>
                <Typography variant="h5">
                    <strong>{unitCost}</strong>
                </Typography>
            </Stack>
        </Box>
    )
}
