import { Typography, TypographyProps } from "@mui/material"
import React from "react"
import { useCurrency } from "../../../containers/currency"
import { colors } from "../../../theme/theme"
import { currencyOptionAUD, currencyOptionTWD, CurrencyType } from "../../../types/importRecords"
import { ShopButton } from "../shopButton"

interface DisplayCurrencyAmountProps extends TypographyProps {
    twd: string | number
    aud: string | number
}

export const DisplayCurrencyAmount = ({ twd, aud, ...typographyProps }: DisplayCurrencyAmountProps) => {
    const { currencyOption } = useCurrency()
    const value = React.useMemo(() => {
        switch (currencyOption.key) {
            case CurrencyType.TWD:
                return twd
            case CurrencyType.AUD:
                return aud
        }
    }, [currencyOption.key, twd, aud])

    return <Typography {...typographyProps}>$ {value}</Typography>
}

export const CurrencyLabel = () => {
    const { currencyOption } = useCurrency()

    return currencyOption.label_en
}

export const CurrencyToggleButton = () => {
    const { currencyOption, setCurrencyOption } = useCurrency()

    return (
        <ShopButton
            key={currencyOption.key}
            size="small"
            sx={{
                border: `${colors.primary} 1px solid`,
                backgroundColor: colors.primary,
                ":hover": {
                    backgroundColor: colors.primary,
                },
            }}
            disableRipple
            onClick={() =>
                setCurrencyOption((prev) => {
                    switch (prev.key) {
                        case CurrencyType.AUD:
                            return currencyOptionTWD
                        case CurrencyType.TWD:
                            return currencyOptionAUD
                    }
                })
            }
        >
            <Typography sx={{ color: colors.secondary }}>
                <strong>{currencyOption.label_en}</strong>
            </Typography>
        </ShopButton>
    )
}
