import { Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../containers/permissions"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { fonts } from "../../../theme/theme"
import { Permission } from "../../../types/permissions"
import { ShopMember } from "../../../types/Shop"
import { ConfirmModal } from "../../common/confirmModal"
import { TypographyTrans } from "../../common/TypographyTrans"

interface ShopMemberArchiveModalProps {
    targetedMember: ShopMember
    onClose: () => void
}

export const ShopMemberArchiveModal = ({ targetedMember, onClose }: ShopMemberArchiveModalProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [shopMember, setShopMember] = React.useState<ShopMember>(targetedMember)
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const { id, deleted_at, payslip_prefer_name } = shopMember
    useServerSubscriptionShop<ShopMember>(
        {
            URI: `/member/${targetedMember.id}`,
            key: WSKeys.WSKeyShopMember,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMember(payload)
            },
        },
    )

    const onArchive = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopMemberUpdate)) return
        try {
            setIsLoading(true)
            await send(deleted_at ? WSKeys.WSKeyShopMemberUnarchive : WSKeys.WSKeyShopMemberArchive, { id })
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : `Failed to ${deleted_at ? "unarchive" : "archive"} member.`)
        } finally {
            setIsLoading(false)
        }
    }, [deleted_at, hasPerm, id, send, onClose])

    return (
        <ConfirmModal
            trans
            title={deleted_at ? "Unarchive Member" : "Archive Member"}
            onClose={onClose}
            error={error}
            isLoading={isLoading}
            onConfirm={onArchive}
        >
            <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>{`Are you sure you want to ${
                deleted_at ? "unarchive" : "archive"
            } the following member?`}</TypographyTrans>
            <Typography variant={"h6"} fontFamily={fonts.sourceSansProBoldIt}>
                {payslip_prefer_name}
            </Typography>
        </ConfirmModal>
    )
}
