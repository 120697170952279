import { Stack, Typography } from "@mui/material"
import React from "react"
import { shadeColor } from "../../helpers"
import { colors } from "../../theme/theme"

export const RedirectPage = () => {
    // Receives token from the url param and passes it to the parent via postMessage
    React.useEffect(() => {
        // Close the window
        setTimeout(() => {
            window.opener.postMessage("check auth")
            window.close()
        }, 750)
    }, [])

    return (
        <Stack
            spacing="3rem"
            alignItems="center"
            justifyContent="center"
            sx={{
                position: "fixed",
                width: "100vw",
                height: "100%",
                backgroundColor: shadeColor(colors.primary, 90),
            }}
        >
            <Typography variant={"h2"} color={colors.offWhite}>
                Welcome
            </Typography>
        </Stack>
    )
}
