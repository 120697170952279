import React from "react"
import { IntermediateProduct } from "../types/intermediateProducts"
import { Ingredient, Item, ItemSource, Manufacturer } from "../types/items"
import { Product } from "../types/products"

export interface ItemState {
    items: Item[]
    setItems: React.Dispatch<React.SetStateAction<Item[]>>
    manufacturers: Manufacturer[]
    setManufacturers: React.Dispatch<React.SetStateAction<Manufacturer[]>>
    ingredients: Ingredient[]
    setIngredients: React.Dispatch<React.SetStateAction<Ingredient[]>>
    itemSources: ItemSource[]
    setItemSources: React.Dispatch<React.SetStateAction<ItemSource[]>>
    intermediateProducts: IntermediateProduct[]
    setIntermediateProducts: React.Dispatch<React.SetStateAction<IntermediateProduct[]>>
    products: Product[]
    setProducts: React.Dispatch<React.SetStateAction<Product[]>>
    resetItem: () => void
}

const initialState: ItemState = {
    items: [],
    setItems: () => {
        return
    },
    manufacturers: [],
    setManufacturers: () => {
        return
    },
    ingredients: [],
    setIngredients: () => {
        return
    },
    itemSources: [],
    setItemSources: () => {
        return
    },
    intermediateProducts: [],
    setIntermediateProducts: () => {
        return
    },
    products: [],
    setProducts: () => {
        return
    },
    resetItem: () => {
        return
    },
}

export const ItemContext = React.createContext<ItemState>(initialState)

export const ItemProvider = ({ children }: { children: React.ReactNode }) => {
    const [items, setItems] = React.useState<Item[]>([])
    const [itemSources, setItemSources] = React.useState<ItemSource[]>([])
    const [ingredients, setIngredients] = React.useState<Ingredient[]>([])
    const [manufacturers, setManufacturers] = React.useState<Manufacturer[]>([])
    const [intermediateProducts, setIntermediateProducts] = React.useState<IntermediateProduct[]>([])
    const [products, setProducts] = React.useState<Product[]>([])

    const resetItem = React.useCallback(() => {
        setItems([])
        setIngredients([])
        setManufacturers([])
        setItemSources([])
        setIntermediateProducts([])
    }, [setItems, setIngredients, setManufacturers])

    return (
        <ItemContext.Provider
            value={{
                items,
                setItems,
                manufacturers,
                setManufacturers,
                ingredients,
                setIngredients,
                itemSources,
                setItemSources,
                intermediateProducts,
                setIntermediateProducts,
                products,
                setProducts,
                resetItem,
            }}
        >
            {children}
        </ItemContext.Provider>
    )
}

export const useItem = () => {
    return React.useContext<ItemState>(ItemContext)
}
