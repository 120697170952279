import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { useDebounce } from "../../../../../hooks"
import { useServerCommandsShop } from "../../../../../hooks/useServer"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { SwapDirection } from "../../../../../types/organisations"
import { Permission } from "../../../../../types/permissions"
import { ShopJobLevel } from "../../../../../types/Shop"
import { AutoSavingIndicator } from "../../../../common/autoSavingIndicator"
import { InputBox } from "../../../../common/InputBox"

interface ShopLevelCardProps {
    shopJobLevel: ShopJobLevel
    isFirstElement: boolean
    isLastElement: boolean

    editMode: boolean
    deletedMode: boolean
}
export const ShopJobLevelCard = ({ shopJobLevel, isFirstElement, isLastElement, editMode, deletedMode }: ShopLevelCardProps) => {
    const { id, label } = shopJobLevel
    const { send } = useServerCommandsShop()
    const { hasPerm } = usePermissions()
    const [error, setError] = React.useState("")

    const [shopLevelLabel, setShopLevelLabel, shopLevelLabelInstant] = useDebounce(label, 1000)
    const [savingLabel, setSavingLabel] = React.useState(false)
    React.useEffect(() => {
        if (!savingLabel || shopLevelLabel === "") return
        send(WSKeys.WSKeyShopJobLevelLabelUpdate, { id, label: shopLevelLabel })
            .catch((e) => setError(typeof e === "string" ? e : "Failed to process."))
            .finally(() => setSavingLabel(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, label, send, shopLevelLabel])

    const deleteJobLevel = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopJobTitleUpdate)) {
            setError("Do not have permission to delete job level")
            return
        }

        try {
            await send(WSKeys.WSKeyShopJobLevelDelete, { id })
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to process.")
        }
    }, [id, hasPerm, send])

    const swapJobLevel = React.useCallback(
        async (direction: SwapDirection) => {
            if (!hasPerm(Permission.ShopJobTitleUpdate)) {
                setError("Do not have permission to swap job level")
                return
            }

            try {
                await send(WSKeys.WSKeyShopJobLevelSwap, { id, direction })
            } catch (e) {
                setError(typeof e === "string" ? e : "Failed to process.")
            }
        },
        [id, hasPerm, send],
    )

    if (editMode) {
        return (
            <InputBox
                disabled={!hasPerm(Permission.ShopJobTitleUpdate)}
                variant={"outlined"}
                value={shopLevelLabelInstant}
                setValue={setShopLevelLabel}
                stackSx={{ flex: 1 }}
                innerSx={{
                    height: "4.5rem",
                    fontSize: "1.82rem",
                    py: 0,
                    px: "1rem",
                }}
                sx={{
                    border: `${colors.primary}99 2px solid`,
                    borderRadius: 0.5,
                }}
                onChange={() => setSavingLabel(true)}
                errMsg={error}
                placeholder={"Enter new level"}
                InputProps={{
                    endAdornment: (
                        <Stack direction="row" spacing="1rem" alignItems="center">
                            <AutoSavingIndicator isLoading={savingLabel} />
                        </Stack>
                    ),
                }}
            />
        )
    }

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
                px: "1rem",
                py: ".5rem",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
                height: "5rem",
            }}
        >
            <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                {label}
            </Typography>

            {deletedMode && (
                <Box sx={{ cursor: "pointer" }} onClick={deleteJobLevel}>
                    <DeleteForeverIcon sx={{ color: colors.lightRed, fontSize: "2.4rem" }} />
                </Box>
            )}

            {!deletedMode && !editMode && (
                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Box
                        onClick={() => swapJobLevel(SwapDirection.Upward)}
                        sx={{
                            cursor: isFirstElement ? "mouse" : "pointer",
                            opacity: isFirstElement ? 0.5 : 1,
                            ":hover": {
                                backgroundColor: isFirstElement ? "unset" : `${colors.primary}20`,
                            },
                        }}
                    >
                        <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }} />
                    </Box>
                    <Box
                        onClick={() => swapJobLevel(SwapDirection.Downward)}
                        sx={{
                            cursor: isLastElement ? "mouse" : "pointer",
                            opacity: isLastElement ? 0.5 : 1,
                            ":hover": {
                                backgroundColor: isLastElement ? "unset" : `${colors.primary}20`,
                            },
                        }}
                    >
                        <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />
                    </Box>
                </Stack>
            )}

            {error && (
                <Typography variant={"h6"} color={colors.lightRed}>
                    {error}
                </Typography>
            )}
        </Stack>
    )
}
