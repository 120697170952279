import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded"
import { Box, Stack } from "@mui/material"
import React, { useState } from "react"
import FlipMove from "react-flip-move"
import { useForm } from "react-hook-form"
import { usePermissions } from "../../../containers/permissions"
import { calComponentCost, roundToDecimalPlaces } from "../../../helpers"
import { useServerCommandsShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { fonts } from "../../../theme/theme"
import { IntermediateProduct } from "../../../types/intermediateProducts"
import { Permission } from "../../../types/permissions"
import { InputBox } from "../../common/InputBox"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ConfirmModal } from "../../common/confirmModal"
import { InputField } from "../../common/form/inputField"
import { ComponentCard } from "./componentCard"
import { ComponentSelectionList, ProductComponent } from "./componentSelectionList"

interface IntermediateProductInput {
    label_en: string
    label: string
    volume: string
    unit_type: string
}

interface IntermediateProductModalProps {
    onClose: () => void
    intermediateProduct?: IntermediateProduct
    parentIDs?: string[]
    initialFirstLevelItems?: ProductComponent[]
}

export const IntermediateProductModal = ({ intermediateProduct, onClose, parentIDs, initialFirstLevelItems = [] }: IntermediateProductModalProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [emptyStackCheck, setEmptyStackCheck] = useState(false)
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedStacks, setSelectedStacks] = React.useState<ProductComponent[]>(initialFirstLevelItems)
    const [cost, setCost] = React.useState("0")

    const { control, trigger, getValues } = useForm<IntermediateProductInput>({
        defaultValues: {
            label: intermediateProduct?.label || "",
            volume: intermediateProduct?.volume || "0",
            unit_type: intermediateProduct?.unit_type || "",
        },
    })

    React.useEffect(() => {
        let newTotalCost = 0

        selectedStacks.forEach((stack) => {
            newTotalCost += calComponentCost(stack.cost, stack.volume, stack.unit_type, stack.setVolume, stack.setUnitType)
        })

        setCost(roundToDecimalPlaces(newTotalCost))
    }, [selectedStacks])

    const onSubmit = React.useCallback(async () => {
        if (!(await trigger())) return

        if (!selectedStacks.length) {
            setEmptyStackCheck(true)
            return
        }

        const value = getValues()

        setIsLoading(true)
        try {
            const resp = await send(intermediateProduct ? WSKeys.WSKeyIntermediateProductUpdate : WSKeys.WSKeyIntermediateProductCreate, {
                id: intermediateProduct?.id,
                label_en: value.label_en,
                label: value.label,
                volume: value.volume,
                unit_type: value.unit_type.toLowerCase(),
                intermediate_product_stacks: selectedStacks
                    .filter((ss) => ss.type === "intermediate_product")
                    .map((ss) => ({ id: ss.id, volume: ss.setVolume, unit_type: ss.setUnitType })),
                item_stacks: selectedStacks.filter((ss) => ss.type === "item").map((ss) => ({ id: ss.id, volume: ss.setVolume, unit_type: ss.setUnitType })),
            })
            if (!resp) return

            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : `Failed to ${intermediateProduct ? "edit" : "create new"} intermediate product.`)
        } finally {
            setIsLoading(false)
        }
    }, [trigger, getValues, intermediateProduct, onClose, send, selectedStacks])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {selectedStacks.map((component) => {
                        return (
                            <div key={`component-${component.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ComponentCard
                                    productComponent={component}
                                    onVolumeChange={(v) => {
                                        setSelectedStacks((prev) => {
                                            return prev.map((p) => {
                                                if (p.id !== component.id) return p
                                                return { ...p, setVolume: v }
                                            })
                                        })
                                    }}
                                    onUnitTypeChange={(v) => {
                                        setSelectedStacks((prev) => {
                                            return prev.map((p) => {
                                                if (p.id !== component.id) return p
                                                return { ...p, setUnitType: v }
                                            })
                                        })
                                    }}
                                    onRemove={() => {
                                        setSelectedStacks((prev) => prev.filter((p) => p.id !== component.id))
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [selectedStacks, setSelectedStacks])

    if (!hasPerm(Permission.ShopProductUpdate)) return null

    return (
        <ConfirmModal
            title={`${intermediateProduct ? "Edit" : "Create new"} intermediate product`}
            onClose={onClose}
            onConfirm={onSubmit}
            width="130rem"
            isLoading={isLoading}
            error={error}
        >
            <Stack flex={1} direction={"row"} justifyContent={"space-between"} spacing={"1rem"}>
                <Stack width={"58rem"} height={"80rem"}>
                    <ComponentSelectionList selectedStacks={selectedStacks} setSelectedStacks={setSelectedStacks} invalidSelectionIDs={parentIDs} />
                </Stack>
                <Stack flex={1} spacing={"1.5rem"}>
                    <Stack spacing={"1rem"} direction={"row"}>
                        <InputField
                            label={"Label *"}
                            name="label"
                            variant={"outlined"}
                            control={control}
                            rules={{
                                required: "Label is required.",
                                maxLength: { value: 50, message: "Label must be less than 50 characters." },
                            }}
                        />
                    </Stack>
                    <Stack spacing={"1rem"} direction={"row"}>
                        <InputBox
                            label="Cost"
                            variant="outlined"
                            value={cost}
                            setValue={() => {
                                return
                            }}
                            disabled
                            InputProps={{
                                startAdornment: <AttachMoneyRoundedIcon fontSize="large" />,
                            }}
                        />
                        <InputField
                            label={"Volume *"}
                            name="volume"
                            variant={"outlined"}
                            control={control}
                            type="number"
                            rules={{
                                required: "Unit type is required.",
                                validate: {
                                    positive: (v: string) => parseFloat(v) > 0 || "Volume must be positive.",
                                },
                            }}
                        />
                        <InputField
                            label={"Unit Type *"}
                            name="unit_type"
                            variant={"outlined"}
                            placeholder="g, kg, ml, l ...etc"
                            control={control}
                            rules={{
                                required: "Unit type is required.",
                            }}
                        />
                    </Stack>

                    <Stack flex={1}>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                            Contains
                        </TypographyTrans>
                        {emptyStackCheck && !selectedStacks.length && (
                            <TypographyTrans color={"error"}>Must contain at least one item or mid-product</TypographyTrans>
                        )}
                        <Stack sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
