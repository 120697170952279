import { ImportCartonVariant } from "./importRecords"

export interface Item {
    id: string
    order_number: number
    manufacturer_id?: string
    label: string
    import_label?: string
    volume: string
    unit_type: string
    cost: string
    deleted_at?: Date

    manufacturer?: Manufacturer
    ingredients?: Ingredient[]
    item_carton_variant?: ImportCartonVariant
    item_sources?: ItemSource[]
}

export interface StackComponent {
    id: string
    volume: string
    unit_type: string
}

export interface ItemSource {
    id: string
    label: string
    deleted_at?: string
}

export interface Ingredient {
    id: string
    label_en: string
    label_ch: string
}

export interface Manufacturer {
    id: string
    label_en: string
    label_ch: string
}

export enum ItemType {
    ITEM = "ITEM",
    INTERMEDIATE_PRODUCT = "INTERMEDIATE_PRODUCT",
    PRODUCT = "PRODUCT",
}

export interface FirstLevelItems {
    item_stacks: StackComponent[]
    intermediate_product_stacks: StackComponent[]
}

export interface ItemStackIDs {
    child_item_ids?: string[]
    child_intermediate_product_ids?: string[]
    parent_intermediate_product_ids?: string[]
    parent_product_ids?: string[]
    first_level_items?: FirstLevelItems
}
