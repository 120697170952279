import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import MenuIcon from "@mui/icons-material/Menu"
import { Box, Divider, Stack } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import { CSSObject, styled } from "@mui/material/styles"
import React from "react"
import { useNavigate } from "react-router-dom"
import { DRAWER_MAX_WIDTH, DRAWER_MIN_WIDTH, DRAWER_TRANSITION_DURATION, TOP_BAR_HEIGHT } from "../../../constants"
import { useAuth } from "../../../containers/auth"
import { useInnerShop } from "../../../containers/inner_shop"
import { useUI } from "../../../containers/ui"
import { colors, siteZIndex } from "../../../theme/theme"
import { TypographyTrans } from "../../common/TypographyTrans"
import { NavLink, leftDrawerOrgNavLinks, leftDrawerPOSsystem, leftDrawerShopNavLinks } from "./leftDrawerOrgNavLinks"

const openedMixin = (): CSSObject => ({
    width: DRAWER_MAX_WIDTH,
    backgroundColor: colors.primary,
    overflowX: "hidden",
    transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,
})

const closedMixin = (): CSSObject => ({
    width: DRAWER_MIN_WIDTH,
    backgroundColor: colors.primary,
    overflowX: "hidden",
    transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ open }) => ({
    width: DRAWER_MAX_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    zIndex: siteZIndex.LeftDrawer,
    ...(open && {
        ...openedMixin(),
        "& .MuiDrawer-paper": openedMixin(),
    }),
    ...(!open && {
        ...closedMixin(),
        "& .MuiDrawer-paper": closedMixin(),
    }),
}))

export const LeftDrawer = () => {
    const { shopID } = useInnerShop()
    const { isNavLinksDrawerOpen, toggleIsNavLinksDrawerOpen } = useUI()
    const { isSystemUser } = useAuth()
    const navigate = useNavigate()

    const navLinks = React.useMemo(() => {
        if (isSystemUser) return leftDrawerPOSsystem

        if (shopID) return leftDrawerShopNavLinks(shopID)

        return leftDrawerOrgNavLinks
    }, [shopID, isSystemUser])

    const renderTab = React.useCallback(
        (nl: NavLink) => {
            const shouldHighlight = window.location.pathname.startsWith(nl.path)
            return (
                <ListItem
                    key={nl.label}
                    disablePadding
                    sx={{
                        display: "block",
                        backgroundColor: shouldHighlight ? colors.darkerNeonBlue : undefined,
                    }}
                >
                    <ListItemButton
                        sx={{
                            minHeight: "5.5rem",
                            justifyContent: isNavLinksDrawerOpen ? "initial" : "center",
                            px: 2.5,
                            mb: "3px",
                        }}
                        onClick={() => navigate(nl.path)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isNavLinksDrawerOpen ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            {nl.icon}
                        </ListItemIcon>
                        <TypographyTrans
                            variant="h5"
                            sx={{
                                color: colors.offWhite,
                                display: isNavLinksDrawerOpen ? undefined : "none",
                                fontWeight: "bolder",
                            }}
                        >
                            {nl.label}
                        </TypographyTrans>
                    </ListItemButton>
                </ListItem>
            )
        },
        [isNavLinksDrawerOpen, navigate],
    )

    return (
        <Drawer variant="permanent" open={isNavLinksDrawerOpen}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: isNavLinksDrawerOpen ? "flex-end" : "center",
                    height: TOP_BAR_HEIGHT,
                }}
            >
                {isNavLinksDrawerOpen ? (
                    <IconButton onClick={() => toggleIsNavLinksDrawerOpen(false)}>
                        <ChevronLeftIcon sx={{ fontSize: "3.5rem" }} />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => toggleIsNavLinksDrawerOpen(true)}>
                        <MenuIcon sx={{ fontSize: "3.5rem" }} />
                    </IconButton>
                )}
            </Box>
            <Divider />
            <Stack justifyContent={"space-between"} height={"100%"}>
                <List sx={{ py: 0 }}>{navLinks.filter((nl) => !nl.isBottom).map((nl) => renderTab(nl))}</List>
                <List sx={{ py: 0 }}>{navLinks.filter((nl) => nl.isBottom).map((nl) => renderTab(nl))}</List>
            </Stack>
        </Drawer>
    )
}
