import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import FlipMove from "react-flip-move"
import { useOrganisation } from "../../../../../containers/organisations"
import { getPaySession } from "../../../../../helpers"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../../hooks/useServer"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { ShopMember, UserShopWorkRecord } from "../../../../../types/Shop"
import { SalaryPayCycle } from "../../../../../types/organisations"
import { DateFormat } from "../../../../../types/types"
import { ShopButton } from "../../../../common/shopButton"
import { WorkRecordRequestModal } from "./WorkRecordRequestModal"
import { WorkRecordCard } from "./workRecordCard"

interface AccountPanelWorkRecordsProps {
    shopMember: ShopMember
}

export const AccountPanelWorkRecords = ({ shopMember }: AccountPanelWorkRecordsProps) => {
    const { id, weekday_pay, saturday_pay, sunday_pay } = shopMember
    const { organisation } = useOrganisation()
    const [showAddModal, setShopAddModal] = React.useState(false)
    const { send } = useServerCommandsShop()
    const [offset, setOffset] = React.useState(0)
    const { startDate, endDate } = getPaySession(
        organisation ? moment(organisation.salary_pay_date) : moment(),
        organisation?.salary_pay_cycle || SalaryPayCycle.Weekly,
    )

    // get the first pay day of the week
    const filterStartDate = React.useMemo(() => startDate.clone().add(1 * offset, "weeks"), [offset, startDate])
    const filterEndDate = React.useMemo(() => endDate.clone().add(1 * offset, "weeks"), [offset, endDate])

    const [workRecords, setWorkRecords] = React.useState<UserShopWorkRecord[]>([])
    useServerSubscriptionShop<UserShopWorkRecord[]>(
        {
            URI: `/member/${id}/work_records`,
            key: WSKeys.WSKeyShopMemberWorkRecords,
            args: {
                started_at: filterStartDate,
                ended_at: filterEndDate,
                bonus_reward_key: localStorage.getItem("BONUS_REWARD_KEY"),
            },
        },
        {
            callback: (payload) => {
                if (!payload) return
                setWorkRecords((prev) => {
                    if (prev.length === 0)
                        payload.filter((p) => moment(p.started_at).isBetween(filterStartDate, filterEndDate, undefined, "[]") && !p.is_deleted)
                    prev = prev.map((usw) => payload.find((p) => p.id === usw.id) || usw)
                    payload.forEach((p) => (prev.some((usw) => usw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usw) => moment(usw.started_at).isBetween(filterStartDate, filterEndDate, undefined, "[]") && !usw.is_deleted)
                })
            },
            filter: (payload) => {
                return payload.filter((wr) => moment(wr.started_at).isBetween(filterStartDate, filterEndDate, undefined, "[]"))
            },
        },
    )

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {workRecords.map((wr) => {
                        return (
                            <div key={`work-record-${wr.id}`} style={{ marginBottom: "1rem" }}>
                                <WorkRecordCard workRecord={wr} weekdayPay={weekday_pay} saturdayPay={saturday_pay} sundayPay={sunday_pay} send={send} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [workRecords, weekday_pay, saturday_pay, sunday_pay, send])

    return (
        <>
            <Stack
                flex={1}
                sx={{
                    p: "1rem",
                }}
                spacing="1rem"
            >
                {/* Date Bar */}
                <Stack direction={"row"} alignItems={"center"} spacing={"1rem"}>
                    <Stack
                        flex={1}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            border: `${colors.primary}DD .5rem solid`,
                            borderRadius: 0.8,
                        }}
                    >
                        <ShopButton buttonColor={`${colors.primary}DD`} sx={{ borderRadius: 0 }} onClick={() => setOffset((prev) => prev - 1)}>
                            <ArrowBackIosNewIcon sx={{ color: colors.offWhite, fontSize: "3rem", fontWeight: "bolder" }} />
                        </ShopButton>
                        <Stack
                            direction={"row"}
                            flex={1}
                            sx={{
                                height: "100%",
                                px: "10rem",
                            }}
                            alignItems={"center"}
                            justifyContent={"space-evenly"}
                        >
                            <Typography variant={"h4"} fontFamily={fonts.sourceSansProBlackIt}>
                                {filterStartDate.format(DateFormat.DateDay)}
                            </Typography>
                            <RemoveIcon sx={{ fontSize: "3rem" }} />
                            <Typography variant={"h4"} fontFamily={fonts.sourceSansProBlackIt}>
                                {filterEndDate.format(DateFormat.DateDay)}
                            </Typography>
                        </Stack>
                        <ShopButton buttonColor={`${colors.primary}DD`} sx={{ borderRadius: 0 }} onClick={() => setOffset((prev) => prev + 1)}>
                            <ArrowForwardIosIcon sx={{ color: colors.offWhite, fontSize: "3rem", fontWeight: "bolder" }} />
                        </ShopButton>
                    </Stack>

                    <ShopButton buttonColor={`${colors.primary}DD`} onClick={() => setShopAddModal((prev) => !prev)}>
                        <AddOutlinedIcon sx={{ color: colors.offWhite, fontSize: "4rem", fontWeight: "bolder" }} />
                    </ShopButton>
                </Stack>

                {/* List body */}
                <Stack sx={{ px: ".5rem", py: ".5rem", flex: 1 }}>
                    <Box
                        sx={{
                            ml: "1rem",
                            mr: ".5rem",
                            pr: "1rem",
                            my: "1rem",
                            flex: 1,
                            overflowY: "auto",
                            overflowX: "hidden",
                            direction: "ltr",
                        }}
                    >
                        {content}
                    </Box>
                </Stack>

                {/* Total value
                {totalStat} */}
            </Stack>
            {showAddModal && <WorkRecordRequestModal onClose={() => setShopAddModal(false)} userID={shopMember.id} send={send} />}
        </>
    )
}
