import { Box, Stack } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../containers/auth"
import { useServerCommandsUser, useServerSubscription } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors } from "../../theme/theme"
import { Shop, ShopMember } from "../../types/Shop"
import { CalculatingCard } from "./calculatingCard"
import { ClockInCard } from "./clockInCard"
import { TotalTodayWageCard } from "./totalTodayWageCard"
import { TotalWeekWageCard } from "./totalWeekWageCard"

export const PosClockInPage = () => {
    const { userID, isSystemUser } = useAuth()
    const navigate = useNavigate()
    const [shop, setShop] = React.useState<Shop>()
    const { send } = useServerCommandsUser()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [shopMembers, setShopMembers] = React.useState<ShopMember[]>([])

    React.useEffect(() => {
        if (!userID || !isSystemUser) return
        setIsLoading(true)
        send<Shop>(WSKeys.WSKeyShop)
            .then((shop) => {
                setShop(shop)
            })
            .catch((e) => {
                setError(typeof e === "string" ? e : "Failed to get shop.")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [send, userID, isSystemUser])

    const [showWageCountDown, setShowWageCountDown] = React.useState(true)
    useServerSubscription<ShopMember[]>(
        {
            URI: `/shop/${shop?.id}/members`,
            key: WSKeys.WSKeyShopMembers,
            ready: !!shop?.id,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMembers((prev) => {
                    if (prev.length === 0) return shuffle(payload.filter((p) => !p.deleted_at))
                    // update existing members
                    prev = prev.map((m) => payload.find((p) => p.id === m.id) || m)
                    // add new data to the list
                    payload.forEach((p) => (prev.some((m) => m.id === p.id) ? undefined : prev.push(p)))
                    return shuffle(prev.filter((p) => !p.deleted_at))
                })

                setShowWageCountDown(false)
                setTimeout(() => setShowWageCountDown(true), 1000)
            },
        },
    )

    const shuffle = React.useCallback((shopMembers: ShopMember[]): ShopMember[] => {
        const workingMembers = shopMembers
            .filter((m) => !!m.ongoing_work_record)
            .sort((a, b) =>
                a.ongoing_work_record?.started_at && b.ongoing_work_record?.started_at && a.ongoing_work_record?.started_at > b.ongoing_work_record?.started_at
                    ? 1
                    : -1,
            )

        const nonWorkingMembers = shopMembers
            .filter((m) => !m.ongoing_work_record)
            .sort((a, b) =>
                a.last_work_record_created_at && b.last_work_record_created_at && a.last_work_record_created_at > b.last_work_record_created_at ? -1 : 1,
            )

        return [...workingMembers, ...nonWorkingMembers]
    }, [])

    const content = React.useMemo(() => {
        return (
            <>
                <Stack paddingLeft={"1rem"} direction={"row"} minHeight={"200px"}>
                    <div key={"today wage panel"} style={{ width: "200px", height: "200px", padding: "10px" }}>
                        {showWageCountDown ? <TotalTodayWageCard /> : <CalculatingCard title="Day Wage" />}
                    </div>

                    <div key={"week wage panel"} style={{ width: "200px", height: "200px", padding: "10px" }}>
                        {showWageCountDown ? <TotalWeekWageCard /> : <CalculatingCard title="Week Wage" />}
                    </div>
                </Stack>
                {/*@ts-ignore*/}
                <FlipMove
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                    }}
                >
                    {shopMembers.map((sm) => {
                        return (
                            <div key={sm.id} style={{ width: "200px", height: "200px", padding: "10px" }}>
                                <ClockInCard shopMember={sm} />
                            </div>
                        )
                    })}
                </FlipMove>
            </>
        )
    }, [shopMembers, showWageCountDown])

    if (!isSystemUser) {
        navigate("/")
    }

    if (window.location.pathname !== "/pos") {
        navigate("/pos")
        return null
    }

    return (
        <Stack
            spacing="3rem"
            sx={{
                width: "100%",
                height: "100%",
                backgroundColor: colors.background,
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    direction: "ltr",
                }}
            >
                {content}
            </Box>
        </Stack>
    )
}
