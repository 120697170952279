import { Stack, Typography } from "@mui/material"
import { Moment } from "moment"
import React from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useServerSubscription } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ShopMember, StaffPayslipSessionStatus, UserShopWorkRecord, payslipStatusIcon } from "../../../types/Shop"

interface PayStaffMemberCardProps {
    shopMember: ShopMember
    selectedShopID: string
    selectedMemberID: string
    startTime: Moment
    endTime: Moment
}

export const PayStaffMemberCard = ({ shopMember, selectedShopID, selectedMemberID, startTime, endTime }: PayStaffMemberCardProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const [workRecords, setWorkRecords] = React.useState<UserShopWorkRecord[]>([])
    useServerSubscription<UserShopWorkRecord[]>(
        {
            URI: `/shop/${selectedShopID}/member/${shopMember.user_id}/work_records`,
            key: WSKeys.WSKeyShopMemberWorkRecords,
            ready: !!selectedShopID && !!shopMember.user_id,
            args: {
                started_at: startTime.toDate(),
                ended_at: endTime.toDate(),
                bonus_reward_key: localStorage.getItem("BONUS_REWARD_KEY"),
            },
        },
        {
            callback: (payload) => {
                if (!payload) return
                payload = payload
                    .filter((wr) => wr.started_at > startTime.toDate() && wr.started_at < endTime.toDate())
                    .sort((a, b) => (a.started_at > b.started_at ? -1 : 1))

                setWorkRecords((prev) => {
                    if (prev.length === 0) payload.filter((p) => !p.is_deleted)
                    prev = prev.map((usw) => payload.find((p) => p.id === usw.id) || usw)
                    payload.forEach((p) => (prev.some((usw) => usw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usw) => !usw.is_deleted)
                })
            },
        },
    )

    const payslipStatus = React.useMemo(() => {
        // show no work record if there is nothing return
        if (workRecords.length === 0) {
            return StaffPayslipSessionStatus.NO_RECORDS
        }

        let status = ""
        let hasUnconfirmedRecord = false
        let hasConfirmedRecord = false

        workRecords.forEach((wr) => {
            // skip if the status is set
            if (status !== "") return
            // check if the record is overlapped
            if (wr.attached_to_payslip) {
                hasConfirmedRecord = true

                if (wr.attached_to_payslip.start_date < startTime.toDate() || wr.attached_to_payslip.end_date > endTime.toDate()) {
                    status = StaffPayslipSessionStatus.OVERLAPPED
                }
                return
            }

            hasUnconfirmedRecord = true
        })

        if (status !== "") {
            return StaffPayslipSessionStatus.OVERLAPPED
        }
        if (hasConfirmedRecord && hasUnconfirmedRecord) {
            return StaffPayslipSessionStatus.INCOMPLETE
        }

        if (hasConfirmedRecord) {
            return StaffPayslipSessionStatus.SENT
        }

        return StaffPayslipSessionStatus.READY
    }, [workRecords, endTime, startTime])

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                border: `${colors.primary}${selectedMemberID === shopMember.id ? "CC" : "60"} 2px solid`,
                width: "100%",
                p: "1rem",
                cursor: "pointer",
            }}
            onClick={() => {
                searchParams.set("selected_member_id", shopMember.id)
                navigate(`${location.pathname}?${searchParams.toString()}`)
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent={"space-between"} flex={1}>
                <Stack direction="row" alignItems="center" spacing={".5rem"}>
                    <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        {shopMember.prefer_name}
                    </Typography>
                    {shopMember.payslip_prefer_name !== shopMember.prefer_name && (
                        <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                            ({shopMember.payslip_prefer_name})
                        </Typography>
                    )}
                </Stack>

                {payslipStatusIcon[payslipStatus]}
            </Stack>
        </Stack>
    )
}
