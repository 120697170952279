import { Stack } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { useDebounce } from "../../../../../hooks"
import { useServerCommandsShop } from "../../../../../hooks/useServer"
import { WSKeys } from "../../../../../key"
import { fonts } from "../../../../../theme/theme"
import { Permission } from "../../../../../types/permissions"
import { ShopJobLevel } from "../../../../../types/Shop"
import { AutoSavingIndicator } from "../../../../common/autoSavingIndicator"
import { InputBox } from "../../../../common/InputBox"
import { TypographyTrans } from "../../../../common/TypographyTrans"

interface ShopJobLevelDutyFieldProps {
    shopJobLevel: ShopJobLevel
}
export const ShopJobLevelDutyField = ({ shopJobLevel }: ShopJobLevelDutyFieldProps) => {
    const { id, description } = shopJobLevel
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [jobLevelDescription, setJobLevelDescription, jobLevelDescriptionInstant] = useDebounce(description, 750)
    const [jobLevelDescriptionLoading, setJobLevelDescriptionLoading] = React.useState(false)

    React.useEffect(() => {
        if (!jobLevelDescriptionLoading || !hasPerm(Permission.ShopJobTitleUpdate)) return
        send(WSKeys.WSKeyShopJobLevelDescriptionUpdate, { id, description: jobLevelDescription }).finally(() => setJobLevelDescriptionLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPerm, id, jobLevelDescription, send])

    return (
        <Stack spacing={"1rem"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                    Duty
                </TypographyTrans>
                {<AutoSavingIndicator isLoading={jobLevelDescriptionLoading} />}
            </Stack>

            <Stack sx={{ px: "2rem" }}>
                <InputBox
                    variant="outlined"
                    placeholder={"Enter the description of this job level..."}
                    value={jobLevelDescriptionInstant}
                    setValue={setJobLevelDescription}
                    onChange={() => setJobLevelDescriptionLoading(true)}
                    multiline
                    rows={4}
                />
            </Stack>
        </Stack>
    )
}
