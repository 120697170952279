import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Divider, IconButton, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useServerSubscriptionOrg, useServerSubscriptionSecure } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { Shop } from "../../../types/Shop"
import { TaxRateType } from "../../../types/organisations"
import { DateFormat } from "../../../types/types"
import { ConfirmModal } from "../../common/confirmModal"
import { PageHeader } from "../../common/pageHeader"
import { PayStaffPanelMemberSelector } from "./payStaffPanelMemberSelector"
import { PayStaffPanelMemberWorkRecords } from "./payStaffPanelMemberWorkRecords"

export const PayStaffPanel = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [exitConfirmModal, setExitConfirmModal] = React.useState(false)
    const [selectedShop, setSelectedShop] = React.useState<Shop>()
    const [taxRateTypes, setTaxRateTypes] = React.useState<TaxRateType[]>([])
    const [superannuationRate, setSuperannuationRate] = React.useState<string>("0.105")
    const startDate = React.useMemo(() => {
        const dateStr = searchParams.get("start_from")
        if (!dateStr) return moment()
        return moment(dateStr)
    }, [searchParams])

    const endDate = React.useMemo(() => {
        const dateStr = searchParams.get("end_at")
        if (!dateStr) return moment()
        return moment(dateStr).endOf("day").startOf("second")
    }, [searchParams])

    const selectedShopID = React.useMemo(() => {
        return searchParams.get("selected_shop_id") || ""
    }, [searchParams])

    const selectedMemberID = React.useMemo(() => {
        return searchParams.get("selected_member_id") || ""
    }, [searchParams])

    useServerSubscriptionOrg<Shop>(
        {
            URI: `/shop/${selectedShopID}`,
            key: WSKeys.WSKeyShop,
            ready: !!selectedShopID,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setSelectedShop(payload)
            },
        },
    )

    useServerSubscriptionSecure<TaxRateType[]>(
        {
            URI: "/tax_rate_types",
            key: WSKeys.WSKeyTaxRateTypes,
        },
        {
            callback: (payload) => {
                if (!payload) return
                // sort the list
                payload = payload.map<TaxRateType>((p) => {
                    // get the last
                    const last = p.tax_rules.find((r) => r.tax_threshold === -1)
                    if (!last) return p

                    return {
                        ...p,
                        tax_rules: p.tax_rules
                            .filter((r) => r.tax_threshold !== -1)
                            .sort((a, b) => a.tax_threshold - b.tax_threshold)
                            .concat(last),
                    }
                })

                setTaxRateTypes((prev) => {
                    if (prev.length === 0) return payload
                    prev = prev.map((r) => payload.find((p) => p.tax_rate_type_label === r.tax_rate_type_label) || r)
                    payload.forEach((p) => (prev.some((r) => r.tax_rate_type_label === p.tax_rate_type_label) ? undefined : prev.push(p)))
                    return prev
                })
            },
        },
    )

    useServerSubscriptionSecure<string>(
        {
            URI: "/superannuation_rate",
            key: WSKeys.WSKeySuperannuationRate,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setSuperannuationRate(payload)
            },
        },
    )

    return (
        <Stack
            direction="column"
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
                width: "100%",
            }}
        >
            <PageHeader
                title={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton size="small" disableRipple onClick={() => setExitConfirmModal(true)} sx={{ p: 0 }}>
                            <ArrowBackIcon sx={{ fontSize: "2.5rem", color: colors.primary, fontWeight: "bold" }} />
                        </IconButton>
                        <Stack direction={"row"} spacing={"2rem"}>
                            <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                                PAY STAFFS PANEL
                            </Typography>
                            <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                                {`${startDate.format(DateFormat.DateDay)} - ${endDate.format(DateFormat.DateDay)}`}
                            </Typography>
                        </Stack>
                    </Stack>
                }
            />

            <Stack direction={"row"} sx={{ p: "1rem", height: "100%" }} spacing={"1rem"}>
                <PayStaffPanelMemberSelector selectedMemberID={selectedMemberID} selectedShopID={selectedShopID} startTime={startDate} endTime={endDate} />
                <Divider sx={{ my: ".35rem !important", border: `${colors.primary}75 1px dashed` }} />
                <PayStaffPanelMemberWorkRecords
                    key={selectedShopID + selectedMemberID}
                    selectedMemberID={selectedMemberID}
                    selectedShopID={selectedShopID}
                    startTime={startDate}
                    endTime={endDate}
                    selectedShop={selectedShop}
                    taxRateTypes={taxRateTypes}
                    superannuationRate={superannuationRate}
                />
            </Stack>

            {exitConfirmModal && (
                <ConfirmModal
                    rowReverse
                    title={"Are you sure you want to end current section?"}
                    onClose={() => setExitConfirmModal(false)}
                    onConfirm={() => navigate("/accountant")}
                    confirmLabel={"YES"}
                    confirmColor={colors.lightGrey}
                    cancelLabel={"Stay on current session"}
                    cancelColor={`${colors.primary}CC`}
                />
            )}
        </Stack>
    )
}
