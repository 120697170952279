import { SERVER_HOST } from "../constants"
import { useAuth } from "../containers/auth"
import { useInnerShop } from "../containers/inner_shop"
import { useOrganisation } from "../containers/organisations"
import { SubOptions, SubProps, useCommands, useSubscription } from "../containers/ws"
import { DataType } from "../containers/ws/util"

// ***********
// ** Fetch **
// ***********
export const useServerCommandsUser = () => {
    const { userID } = useAuth()
    return useCommands({ host: SERVER_HOST, URI: `/secure/user_commander`, ready: !!userID })
}

export const useServerCommandsOrg = () => {
    const { organisation } = useOrganisation()
    return useCommands({ host: SERVER_HOST, URI: `/org/${organisation?.id}/org_commander`, ready: !!organisation?.id })
}

export const useServerCommandsShop = () => {
    const { shopID } = useInnerShop()
    return useCommands({ host: SERVER_HOST, URI: `/shop/${shopID}/shop_commander`, ready: !!shopID })
}

// ******************
// ** Subscription **
// ******************

export function useServerSubscriptionSecure<T = DataType>({ URI, key, batchURI, ready = true, args }: SubProps, option?: SubOptions<T>) {
    const { userID } = useAuth()
    return useSubscription({ URI: `/secure${URI}`, key, host: SERVER_HOST, batchURI: batchURI, ready: !!userID && ready, args }, option)
}

export function useServerSubscriptionSecureUser<T = DataType>({ URI, key, batchURI, ready = true, args }: SubProps, option?: SubOptions<T>) {
    const { userID } = useAuth()
    return useSubscription({ URI: `/secure/user/${userID}${URI}`, key, host: SERVER_HOST, batchURI: batchURI, ready: !!userID && ready, args }, option)
}

export function useServerSubscriptionOrg<T = DataType>({ URI, key, batchURI, ready = true, args }: SubProps, option?: SubOptions<T>) {
    const { organisation } = useOrganisation()
    return useSubscription(
        { URI: `/org/${organisation?.id}${URI}`, key, host: SERVER_HOST, batchURI: batchURI, ready: !!organisation?.id && ready, args },
        option,
    )
}

export function useServerSubscriptionShop<T = DataType>({ URI, key, batchURI, ready = true, args }: SubProps, option?: SubOptions<T>) {
    const { shopID } = useInnerShop()
    return useSubscription({ URI: `/shop/${shopID}${URI}`, key, host: SERVER_HOST, batchURI: batchURI, ready: !!shopID && ready, args }, option)
}

export function useServerSubscription<T = DataType>({ URI, key, batchURI, ready = true, args }: SubProps, option?: SubOptions<T>) {
    return useSubscription({ URI: `${URI}`, key, host: SERVER_HOST, batchURI: batchURI, ready: ready, args }, option)
}
