import { Stack, TextField, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { colors, fonts } from "../../../theme/theme"

interface DateFiledProps {
    control: Control<any, object>
    name: string
    rules?: any
    label?: React.ReactNode
    onChange?: (date: moment.Moment | null) => void
    min?: string
    max?: string
}

export const DateField = ({ control, rules, name, label, onChange, min, max }: DateFiledProps) => {
    const inputLabel = React.useMemo(() => {
        if (!label) return null
        if (typeof label === "string") {
            return (
                <Typography variant="h6" fontWeight="bold">
                    {label}
                </Typography>
            )
        }
        return label
    }, [label])
    return (
        <Stack flex={1}>
            {inputLabel}
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue=""
                render={({ field, fieldState }) => (
                    <TextField
                        id="time"
                        hiddenLabel
                        type="date"
                        fullWidth
                        variant="outlined"
                        defaultValue={field.value ? field.value.format("YYYY-MM-DD") : undefined}
                        onChange={(e) => {
                            field.onChange(e)
                            if (onChange) onChange(moment(e.target.value, "YYYY-MM-DD"))
                        }}
                        inputProps={{
                            min,
                            max,
                        }}
                        sx={{
                            width: "100%",
                            ".MuiOutlinedInput-input": {
                                p: ".37rem 1.6rem",
                                pt: ".55rem",
                                fontSize: "2.2rem",
                                height: `4.3rem`,
                                fontFamily: fonts.sourceSansProBold,
                                "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                },
                                appearance: "textfield",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: `${colors.primary} 2px solid`,
                                borderRadius: 0.8,
                            },
                        }}
                    />
                )}
            />
        </Stack>
    )
}
