import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"
import { Divider, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { dateFormatter, DateFormatType, roundedTo } from "../../../helpers"
import { useImportRecordPrice } from "../../../hooks/useImportRecord"
import { colors } from "../../../theme/theme"
import { ImportRecord } from "../../../types/importRecords"
import { DisplayCurrencyAmount } from "../../common/currency/currency"
import { DotPointHeader } from "../../common/dotPointHeader"
import { LabelValue } from "../../common/labelValue"
import { ImportRecordDetailUpdateModal } from "./importRecordDetailUpdateModal"

interface ImportRecordDetailInnerProps {
    importRecord: ImportRecord
}

export const ImportRecordDetailInner = ({ importRecord }: ImportRecordDetailInnerProps) => {
    const {
        shipping_fee_twd,
        shipping_fee_aud,
        custom_fee_twd,
        custom_fee_aud,
        pallet_cost_twd,
        pallet_cost_aud,
        surcharge_twd,
        surcharge_aud,
        cargo_packing_fee_twd,
        cargo_packing_fee_aud,
        cost_twd,
        cost_aud,
        cbm,

        order_placed_at,
        payment_transfer_at,
        ship_arrived_at,
        ship_left_at,

        pallet_count,
        pallet_type,
    } = importRecord

    const { totalCostTWD, totalCostAUD, extraChargeAUD, extraChargeTWD } = useImportRecordPrice({ importRecord })

    const [openUpdateModal, setOpenUpdateModal] = React.useState(false)

    return (
        <>
            <Stack direction="column" flex={1}>
                <Stack
                    direction="column"
                    sx={{
                        p: "1rem",
                    }}
                >
                    <DotPointHeader
                        title="Detail"
                        suffixComponent={
                            <IconButton size="small" onClick={() => setOpenUpdateModal((prev) => !prev)}>
                                <ModeEditOutlineIcon fontSize="large" sx={{ color: colors.primary }} />
                            </IconButton>
                        }
                    />
                    {/* pallet */}
                    <Stack direction="row" flex={1} justifyContent="space-between">
                        <Stack direction="column" flex={1}>
                            <LabelValue label="CBM:" value={roundedTo(cbm)} />
                            <LabelValue
                                label="Order placed:"
                                value={order_placed_at ? dateFormatter(order_placed_at, DateFormatType.DateMonthYear) : "EMPTY"}
                            />
                            <LabelValue
                                label="Paid at:"
                                value={payment_transfer_at ? dateFormatter(payment_transfer_at, DateFormatType.DateMonthYear) : "EMPTY"}
                            />
                            <LabelValue label="Ship Left:" value={ship_left_at ? dateFormatter(ship_left_at, DateFormatType.DateMonthYear) : "EMPTY"} />
                            <LabelValue
                                label="Ship Arrived:"
                                value={ship_arrived_at ? dateFormatter(ship_arrived_at, DateFormatType.DateMonthYear) : "EMPTY"}
                            />
                        </Stack>
                        <Divider sx={{ border: `${colors.primary}60 1px dashed`, mx: "1rem" }} />
                        <Stack direction="column" flex={1}>
                            <Typography variant="h5" fontWeight="bolder">
                                Pallet
                            </Typography>
                            <Stack
                                direction="column"
                                sx={{
                                    pl: "1rem",
                                }}
                            >
                                <LabelValue label="Type:" value={pallet_type} />
                                <LabelValue label="Used:" value={pallet_count} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack
                    direction="column"
                    sx={{
                        p: "1rem",
                    }}
                >
                    <DotPointHeader title="COST" />
                    <LabelValue label="Item Cost" value={<DisplayCurrencyAmount variant="h6" twd={cost_twd} aud={cost_aud} />} />
                    <LabelValue label="Pallet Cost" value={<DisplayCurrencyAmount variant="h6" twd={pallet_cost_twd} aud={pallet_cost_aud} />} />
                    <LabelValue label="Extra Charge" value={<DisplayCurrencyAmount variant="h6" twd={extraChargeTWD} aud={extraChargeAUD} />} />

                    <LabelValue label="Surcharge" value={<DisplayCurrencyAmount variant="h6" twd={surcharge_twd} aud={surcharge_aud} />} />
                    <LabelValue
                        label="Cargo Packing Fee"
                        value={<DisplayCurrencyAmount variant="h6" twd={cargo_packing_fee_twd} aud={cargo_packing_fee_aud} />}
                    />
                    <LabelValue label="Shipping Fee" value={<DisplayCurrencyAmount variant="h6" twd={shipping_fee_twd} aud={shipping_fee_aud} />} />
                    <LabelValue label="Custom Fee" value={<DisplayCurrencyAmount variant="h6" twd={custom_fee_twd} aud={custom_fee_aud} />} />

                    <Divider sx={{ my: ".35rem !important", border: `${colors.primary}60 1px dashed` }} />
                    <Stack direction="row" justifyContent="flex-end">
                        <Typography variant="h6" sx={{ mr: "1rem" }}>
                            <strong>Total:</strong>
                        </Typography>
                        <DisplayCurrencyAmount variant="h6" twd={totalCostTWD} aud={totalCostAUD} />
                    </Stack>
                </Stack>
            </Stack>
            {openUpdateModal && <ImportRecordDetailUpdateModal setOpen={setOpenUpdateModal} importRecord={importRecord} />}
        </>
    )
}
