import React from "react"
import { useServerSubscription } from "../hooks/useServer"
import { WSKeys } from "../key"
import { Shop } from "../types/Shop"
import { useAuth } from "./auth"

export interface InnerShopState {
    setShop: React.Dispatch<React.SetStateAction<Shop | undefined>>
    shop?: Shop
    shopID: string
    shopName: string
    shopType: string
    businessName?: string
    abn?: string
    address?: string
}

const initialState: InnerShopState = {
    setShop: () => {
        return
    },
    shop: undefined,
    shopID: "",
    shopName: "",
    shopType: "",
    businessName: undefined,
    abn: undefined,
    address: undefined,
}

export const InnerShopContext = React.createContext<InnerShopState>(initialState)

export const InnerShopProvider = ({ children }: { children: React.ReactNode }) => {
    const [shop, setShop] = React.useState<Shop>()
    return (
        <InnerShopContext.Provider
            value={{
                setShop,
                shop,
                shopID: shop?.id || "",
                shopName: shop?.label || "",
                shopType: shop?.type || "",
                businessName: shop?.business_name,
                abn: shop?.abn,
                address: shop?.address,
            }}
        >
            {children}
        </InnerShopContext.Provider>
    )
}

export const useInnerShop = () => {
    return React.useContext<InnerShopState>(InnerShopContext)
}

export const InnerShopUpdater = () => {
    const { userID } = useAuth()
    const { setShop } = useInnerShop()

    const { pathname } = window.location

    const shopIDFromURL = React.useMemo(() => {
        const list = pathname.split("/").filter((str) => str !== "")

        if (list.length < 2 || list[0] !== "inner_shop") return ""

        return list[1]
    }, [pathname])

    React.useEffect(() => {
        if (shopIDFromURL === "") setShop(undefined)
    }, [setShop, shopIDFromURL])

    useServerSubscription<Shop>(
        {
            URI: `/shop/${shopIDFromURL}`,
            key: WSKeys.WSKeyShop,
            ready: !!userID && !!shopIDFromURL,
        },
        {
            callback: (payload) => {
                setShop(payload)
            },
        },
    )

    return null
}
