export enum SwapDirection {
    Upward = "UPWARD",
    Downward = "DOWNWARD",
}

export enum SalaryPayCycle {
    Weekly = "WEEKLY",
    Fortnightly = "FORTNIGHTLY",
    Monthly = "MONTHLY",
}

export interface Organisation {
    id: string
    label: string
    role: OrganisationRole
    salary_pay_date: Date
    salary_pay_cycle: SalaryPayCycle
    payslip_cc_emails: string[]
}

export interface OrganisationRole {
    id: string
    organisation_id: string
    label: string
}

export interface VisaType {
    id: string
    label: string
    tax_id: string
}

export interface TaxRateType {
    id: string
    tax_rate_type_label: string
    version: string
    tax_rules: TaxRule[]
    take_effect_from: Date
}

export interface TaxRule {
    tax_rate: number
    tax_threshold: number
}

export interface SalaryPayoutUser {
    user_id: string
    preferred_name: string
    hours: string
    paid: string
    superannuation: string
}

export enum SalaryPayoutSessionType {
    Week = "WEEK",
    Month = "MONTH",
    Quarter = "QUARTER",
    Year = "YEAR",
}
