import { Box, Stack } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useImportRecord } from "../../../containers/importRecords"
import { ImportRecord } from "../../../types/importRecords"
import { ConfirmModal } from "../../common/confirmModal"
import { ImportRecordCard } from "../importRecordCard"

interface ImportRecordSelectorProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    onSelectedImportRecordID: (importRecordID: string) => void
}

export const ImportRecordSelector = ({ setOpen, onSelectedImportRecordID }: ImportRecordSelectorProps) => {
    const { importRecords } = useImportRecord()
    const [list, setList] = React.useState<ImportRecord[]>([])
    React.useEffect(() => setList(importRecords), [importRecords])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((importRecord) => {
                        return (
                            <div
                                key={`import-record-${importRecord.id}`}
                                style={{
                                    marginBottom: "1.3rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => onSelectedImportRecordID(importRecord.id)}
                            >
                                <ImportRecordCard importRecord={importRecord} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, onSelectedImportRecordID])

    return (
        <ConfirmModal title="Select Import Record" omitButton onClose={() => setOpen(false)} width="100rem">
            <Stack sx={{ height: "50rem" }}>
                <Stack sx={{ px: ".25rem", py: ".25rem", flex: 1 }}>
                    <Box
                        sx={{
                            ml: ".25rem",
                            mr: ".25rem",
                            pr: ".25rem",
                            my: ".25rem",
                            flex: 1,
                            overflowY: "auto",
                            overflowX: "hidden",
                            direction: "ltr",
                        }}
                    >
                        {content}
                    </Box>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
