import { Box, BoxProps, Typography } from "@mui/material"
import { autoTextColor } from "../../helpers"
import { colors, fonts } from "../../theme/theme"

interface Props {
    text: string
    borderColor?: string
}

const TextTablet = ({ text, borderColor, sx }: Props & BoxProps) => {
    const backgroundColor = borderColor ? `${borderColor}AA` : `${colors.primary}AA`
    const textColor = autoTextColor(backgroundColor)

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor,
                px: "1rem",
                py: "0.12rem",
                borderRadius: "15px",
                border: `1px solid ${borderColor || colors.primary}`,
                ...sx,
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    color: textColor,
                    fontFamily: fonts.sourceSansProSemiboldIt,
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

export default TextTablet
