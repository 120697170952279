import { Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { roundToDecimalPlaces } from "../../helpers"
import { useInterval } from "../../hooks"
import { fonts } from "../../theme/theme"

interface CountUpWageTimerProps {
    baseWage: number
    startedRecords: StartAtRecord[]
    sx?: SxProps
}

interface StartAtRecord {
    started_at: Date
    wage: number
    withSuperRate: number
}

export const CountUpWageTimer = ({ baseWage, startedRecords, sx }: CountUpWageTimerProps) => {
    const [wageDisplay, setWageDisplay] = React.useState("Calculating...")

    // count up second
    useInterval(() => {
        let newTotal = baseWage
        startedRecords.forEach((record) => {
            newTotal += ((new Date().getTime() - record.started_at.getTime()) / (60 * 60 * 1000)) * record.wage * record.withSuperRate
        })

        if (newTotal <= 0) {
            setWageDisplay("Calculating...")
            return
        }

        setWageDisplay(`$ ${roundToDecimalPlaces(newTotal, 2)}`)
    }, 1000)

    return <Typography sx={{ fontFamily: fonts.sourceSansProBold, fontSize: "2.5rem", ...sx }}>{wageDisplay}</Typography>
}
