import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded"
import TimerIcon from "@mui/icons-material/Timer"
import { Box, Stack, SxProps, Typography } from "@mui/material"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { activateTextTruncateScroll } from "text-truncate-scroll"
import { snakeToTitle } from "../../../../../helpers"
import { useServerCommandsOrg } from "../../../../../hooks/useServer"
import { colors, fonts } from "../../../../../theme/theme"
import { LeaveStatus, UserShopLeaveRequest } from "../../../../../types/Shop"
import { DateFormat } from "../../../../../types/types"
import { UserShopLeaveRequestCancelModal } from "./LeaveRequestCancelModal"
import { UserShopLeaveRequestModal } from "./LeaveRequestModal"

interface LeaveRequestCardProps {
    leaveRequest: UserShopLeaveRequest
}

export const LeaveRequestCard = ({ leaveRequest }: LeaveRequestCardProps) => {
    const { date, status, leave_type, hours, shop_id, user_id, attached_to_payslip_id } = leaveRequest
    const [openEditLeaveModal, setOpenEditLeaveModal] = useState(false)
    const [openCancelLeaveModal, setOpenCancelLeaveModal] = useState(false)
    const { send } = useServerCommandsOrg()

    const sxOpacity = React.useMemo<SxProps>(() => {
        if (attached_to_payslip_id) return { opacity: 0.5 }
        return { opacity: 1 }
    }, [attached_to_payslip_id])

    useEffect(() => {
        activateTextTruncateScroll()
    }, [])

    const statusTag = React.useMemo(() => {
        let backgroundColor = ""
        switch (status) {
            case LeaveStatus.Pending:
                backgroundColor = colors.darkGrey
                break
            case LeaveStatus.Approved:
                backgroundColor = colors.green
                break
            case LeaveStatus.Cancelled:
                backgroundColor = colors.red
                break
        }
        return (
            <Box
                sx={{
                    backgroundColor,

                    height: "fit-content",
                    px: ".5rem",
                    borderRadius: 0.8,
                }}
            >
                <Typography variant={"body2"} color="white" fontFamily={fonts.sourceSansProSemibold}>
                    {status}
                </Typography>
            </Box>
        )
    }, [status])

    const action = React.useMemo(() => {
        if (attached_to_payslip_id)
            return (
                <Box
                    sx={{
                        backgroundColor: colors.darkGrey,

                        height: "fit-content",
                        px: ".5rem",
                        borderRadius: 0.8,
                    }}
                >
                    <Typography variant={"body2"} color="white" fontFamily={fonts.sourceSansProSemibold}>
                        CLAIMED
                    </Typography>
                </Box>
            )

        return (
            <>
                {status === LeaveStatus.Pending && (
                    <Box data-testid="work-record-edit-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenEditLeaveModal(true)}>
                        <ModeEditRoundedIcon
                            sx={{
                                fontSize: "2rem",
                                color: colors.primary,
                            }}
                        />
                    </Box>
                )}
                <Box data-testid="work-record-delete-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenCancelLeaveModal(true)}>
                    <DeleteForeverOutlinedIcon
                        sx={{
                            fontSize: "2rem",
                            color: colors.lightRed,
                        }}
                    />
                </Box>
            </>
        )
    }, [attached_to_payslip_id, status, setOpenEditLeaveModal, setOpenCancelLeaveModal])

    return (
        <Stack
            flex={1}
            sx={{
                display: "grid",
                gridTemplateColumns: "32% 20% 18% 15% 10%",
                gridTemplateRows: "repeat(auto-fill, 5.5rem) ",
                gap: ".5rem",
                borderRadius: 0.8,
                border: `${colors.primary}${attached_to_payslip_id ? "15" : "30"} 3px solid`,
                px: "1rem",
            }}
        >
            <Stack direction={"row"} alignItems={"center"} spacing={"1rem"} sx={sxOpacity}>
                <InsertInvitationIcon
                    sx={{
                        fontSize: "2rem",
                        color: colors.primary,
                    }}
                />
                <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                    {moment(date).format(DateFormat.DateDay)}
                </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={".2rem"} justifyContent={"flex-start"} sx={sxOpacity}>
                <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold} className="text-truncate-scroll">
                    {snakeToTitle(leave_type)}
                </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={".2rem"} justifyContent={"center"} sx={sxOpacity}>
                <TimerIcon
                    sx={{
                        fontSize: "2rem",
                        color: colors.primary,
                    }}
                />
                <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                    {hours} hrs
                </Typography>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={".2rem"} justifyContent={"flex-start"} sx={sxOpacity}>
                {statusTag}
            </Stack>

            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={"1rem"}>
                {action}
            </Stack>
            {openEditLeaveModal && (
                <UserShopLeaveRequestModal
                    leaveRequest={leaveRequest}
                    onClose={() => setOpenEditLeaveModal(false)}
                    selectedMemberID={user_id}
                    selectedShopID={shop_id}
                    send={send}
                />
            )}

            {openCancelLeaveModal && <UserShopLeaveRequestCancelModal leaveRequest={leaveRequest} onClose={() => setOpenCancelLeaveModal(false)} send={send} />}
        </Stack>
    )
}
