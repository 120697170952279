import JoinLeftIcon from "@mui/icons-material/JoinLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"
import RuleIcon from "@mui/icons-material/Rule"
import React from "react"
import { TextBox } from "../components/common/textBox"
import { EmploymentStatus } from "../key"
import { colors } from "../theme/theme"

export enum ShopType {
    Warehouse = "WAREHOUSE",
    Restaurant = "RESTAURANT",
}

export const Days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

export enum LeaveType {
    Annual = "ANNUAL_LEAVE",
    PersonalCare = "PERSONAL_CARE_LEAVE",
    TimeInLieu = "TIME_IN_LIEU",
}

export enum LeaveStatus {
    Pending = "PENDING",
    Approved = "APPROVED",
    Cancelled = "CANCELLED",
}

export enum StaffPayslipSessionStatus {
    NO_RECORDS = "NO_RECORDS",
    READY = "READY",
    INCOMPLETE = "INCOMPLETE",
    SENT = "SENT",
    OVERLAPPED = "OVERLAPPED",
}

export const payslipStatusIcon: { [key: string]: React.ReactNode } = {
    [StaffPayslipSessionStatus.NO_RECORDS]: <div data-testid={StaffPayslipSessionStatus.NO_RECORDS}></div>,
    [StaffPayslipSessionStatus.READY]: <KeyboardDoubleArrowRightIcon data-testid={StaffPayslipSessionStatus.READY} />,
    [StaffPayslipSessionStatus.INCOMPLETE]: <RuleIcon data-testid={StaffPayslipSessionStatus.INCOMPLETE} />,
    [StaffPayslipSessionStatus.SENT]: <MarkEmailReadIcon data-testid={StaffPayslipSessionStatus.SENT} fontSize="large" sx={{ color: colors.darkNeonBlue }} />,
    [StaffPayslipSessionStatus.OVERLAPPED]: <JoinLeftIcon data-testid={StaffPayslipSessionStatus.OVERLAPPED} />,
}

export const payslipStatusColorBox: { [key: string]: React.ReactNode } = {
    [StaffPayslipSessionStatus.NO_RECORDS]: (
        <TextBox data-testid={StaffPayslipSessionStatus.NO_RECORDS} label={StaffPayslipSessionStatus.NO_RECORDS} backgroundColor={colors.silver} />
    ),
    [StaffPayslipSessionStatus.READY]: (
        <TextBox data-testid={StaffPayslipSessionStatus.READY} label={StaffPayslipSessionStatus.READY} backgroundColor={colors.primary} />
    ),
    [StaffPayslipSessionStatus.INCOMPLETE]: (
        <TextBox data-testid={StaffPayslipSessionStatus.INCOMPLETE} label={StaffPayslipSessionStatus.INCOMPLETE} backgroundColor={colors.lightRed} />
    ),
    [StaffPayslipSessionStatus.SENT]: (
        <TextBox data-testid={StaffPayslipSessionStatus.SENT} label={StaffPayslipSessionStatus.SENT} backgroundColor={colors.darkNeonBlue} />
    ),
    [StaffPayslipSessionStatus.OVERLAPPED]: (
        <TextBox data-testid={StaffPayslipSessionStatus.OVERLAPPED} label={StaffPayslipSessionStatus.OVERLAPPED} backgroundColor={colors.yellow} />
    ),
}

export interface Shop {
    id: string
    organisation_id: string
    label: string
    type: ShopType
    business_name?: string
    abn?: string
    address?: string

    // pos
    pos_user_id: string
    pos_username: string
    pos_is_set: string

    deleted_at?: Date
}

export interface ShopMember {
    id: string
    email: string
    shop_id: string
    user_id: string
    prefer_name: string
    visa_type_id: string
    visa_type_label: string
    tax_rate_type_label: string
    shop_role_id: string
    shop_role_label: string
    payslip_full_name: string
    payslip_prefer_name: string
    job_title_id: string
    job_level_id: string
    job_title: string
    job_wage_id: string
    employment_status: EmploymentStatus
    clock_in_pin: string
    last_work_record_created_at?: Date
    ongoing_work_record?: UserShopWorkRecord
    user_shop_leave?: UserShopLeave
    deleted_at?: Date
    weekday_pay: string
    saturday_pay: string
    sunday_pay: string
    holiday_pay: string
}

export interface UserShopSpecialPayslipRequirement {
    user_id: string
    shop_id: string
    modify_type: string
    modified_by_value: string
}

export interface UserShopYTDPayment {
    shop_id: string
    user_id: string
    weekday_pay: string
    saturday_pay: string
    sunday_pay: string
    holiday_pay: string
    tax: string
    superannuation: string
}

export interface UserShopWorkRecord {
    id: string
    user_id: string
    shop_id: string
    started_at: Date
    ended_at?: Date
    total_seconds: number
    created_at: Date
    updated_at: Date
    is_deleted?: boolean
    attached_to_payslip_id?: string
    attached_to_payslip?: UserShopPayslip
}

export interface UserOrdinaryPyament {
    id: string
    user_id: string
    shop_id: string
    started_at: Date
    total_hours: string
    created_at: Date
    updated_at: Date
    deleted_at?: Date
    attached_to_payslip_id?: string
}

export interface UserShopPayslipBrief {
    id: string
    start_date: Date
    end_date: Date
    user_id: string
    shop_id: string
    total_earnings: string
    net_pay: string
    deleted_at?: Date
}

export interface UserShopPayslip {
    id: string
    start_date: Date
    end_date: Date
    organisation_id: string
    salary_pay_cycle: string
    user_id: string
    shop_id: string
    job_title: string
    employment_status: string
    weekday_hours: string
    weekday_wage: string
    saturday_hours: string
    saturday_wage: string
    sunday_hours: string
    sunday_wage: string
    holiday_hours: string
    holiday_wage: string
    tax_rate_type_version_id: string
    tax: string
    superannuation_rate_version: string
    superannuation: string
    annual_leave_accrued: string
    annual_leave_used: string
    annual_leave_balance: string
    personal_care_leave_accrued: string
    personal_care_leave_used: string
    personal_care_leave_balance: string
    time_in_lieu_accrued: string
    time_in_lieu_used: string
    time_in_lieu_balance: string

    modify_type?: string
    modified_by_value: string
    ordinary_hours: string

    created_at: Date
    deleted_at?: Date
}

export interface UserShopPayslipComplete extends UserShopPayslip {
    total_earnings: string
    net_pay: string
    business_name: string
    abn: string
    address: string
    payslip_full_name: string
    payslip_prefer_name: string
    weekday_pay_ytd: string
    saturday_pay_ytd: string
    sunday_pay_ytd: string
    holiday_pay_ytd: string
    annual_leave_pay_ytd: string
    personal_care_leave_pay_ytd: string
    time_in_lieu_pay_ytd: string
    total_earnings_ytd: string
    tax_ytd: string
    superannuation_ytd: string
}

export interface ShopJobTitle {
    id: string
    shop_id: string
    title: string
    deleted_at?: Date
}

export interface ShopJobLevel {
    id: string
    shop_id: string
    shop_job_title_id: string
    order_number: string
    label: string
    description: string
    deleted_at?: Date
}

export interface ShopJobWage {
    id: string
    shop_id: string
    shop_job_title_id: string
    shop_job_level_id: string

    order_number: string
    job_description: string
    employment_status: string
    weekday_pay: string
    saturday_pay: string
    sunday_pay: string
    holiday_pay: string
    deleted_at?: Date

    // todo: remove this
    job_title: string
}

export interface UserShopLeave {
    id: string
    annual_leave_hours: string
    time_in_lieu_hours: string
    personal_care_leave_hours: string
}

export interface UserShopLeaveRequest {
    id: string
    user_id: string
    shop_id: string
    leave_type: LeaveType
    status: LeaveStatus
    created_by_id: string
    approved_by_id?: string
    cancelled_by_id?: string
    date: Date
    hours: string
    attached_to_payslip_id?: string
}

export interface ShopRole {
    id: string
    label: string
    organisation_id: string
    deleted_at?: Date
}

export interface WorkRecordLiveList {
    total_history_amount: string
    unfinished_work_records: UnfinishedWorkRecord[]
}

export interface UnfinishedWorkRecord extends UserShopWorkRecord {
    wage: string
    with_super_rate: string
}
