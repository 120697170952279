import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded"
import { Box, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { BiSolidFoodMenu } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { useInnerShop } from "../../../containers/inner_shop"
import { useItem } from "../../../containers/items"
import { calCostRatio, roundToDecimalPlaces } from "../../../helpers"
import { useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ItemStackIDs, ItemType } from "../../../types/items"
import { TypographyTrans } from "../../common/TypographyTrans"
import { DotPointHeader } from "../../common/dotPointHeader"
import IconLabelButton from "../../common/iconLabelButton"
import { PageHeader } from "../../common/pageHeader"
import { ItemCard } from "../../items/itemCard"
import RecipeModal from "./RecipeModal"
import { ProductComponent } from "./componentSelectionList"
import { IntermediateProductModal } from "./intermediateProductModal"
import IntermediateProductStackList from "./intermediateProductStackList"
import ProductStackList from "./productStackList"

interface Props {
    intermediateProductID: string
}

const IntermediateProductDetail = ({ intermediateProductID }: Props) => {
    const { shopID } = useInnerShop()
    const { items, intermediateProducts, products } = useItem()
    const [itemStackIDs, setItemStackIDs] = React.useState<ItemStackIDs>({})
    const [openIntermediateProductModal, setOpenIntermediateProductModal] = React.useState(false)
    const [openRecipeModal, setOpenRecipeModal] = React.useState(false)
    const navigate = useNavigate()

    useServerSubscriptionShop<ItemStackIDs>(
        {
            URI: `/item_stacks/${intermediateProductID}`,
            key: WSKeys.WSKeyItemStacks,
        },
        {
            callback: (payload) => {
                setItemStackIDs(payload)
            },
        },
    )

    const { first_level_items, parent_intermediate_product_ids, parent_product_ids } = itemStackIDs
    const parentIDs = React.useMemo(() => {
        const pIDs: string[] = []

        if (parent_intermediate_product_ids) {
            pIDs.push(...parent_intermediate_product_ids)
        }

        if (parent_product_ids) {
            pIDs.push(...parent_product_ids)
        }

        return pIDs
    }, [parent_intermediate_product_ids, parent_product_ids])

    const { item_stacks, intermediate_product_stacks } = first_level_items || {}

    const firstLevelItems = React.useMemo<ProductComponent[]>(() => {
        const stackedItems: ProductComponent[] = []

        if (item_stacks && item_stacks.length > 0) {
            item_stacks.forEach((is) => {
                const item = items.find((i) => i.id === is.id)
                if (!item) return

                stackedItems.push({
                    id: item.id,
                    volume: item.volume,
                    type: "item",
                    label: item.label,
                    cost: item.cost,
                    unit_type: item.unit_type,
                    setVolume: is.volume,
                    setUnitType: is.unit_type,
                })
            })
        }

        if (intermediate_product_stacks && intermediate_product_stacks.length > 0) {
            intermediate_product_stacks.forEach((is) => {
                const ip = intermediateProducts.find((i) => i.id === is.id)
                if (!ip) return

                stackedItems.push({
                    id: ip.id,
                    volume: ip.volume,
                    type: "intermediate_product",
                    label: ip.label,
                    cost: ip.cost,
                    unit_type: ip.unit_type,
                    setVolume: is.volume,
                    setUnitType: is.unit_type,
                })
            })
        }

        return stackedItems
    }, [item_stacks, items, intermediate_product_stacks, intermediateProducts])

    // included intermediate products
    const includedIntermediateProducts = React.useMemo(() => {
        if (!intermediate_product_stacks || !intermediateProducts) return null

        return [...intermediate_product_stacks].map((is) => {
            const ip = intermediateProducts.find((i) => i.id === is.id)
            if (!ip) return <React.Fragment key={is.id}></React.Fragment>
            return (
                <Box key={is.id} mb={"0.25rem"}>
                    <ItemCard
                        item={ip}
                        currentUnitType={is.unit_type}
                        currentVolume={is.volume}
                        linkTo={() => {
                            navigate(`/inner_shop/${shopID}/intermediate_products?intermediate_product_id=${ip.id}`)
                        }}
                    />
                </Box>
            )
        })
    }, [intermediate_product_stacks, intermediateProducts, navigate, shopID])

    // included items
    const includedItems = React.useMemo(() => {
        if (!item_stacks || !items) return null

        return [...item_stacks].map((is) => {
            const item = items.find((i) => i.id === is.id)
            if (!item) return <React.Fragment key={is.id}></React.Fragment>
            return (
                <Box key={is.id} mb={"0.25rem"}>
                    <ItemCard
                        item={item}
                        currentUnitType={is.unit_type}
                        currentVolume={is.volume}
                        linkTo={() => {
                            navigate(`/inner_shop/${shopID}/items?item_id=${item.id}`)
                        }}
                    />
                </Box>
            )
        })
    }, [item_stacks, items, navigate, shopID])

    const usedByIntermediateProducts = React.useMemo(() => {
        if (!intermediateProducts || !itemStackIDs || !itemStackIDs.parent_intermediate_product_ids) return null

        return [...intermediateProducts].filter((ip) => itemStackIDs.parent_intermediate_product_ids?.includes(ip.id))
    }, [intermediateProducts, itemStackIDs])

    const usedByProducts = React.useMemo(() => {
        if (!products || !itemStackIDs || !itemStackIDs.parent_product_ids) return null

        return [...products].filter((p) => itemStackIDs.parent_product_ids?.includes(p.id))
    }, [products, itemStackIDs])

    const labelValue = React.useCallback((label: string, value: string) => {
        return (
            <Stack direction={"row"} spacing={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
                <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                    {label}
                </TypographyTrans>
                <Typography variant="h5" fontFamily={fonts.sourceSansProBold}>
                    {value}
                </Typography>
            </Stack>
        )
    }, [])

    const intermediateProduct = React.useMemo(() => {
        if (!intermediateProducts) return null

        return [...intermediateProducts].find((i) => i.id === intermediateProductID)
    }, [intermediateProducts, intermediateProductID])

    if (!intermediateProduct) return null

    const { label, cost, volume, unit_type } = intermediateProduct

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    flex: 1,
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>DETAIL</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Update Intermediate Product",
                            onClick: () => setOpenIntermediateProductModal(true),
                            icon: <ConstructionRoundedIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="column" flex={1} sx={{ p: "1rem" }} spacing={"1rem"} minHeight={0}>
                    {/* Item details */}
                    <Stack direction="row" flex={1} spacing={"3rem"} minHeight={0}>
                        <Stack direction="column" sx={{ width: "60rem" }}>
                            {labelValue("Label:", label)}
                            {labelValue("Cost:", `$ ${roundToDecimalPlaces(cost)}`)}
                            {labelValue("Volume:", `${volume} ${unit_type.toLowerCase()}`)}
                            {labelValue("Unit Price:", calCostRatio(cost, volume, unit_type))}
                            {/* actions */}
                            <Stack direction={"row"} spacing={"1rem"} sx={{ mt: "2rem" }}>
                                <IconLabelButton
                                    icon={<BiSolidFoodMenu style={{ fontSize: "2.25rem", fontWeight: "bolder", color: colors.offWhite }} />}
                                    label={"Export Recipe"}
                                    sx={{ fontWeight: "bold", color: colors.offWhite }}
                                    buttonSx={{
                                        border: `${colors.primary}60 2px solid`,
                                        backgroundColor: `${colors.primary}AA`,
                                    }}
                                    onClick={() => setOpenRecipeModal(true)}
                                />
                            </Stack>
                        </Stack>
                        <Stack direction="column" flex={1}>
                            <Box sx={{ flex: 1, overflowY: "auto", overflowX: "hidden", direction: "ltr" }}>
                                {includedIntermediateProducts}
                                {includedItems}
                            </Box>
                        </Stack>
                    </Stack>

                    {/* Used by */}
                    <Stack direction={"column"} flex={1} minHeight={0}>
                        <DotPointHeader trans title="USED BY" />
                        <Stack direction="row" spacing={"1rem"} flex={1}>
                            <Stack direction="column" flex={1}>
                                <Typography variant="h5" fontFamily={fonts.sourceSansProSemiboldIt}>
                                    Mid Products
                                </Typography>
                                {usedByIntermediateProducts && <IntermediateProductStackList intermediateProducts={usedByIntermediateProducts} />}
                            </Stack>
                            <Divider sx={{ my: ".35rem !important", border: `${colors.primary}80 1px dashed` }} />
                            <Stack direction="column" flex={1}>
                                <Typography variant="h5" fontFamily={fonts.sourceSansProSemiboldIt}>
                                    Products
                                </Typography>
                                {usedByProducts && <ProductStackList products={usedByProducts} />}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {openIntermediateProductModal && (
                <IntermediateProductModal
                    onClose={() => setOpenIntermediateProductModal(false)}
                    intermediateProduct={intermediateProduct}
                    parentIDs={[...parentIDs, intermediateProductID]}
                    initialFirstLevelItems={firstLevelItems}
                />
            )}
            {openRecipeModal && (
                <RecipeModal
                    itemType={ItemType.INTERMEDIATE_PRODUCT}
                    onClose={() => setOpenRecipeModal(false)}
                    shopID={shopID}
                    exportItem={intermediateProduct}
                    components={firstLevelItems}
                />
            )}
        </>
    )
}

export default IntermediateProductDetail
