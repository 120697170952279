import { Stack } from "@mui/material"
import { ShopSalaryPayout } from "../shopSalaryPayout"

interface ShopOverviewProps {
    shopID: string
}

export const ShopOverview = ({ shopID }: ShopOverviewProps) => {
    return (
        <Stack
            sx={{
                flex: 1,
                height: "100%",
                p: "1rem",
            }}
        >
            <ShopSalaryPayout shopID={shopID} />
        </Stack>
    )
}
