import { Stack } from "@mui/material"
import moment from "moment"
import React from "react"
import { SendFunc } from "../../../../../containers/ws"
import { snakeToTitle } from "../../../../../helpers"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { UserShopLeaveRequest } from "../../../../../types/Shop"
import { DateFormat } from "../../../../../types/types"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"

interface UserShopLeaveRequestCancelModalProps {
    leaveRequest: UserShopLeaveRequest
    onClose: () => void
    send: SendFunc
}

export const UserShopLeaveRequestCancelModal = ({ leaveRequest, onClose, send }: UserShopLeaveRequestCancelModalProps) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const cancelUserShopLeaveRequest = React.useCallback(async () => {
        setIsLoading(true)
        try {
            const resp = await send(WSKeys.WSKeyUserShopLeaveRequestCancel, {
                id: leaveRequest.id,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to cancel user leave request.")
        } finally {
            setIsLoading(false)
        }
        setIsLoading(false)
    }, [leaveRequest, onClose, send])

    const labelValue = (label: string, value: string) => {
        return (
            <Stack direction={"row"} spacing={"0.5rem"}>
                <TypographyTrans minWidth={"6rem"} variant={"body1"} fontFamily={fonts.sourceSansProSemibold}>
                    {label}
                </TypographyTrans>
                <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProBold} color={colors.primary}>
                    {value}
                </TypographyTrans>
            </Stack>
        )
    }

    return (
        <ConfirmModal title={`Cancel User Leave`} onClose={onClose} onConfirm={cancelUserShopLeaveRequest} isLoading={isLoading} error={error} omitCancel>
            <Stack flex={1} spacing={"1rem"} sx={{ pb: "2rem" }}>
                <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProBold}>
                    Are you sure you want to reject this leave request?
                </TypographyTrans>

                {labelValue("Date:", moment(leaveRequest.date).format(DateFormat.DateDay))}
                {labelValue("Type:", snakeToTitle(leaveRequest.leave_type))}
                {labelValue("Hours:", `${leaveRequest.hours} hr`)}
            </Stack>
        </ConfirmModal>
    )
}
