import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../containers/permissions"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { Shop } from "../../../../types/Shop"
import { DeviceToken } from "../../../../types/device_token"
import { Permission } from "../../../../types/permissions"
import { TypographyTrans } from "../../../common/TypographyTrans"
import ProgressBarTimer from "../../../timer/progressBarTimer"

interface Prop {
    shop: Shop
}

export const ShopDevice = ({ shop }: Prop) => {
    const [deviceToken, setDeviceToken] = React.useState<DeviceToken>()
    const { send } = useServerCommandsShop()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const { hasPerm } = usePermissions()
    const [canGenerate, setCanGenerate] = React.useState(true)
    const [expired, setExpired] = React.useState(false)

    const { paired } = deviceToken || {}

    useServerSubscriptionShop<DeviceToken>(
        {
            URI: "/device_token",
            key: WSKeys.WSKeyShopDeviceTokenGet,
            ready: hasPerm(Permission.ShopDeviceTokenGenerate),
        },
        {
            callback: (payload) => {
                if (!payload) return

                setDeviceToken(payload)
                if (new Date(payload.expires_at) > new Date()) setCanGenerate(false)
            },
        },
    )

    const generateToken = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopDeviceTokenGenerate) || !canGenerate || isLoading) return

        setIsLoading(true)
        try {
            const token = await send<DeviceToken>(WSKeys.WSKeyShopDeviceTokenGenerate)
            setDeviceToken(token)
            setCanGenerate(false)
            setExpired(false)
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to generate token.")
        } finally {
            setIsLoading(false)
        }
    }, [send, hasPerm, isLoading, canGenerate])

    const TimerDisplay = React.useMemo(() => {
        if (expired)
            return (
                <Box
                    sx={{
                        backgroundColor: `${colors.lightRed}CC`,
                        px: "1.2rem",
                        py: 0,
                        borderRadius: "20rem",
                    }}
                >
                    <Typography variant="body2" fontFamily={fonts.sourceSansProSemibold} color={colors.offWhite}>
                        Expired
                    </Typography>
                </Box>
            )

        if (deviceToken) {
            return (
                <ProgressBarTimer
                    key={deviceToken.id}
                    startedAt={new Date(deviceToken.created_at)}
                    endedAt={new Date(deviceToken.expires_at)}
                    onTimeUp={() => {
                        setCanGenerate(true)
                        setExpired(true)
                    }}
                />
            )
        }

        return null
    }, [expired, deviceToken])

    return (
        <Stack flex={1} spacing={"1rem"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    Device
                </TypographyTrans>
                {/* {<AutoSavingIndicator isLoading={isChanging} />}
                {!isChanging && error && <Typography color={colors.lightRed}>{error}</Typography>} */}
            </Stack>
            <Stack flex={1} spacing={"1rem"}>
                <Stack sx={{ height: "fit-content" }}>
                    <Stack direction={"row"} spacing={"1rem"} alignItems={"center"}>
                        <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                            Token
                        </Typography>
                        {!paired && TimerDisplay}
                    </Stack>
                    {paired ? (
                        <Stack
                            direction={"row"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                opacity: 0.5,
                            }}
                        >
                            <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                                Successfully Paired
                            </Typography>
                        </Stack>
                    ) : (
                        <Box
                            flex={1}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                maxHeight: "4.2rem",
                                minHeight: "4.2rem",
                                borderTop: `2px ${colors.primary}AA solid`,
                                borderBottom: `2px ${colors.primary}AA solid`,
                                borderLeft: `2px ${colors.primary}AA solid`,
                                py: "1rem",
                                pl: "1.5rem",
                                opacity: canGenerate ? 1 : 0.5,
                            }}
                        >
                            <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                                {deviceToken?.token}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    py: 0,
                                    px: "1rem",
                                    height: "4.2rem",
                                    backgroundColor: `${colors.primary}`,
                                    ":hover": {
                                        backgroundColor: `${colors.primary}`,
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={generateToken}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: fonts.sourceSansProSemibold,
                                        color: `${colors.offWhite}`,
                                    }}
                                >
                                    Generate
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}
