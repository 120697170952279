import { Stack, Typography } from "@mui/material"

export const WarehouseOverview = () => {
    return (
        <Stack
            direction="column"
            sx={{
                width: "60rem",
                height: "100%",
            }}
        >
            <Typography variant="h5">
                <strong>OVERVIEW</strong>
            </Typography>
        </Stack>
    )
}
