import { Divider, Stack, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { colors, fonts } from "../../theme/theme"
import { TypographyTrans } from "./TypographyTrans"

interface DotPointHeaderProps {
    title: string
    appendedComponent?: React.ReactNode
    suffixComponent?: React.ReactNode
    titleSx?: SxProps
    trans?: boolean
}

export const DotPointHeader = ({ title, appendedComponent, suffixComponent, titleSx, trans }: DotPointHeaderProps) => {
    const titleDisplay = React.useMemo(() => {
        if (trans)
            return (
                <TypographyTrans
                    variant="h4"
                    sx={{
                        fontFamily: fonts.sourceSansProBoldIt,
                        ...titleSx,
                    }}
                >
                    {title}
                </TypographyTrans>
            )

        return (
            <Typography
                variant="h4"
                sx={{
                    fontFamily: fonts.sourceSansProBoldIt,
                    ...titleSx,
                }}
            >
                {title}
            </Typography>
        )
    }, [title, titleSx, trans])
    return (
        <Stack direction="column">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                    {titleDisplay}

                    {appendedComponent}
                </Stack>

                {suffixComponent}
            </Stack>
            <Divider sx={{ my: ".35rem !important", border: `${colors.primary}75 2px double` }} />
        </Stack>
    )
}
