import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { usePermissions } from "../../../../../../containers/permissions"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../../../hooks/useServer"
import { WSKeys } from "../../../../../../key"
import { colors, fonts } from "../../../../../../theme/theme"
import { ShopJobWage, ShopMember } from "../../../../../../types/Shop"
import { Permission } from "../../../../../../types/permissions"
import { TypographyTrans } from "../../../../../common/TypographyTrans"
import { ShopButton } from "../../../../../common/shopButton"
import { ShopJobWageEntitledMemberCard } from "./shopJobWageEntitledMemberCard"

interface ShopJobWageMemberSyncPanelProps {
    shopJobLevelID: string
}

export const ShopJobWageEntitledMemberPanel = ({ shopJobLevelID }: ShopJobWageMemberSyncPanelProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [error, setError] = React.useState("")
    const [entitledMembers, setEntitledMembers] = React.useState<ShopMember[]>([])
    useServerSubscriptionShop<ShopMember[]>(
        {
            URI: `/job_level/${shopJobLevelID}/entitled_members`,
            key: WSKeys.WSKeyShopJobLevelEntitledMembers,
            ready: hasPerm(Permission.ShopMemberView),
        },
        {
            callback: (payload) => {
                setEntitledMembers((prev) => {
                    if (prev.length === 0)
                        return payload.filter((m) => !m.deleted_at).sort((a, b) => a.payslip_prefer_name.localeCompare(b.payslip_prefer_name))
                    // update existing members
                    prev = prev.map((m) => payload.find((p) => p.id === m.id) || m)
                    // add new data to the list
                    payload.forEach((p) => (prev.some((m) => m.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((m) => !m.deleted_at).sort((a, b) => a.payslip_prefer_name.localeCompare(b.payslip_prefer_name))
                })
            },
        },
    )

    const [shopJobWages, setShopJobWages] = React.useState<ShopJobWage[]>([])
    useServerSubscriptionShop<ShopJobWage[]>(
        {
            URI: `/job_level/${shopJobLevelID}/job_wages`,
            key: WSKeys.WSKeyShopJobWages,
            ready: !!shopJobLevelID && hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobWages((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                    prev = prev.map((jw) => payload.find((p) => p.id === jw.id) || jw)
                    payload.forEach((p) => (prev.some((jw) => jw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jw) => !jw.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )

    const wageSyncRequired = React.useMemo(() => {
        for (let i = 0; i < entitledMembers.length; i++) {
            const em = entitledMembers[i]
            const wage = shopJobWages.find((sjw) => sjw.id === em.job_wage_id)
            if (!wage) continue
            if (
                wage.weekday_pay === em.weekday_pay &&
                wage.saturday_pay === em.saturday_pay &&
                wage.sunday_pay === em.sunday_pay &&
                wage.holiday_pay === em.holiday_pay
            ) {
                continue
            }
            return true
        }
        return false
    }, [entitledMembers, shopJobWages])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {entitledMembers.map((shopMember) => {
                        return (
                            <div key={`shop-member-${shopMember.id}`} style={{ marginBottom: ".3rem" }}>
                                <ShopJobWageEntitledMemberCard shopMember={shopMember} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [entitledMembers])

    const wageSync = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have the permission.")
            return
        }

        if (entitledMembers.length === 0) return

        try {
            await send(WSKeys.WSKeyShopMembersWageSync, { user_ids: entitledMembers.map((em) => em.id) })
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to sync members' wage.")
        }
    }, [entitledMembers, hasPerm, send])

    return (
        <Stack
            spacing={"1rem"}
            sx={{
                width: "35rem",
                px: "1rem",
            }}
        >
            <Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ pr: ".5rem" }}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        Entitled Members
                    </TypographyTrans>

                    <ShopButton trans disabled={!wageSyncRequired} buttonColor={colors.green} sx={{ p: 0 }} onClick={wageSync}>
                        sync
                    </ShopButton>
                    {error && (
                        <Typography variant={"h6"} color={colors.lightRed}>
                            {error}
                        </Typography>
                    )}
                </Stack>
            </Stack>

            <Stack
                flex={1}
                sx={{
                    pr: ".5rem",
                    overflowY: "auto",
                    overflowX: "hidden",
                    direction: "ltr",
                }}
            >
                {content}
            </Stack>
        </Stack>
    )
}
