import { Divider, Stack, StackProps, Typography, TypographyProps } from "@mui/material"
import moment from "moment"
import React, { useCallback } from "react"
import { SERVER_HOST } from "../../../constants"
import { useOrganisation } from "../../../containers/organisations"
import { usePermissions } from "../../../containers/permissions"
import { SendFunc } from "../../../containers/ws"
import { roundToDecimalPlaces, roundedTo, salaryCalculator, salaryYTDCalculator, snakeToTitle } from "../../../helpers"
import { EmploymentStatus, WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import {
    Shop,
    ShopMember,
    UserOrdinaryPyament,
    UserShopLeaveRequest,
    UserShopPayslipBrief,
    UserShopPayslipComplete,
    UserShopSpecialPayslipRequirement,
    UserShopWorkRecord,
    UserShopYTDPayment,
} from "../../../types/Shop"
import { SalaryPayCycle, TaxRateType } from "../../../types/organisations"
import { Permission } from "../../../types/permissions"
import { DateFormat } from "../../../types/types"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ShopButton } from "../../common/shopButton"

interface Props {
    payslips: UserShopPayslipBrief[]
    payslipID: string
    shop: Shop
    shopMember: ShopMember
    startDate: moment.Moment
    endDate: moment.Moment
    workRecords: UserShopWorkRecord[]
    ordinaryPayments: UserOrdinaryPyament[]
    leaveRequests: UserShopLeaveRequest[]
    taxRateTypes: TaxRateType[]
    superannuationRate: string
    userShopYTDPayment?: UserShopYTDPayment
    userShopSpecialPayslipRequirement?: UserShopSpecialPayslipRequirement
    send: SendFunc
}

export const PayslipTemplate = ({
    payslips,
    payslipID,
    shop,
    shopMember,
    startDate,
    endDate,
    workRecords,
    ordinaryPayments,
    leaveRequests,
    taxRateTypes,
    superannuationRate,
    userShopYTDPayment,
    userShopSpecialPayslipRequirement,
    send,
}: Props) => {
    const { organisation } = useOrganisation()
    const { hasPerm } = usePermissions()
    const [payslipComplete, setPayslipComplete] = React.useState<UserShopPayslipComplete>()
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    const showLeaveOption = React.useMemo(() => {
        if (!shopMember) return false
        return [EmploymentStatus.FullTime, EmploymentStatus.PartTime].includes(shopMember.employment_status)
    }, [shopMember])

    const paymentCycle = organisation?.salary_pay_cycle || SalaryPayCycle.Weekly
    const taxRateType = React.useMemo(() => {
        return taxRateTypes.find((t) => t.tax_rate_type_label === shopMember.tax_rate_type_label)
    }, [shopMember, taxRateTypes])

    const {
        weekdayPayRate,
        ordinaryHours,
        weekdayHours,
        saturdayHours,
        sundayHours,
        ordinaryHoursPay,
        weekdayPay,
        saturdayPay,
        sundayPay,
        totalEarnings,
        netPay,
        tax,
        superannuation,
        annualLeavePay,
        personalCareLeavePay,
        timeInLieuPay,
        annualLeaveAccrued,
        annualLeaveUsed,
        personalCareLeaveAccrued,
        personalCareLeaveUsed,
        timeInLieuUsed,
        annualLeaveBalance,
        personalCareLeaveBalance,
        timeInLieuLeaveBalance,
    } = React.useMemo(() => {
        if (!payslipComplete)
            return salaryCalculator(
                shopMember,
                workRecords,
                ordinaryPayments,
                leaveRequests,
                paymentCycle,
                taxRateType,
                superannuationRate,
                userShopSpecialPayslipRequirement,
            )

        return {
            weekdayPayRate: roundedTo(payslipComplete.weekday_wage, 2),
            ordinaryHours: roundedTo(payslipComplete.ordinary_hours, 2),
            weekdayHours: roundedTo(payslipComplete.weekday_hours, 2),
            saturdayHours: roundedTo(payslipComplete.saturday_hours, 2),
            sundayHours: roundedTo(payslipComplete.sunday_hours, 2),
            ordinaryHoursPay: roundedTo(parseFloat(payslipComplete.ordinary_hours) * parseFloat(payslipComplete.weekday_wage), 2),
            weekdayPay: roundedTo(parseFloat(payslipComplete.weekday_hours) * parseFloat(payslipComplete.weekday_wage), 2),
            saturdayPay: roundedTo(parseFloat(payslipComplete.saturday_hours) * parseFloat(payslipComplete.saturday_wage), 2),
            sundayPay: roundedTo(parseFloat(payslipComplete.sunday_hours) * parseFloat(payslipComplete.sunday_wage), 2),
            totalEarnings: roundedTo(payslipComplete.total_earnings),
            netPay: roundedTo(payslipComplete.net_pay),
            tax: roundedTo(payslipComplete.tax),
            superannuation: roundedTo(payslipComplete.superannuation),
            annualLeavePay: roundedTo(parseFloat(payslipComplete.annual_leave_used) * parseFloat(payslipComplete.weekday_wage)),
            personalCareLeavePay: roundedTo(parseFloat(payslipComplete.personal_care_leave_used) * parseFloat(payslipComplete.weekday_wage)),
            timeInLieuPay: roundedTo(parseFloat(payslipComplete.time_in_lieu_used) * parseFloat(payslipComplete.weekday_wage)),
            annualLeaveAccrued: roundedTo(payslipComplete.annual_leave_accrued, 4),
            annualLeaveUsed: roundedTo(payslipComplete.annual_leave_used, 4),
            personalCareLeaveAccrued: roundedTo(payslipComplete.personal_care_leave_accrued, 4),
            personalCareLeaveUsed: roundedTo(payslipComplete.personal_care_leave_used, 4),
            timeInLieuUsed: roundedTo(payslipComplete.time_in_lieu_used, 4),
            annualLeaveBalance: roundedTo(payslipComplete.annual_leave_balance, 4),
            personalCareLeaveBalance: roundedTo(payslipComplete.personal_care_leave_balance, 4),
            timeInLieuLeaveBalance: roundedTo(payslipComplete.time_in_lieu_balance, 4),
        }
    }, [
        shopMember,
        workRecords,
        ordinaryPayments,
        leaveRequests,
        taxRateType,
        paymentCycle,
        payslipComplete,
        userShopSpecialPayslipRequirement,
        superannuationRate,
    ])

    const { totalEarningsYTD, taxYTD, superannuationYTD } = React.useMemo(() => {
        if (!payslipComplete) {
            const { totalEarningsYTD, taxYTD, superannuationYTD, ...rest } = salaryYTDCalculator(userShopYTDPayment)
            return {
                totalEarningsYTD: totalEarnings + totalEarningsYTD,
                taxYTD: tax + taxYTD,
                superannuationYTD: superannuation + superannuationYTD,
                ...rest,
            }
        }
        return {
            weekdayPayYTD: roundedTo(payslipComplete.weekday_pay_ytd),
            saturdayPayYTD: roundedTo(payslipComplete.saturday_pay_ytd),
            sundayPayYTD: roundedTo(payslipComplete.sunday_pay_ytd),
            holidayPayYTD: roundedTo(payslipComplete.holiday_pay_ytd),
            totalEarningsYTD: roundedTo(payslipComplete.total_earnings_ytd),
            taxYTD: roundedTo(payslipComplete.tax_ytd),
            superannuationYTD: roundedTo(payslipComplete.superannuation_ytd),
        }
    }, [userShopYTDPayment, payslipComplete, totalEarnings, tax, superannuation])

    const fetchUserShopPayslip = React.useCallback(async () => {
        if (!payslipID) {
            setPayslipComplete(undefined)
            return
        }

        try {
            setLoading(true)
            const resp = await send<UserShopPayslipComplete>(WSKeys.WSKeyUserShopPayslip, { id: payslipID })
            if (!resp) return
            setPayslipComplete(resp)
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to load payslip.")
        } finally {
            setLoading(false)
        }
    }, [payslipID, send, setError, setLoading])
    React.useEffect(() => {
        fetchUserShopPayslip()
    }, [payslipID, fetchUserShopPayslip])

    const downloadPayslip = useCallback(() => {
        fetch(`${window.location.protocol}//${SERVER_HOST}/api/files/payslip`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopMember.shop_id,
                user_id: shopMember.user_id,
                start_date: startDate.startOf("day"),
                end_date: endDate.endOf("day").startOf("second"),
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    response.json().then(console.log)
                    return
                }

                response
                    .blob()
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement("a")
                        a.href = url
                        a.download = `${shopMember.payslip_prefer_name}_payslip_${startDate.format("DDMMYYYY")}_${endDate.format("DDMMYYYY")}.pdf`
                        a.click()
                    })
                    .catch((e) => {
                        setError(typeof e === "string" ? e : e.message)
                    })
            })
            .catch((e) => {
                console.log(e)
                setError(typeof e === "string" ? e : e.message)
            })
    }, [shopMember, startDate, endDate, setError])

    // const payslipStatus = React.useMemo(() => {
    //     if (payslips.length === 0) {
    //         if (workRecords.length === 0 && leaveRequests.length === 0) return StaffPayslipSessionStatus.NO_RECORDS
    //         return StaffPayslipSessionStatus.READY
    //     }

    //     if (payslips.length === 1) {
    //         const payslip = payslips[0]
    //         if (moment(payslip.start_date).isSame(startDate) && moment(payslip.end_date).isSame(endDate)) {
    //             return StaffPayslipSessionStatus.SENT
    //         }
    //     }
    //     return StaffPayslipSessionStatus.OVERLAPPED
    // }, [payslips, startDate, endDate, workRecords, leaveRequests])

    const sendPayslip = useCallback(async () => {
        if (!hasPerm(Permission.OrgPermissionAccountantUpdate)) {
            setError("You do not have permission to perform this action.")
            return
        }

        try {
            setLoading(true)
            const resp = await send(payslipID ? WSKeys.WSKeyUserShopPayslipSend : WSKeys.WSKeyUserShopPayslipConfirmAndSend, {
                id: payslipID,
                shop_id: shopMember.shop_id,
                user_id: shopMember.user_id,
                start_date: startDate,
                end_date: endDate,
            })
            if (!resp) return
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to send payslip.")
        } finally {
            setLoading(false)
        }
    }, [send, shopMember, startDate, endDate, setError, setLoading, hasPerm, payslipID])

    return (
        <Stack
            spacing={"1.5rem"}
            flex={1}
            height={"100%"}
            sx={{
                border: `1px solid ${colors.grey}`,
                borderRadius: 0.8,
                p: "2rem",
            }}
        >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"} spacing={"1rem"}>
                    <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProBlack}>
                        PAYSLIP
                    </TypographyTrans>
                    {userShopSpecialPayslipRequirement && (
                        <Typography variant="h5" fontFamily={fonts.sourceSansProBlack} sx={{ color: `${colors.lightRed}80` }}>
                            [ Modified ]
                        </Typography>
                    )}
                </Stack>
                {shopMember.employment_status !== EmploymentStatus.Contract && (
                    <Stack direction={"row"} spacing={"2rem"}>
                        <ShopButton
                            sx={{
                                py: 0,
                                px: "1rem",
                                backgroundColor: colors.primary,
                                ":hover": {
                                    backgroundColor: colors.primary,
                                },
                            }}
                            onClick={downloadPayslip}
                        >
                            Download
                        </ShopButton>
                        <ShopButton
                            sx={{
                                py: 0,
                                px: "1rem",
                                backgroundColor: colors.primary,
                                ":hover": {
                                    backgroundColor: colors.primary,
                                },
                            }}
                            onClick={sendPayslip}
                        >
                            Send
                        </ShopButton>
                    </Stack>
                )}
            </Stack>
            <Divider sx={{ my: ".35rem !important", border: `black 1px solid` }} />
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"column"} spacing={".5rem"}>
                    <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                        PAID FOR
                    </TypographyTrans>
                    <Stack direction={"column"}>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProBold}>{`${shopMember.payslip_full_name} ${
                            shopMember.prefer_name !== shopMember.payslip_prefer_name ? `(${shopMember.payslip_prefer_name})` : ""
                        }`}</TypographyTrans>
                        <BoldedSpan>
                            Pay Rate: <span>${roundToDecimalPlaces(weekdayPayRate)}</span> per hour
                        </BoldedSpan>
                        <BoldedSpan>
                            Employment Type: <span>{snakeToTitle(shopMember.employment_status)}</span>
                        </BoldedSpan>
                        <BoldedSpan>
                            Job Title: <span>{shopMember.job_title}</span>
                        </BoldedSpan>
                    </Stack>
                </Stack>
                <Stack direction={"column"} spacing={".5rem"} alignItems={"flex-end"}>
                    <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                        PAID FROM
                    </TypographyTrans>
                    <Stack direction={"column"} alignItems={"flex-end"}>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProBold}>
                            {shop.business_name}
                        </TypographyTrans>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProBold}>
                            {shop.abn}
                        </TypographyTrans>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProBold}>
                            {shop.address}
                        </TypographyTrans>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                    px: "1rem",
                    py: "1rem",
                    borderTop: `${colors.grey}99 1px solid`,
                    borderBottom: `${colors.grey}99 1px solid`,
                    backgroundColor: `${colors.grey}15`,
                }}
            >
                <BoldedSpan>
                    Pay Period:{" "}
                    <span>
                        {startDate.format(DateFormat.DateOnly)} - {endDate.format(DateFormat.DateOnly)}
                    </span>
                </BoldedSpan>
                <BoldedSpan>
                    Payment Date:{" "}
                    <span>
                        {payslipComplete?.created_at ? moment(payslipComplete.created_at).format(DateFormat.DateOnly) : moment().format(DateFormat.DateOnly)}
                    </span>
                </BoldedSpan>
                <BoldedSpan>
                    Total Earnings: <span>${roundToDecimalPlaces(totalEarnings)}</span>
                </BoldedSpan>
                <BoldedSpan>
                    Net Pay: <span>${roundToDecimalPlaces(netPay)}</span>
                </BoldedSpan>
            </Stack>

            <PayslipGrid>
                <div />
                <div />
                <div />
                <Typography textAlign={"end"} fontFamily={fonts.sourceSansProBold}>
                    THIS PAY
                </Typography>
                <Typography textAlign={"end"} fontFamily={fonts.sourceSansProBold}>
                    YTD
                </Typography>
            </PayslipGrid>

            <Divider sx={{ my: ".35rem !important", borderTop: `black 1px solid` }} />

            {/* Earning */}
            <Stack direction={"column"}>
                <PayslipGrid>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        SALARY & WAGES
                    </Typography>
                    <div />
                    <Typography variant="h6" textAlign={"end"}>
                        RATE
                    </Typography>
                    <div />
                    <div />
                </PayslipGrid>

                {ordinaryHours > 0 && (
                    <SalaryRow title="Ordinary Hours Pay" hours={ordinaryHours} payRate={roundToDecimalPlaces(weekdayPayRate)} thisPay={ordinaryHoursPay} />
                )}
                {weekdayHours > 0 && <SalaryRow title="Weekday Pay" hours={weekdayHours} payRate={roundToDecimalPlaces(weekdayPayRate)} thisPay={weekdayPay} />}
                {saturdayHours > 0 && <SalaryRow title="Saturday Pay" hours={saturdayHours} payRate={shopMember.saturday_pay} thisPay={saturdayPay} />}
                {sundayHours > 0 && <SalaryRow title="Sunday Pay" hours={sundayHours} payRate={shopMember.sunday_pay} thisPay={sundayPay} />}
                {annualLeavePay > 0 && <SalaryRow title="Annual Leave Pay" hours={annualLeaveUsed} payRate={shopMember.weekday_pay} thisPay={annualLeavePay} />}
                {personalCareLeavePay > 0 && (
                    <SalaryRow title="Personal Care Leave Pay" hours={personalCareLeaveUsed} payRate={shopMember.weekday_pay} thisPay={personalCareLeavePay} />
                )}
                {timeInLieuPay > 0 && <SalaryRow title="Time In Lieu Pay" hours={timeInLieuUsed} payRate={shopMember.weekday_pay} thisPay={timeInLieuPay} />}
            </Stack>
            <PayslipGrid
                sx={{
                    py: ".5rem",
                    borderTop: `${colors.grey}99 1px solid`,
                    borderBottom: `${colors.grey}99 1px solid`,
                    backgroundColor: `${colors.grey}15`,
                }}
            >
                <div />
                <div />
                <Typography textAlign={"end"}>TOTAL</Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(totalEarnings)}
                </Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(totalEarningsYTD)}
                </Typography>
            </PayslipGrid>

            {/* TAX */}
            <Stack>
                <PayslipGrid>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        TAX
                    </Typography>
                </PayslipGrid>
                <PayslipGrid>
                    <Typography variant="h6">PAYG</Typography>
                    <div />
                    <div />
                    <Typography variant="h6" textAlign={"end"}>
                        ${roundToDecimalPlaces(tax)}
                    </Typography>
                    <div />
                </PayslipGrid>
            </Stack>
            <PayslipGrid
                sx={{
                    py: ".5rem",
                    borderTop: `${colors.grey}99 1px solid`,
                    borderBottom: `${colors.grey}99 1px solid`,
                    backgroundColor: `${colors.grey}15`,
                }}
            >
                <div />
                <div />
                <Typography textAlign={"end"}>TOTAL</Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(tax)}
                </Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(taxYTD)}
                </Typography>
            </PayslipGrid>

            {/* Superannuation */}
            <Stack>
                <PayslipGrid>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        SUPERANNUATION
                    </Typography>
                </PayslipGrid>
                <PayslipGrid>
                    <Typography variant="h6">Contribution</Typography>
                    <div />
                    <div />
                    <Typography variant="h6" textAlign={"end"}>
                        ${roundToDecimalPlaces(superannuation)}
                    </Typography>
                    <div />
                </PayslipGrid>
            </Stack>
            <PayslipGrid
                sx={{
                    py: ".5rem",
                    borderTop: `${colors.grey}99 1px solid`,
                    borderBottom: `${colors.grey}99 1px solid`,
                    backgroundColor: `${colors.grey}15`,
                }}
            >
                <div />
                <div />
                <Typography textAlign={"end"}>TOTAL</Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(superannuation)}
                </Typography>
                <Typography variant="h6" fontFamily={fonts.sourceSansProBold} textAlign={"end"}>
                    ${roundToDecimalPlaces(superannuationYTD)}
                </Typography>
            </PayslipGrid>

            {/* NOTE: hide leave section for now, till figuring out the correct leave calculation */}
            {/* {showLeaveOption && (
                <>
                    <PayslipGrid>
                        <Typography fontFamily={fonts.sourceSansProBold}>LEAVE</Typography>
                        <div />
                        <Typography textAlign={"end"}>ACCRUED</Typography>
                        <Typography textAlign={"end"}>USED</Typography>
                        <Typography textAlign={"end"}>BALANCE</Typography>
                    </PayslipGrid>
                    <Divider sx={{ my: ".35rem !important", borderTop: `black 1px solid` }} />
                    <Stack>
                        <LeaveRow title="Annual Leave in Hours" accrued={annualLeaveAccrued} used={annualLeaveUsed} balance={annualLeaveBalance} />
                        <LeaveRow
                            title="Personal care leave in Hours"
                            accrued={personalCareLeaveAccrued}
                            used={personalCareLeaveUsed}
                            balance={personalCareLeaveBalance}
                        />
                        <LeaveRow title="Time in Lieu in Hours" accrued={0} used={timeInLieuUsed} balance={timeInLieuLeaveBalance} />
                    </Stack>
                </>
            )} */}
        </Stack>
    )
}

const BoldedSpan = ({ children, ...props }: TypographyProps) => {
    return (
        <TypographyTrans
            variant="h6"
            fontFamily={fonts.sourceSansProRegular}
            color={`${colors.black3}DD`}
            sx={{
                span: {
                    fontFamily: fonts.sourceSansProBold,
                    color: colors.black3,
                },
            }}
            {...props}
        >
            {children}
        </TypographyTrans>
    )
}

interface SalaryRowProps {
    title: string
    hours: number
    payRate: string
    thisPay: number
}
const SalaryRow = ({ title, hours, payRate, thisPay }: SalaryRowProps) => {
    return (
        <PayslipGrid>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h6" textAlign={"end"}>
                {roundToDecimalPlaces(hours)}
            </Typography>
            <Typography variant="h6" textAlign={"end"}>
                ${roundToDecimalPlaces(payRate)}
            </Typography>
            <Typography variant="h6" textAlign={"end"}>
                ${roundToDecimalPlaces(thisPay)}
            </Typography>
            <div />
        </PayslipGrid>
    )
}

interface LeaveRowProps {
    title: string
    accrued: number
    used: number
    balance: number
}
const LeaveRow = ({ title, accrued, used, balance }: LeaveRowProps) => {
    return (
        <PayslipGrid>
            <Typography variant="h6">{title}</Typography>
            <div />
            <Typography variant="h6" textAlign={"end"}>
                {roundToDecimalPlaces(accrued, 4)}
            </Typography>
            <Typography variant="h6" textAlign={"end"}>
                {roundToDecimalPlaces(used, 4)}
            </Typography>
            <Typography variant="h6" textAlign={"end"}>
                {roundToDecimalPlaces(balance, 4)}
            </Typography>
        </PayslipGrid>
    )
}

const PayslipGrid = ({ children, sx }: StackProps) => {
    return (
        <Stack
            sx={{
                display: "grid",
                gridTemplateColumns: "38% 13% 15% 17% 17%",
                borderRadius: 0.8,
                px: "1rem",
                ...sx,
            }}
        >
            {children}
        </Stack>
    )
}
