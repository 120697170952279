import React from "react"
import { useServerSubscriptionOrg } from "../hooks/useServer"
import { WSKeys } from "../key"
import { ImportRecord } from "../types/importRecords"
import { Permission } from "../types/permissions"
import { usePermissions } from "./permissions"

export interface ImportRecordState {
    importRecords: ImportRecord[]
}

const initialState: ImportRecordState = {
    importRecords: [],
}

export const ImportRecordContext = React.createContext<ImportRecordState>(initialState)

export const ImportRecordProvider = ({ children }: { children: React.ReactNode }) => {
    const { hasPerm } = usePermissions()

    const [importRecords, setImportRecords] = React.useState<ImportRecord[]>([])
    useServerSubscriptionOrg<ImportRecord[]>(
        {
            URI: "/import_record_list",
            key: WSKeys.WSKeyImportRecordList,
            ready: hasPerm(Permission.ImportRecordView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setImportRecords((prev) => {
                    if (prev.length === 0) {
                        return payload
                    }

                    // replace the one that already exists
                    const list = [...prev].map((importRecord) => payload.find((p) => p.id === importRecord.id) || importRecord)

                    // append any new items
                    payload.forEach((p) => {
                        if (list.some((importRecord) => importRecord.id === p.id)) return
                        list.push(p)
                    })

                    // set new item list
                    return list.filter((importRecord) => !importRecord.deleted_at)
                })
            },
        },
    )

    return (
        <ImportRecordContext.Provider
            value={{
                importRecords,
            }}
        >
            {children}
        </ImportRecordContext.Provider>
    )
}

export const useImportRecord = () => {
    return React.useContext<ImportRecordState>(ImportRecordContext)
}
