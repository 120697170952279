import { Action } from "react-fetching-library"
import { SERVER_HOST } from "../constants"
import { User } from "../types/users"

export const LoginCheck = (): Action<User> => {
    return {
        method: "GET",
        endpoint: `${window.location.protocol}//${SERVER_HOST}/api/auth/check`,
        credentials: "include",
        responseType: "json",
    }
}

export const Logout = (): Action<boolean> => {
    return {
        method: "GET",
        endpoint: `${window.location.protocol}//${SERVER_HOST}/api/auth/logout`,
        credentials: "include",
        responseType: "json",
    }
}
