import { Autocomplete, Box, Stack, TextField, Typography, createFilterOptions } from "@mui/material"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { colors, fonts } from "../../../theme/theme"
import { TypographyTrans } from "../TypographyTrans"

interface MultiSelectFieldProps {
    control: Control<any, object>
    rules?: any
    name: string

    label?: React.ReactNode
    options: SelectBoxOption[]
    onChange?: (value: SelectBoxOption | string) => void
    trans?: boolean
}
export interface SelectBoxOption {
    id: string
    label: string
    description?: string
}
export const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: SelectBoxOption) => `${option.label} ${option.description || ""}`.toLowerCase(),
})

export const MultiSelectField = ({ control, rules, name, options, label, onChange, trans }: MultiSelectFieldProps) => {
    const inputLabel = React.useMemo(() => {
        if (!label) return null
        if (typeof label === "string") {
            if (trans)
                return (
                    <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                        {label}
                    </TypographyTrans>
                )
            return (
                <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                    {label}
                </Typography>
            )
        }
        return label
    }, [label, trans])

    return (
        <Stack>
            {inputLabel}
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue=""
                render={({ field, fieldState }) => (
                    <Stack>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            fullWidth
                            value={options.filter((option) => field.value.includes(option.id))}
                            options={options}
                            filterSelectedOptions
                            filterOptions={filterOptions}
                            sx={{
                                border: `${fieldState.error ? colors.lightRed : colors.primary}50 2px solid`,
                                borderRadius: 0.5,
                                "&& .MuiInputBase-hiddenLabel": {
                                    p: 0,
                                    backgroundColor: "unset",
                                },
                                "&& .MuiOutlinedInput-root": {
                                    p: 0,
                                    backgroundColor: "unset",
                                },
                                "&& .MuiAutocomplete-input": {
                                    height: "4rem",
                                    px: "1.5rem",
                                    py: "0",
                                },
                                "&& .MuiChip-root": {
                                    height: "26px",
                                    backgroundColor: `${colors.primary}50`,
                                    ".MuiChip-label": {
                                        fontSize: "1.5rem",
                                        fontFamily: fonts.sourceSansProBold,
                                    },
                                    ".MuiSvgIcon-root": {
                                        margin: "0 2px 0 -10px",
                                        color: "aliceblue",
                                    },
                                },
                            }}
                            onChange={(e, value) => {
                                field.onChange(value.map((v) => v.id))
                            }}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        backgroundColor: colors.background,
                                        borderRadius: 0.5,
                                    },
                                },
                                popper: {
                                    sx: {
                                        borderBottom: `${colors.primary}DD 2px solid`,
                                        borderLeft: `${colors.primary}DD 2px solid`,
                                        borderRight: `${colors.primary}DD 2px solid`,
                                        borderRadius: 0.5,
                                    },
                                },
                            }}
                            noOptionsText={
                                <Typography sx={{ opacity: 0.6 }}>
                                    <i>No options</i>
                                </Typography>
                            }
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    <Typography variant="h5" fontFamily={fonts.sourceSansProBold}>
                                        {option.label}
                                    </Typography>
                                    {option.description && (
                                        <Typography variant="h5" sx={{ ml: "1rem" }}>
                                            {option.description}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    hiddenLabel
                                    sx={{
                                        ".MuiOutlinedInput-root": {
                                            p: 0,
                                        },
                                        ".MuiOutlinedInput-input": {
                                            height: "unset",
                                            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                                                WebkitAppearance: "none",
                                            },
                                            fontSize: "2.2rem",
                                            fontFamily: fonts.sourceSansProBold,
                                            borderRadius: 0.5,
                                        },
                                        ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                                        ".MuiFormHelperText-root": {
                                            display: "none",
                                        },
                                    }}
                                />
                            )}
                        />
                        {fieldState.error && (
                            <Typography
                                variant="caption"
                                sx={{
                                    margin: "1px 0 0 3px",
                                    color: colors.lightRed,
                                    fontSize: "1.35rem",
                                    fontFamily: fonts.sourceSansProSemibold,
                                }}
                            >
                                {fieldState.error.message}
                            </Typography>
                        )}
                    </Stack>
                )}
            />
        </Stack>
    )
}
