import { Stack, Typography } from "@mui/material"
import moment, { Moment } from "moment"
import React from "react"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { fonts } from "../../theme/theme"
import { ShopMember } from "../../types/Shop"
import { ConfirmModal } from "../common/confirmModal"
import { TimeSelector } from "../common/timeSelector"
import { TypographyTrans } from "../common/TypographyTrans"
import { CountUpTimer } from "../timer/countUpTimer"

interface ClockInOutModalProps {
    targetedMember: ShopMember
    onClose: () => void
}

export const ClockInOutModal = ({ targetedMember, onClose }: ClockInOutModalProps) => {
    const { send } = useServerCommandsShop()
    const [shopMember, setShopMember] = React.useState<ShopMember>(targetedMember)
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const { id, shop_id, prefer_name, payslip_prefer_name, ongoing_work_record } = shopMember
    const [startedAt, setStartedAt] = React.useState<Moment | null>(moment())
    useServerSubscriptionShop<ShopMember>(
        {
            URI: `/member/${targetedMember.id}`,
            key: WSKeys.WSKeyShopMember,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMember(payload)
            },
        },
    )

    const onClockIn = React.useCallback(async () => {
        if (ongoing_work_record) return
        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopClockIn, {
                user_id: id,
                shop_id,
                started_at: startedAt,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to clock in.")
        } finally {
            setIsLoading(false)
        }
    }, [id, onClose, ongoing_work_record, send, shop_id, startedAt])

    const onClockOut = React.useCallback(async () => {
        if (!ongoing_work_record) return
        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopClockOut, {
                work_record_id: ongoing_work_record.id,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to clock out.")
        } finally {
            setIsLoading(false)
        }
    }, [onClose, ongoing_work_record, send])

    const content = React.useMemo(() => {
        if (ongoing_work_record)
            return (
                <Stack spacing={1}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                        Are you sure you want to clock out?
                    </TypographyTrans>
                    <CountUpTimer startedAt={ongoing_work_record.started_at} />
                </Stack>
            )

        return (
            <Stack spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    Please set up the starting time
                </TypographyTrans>
                <TimeSelector date={startedAt} setDate={setStartedAt} />
            </Stack>
        )
    }, [ongoing_work_record, startedAt])

    return (
        <ConfirmModal
            trans
            title={ongoing_work_record ? "Clock Out" : "Clock In"}
            onClose={onClose}
            omitCancel
            onConfirm={async () => {
                if (ongoing_work_record) {
                    await onClockOut()
                    return
                }
                await onClockIn()
            }}
            error={error}
            isLoading={isLoading}
        >
            <Stack direction={"row"} alignItems={"center"}>
                <Typography variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                    {prefer_name}
                </Typography>
                {prefer_name !== payslip_prefer_name && <Typography>({payslip_prefer_name})</Typography>}
            </Stack>
            {content}
        </ConfirmModal>
    )
}
