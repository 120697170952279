import { Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../containers/permissions"
import { useServerCommandsShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { fonts } from "../../../../theme/theme"
import { Permission } from "../../../../types/permissions"
import { ShopJobTitle } from "../../../../types/Shop"
import { ConfirmModal } from "../../../common/confirmModal"
import { TypographyTrans } from "../../../common/TypographyTrans"

interface ShopJobTitleDeleteModalProps {
    shopJobTitle: ShopJobTitle
    onClose: () => void
}
export const ShopJobTitleDeleteModal = ({ shopJobTitle, onClose }: ShopJobTitleDeleteModalProps) => {
    const { hasPerm } = usePermissions()
    const { id, title } = shopJobTitle
    const { send } = useServerCommandsShop()
    const [error, setError] = React.useState("")
    const onDelete = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopJobTitleUpdate)) return
        try {
            await send(WSKeys.WSKeyShopJobTitleDelete, { id })
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to delete job title")
        }
    }, [hasPerm, id, onClose, send])
    return (
        <ConfirmModal trans title={"Delete Job Title"} error={error} onClose={onClose} onConfirm={onDelete}>
            <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                {`Are you sure you want to delete the following job title?`}
            </TypographyTrans>
            <Typography variant={"h6"} fontFamily={fonts.sourceSansProBlackIt}>
                {title}
            </Typography>
        </ConfirmModal>
    )
}
