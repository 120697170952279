import { CircularProgress, SxProps } from "@mui/material"
import React, { useEffect } from "react"
import { useInterval } from "../../hooks"
import { colors } from "../../theme/theme"
import { TooltipHelper } from "../common/tooltipHelper"

interface Props {
    startedAt: Date
    endedAt: Date
    sx?: SxProps
    timeUpText?: string
    onTimeUp?: () => void
}

const ProgressBarTimer = ({ startedAt, endedAt, sx, timeUpText, onTimeUp }: Props) => {
    const maxSeconds = React.useMemo(() => Math.floor((endedAt.getTime() - startedAt.getTime()) / 1000), [startedAt, endedAt])
    const [remainSeconds, setRemainSeconds] = React.useState(0)
    const [isTimeUp, setIsTimeUp] = React.useState(false)

    useEffect(() => {
        if (isTimeUp && onTimeUp) onTimeUp()
    }, [onTimeUp, isTimeUp])

    // count up second
    useInterval(() => {
        setRemainSeconds(() => {
            const seconds = Math.floor((endedAt.getTime() - new Date().getTime()) / 1000)

            if (seconds <= 0) {
                setIsTimeUp(true)
                return 0
            }

            return seconds
        })
    }, 1000)

    return (
        <TooltipHelper text={`${remainSeconds} s`} placement="right">
            <CircularProgress
                variant="determinate"
                size={15}
                thickness={15}
                value={(remainSeconds / maxSeconds) * 100}
                sx={{ color: remainSeconds / maxSeconds < 0.3 ? colors.lightRed : `${colors.primary}AA`, ...sx }}
            />
        </TooltipHelper>
    )
}

export default ProgressBarTimer
