export enum WSKeys {
    WSKeyVisaTypes = "VISA:TYPES",
    WSKeyTaxRateTypes = "TAX:RATE:TYPES",
    WSKeySuperannuationRate = "SUPERANNUATION:RATE",

    // user management
    WSKeyUserLanguagePreferenceUpdate = "USER:LANGUAGE:PREFERENCE:UPDATE",
    WSKeyUserTimeFormatPreferenceUpdate = "USER:TIME:FORMAT:PREFERENCE:UPDATE",
    WSKeyUserSubscribe = "USER:SUBSCRIBE",
    WSKeyUserPermissionsSubscribe = "USER:PERMISSIONS:SUBSCRIBE",
    WSKeyUserOrganisations = "USER:ORGANISATIONS",
    WSKeyOrganisationAccountantCCEmailAdd = "ORGANISATION:ACCOUNTANT:CC:EMAIL:ADD",
    WSKeyOrganisationAccountantCCEmailUpdate = "ORGANISATION:ACCOUNTANT:CC:EMAIL:UPDATE",
    WSKeyOrganisationAccountantCCEmailDelete = "ORGANISATION:ACCOUNTANT:CC:EMAIL:DELETE",

    // organisation management
    WSKeyOrganisation = "ORGANISATION",
    WSKeyOrganisationSalarySettingsUpdate = "ORGANISATION:SALARY:SETTINGS:UPDATE",
    WSKeyUserShopPayslipConfirmAndSend = "USER:SHOP:PAYSLIP:CONFIRM:AND:SEND",
    WSKeyUserShopPayslipSend = "USER:SHOP:PAYSLIP:SEND",
    WSKeyShopRoles = "SHOP:ROLES",

    // item management

    WSKeyItemStacks = "ITEM:STACKS",
    WSKeyItemCreate = "ITEM:CREATE",
    WSKeyItemUpdate = "ITEM:UPDATE",
    WSKeyItemList = "ITEM:LIST",

    // item source management
    WSKeyItemSourceList = "ITEM:SOURCE:LIST",
    WSKeyItemSourceCreate = "ITEM:SOURCE:CREATE",
    WSKeyItemSourceUpdate = "ITEM:SOURCE:UPDATE",
    WSKeyItemSourceDelete = "ITEM:SOURCE:DELETE",

    // intermediate product management
    WSKeyIntermediateProductList = "INTERMEDIATE:PRODUCT:LIST",
    WSKeyIntermediateProductCreate = "INTERMEDIATE:PRODUCT:CREATE",
    WSKeyIntermediateProductUpdate = "INTERMEDIATE:PRODUCT:UPDATE",

    // product management
    WSKeyProductList = "PRODUCT:LIST",
    WSKeyProductCreate = "PRODUCT:CREATE",
    WSKeyProductUpdate = "PRODUCT:UPDATE",

    // import management

    WSKeyImportRecordCreate = "IMPORT:RECORD:CREATE",
    WSKeyImportRecordUpdate = "IMPORT:RECORD:UPDATE",
    WSKeyImportRecordArchive = "IMPORT:RECORD:ARCHIVE",
    WSKeyImportRecordUnarchive = "IMPORT:RECORD:UNARCHIVE",
    WSKeyImportRecordList = "IMPORT:RECORD:LIST",
    WSKeyImportedItemList = "IMPORTED:ITEM:LIST",
    WSKeyImportRecordGet = "IMPORT:RECORD:GET",

    WSKeyImportRecordItemList = "IMPORT:RECORD:ITEM:LIST",
    WSKeyImportRecordItemCreate = "IMPORT:RECORD:ITEM:CREATE",
    WSKeyImportRecordItemUpdate = "IMPORT:RECORD:ITEM:UPDATE",
    WSKeyImportRecordItemArchive = "IMPORT:RECORD:ITEM:ARCHIVE",

    // manufacturer management
    WSKeyManufacturerList = "MANUFACTURER:LIST",

    // item ingredient management
    WSKeyIngredientList = "INGREDIENT:LIST",

    // shop management
    WSKeyShop = "SHOP",
    WSKeyPosClockIn = "POS:CLOCK:IN",
    WSKeyPosClockOut = "POS:CLOCK:OUT",
    WSKeyShopUpdate = "SHOP:UPDATE",
    WSKeyShops = "SHOPS",
    WSKeyShopPosTotpKey = "SHOP:POS:TOTP:KEY",
    WSKeyShopPosTotpKeySetup = "SHOP:POS:TOTP:KEY:SETUP",

    WSKeyShopMembers = "SHOP:MEMBERS",
    WSKeyShopMember = "SHOP:MEMBER",
    WSKeyUserShopYTDPayment = "USER:SHOP:YTD:PAYMENT",
    WSKeyUserShopPayslipReset = "USER:SHOP:PAYSLIP:RESET",
    WSKeyUserShopSpecialPayslipRequirement = "USER:SHOP:SPECIAL:PAYSLIP:REQUIREMENT",
    WSKeyUserShopPayslips = "USER:SHOP:PAYSLIPS",
    WSKeyUserShopPayslip = "USER:SHOP:PAYSLIP",
    WSKeyShopMemberArchive = "SHOP:MEMBER:ARCHIVE",
    WSKeyShopMemberUnarchive = "SHOP:MEMBER:UNARCHIVE",
    WSKeyShopMembersWageSync = "SHOP:MEMBERS:WAGE:SYNC",

    WSKeyShopMemberCreate = "SHOP:MEMBER:CREATE",
    WSKeyShopMemberUpdate = "SHOP:MEMBER:UPDATE",

    WSKeyUserShopClockIn = "USER:SHOP:CLOCK:IN",
    WSKeyUserShopClockOut = "USER:SHOP:CLOCK:OUT",
    WSKeyUserShopLiveWorkRecord = "USER:SHOP:LIVE:WORK:RECORD",
    WSKeyShopMemberWorkRecords = "SHOP:MEMBER:WORK:RECORDS",
    WSKeyUserShopWorkRecordAdd = "USER:SHOP:WORK:RECORD:ADD",
    WSKeyUserShopOrdinaryPaymentAdd = "USER:SHOP:ORDINARY:PAYMENT:ADD",
    WSKeyUserShopOrdinaryPaymentUpdate = "USER:SHOP:ORDINARY:PAYMENT:UPDATE",
    WSKeyUserShopOrdinaryPaymentDelete = "USER:SHOP:ORDINARY:PAYMENT:DELETE",
    WSKeyUserShopOrdinaryPaymentRecords = "USER:SHOP:ORDINARY:PAYMENT:RECORDS",
    WSKeyUserShopLeaveRequests = "USER:SHOP:LEAVE:REQUESTS",
    WSKeyUserShopLeaveRequestCreate = "USER:SHOP:LEAVE:REQUEST:CREATE",
    WSKeyUserShopLeaveRequestUpdate = "USER:SHOP:LEAVE:REQUEST:UPDATE",
    WSKeyUserShopLeaveRequestCancel = "USER:SHOP:LEAVE:REQUEST:CANCEL",
    WSKeyUserShopWorkRecordUpdate = "USER:SHOP:WORK:RECORD:UPDATE",
    WSKeyUserShopWorkRecordDelete = "USER:SHOP:WORK:RECORD:DELETE",
    WSKeyShopJobTitles = "SHOP:JOB:TITLES",
    WSKeyShopJobTitleCreate = "SHOP:JOB:TITLE:CREATE",
    WSKeyShopJobTitleUpdate = "SHOP:JOB:TITLE:UPDATE",
    WSKeyShopJobTitleDelete = "SHOP:JOB:TITLE:DELETE",
    WSKeyShopJobLevels = "SHOP:JOB:LEVELS",
    WSKeyShopJobLevelCreate = "SHOP:JOB:LEVEL:CREATE",
    WSKeyShopJobLevelDelete = "SHOP:JOB:LEVEL:DELETE",
    WSKeyShopJobLevelLabelUpdate = "SHOP:JOB:LEVEL:LABEL:UPDATE",
    WSKeyShopJobLevelDescriptionUpdate = "SHOP:JOB:LEVEL:DESCRIPTION:UPDATE",
    WSKeyShopJobLevelSwap = "SHOP:JOB:LEVEL:SWAP",
    WSKeyShopJobWages = "SHOP:JOB:WAGES",
    WSKeyShopJobWage = "SHOP:JOB:WAGE",
    WSKeyShopJobWageUpdate = "SHOP:JOB:WAGE:UPDATE",
    WSKeyShopJobLevelEntitledMembers = "SHOP:JOB:LEVEL:ENTITLED:MEMBERS",

    WSKeyShopSalaryPayout = "SHOP:SALARY:PAYOUT",
    WSKeyOrganisationSalaryPayout = "ORGANISATION:SALARY:PAYOUT",

    WSKeyShopLineUserList = "USER:SHOP:LINE:USER:LIST",
    WSKeyLineMessagingRecipe = "LINE:MESSAGING:RECIPE",

    WSKeyShopDeviceTokenGet = "SHOP:DEVICE:TOKEN:GET",
    WSKeyShopDeviceTokenGenerate = "SHOP:DEVICE:TOKEN:GENERATE",
}

export enum EmploymentStatus {
    FullTime = "FULL_TIME",
    PartTime = "PART_TIME",
    Casual = "CASUAL",
    Contract = "CONTRACT",
}
