import AddBoxIcon from "@mui/icons-material/AddBox"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow"
import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { useForm } from "react-hook-form"
import { DRAWER_TRANSITION_DURATION } from "../../../../constants"
import { useItem } from "../../../../containers/items"
import { usePermissions } from "../../../../containers/permissions"
import { roundedTo } from "../../../../helpers"
import { useDebounce } from "../../../../hooks"
import { useServerCommandsOrg } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { fadeInAndOutEffect } from "../../../../theme/keyframes"
import { colors, fonts } from "../../../../theme/theme"
import { ImportedItem } from "../../../../types/importRecords"
import { Ingredient } from "../../../../types/items"
import { Permission } from "../../../../types/permissions"
import { InputBox } from "../../../common/InputBox"
import { ConfirmModal } from "../../../common/confirmModal"
import { InputField } from "../../../common/form/inputField"
import { SelectField } from "../../../common/form/selectField"
import { ImportedItemIngredientTable } from "./importedItemIngredientTable"

interface ImportedItemSubmitForm {
    item_id: string
    carton_amount: string
    cost_twd: string
    cost_aud: string
    carton_width_cm: string
    carton_depth_cm: string
    carton_height_cm: string
    unit_count_per_carton: string
    manufacturer_id?: string
}

interface ImportedItemRequest {
    id?: string
    import_record_id: string
    item_id: string
    carton_amount: number
    unit_count_per_carton: number
    cost_twd: string
    cost_aud: string
    carton_width_cm: string
    carton_depth_cm: string
    carton_height_cm: string
    manufacturer_id?: string
    ingredient_ids: string[]
}

interface ImportedItemEditModalProps {
    importRecordID: string
    importedItem?: ImportedItem
    audToTwdRate: string
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ImportedItemModal = ({ importRecordID, importedItem, audToTwdRate, setOpen }: ImportedItemEditModalProps) => {
    const { manufacturers, items } = useItem()
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsOrg()

    const { control, getValues, trigger, watch, setValue, clearErrors } = useForm<ImportedItemSubmitForm>({
        defaultValues: {
            item_id: importedItem?.item?.id || "",
            carton_amount: `${importedItem?.carton_amount || 0}`,
            cost_twd: importedItem?.cost_twd || "0",
            cost_aud: importedItem?.cost_aud || "0",
            carton_width_cm: importedItem?.item.item_carton_variant?.carton_width_cm || "0",
            carton_depth_cm: importedItem?.item.item_carton_variant?.carton_depth_cm || "0",
            carton_height_cm: importedItem?.item.item_carton_variant?.carton_height_cm || "0",
            unit_count_per_carton: `${importedItem?.item.item_carton_variant?.unit_count_per_carton || "0"}`,
            manufacturer_id: importedItem?.item?.manufacturer_id || "",
        },
    })

    const itemID = watch("item_id")
    React.useEffect(() => {
        // don't change anything, if it is in update mode
        if (importedItem) return

        const targetItem = items.find((i) => i.id === itemID)
        if (!targetItem) return

        // update manufacturer
        setValue("manufacturer_id", targetItem.manufacturer_id)

        // update ingredients
        setItemIngredientList(targetItem.ingredients || [])

        // update item carton variant
        if (targetItem.item_carton_variant) {
            setValue("carton_width_cm", targetItem.item_carton_variant.carton_width_cm)
            setValue("carton_depth_cm", targetItem.item_carton_variant.carton_depth_cm)
            setValue("carton_height_cm", targetItem.item_carton_variant.carton_height_cm)
            setValue("unit_count_per_carton", `${targetItem.item_carton_variant.unit_count_per_carton}`)
        }
    }, [importedItem, itemID, items, setValue])

    const cartonWidthCM = watch("carton_width_cm")
    const cartonDepthCM = watch("carton_depth_cm")
    const cartonHeightCM = watch("carton_height_cm")

    const cbm = React.useMemo(() => {
        const width = parseFloat(cartonWidthCM)
        const depth = parseFloat(cartonDepthCM)
        const height = parseFloat(cartonHeightCM)

        if (isNaN(width) || isNaN(depth) || isNaN(height)) {
            return 0
        }

        return roundedTo((width * depth * height) / 1000000, 4)
    }, [cartonWidthCM, cartonDepthCM, cartonHeightCM])

    const cartonAmount = watch("carton_amount")
    const totalCBM = React.useMemo(() => roundedTo(cbm * parseFloat(cartonAmount), 4), [cbm, cartonAmount])

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)
    const [itemIngredientList, setItemIngredientList] = React.useState<Ingredient[]>(importedItem?.item?.ingredients || [])
    const [isDeleteMode, setIsDeleteMode] = React.useState(false)
    const [isAddMode, setIsAddMode] = React.useState(false)

    const [showSuccess, setShowSuccess] = React.useState(false)

    const onSave = React.useCallback(async () => {
        if (!hasPerm(Permission.ItemUpdate) || !hasPerm(Permission.ImportRecordUpdate) || !importedItem || !importedItem.item) return

        // valid
        if (!(await trigger())) return

        const data: ImportedItemSubmitForm = getValues()

        try {
            await send<null, ImportedItemRequest>(WSKeys.WSKeyImportRecordItemUpdate, {
                ...data,
                id: importedItem.id,
                import_record_id: importRecordID,
                carton_amount: parseInt(data.carton_amount),
                unit_count_per_carton: parseInt(data.unit_count_per_carton),
                manufacturer_id: data.manufacturer_id || undefined,
                ingredient_ids: itemIngredientList.map((ii) => ii.id),
            })
            setShowSuccess(true)
            setTimeout(() => setShowSuccess(false), 2000)
        } catch (e) {
            console.log(e)
        }
    }, [getValues, hasPerm, importRecordID, importedItem, itemIngredientList, send, trigger])

    const onCreate = React.useCallback(async () => {
        if (!hasPerm(Permission.ItemUpdate) || !hasPerm(Permission.ImportRecordUpdate) || importedItem) return
        // valid
        if (!(await trigger())) return

        const data: ImportedItemSubmitForm = getValues()

        try {
            await send<null, ImportedItemRequest>(WSKeys.WSKeyImportRecordItemCreate, {
                ...data,
                import_record_id: importRecordID,
                carton_amount: parseInt(data.carton_amount),
                unit_count_per_carton: parseInt(data.unit_count_per_carton),
                manufacturer_id: data.manufacturer_id || undefined,
                ingredient_ids: itemIngredientList.map((ii) => ii.id),
            })
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
                setOpen(false)
            }, 2000)
        } catch (e) {
            console.log(e)
        }
    }, [getValues, hasPerm, importRecordID, importedItem, itemIngredientList, send, setOpen, trigger])

    return (
        <ConfirmModal
            title={
                <Stack direction="row" alignItems="center" sx={{ height: "3.5rem" }}>
                    <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                        {importedItem ? "Update" : "Add"} Imported Item
                    </Typography>
                    {showSuccess && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: "1rem",
                                px: "1rem",
                                borderRadius: 2,
                                backgroundColor: colors.green,
                                animation: `${fadeInAndOutEffect} 2s`,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: fonts.sourceSansProBlack,
                                    fontWeight: "bold",
                                    color: colors.offWhite,
                                }}
                            >
                                SUCCESS
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            }
            onConfirm={importedItem ? onSave : onCreate}
            confirmLabel={importedItem ? "SAVE" : "CREATE"}
            omitCancel
            confirmColor={colors.primary}
            onClose={() => setOpen(false)}
            width="125rem"
        >
            <form style={{ height: "65rem" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={4}
                    sx={{
                        height: "100%",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        flex={1}
                        sx={{
                            height: "100%",
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Item:
                        </Typography>
                        {importedItem && importedItem.item ? (
                            <Stack spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                                <Typography variant="h6">{importedItem.item.label}</Typography>
                                <Typography variant="body1">{importedItem.item.import_label}</Typography>
                            </Stack>
                        ) : (
                            <Stack spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                                <SelectField
                                    control={control}
                                    name="item_id"
                                    options={items.map((i) => ({ id: i.id, label: i.label, description: i.import_label }))}
                                    rules={{ required: "filed is required" }}
                                    onChange={() => clearErrors("item_id")}
                                />
                            </Stack>
                        )}

                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Manufacturer:
                        </Typography>
                        <Stack spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                            <SelectField
                                name="manufacturer_id"
                                control={control}
                                options={manufacturers.map((m) => ({ id: m.id, label: m.label_ch, description: m.label_en }))}
                            />
                        </Stack>

                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Cost:
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                            <Typography variant="h6" fontWeight="bold">
                                TWD:
                            </Typography>
                            <InputField
                                name="cost_twd"
                                variant="outlined"
                                control={control}
                                onChange={(e) => setValue("cost_aud", `${roundedTo(parseFloat(e.currentTarget.value) / parseFloat(audToTwdRate))}`)}
                                type="number"
                            />

                            <Typography variant="h6" fontWeight="bold">
                                AUD:
                            </Typography>
                            <InputField
                                name="cost_aud"
                                variant="outlined"
                                control={control}
                                onChange={(e) => setValue("cost_twd", `${roundedTo(parseFloat(e.currentTarget.value) * parseFloat(audToTwdRate))}`)}
                                type="number"
                            />
                        </Stack>
                        <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                            Carton:
                        </Typography>
                        <Stack spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                            <Stack width="100%">
                                <Typography variant="h6" fontWeight="bold">
                                    Size: <span style={{ color: `${colors.primary}ba` }}>(CBM: {cbm})</span>
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="h6" fontWeight="bold">
                                        W (cm):
                                    </Typography>
                                    <Stack width="7.3rem">
                                        <InputField control={control} name="carton_width_cm" variant="outlined" type="number" />
                                    </Stack>
                                    <Typography variant="h6" fontWeight="bold">
                                        D (cm):
                                    </Typography>
                                    <Stack width="7.3rem">
                                        <InputField control={control} name="carton_depth_cm" variant="outlined" type="number" />
                                    </Stack>
                                    <Typography variant="h6" fontWeight="bold">
                                        H (cm):
                                    </Typography>
                                    <Stack width="7.3rem">
                                        <InputField control={control} name="carton_height_cm" variant="outlined" type="number" />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <InputField label="Unit Per Carton:" name="unit_count_per_carton" variant="outlined" control={control} type="number" />
                            <InputField label="Carton Amount:" name="carton_amount" variant="outlined" control={control} type="number" />
                            <Typography variant="h6" fontWeight="bold" textAlign="end" sx={{ pt: "1rem" }}>
                                Total CBM: {totalCBM}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        sx={{
                            height: "100%",
                            width: isAddMode ? "65rem" : "40rem",
                            transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,
                            opacity: itemID ? 1 : 0.4,
                        }}
                        spacing={1}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                Ingredients:
                            </Typography>
                            <Stack direction="row" alignItems="center">
                                <IconButton
                                    disableRipple
                                    size="small"
                                    sx={{
                                        p: 0,
                                    }}
                                    disabled={!itemID}
                                    onClick={() => {
                                        setIsDeleteMode(false)
                                        setIsAddMode((prev) => !prev)
                                    }}
                                >
                                    <AddBoxIcon sx={{ color: isAddMode ? colors.primary : `${colors.primary}70`, fontSize: "2.5rem" }} />
                                </IconButton>
                                <IconButton
                                    disableRipple
                                    size="small"
                                    sx={{
                                        p: 0,
                                    }}
                                    disabled={!itemID}
                                    onClick={() => {
                                        setIsAddMode(false)
                                        setIsDeleteMode((prev) => !prev)
                                    }}
                                >
                                    <DeleteForeverIcon sx={{ color: isDeleteMode ? colors.red : `${colors.lightRed}80`, fontSize: "2.5rem" }} />
                                </IconButton>
                            </Stack>
                        </Stack>

                        <Stack
                            flex={1}
                            sx={{
                                px: "1rem",
                                py: "1rem",
                                border: `${colors.primary}60 2px solid`,
                                borderRadius: 0.8,
                                height: "100%",
                            }}
                        >
                            <Stack
                                sx={{
                                    height: "4rem",
                                    mr: ".5rem",
                                    pr: "1.4rem",
                                    mb: ".25rem",
                                }}
                            >
                                <InputBox variant="standard" disabled={!itemID} value={searchValueInstant} setValue={setSearchValue} />
                            </Stack>
                            <Stack direction="row" flex={1}>
                                {isAddMode && (
                                    <>
                                        <ImportedItemIngredientTable
                                            itemIngredients={itemIngredientList}
                                            setItemIngredientList={setItemIngredientList}
                                            searchValue={searchValue}
                                            addIngredientIcon={<ArrowCircleRightOutlinedIcon fontSize="large" sx={{ color: colors.primary }} />}
                                            offsetIngredient
                                        />
                                        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: "100%", minWidth: "7rem" }}>
                                            <DoubleArrowIcon
                                                sx={{
                                                    fontSize: "4.5rem",
                                                    color: colors.secondary,
                                                    backgroundImage: `linear-gradient(to right, ${colors.primary}10 0%, ${colors.primary}80)`,
                                                }}
                                            />
                                        </Stack>
                                    </>
                                )}
                                <ImportedItemIngredientTable
                                    itemIngredients={itemIngredientList}
                                    setItemIngredientList={setItemIngredientList}
                                    searchValue={searchValue}
                                    removeIngredientIcon={
                                        isDeleteMode || isAddMode ? <DeleteOutlineOutlinedIcon fontSize="large" sx={{ color: colors.lightRed }} /> : undefined
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </ConfirmModal>
    )
}
