import { Stack, TextField, TextFieldProps, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { colors, fonts } from "../../theme/theme"
import { TypographyTrans } from "./TypographyTrans"

export const InputBox = ({
    variant = "standard",
    value,
    setValue,
    placeholder,
    disabled,
    label,
    stackSx,
    onChange,
    sx,
    trans,
    innerSx,
    errMsg,
    ...props
}: {
    value: string
    setValue: (v: string) => void
    stackSx?: SxProps
    trans?: boolean
    innerSx?: SxProps
    errMsg?: string
} & TextFieldProps) => {
    const inputLabel = React.useMemo(() => {
        if (!label) return null
        if (typeof label === "string") {
            if (trans) {
                return (
                    <TypographyTrans variant="h6" fontWeight="bold">
                        {label}
                    </TypographyTrans>
                )
            }

            return (
                <Typography variant="h6" fontWeight="bold">
                    {label}
                </Typography>
            )
        }
        return label
    }, [trans, label])

    const sxProps: SxProps = React.useMemo(() => {
        const primaryColor = colors.primary
        switch (variant) {
            case "filled":
                return {
                    backgroundColor: "unset",
                    ".MuiFilledInput-input": {
                        px: "1.5rem",
                        py: ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.3rem",
                        borderRadius: 0.5,
                        border: `${primaryColor}50 2px solid`,
                        ":hover, :focus, :active": { border: `${primaryColor}99 2px solid` },
                        ...innerSx,
                    },
                    ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                }
            case "outlined":
                return {
                    backgroundColor: "unset",
                    ".MuiOutlinedInput-input": {
                        px: "1.5rem",
                        py: ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.2rem",
                        fontFamily: fonts.sourceSansProSemibold,
                        borderRadius: 0.5,
                        ...innerSx,
                    },
                    border: `${primaryColor}50 2px solid`,
                    ":hover, :focus, :active": { border: `${primaryColor}99 2px solid` },
                    ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                }
            default:
                return {
                    backgroundColor: "unset",
                    ".MuiInput-input": {
                        px: "1.5rem",
                        py: ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.3rem",
                        borderRadius: 0.5,
                        borderBottom: `${primaryColor}50 2px solid`,
                        ":hover, :focus, :active": { borderBottom: `${primaryColor}99 2px solid` },
                        ...innerSx,
                    },
                }
        }
    }, [variant, innerSx])

    return (
        <Stack spacing={".35rem"}>
            <Stack sx={stackSx}>
                {inputLabel}
                <TextField
                    {...props}
                    variant={variant}
                    hiddenLabel
                    fullWidth
                    placeholder={placeholder ? `${placeholder}...` : "Enter keywords..."}
                    sx={{
                        ...sxProps,
                        ...sx,
                    }}
                    value={value}
                    onChange={(e) => {
                        if (disabled) return
                        if (onChange) onChange(e)
                        setValue(e.target.value)
                    }}
                />
            </Stack>
            {errMsg && (
                <Typography variant={"h6"} color={colors.lightRed}>
                    {errMsg}
                </Typography>
            )}
        </Stack>
    )
}
