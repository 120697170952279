import { Stack, Typography } from "@mui/material"

export const WarehouseInventoryOverview = () => {
    return (
        <Stack direction="column" flex={1}>
            <Typography variant="h5">
                <strong>Inventory</strong>
            </Typography>
        </Stack>
    )
}
