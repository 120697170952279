import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CategoryIcon from "@mui/icons-material/Category"
import { Box, IconButton, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { usePermissions } from "../../containers/permissions"
import { useDebounce } from "../../hooks"
import { usePagination } from "../../hooks/usePagination"
import { useServerSubscriptionShop } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts } from "../../theme/theme"
import { ShopMember } from "../../types/Shop"
import { Permission } from "../../types/permissions"
import { InputBox } from "../common/InputBox"
import { TypographyTrans } from "../common/TypographyTrans"
import { PageHeader } from "../common/pageHeader"
import { TotalAndPageSizeOptions } from "../common/totalAndPageSizeOptions"
import { ClockInOutModal } from "./clockInOutModal"
import { ShopMemberAccountPanel } from "./shopMember/accountPanel/shopMemberAccountPanel"
import { ShopMemberCard } from "./shopMember/shopMemberCard"
import { ShopMemberDetail } from "./shopMember/shopMemberDetail/shopMemberDetail"
import { ShopMemberModal } from "./shopMember/shopMemberModal"

export const ShopMembers = () => {
    const { hasPerm } = usePermissions()
    const [shopMembers, setShopMembers] = React.useState<ShopMember[]>([])
    const [clockInOutUser, setClockInOutUser] = React.useState<ShopMember>()
    const [openShopMemberModal, setOpenShopMemberModal] = React.useState(false)
    const [showArchivedMembers, setShowArchivedMembers] = React.useState(false)

    const [selectedShopMemberID, setSelectedShopMemberID] = React.useState<string>("")
    const selectedShopMember = React.useMemo(() => {
        let list = [...shopMembers]

        if (showArchivedMembers) {
            list = list.filter((sm) => sm.deleted_at)
        } else {
            list = list.filter((sm) => !sm.deleted_at)
        }

        return list.find((sm) => sm.id === selectedShopMemberID)
    }, [shopMembers, showArchivedMembers, selectedShopMemberID])

    useServerSubscriptionShop<ShopMember[]>(
        {
            URI: "/members",
            key: WSKeys.WSKeyShopMembers,
            ready: hasPerm(Permission.ShopMemberView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMembers((prev) => {
                    if (prev.length === 0) return payload
                    // update existing members
                    prev = prev.map((m) => payload.find((p) => p.id === m.id) || m)
                    // add new data to the list
                    payload.forEach((p) => (prev.some((m) => m.id === p.id) ? undefined : prev.push(p)))
                    return prev
                })
            },
        },
    )

    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    const [list, setList] = React.useState<ShopMember[]>([])
    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...shopMembers]

        if (showArchivedMembers) {
            sorted = sorted.filter((sm) => sm.deleted_at)
        } else {
            sorted = sorted.filter((sm) => !sm.deleted_at)
        }

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => `${s.prefer_name} ${s.payslip_prefer_name}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        // sort data
        sorted = sorted.sort((a, b) => a.payslip_prefer_name.localeCompare(b.payslip_prefer_name))

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, shopMembers, searchValue, page, pageSize, setTotalItems, showArchivedMembers])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((member) => {
                        return (
                            <div
                                key={`shop-member-${member.id}`}
                                style={{ marginBottom: ".5rem", cursor: "pointer" }}
                                onClick={() => setSelectedShopMemberID(member.id)}
                            >
                                <ShopMemberCard
                                    key={member.id}
                                    shopMember={member}
                                    shouldHighlight={member.id === selectedShopMemberID}
                                    triggerClockInClockOutModal={() => setClockInOutUser(member)}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, selectedShopMemberID])

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "62.5rem",
                }}
            >
                <PageHeader
                    title={
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                            {showArchivedMembers && (
                                <IconButton
                                    size="small"
                                    disableRipple
                                    onClick={() => {
                                        setShowArchivedMembers(false)
                                        setSelectedShopMemberID("")
                                    }}
                                    sx={{ p: 0 }}
                                >
                                    <ArrowBackIcon sx={{ fontSize: "2.5rem", color: colors.primary, fontWeight: "bold" }} />
                                </IconButton>
                            )}
                            <TypographyTrans variant="h5" sx={{ fontWeight: "bolder", fontFamily: fonts.sourceSansProBlack }}>
                                {`${showArchivedMembers ? "ARCHIVED " : ""}MEMBERS`}
                            </TypographyTrans>
                        </Stack>
                    }
                    popoverFuncs={
                        showArchivedMembers
                            ? []
                            : [
                                  {
                                      label: "New Member",
                                      onClick: () => setOpenShopMemberModal(true),
                                      icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                                      trans: true,
                                  },
                                  {
                                      label: "Archived Members",
                                      onClick: () => {
                                          setShowArchivedMembers(true)
                                          setSelectedShopMemberID("")
                                      },
                                      icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                                      trans: true,
                                  },
                              ]
                    }
                />

                <Stack direction="column" flex={1} sx={{ p: ".5rem" }}>
                    <Stack direction="column" flex={1}>
                        <TotalAndPageSizeOptions
                            countItems={list.length}
                            pageSizeOptions={[10, 20, 40]}
                            pageSize={pageSize}
                            changePageSize={changePageSize}
                            changePage={changePage}
                        >
                            {/* Search */}
                            <Stack spacing="1rem" direction="row" alignItems="center" width="30rem">
                                <TypographyTrans variant="body1" sx={{ fontWeight: "bolder", fontFamily: fonts.sourceSansProBlack, whiteSpace: "pre" }}>
                                    SEARCH
                                </TypographyTrans>
                                <Typography variant="body1" sx={{ fontWeight: "bolder", fontFamily: fonts.sourceSansProBlack, whiteSpace: "pre" }}>
                                    :
                                </Typography>
                                <InputBox value={searchValueInstant} setValue={setSearchValue} />
                            </Stack>
                        </TotalAndPageSizeOptions>

                        <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                            <Box
                                sx={{
                                    ml: "1.9rem",
                                    mr: ".5rem",
                                    pr: "1.4rem",
                                    my: "1rem",
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>

                        {totalPages > 1 && (
                            <Box
                                sx={{
                                    mt: "auto",
                                    px: "1rem",
                                    py: ".7rem",
                                    backgroundColor: colors.background,
                                }}
                            >
                                <Pagination
                                    size="medium"
                                    count={totalPages}
                                    page={page}
                                    sx={{
                                        ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                        ".Mui-selected": {
                                            color: colors.primary,
                                            backgroundColor: `${colors.background} !important`,
                                        },
                                    }}
                                    onChange={(e, p) => changePage(p)}
                                />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Stack>

            <Stack flex={1} sx={{ height: "100%" }}>
                {selectedShopMember && (
                    <Stack direction={"column"} spacing="1rem" flex={1}>
                        <ShopMemberDetail shopMember={selectedShopMember} />
                        <ShopMemberAccountPanel shopMember={selectedShopMember} />
                    </Stack>
                )}
            </Stack>

            {clockInOutUser && <ClockInOutModal targetedMember={clockInOutUser} onClose={() => setClockInOutUser(undefined)} />}
            {openShopMemberModal && <ShopMemberModal onClose={() => setOpenShopMemberModal(false)} />}
        </>
    )
}
