import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton"
import { Typography } from "@mui/material"
import { Box, SxProps } from "@mui/system"
import React from "react"
import { Link } from "react-router-dom"
import { autoTextColor } from "../../helpers"
import { colors, fonts } from "../../theme/theme"
import { TypographyTrans } from "./TypographyTrans"
interface FancyButtonProps extends LoadingButtonProps {
    sx?: SxProps
    innerSx?: SxProps
    href?: string
    to?: string
    target?: React.HTMLAttributeAnchorTarget | undefined
    buttonColor?: string
    trans?: boolean
}

export const ShopButton = React.forwardRef(function FancyButton(
    { sx, innerSx, disabled, children, loading, to, href, target, buttonColor, trans, ...props }: FancyButtonProps,
    ref,
) {
    const isDisabled = loading || disabled
    const fillColor = React.useMemo(() => buttonColor || "#00000000", [buttonColor])
    const contrastTextColor = React.useMemo(() => (fillColor ? autoTextColor(fillColor) : "#FFFFFF"), [fillColor])

    const content = React.useMemo(() => {
        if (!children) return ""

        if (typeof children === "string") {
            if (trans)
                return (
                    <TypographyTrans fontWeight={"bold"} variant={"h6"} fontFamily={fonts.sourceSansProBlack} color={contrastTextColor}>
                        {children}
                    </TypographyTrans>
                )
            return (
                <Typography fontWeight={"bold"} variant={"h6"} fontFamily={fonts.sourceSansProBlack} color={contrastTextColor}>
                    {children}
                </Typography>
            )
        }

        return children
    }, [trans, children, contrastTextColor])

    return (
        <Box
            sx={{
                overflow: "hidden",
                borderStyle: `transparent linear-gradient(45deg, ${colors.neonBlue} 0%, ${colors.neonBlue}28 51%, ${colors.neonBlue} 100%) 0% 0% no-repeat padding-box`,
                borderWidth: "2px",
                borderRadius: 0,
            }}
        >
            <LoadingButton
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ref={ref as any}
                disabled={isDisabled}
                sx={{
                    px: "1.5rem",
                    borderRadius: 0.8,
                    fontWeight: "fontWeightBold",
                    color: colors.text,
                    border: `${fillColor} 1px solid`,
                    backgroundColor: fillColor,
                    opacity: isDisabled ? 0.5 : 1,
                    ":hover": {
                        backgroundColor: fillColor,
                    },
                    "&:focus": {
                        boxShadow: "none",
                    },
                    "&:active": {
                        opacity: 0.75,
                    },
                    "& .MuiLoadingButton-loadingIndicator": {
                        color: colors.text,
                    },
                    ".MuiCircularProgress-root": {
                        color: colors.primary,
                    },
                    ...sx,
                }}
                fullWidth
                loading={loading}
                {...props}
            >
                <Box sx={{ py: "0", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", ...innerSx }}>
                    {to ? (
                        <Link to={to} target={target}>
                            {content}
                        </Link>
                    ) : href ? (
                        <a href={href} target={target}>
                            {content}
                        </a>
                    ) : (
                        content
                    )}
                </Box>
            </LoadingButton>
        </Box>
    )
})
