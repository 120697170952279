import { Stack } from "@mui/material"
import { Shops } from "../../components/shop/shops"

export const ShopPage = () => {
    return (
        <Stack
            direction="row"
            sx={{
                flex: 1,
                overflow: "hidden",
                height: "100%",
            }}
        >
            <Shops />
        </Stack>
    )
}
