import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Box, IconButton, Popover, Stack, Typography } from "@mui/material"
import React from "react"
import { colors, fonts, siteZIndex } from "../../theme/theme"
import { ShopButton } from "./shopButton"
import { TypographyTrans } from "./TypographyTrans"

interface PopoverFunc {
    label: string
    onClick: () => void
    icon: JSX.Element
    trans?: boolean
}

interface PageHeaderProps {
    title: React.ReactNode
    description?: React.ReactNode
    imageUrl?: string
    children?: React.ReactNode
    smallSize?: boolean
    primaryColor?: string

    imageHeight?: string
    imageWidth?: string

    popoverFuncs?: PopoverFunc[]
}

export const PageHeader = React.memo(function PageHeader({
    title,
    description,
    imageUrl,
    children,
    smallSize,
    primaryColor,
    imageHeight,
    imageWidth,
    popoverFuncs,
}: PageHeaderProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const showTitle = React.useMemo(() => {
        if (typeof title === "string") {
            return (
                <Typography variant={smallSize ? "h6" : "h5"} sx={{ fontFamily: fonts.sourceSansProBlack }}>
                    {title}
                </Typography>
            )
        }
        return title
    }, [smallSize, title])

    const showDescription = React.useMemo(() => {
        if (!description) return null

        if (typeof description === "string") return <Typography sx={{ fontSize: "1.85rem" }}>{description}</Typography>

        return description
    }, [description])

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing="2rem"
            sx={{
                backgroundColor: colors.background,
                borderBottom: `${primaryColor || colors.primary}80 1.5px solid`,
                backgroundImage: `linear-gradient(to bottom, ${colors.background} 0%, ${colors.primary}80)`,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    px: smallSize ? "1.6rem" : "2rem",
                    py: smallSize ? "1rem" : "1.5rem",
                }}
            >
                {imageUrl && (
                    <Box
                        sx={{
                            alignSelf: "flex-start",
                            flexShrink: 0,
                            mr: "1.6rem",
                            width: imageWidth || "7rem",
                            height: imageHeight || "5.2rem",
                            background: `url(${imageUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                        }}
                    />
                )}
                <Box>
                    {showTitle}

                    {showDescription}
                </Box>
            </Stack>

            {popoverFuncs && popoverFuncs.length > 0 && (
                <>
                    <Box
                        sx={{
                            mb: "1rem",
                        }}
                    >
                        <IconButton size="medium" disableRipple aria-describedby={"PageHeader-popover-trigger-button"} onClick={handleClick}>
                            <MoreVertIcon sx={{ fontSize: "3rem", color: colors.black2 }} />
                        </IconButton>
                        <Popover
                            id={"PageHeader-popover-modal"}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{
                                mt: ".5rem",
                                zIndex: siteZIndex.Popover,
                                ".MuiPaper-root": {
                                    background: colors.background,
                                },
                            }}
                        >
                            <Stack
                                direction="column"
                                sx={{
                                    minWidth: "10rem",
                                    p: ".5rem",
                                }}
                            >
                                {popoverFuncs.map((pf) => (
                                    <ShopButton
                                        key={`PageHeader-option-${pf.label}`}
                                        id={pf.label}
                                        onClick={(e) => {
                                            e.preventDefault()

                                            pf.onClick()
                                            handleClose()
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                            {pf.icon}

                                            {pf.trans ? (
                                                <TypographyTrans variant="body1" fontWeight="bolder" ml="1rem">
                                                    {pf.label}
                                                </TypographyTrans>
                                            ) : (
                                                <Typography variant="body1" fontWeight="bolder" ml="1rem">
                                                    {pf.label}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </ShopButton>
                                ))}
                            </Stack>
                        </Popover>
                    </Box>
                </>
            )}

            {children}
        </Stack>
    )
})
