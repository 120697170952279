import React from "react"
import { CurrencyOption, currencyOptionTWD } from "../types/importRecords"

export interface CurrencyState {
    setCurrencyOption: React.Dispatch<React.SetStateAction<CurrencyOption>>
    currencyOption: CurrencyOption
}

const initialState: CurrencyState = {
    setCurrencyOption: () => {
        return
    },
    currencyOption: currencyOptionTWD,
}

export const CurrencyContext = React.createContext<CurrencyState>(initialState)

export const CurrencyProvider = ({ children }: { children: React.ReactNode }) => {
    const [currencyOption, setCurrencyOption] = React.useState(initialState.currencyOption)
    return (
        <CurrencyContext.Provider
            value={{
                setCurrencyOption,
                currencyOption,
            }}
        >
            {children}
        </CurrencyContext.Provider>
    )
}

export const useCurrency = () => {
    return React.useContext<CurrencyState>(CurrencyContext)
}
