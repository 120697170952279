import { Stack, Typography } from "@mui/material"
import React from "react"
import { snakeToTitle } from "../../../helpers"
import { colors, fonts } from "../../../theme/theme"
import { ShopMember } from "../../../types/Shop"
import { ShopButton } from "../../common/shopButton"
import { CountUpTimer } from "../../timer/countUpTimer"

interface ShopMemberCardProps {
    shopMember: ShopMember
    shouldHighlight: boolean
    triggerClockInClockOutModal: () => void
}

export const ShopMemberCard = ({ shopMember, shouldHighlight, triggerClockInClockOutModal }: ShopMemberCardProps) => {
    const { prefer_name, payslip_prefer_name, job_title, employment_status, ongoing_work_record, deleted_at } = shopMember
    const clockInOut = React.useMemo(() => {
        if (ongoing_work_record)
            return (
                <>
                    <CountUpTimer startedAt={ongoing_work_record.started_at} sx={{ opacity: deleted_at ? 0.5 : 1 }} />
                    <ShopButton
                        trans
                        buttonColor={`${colors.lightRed}CC`}
                        sx={{ px: ".8rem", py: ".25rem" }}
                        disabled={!!deleted_at}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            triggerClockInClockOutModal()
                        }}
                    >
                        Clock Out
                    </ShopButton>
                </>
            )

        return (
            <>
                <Typography variant={"h4"} fontFamily={fonts.sourceSansProBold} sx={{ opacity: deleted_at ? 0.5 : 1 }}>
                    --:--:--
                </Typography>
                <ShopButton
                    trans
                    buttonColor={`${colors.primary}CC`}
                    sx={{ px: ".8rem", py: ".25rem" }}
                    disabled={!!deleted_at}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        triggerClockInClockOutModal()
                    }}
                >
                    Clock In
                </ShopButton>
            </>
        )
    }, [deleted_at, ongoing_work_record, triggerClockInClockOutModal])

    return (
        <Stack
            sx={{
                width: "100%",
                border: `${shouldHighlight ? colors.primary : `${colors.primary}50`} 3px solid`,
                borderRadius: 0.8,
                px: "1.3rem",
                py: ".8rem",
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Typography variant="h4" fontWeight="bolder" fontFamily={fonts.sourceSansProRegular}>
                        {prefer_name}
                    </Typography>
                    {prefer_name !== payslip_prefer_name && (
                        <Typography variant="h5" fontWeight="bolder" fontFamily={fonts.sourceSansProIt}>
                            ({payslip_prefer_name})
                        </Typography>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing="2rem">
                    {clockInOut}
                </Stack>
            </Stack>

            <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography variant={"h6"} fontWeight={"bold"}>
                    {job_title}
                </Typography>
                <Typography variant={"h6"} fontFamily={fonts.sourceSansProIt}>
                    ({snakeToTitle(employment_status)})
                </Typography>
            </Stack>
        </Stack>
    )
}
