import { Stack, Tooltip, Typography } from "@mui/material"
import React from "react"
import { colors, fonts, siteZIndex } from "../../theme/theme"

export const TooltipHelper = ({
    text,
    renderNode,
    children,
    isCentered,
    placement,
    open,
    color,
    textColor: tColor,
}: {
    text?: string
    renderNode?: React.ReactNode
    children: React.ReactElement
    isCentered?: boolean
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top"
    open?: boolean
    color?: string
    textColor?: string
}) => {
    if (!text && !renderNode) return <>{children}</>

    const primaryColor = color || colors.primary
    const textColor = tColor || colors.text

    return (
        <Tooltip
            open={open}
            placement={placement || (isCentered ? "bottom" : "bottom-start")}
            sx={{
                zIndex: `${siteZIndex.Tooltip} !important`,
                ".MuiTooltip-popper": {
                    zIndex: `${siteZIndex.Tooltip} !important`,
                },
            }}
            title={
                <Stack sx={{ height: "100%", px: "1.1rem", py: ".6rem" }}>
                    {renderNode || (
                        <Typography
                            variant="body1"
                            sx={{ color: textColor || "#FFFFFF", fontFamily: fonts.sourceSansProRegular, textAlign: isCentered ? "center" : "start" }}
                        >
                            <strong>{text}</strong>
                        </Typography>
                    )}
                </Stack>
            }
            componentsProps={{
                popper: {
                    style: { filter: "drop-shadow(0 3px 3px #00000050)", zIndex: 999999 },
                },
                arrow: { sx: { color: primaryColor } },
                tooltip: { sx: { background: "unset" } },
            }}
        >
            {children}
        </Tooltip>
    )
}
