import AcUnitIcon from "@mui/icons-material/AcUnit"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { ConfirmModal } from "../../components/common/confirmModal"
import { useServerCommandsUser } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts } from "../../theme/theme"
import { ShopMember } from "../../types/Shop"

const NumberPanel = [
    { value: "1", colSpan: 1 },
    { value: "2", colSpan: 1 },
    { value: "3", colSpan: 1 },
    { value: "4", colSpan: 1 },
    { value: "5", colSpan: 1 },
    { value: "6", colSpan: 1 },
    { value: "7", colSpan: 1 },
    { value: "8", colSpan: 1 },
    { value: "9", colSpan: 1 },
    { value: "0", colSpan: 2 },
    { value: "C", colSpan: 1 },
]

interface ClockingModalProps {
    shopMember: ShopMember
    onClose: () => void
}

export const ClockingModal = ({ shopMember, onClose }: ClockingModalProps) => {
    const { user_id, prefer_name, ongoing_work_record } = shopMember
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [pin, setPIN] = React.useState(["", "", "", ""])
    const { send } = useServerCommandsUser()
    useEffect(() => {
        if (pin.filter((p) => p !== "").length < 4) return

        setIsLoading(true)

        send(ongoing_work_record ? WSKeys.WSKeyPosClockOut : WSKeys.WSKeyPosClockIn, {
            user_id,
            pin: pin.join(""),
        })
            .then(() => {
                onClose()
            })
            .catch((e) => {
                setError(typeof e === "string" ? e : "Failed to clock in.")
                setPIN(["", "", "", ""])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [pin, user_id, ongoing_work_record, onClose, send, setPIN])

    const panel = React.useMemo(() => {
        return (
            <Box
                sx={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "33.33% 33.33% 33.33%",
                }}
            >
                {NumberPanel.map((np) => (
                    <Stack
                        key={np.value}
                        direction={"row"}
                        sx={{
                            height: "10rem",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: ".5rem",
                            gridColumn: `span ${np.colSpan}`,
                        }}
                        onClick={() => {
                            if (np.value === "C") {
                                setPIN(["", "", "", ""])
                                return
                            }

                            if (pin.filter((p) => p !== "").length >= 4) return

                            setPIN((prev) => {
                                const newPIN = [...prev]
                                newPIN[newPIN.findIndex((p) => p === "")] = np.value
                                return newPIN
                            })
                        }}
                    >
                        <Stack
                            sx={{
                                direction: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: `${colors.primary}CC`,
                                borderRadius: 2,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                            }}
                        >
                            <Typography variant="h4" fontFamily={fonts.nostromoBlack} color={colors.offWhite}>
                                {np.value}
                            </Typography>
                        </Stack>
                    </Stack>
                ))}
            </Box>
        )
    }, [pin])
    return (
        <ConfirmModal
            title={`${shopMember?.ongoing_work_record ? "Clock Out" : "Clock In"} ${prefer_name}`}
            onConfirm={() => console.log("confirm")}
            onClose={onClose}
            omitButton
            isLoading={isLoading}
            error={error}
        >
            <Stack
                sx={{
                    direction: "column",
                    alignItems: "center",
                    opacity: isLoading ? 0.5 : 1,
                }}
            >
                <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        py: "2rem",
                    }}
                >
                    {pin.map((p, i) => (p !== "" ? <AcUnitIcon key={i} sx={{ fontSize: "6rem" }} /> : <RemoveIcon key={i} sx={{ fontSize: "6rem" }} />))}
                </Stack>
                {panel}
            </Stack>
        </ConfirmModal>
    )
}
