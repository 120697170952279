import PostAddIcon from "@mui/icons-material/PostAdd"
import { Box, Pagination, Stack, Typography } from "@mui/material"
import * as React from "react"
import FlipMove from "react-flip-move"
import { useNavigate } from "react-router-dom"
import { useImportRecord } from "../../containers/importRecords"
import { usePagination } from "../../hooks/usePagination"
import { colors, fonts } from "../../theme/theme"
import { ImportRecord } from "../../types/importRecords"
import { CurrencyToggleButton } from "../common/currency/currency"
import { PageHeader } from "../common/pageHeader"
import { TotalAndPageSizeOptions } from "../common/totalAndPageSizeOptions"
import { ImportRecordDetail } from "./detail/importRecordDetail"
import { ImportRecordCard } from "./importRecordCard"

export const ImportRecords = () => {
    const { importRecords } = useImportRecord()
    const navigate = useNavigate()

    const [selectedRecordID, setSelectedRecordID] = React.useState("")

    const selectedRecord = React.useMemo(() => {
        return importRecords.find((ir) => ir.id === selectedRecordID)
    }, [importRecords, selectedRecordID])

    const [list, setList] = React.useState<ImportRecord[]>([])
    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    React.useEffect(() => {
        let sorted = [...importRecords]

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [importRecords, page, pageSize, setTotalItems])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((importRecord) => {
                        return (
                            <div
                                key={`import-record-${importRecord.id}`}
                                style={{
                                    marginBottom: "1.3rem",
                                    cursor: "pointer",
                                    backgroundColor: selectedRecordID === importRecord.id ? `${colors.primary}10` : colors.background,
                                }}
                                onClick={() => setSelectedRecordID(importRecord.id)}
                            >
                                <ImportRecordCard importRecord={importRecord} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, selectedRecordID])

    return (
        <>
            <Stack
                minWidth="80rem"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    mr: "1rem",
                }}
            >
                <PageHeader
                    title={
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                <strong>IMPORT RECORDS</strong>
                            </Typography>
                        </Stack>
                    }
                    popoverFuncs={[
                        {
                            label: "Add Record",
                            onClick: () => navigate("/import/create"),
                            icon: <PostAddIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="row" flex={1}>
                    <Stack direction="column" flex={1}>
                        <TotalAndPageSizeOptions
                            countItems={list.length}
                            pageSizeOptions={[5, 10, 15]}
                            pageSize={pageSize}
                            changePageSize={changePageSize}
                            changePage={changePage}
                        >
                            <Typography variant="body1">
                                <strong>CURRENCY:</strong>
                            </Typography>
                            <CurrencyToggleButton />
                        </TotalAndPageSizeOptions>

                        <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                            <Box
                                sx={{
                                    ml: "1.9rem",
                                    mr: ".5rem",
                                    pr: "1.4rem",
                                    my: "1rem",
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>

                        {totalPages > 1 && (
                            <Box
                                sx={{
                                    mt: "auto",
                                    px: "1rem",
                                    py: ".7rem",
                                    backgroundColor: colors.background,
                                }}
                            >
                                <Pagination
                                    size="medium"
                                    count={totalPages}
                                    page={page}
                                    sx={{
                                        ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                        ".Mui-selected": {
                                            color: colors.primary,
                                            backgroundColor: `${colors.background} !important`,
                                        },
                                    }}
                                    onChange={(e, p) => changePage(p)}
                                />
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <Box
                flex={1}
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    display: !selectedRecordID ? "none" : undefined,
                }}
            >
                {selectedRecord && <ImportRecordDetail importRecord={selectedRecord} />}
            </Box>
        </>
    )
}
