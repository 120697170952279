import { Box, Popover, Stack, Typography } from "@mui/material"
import React from "react"
import { TimeFormatOption, useTimeFormat } from "../../containers/timeFormatter"
import { useServerCommandsUser } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts, siteZIndex } from "../../theme/theme"

export const TimeFormatSelector = () => {
    const { send } = useServerCommandsUser()
    const { timeFormatOption } = useTimeFormat()
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)

    const handleTimeFormatChange = async (timeFormatPreference: TimeFormatOption) => {
        try {
            await send(WSKeys.WSKeyUserTimeFormatPreferenceUpdate, { time_format_preference: timeFormatPreference })
        } finally {
            setAnchorEl(null)
        }
    }

    return (
        <Box>
            <Stack
                sx={{
                    mr: "1rem",
                    border: `${colors.offWhite} 1px solid`,
                    borderRadius: 0.8,
                    px: "1rem",
                    cursor: "pointer",
                }}
                onClick={handleClick}
            >
                <Typography color={colors.offWhite} fontFamily={fonts.sourceSansProBoldIt}>
                    {timeFormatOption}
                </Typography>
            </Stack>
            <Popover
                id={"PageHeader-popover-modal"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    mt: ".5rem",
                    zIndex: siteZIndex.Popover,
                    ".MuiPaper-root": {
                        background: colors.background,
                    },
                }}
            >
                <Stack
                    direction="column"
                    sx={{
                        minWidth: "6rem",
                        p: ".5rem",
                    }}
                >
                    {Object.entries(TimeFormatOption).map((entry) => {
                        const key = entry[0]
                        const value = entry[1]
                        return (
                            <Stack
                                key={key}
                                sx={{
                                    display: "flex",
                                    direction: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    my: ".25rem",
                                    borderRadius: 0.8,
                                    cursor: "pointer",
                                    ":hover": {
                                        background: `${colors.primary}30`,
                                    },
                                }}
                                onClick={() => {
                                    handleTimeFormatChange(value)
                                }}
                            >
                                <Typography fontFamily={fonts.sourceSansProSemiboldIt}>{value}</Typography>
                            </Stack>
                        )
                    })}
                </Stack>
            </Popover>
        </Box>
    )
}
