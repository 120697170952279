import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded"
import { Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { useItem } from "../../../containers/items"
import { calCostRatio } from "../../../helpers"
import { useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ItemStackIDs } from "../../../types/items"
import { TypographyTrans } from "../../common/TypographyTrans"
import { DotPointHeader } from "../../common/dotPointHeader"
import { PageHeader } from "../../common/pageHeader"
import TextTablet from "../../common/textTablet"
import IntermediateProductStackList from "./intermediateProductStackList"
import ProductStackList from "./productStackList"
import { ShopItemModal } from "./shopItemModal"

interface Props {
    itemID: string
}

const ShopItemDetail = ({ itemID }: Props) => {
    const { items, intermediateProducts, products } = useItem()
    const [openItemModal, setOpenItemModal] = React.useState(false)
    const [itemStackIDs, setItemStackIDs] = React.useState<ItemStackIDs>()

    useServerSubscriptionShop<ItemStackIDs>(
        {
            URI: `/item_stacks/${itemID}`,
            key: WSKeys.WSKeyItemStacks,
        },
        {
            callback: (payload) => {
                setItemStackIDs(payload)
            },
        },
    )

    const item = React.useMemo(() => {
        if (!items) return null

        return items.find((i) => i.id === itemID)
    }, [items, itemID])

    const usedByIntermediateProducts = React.useMemo(() => {
        if (!intermediateProducts || !itemStackIDs || !itemStackIDs.parent_intermediate_product_ids) return null

        return [...intermediateProducts].filter((ip) => itemStackIDs.parent_intermediate_product_ids?.includes(ip.id))
    }, [intermediateProducts, itemStackIDs])

    const usedByProducts = React.useMemo(() => {
        if (!products || !itemStackIDs || !itemStackIDs.parent_product_ids) return null

        return [...products].filter((p) => itemStackIDs.parent_product_ids?.includes(p.id))
    }, [products, itemStackIDs])

    const labelValue = React.useCallback((label: string, value: string) => {
        return (
            <Stack direction={"row"} spacing={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
                <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                    {label}
                </TypographyTrans>
                <Typography variant="h5" fontFamily={fonts.sourceSansProBold}>
                    {value}
                </Typography>
            </Stack>
        )
    }, [])

    if (!item) return null

    const { label, cost, unit_type, volume, item_sources } = item

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    flex: 1,
                }}
            >
                <PageHeader
                    title={
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>DETAIL</strong>
                        </Typography>
                    }
                    popoverFuncs={[
                        {
                            label: "Update Item",
                            onClick: () => setOpenItemModal(true),
                            icon: <ConstructionRoundedIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="column" flex={1} sx={{ p: "1rem" }} spacing={"1rem"}>
                    {/* Item details */}
                    <Stack direction="column" sx={{ height: "fit-content", mb: "3rem", maxWidth: "60rem" }}>
                        {labelValue("Label:", label)}
                        {labelValue("Cost:", `$ ${cost}`)}
                        {labelValue("Volume:", `${volume} ${unit_type.toLowerCase()}`)}
                        {labelValue("Unit Price:", calCostRatio(cost, volume, unit_type))}
                        {item_sources && (
                            <Stack direction={"column"} spacing={".2rem"}>
                                <TypographyTrans variant="h5" fontFamily={fonts.sourceSansProRegular}>
                                    {`Source${item_sources.length > 1 ? "s" : ""}:`}
                                </TypographyTrans>
                                <Stack direction="row" paddingLeft={"1rem"}>
                                    {item_sources.map((is, i) => (
                                        <TextTablet key={i} text={is.label} />
                                    ))}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>

                    {/* Item stacks */}
                    <Stack direction="column" flex={1}>
                        <DotPointHeader trans title="USED BY" />
                        <Stack direction="row" spacing={"1rem"} flex={1}>
                            <Stack direction="column" flex={1}>
                                <Typography variant="h5" fontFamily={fonts.sourceSansProSemiboldIt}>
                                    Mid Products
                                </Typography>
                                {usedByIntermediateProducts && <IntermediateProductStackList intermediateProducts={usedByIntermediateProducts} />}
                            </Stack>
                            <Divider sx={{ my: ".35rem !important", border: `${colors.primary}80 1px dashed` }} />
                            <Stack direction="column" flex={1}>
                                <Typography variant="h5" fontFamily={fonts.sourceSansProSemiboldIt}>
                                    Products
                                </Typography>
                                {usedByProducts && <ProductStackList products={usedByProducts} />}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {openItemModal && item && <ShopItemModal item={item} onClose={() => setOpenItemModal(false)} />}
        </>
    )
}

export default ShopItemDetail
