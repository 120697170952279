import { Box, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useItem } from "../../../../containers/items"
import { typographyMaxLength } from "../../../../theme/sxProps"
import { colors } from "../../../../theme/theme"
import { Ingredient } from "../../../../types/items"

interface ImportedItemIngredientTableProps {
    itemIngredients: Ingredient[]
    setItemIngredientList: React.Dispatch<React.SetStateAction<Ingredient[]>>
    searchValue: string
    offsetIngredient?: boolean
    removeIngredientIcon?: JSX.Element
    addIngredientIcon?: JSX.Element
}

export const ImportedItemIngredientTable = ({
    itemIngredients,
    setItemIngredientList,
    offsetIngredient,
    removeIngredientIcon,
    addIngredientIcon,
    searchValue,
}: ImportedItemIngredientTableProps) => {
    const { ingredients } = useItem()

    const list = React.useMemo(() => {
        return [...ingredients]
            .filter((f) => {
                if (offsetIngredient) return !itemIngredients.some((ii) => ii.id === f.id)
                return itemIngredients.some((ii) => ii.id === f.id)
            })
            .filter((ii) => `${ii.label_ch} ${ii.label_en}`.toLowerCase().includes(searchValue.toLowerCase()))
    }, [ingredients, itemIngredients, offsetIngredient, searchValue])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((ii) => {
                        return (
                            <div key={`ingredient-${ii.id}`} style={{ marginBottom: "1rem" }}>
                                <ItemIngredientCard
                                    itemIngredient={ii}
                                    removeIngredientIcon={removeIngredientIcon}
                                    setItemIngredientList={setItemIngredientList}
                                    addIngredientIcon={addIngredientIcon}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [addIngredientIcon, list, removeIngredientIcon, setItemIngredientList])

    return (
        <Stack direction="column" spacing={0.6} flex={1}>
            <Box
                sx={{
                    mr: ".5rem",
                    pr: "1.4rem",
                    flex: 1,
                    overflowY: "auto",
                    overflowX: "hidden",
                    direction: "ltr",
                }}
            >
                {content}
            </Box>
        </Stack>
    )
}

interface ItemIngredientCardProps {
    itemIngredient: Ingredient
    removeIngredientIcon?: JSX.Element
    addIngredientIcon?: JSX.Element
    setItemIngredientList: React.Dispatch<React.SetStateAction<Ingredient[]>>
}

export const ItemIngredientCard = ({ itemIngredient, removeIngredientIcon, addIngredientIcon, setItemIngredientList }: ItemIngredientCardProps) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                border: `${colors.primary} 1px solid`,
                width: "100%",
                p: "1rem",
            }}
        >
            <Stack>
                <Typography variant="h6" sx={{ ...typographyMaxLength }}>
                    {itemIngredient.label_ch}
                </Typography>
                <Typography variant="body2" sx={{ ...typographyMaxLength }}>
                    {itemIngredient.label_en}
                </Typography>
            </Stack>

            {removeIngredientIcon && (
                <IconButton
                    disableRipple
                    size="small"
                    sx={{
                        p: 0,
                    }}
                    onClick={() => setItemIngredientList((prev) => prev.filter((pre) => pre.id !== itemIngredient.id))}
                >
                    {removeIngredientIcon}
                </IconButton>
            )}

            {addIngredientIcon && (
                <IconButton
                    disableRipple
                    size="small"
                    sx={{
                        p: 0,
                    }}
                    onClick={() => setItemIngredientList((prev) => prev.filter((pre) => pre.id !== itemIngredient.id).concat(itemIngredient))}
                >
                    {addIngredientIcon}
                </IconButton>
            )}
        </Stack>
    )
}
