import { Stack } from "@mui/material"
import React from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { usePermissions } from "../../../containers/permissions"
import { roundedTo, snakeToTitle } from "../../../helpers"
import { useServerCommandsShop, useServerSubscriptionOrg, useServerSubscriptionSecure, useServerSubscriptionShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors } from "../../../theme/theme"
import { ShopJobLevel, ShopJobTitle, ShopJobWage, ShopMember, ShopRole } from "../../../types/Shop"
import { VisaType } from "../../../types/organisations"
import { Permission } from "../../../types/permissions"
import { ConfirmModal } from "../../common/confirmModal"
import { DotPointHeader } from "../../common/dotPointHeader"
import { InputField } from "../../common/form/inputField"
import { SelectField } from "../../common/form/selectField"

export interface ShopMemberForm {
    prefer_name: string
    email: string
    visa_type_id: string
    clock_in_pin: string
    shop_role_id: string

    payslip_full_name: string
    payslip_prefer_name: string

    job_title_id: string

    job_level_id: string
    job_wage_id: string
    weekday_pay: string
    saturday_pay: string
    sunday_pay: string
    holiday_pay: string
}

interface ShopMemberModalProps {
    shopMember?: ShopMember
    onClose: () => void
}
export const ShopMemberModal = ({ shopMember, onClose }: ShopMemberModalProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const formMethods = useForm<ShopMemberForm>({
        defaultValues: {
            prefer_name: shopMember?.prefer_name || "",
            email: shopMember?.email || "",
            visa_type_id: shopMember?.visa_type_id || "",
            clock_in_pin: shopMember?.clock_in_pin || "",
            shop_role_id: shopMember?.shop_role_id || "",
            job_title_id: shopMember?.job_title_id || "",
            job_level_id: shopMember?.job_level_id || "",
            job_wage_id: shopMember?.job_wage_id || "",
            weekday_pay: shopMember?.weekday_pay || "0",
            saturday_pay: shopMember?.saturday_pay || "0",
            sunday_pay: shopMember?.sunday_pay || "0",
            holiday_pay: shopMember?.holiday_pay || "0",
            payslip_full_name: shopMember?.payslip_full_name || "",
            payslip_prefer_name: shopMember?.payslip_prefer_name || "",
        },
    })
    const { control, setValue, trigger, getValues } = formMethods

    const [visaTypes, setVisaTypes] = React.useState<VisaType[]>([])
    useServerSubscriptionSecure<VisaType[]>(
        {
            URI: `/visa_types`,
            key: WSKeys.WSKeyVisaTypes,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setVisaTypes(payload)
            },
        },
    )

    const [shopRoleOptions, setShopRoleOptions] = React.useState<ShopRole[]>([])
    useServerSubscriptionOrg<ShopRole[]>(
        {
            URI: `/shop_roles`,
            key: WSKeys.WSKeyShopRoles,
            ready: hasPerm(Permission.ShopRoleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopRoleOptions((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at)
                    prev = prev.map((sr) => payload.find((p) => p.id === sr.id) || sr)
                    payload.forEach((p) => (prev.some((sr) => sr.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((sr) => !sr.deleted_at)
                })
            },
        },
    )

    const [shopJobTitles, setShopJobTitles] = React.useState<ShopJobTitle[]>([])
    useServerSubscriptionShop<ShopJobTitle[]>(
        {
            URI: `/job_titles`,
            key: WSKeys.WSKeyShopJobTitles,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobTitles((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => a.title.localeCompare(b.title))
                    prev = prev.map((jt) => payload.find((p) => p.id === jt.id) || jt)
                    payload.forEach((p) => (prev.some((jt) => jt.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jt) => !jt.deleted_at).sort((a, b) => a.title.localeCompare(b.title))
                })
            },
        },
    )

    const handleShopMember = React.useCallback(async () => {
        if (!(await trigger())) return

        const data = getValues()

        try {
            setIsLoading(true)
            const resp = await send(shopMember ? WSKeys.WSKeyShopMemberUpdate : WSKeys.WSKeyShopMemberCreate, {
                id: shopMember?.id || "",
                email: data.email,
                prefer_name: data.prefer_name,
                visa_type_id: data.visa_type_id,
                payslip_full_name: data.payslip_full_name,
                payslip_prefer_name: data.payslip_prefer_name,
                clock_in_pin: data.clock_in_pin,
                shop_role_id: data.shop_role_id,
                shop_job_title_id: data.job_title_id,
                shop_job_level_id: data.job_level_id,
                shop_job_wage_id: data.job_wage_id,
                weekday_pay: roundedTo(data.weekday_pay, 2),
                saturday_pay: roundedTo(data.saturday_pay, 2),
                sunday_pay: roundedTo(data.sunday_pay, 2),
                holiday_pay: roundedTo(data.holiday_pay, 2),
            })
            if (resp) {
                setIsLoading(false)
                onClose()
            }
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to create new shop member")
        } finally {
            setIsLoading(false)
        }
    }, [getValues, onClose, send, shopMember, trigger])

    return (
        <ConfirmModal
            trans
            title={`${shopMember ? "Update" : "Create"} Member`}
            onClose={onClose}
            onConfirm={handleShopMember}
            width={"min(80vw, 100rem)"}
            error={error}
            isLoading={isLoading}
        >
            <FormProvider {...formMethods}>
                <Stack direction={"row"} spacing={"2rem"}>
                    <Stack direction={"column"} flex={1} spacing={"1rem"}>
                        <Stack spacing={".8rem"}>
                            <DotPointHeader
                                trans
                                title="GENERAL"
                                titleSx={{
                                    color: colors.primary,
                                    fontSize: "2rem",
                                }}
                            />
                            <InputField
                                trans
                                label="Prefer Name"
                                name="prefer_name"
                                variant="outlined"
                                control={control}
                                rules={{ required: "Prefer name is required" }}
                            />
                            <SelectField
                                trans
                                title={"Visa Type"}
                                control={control}
                                name="visa_type_id"
                                options={visaTypes.map((i) => ({ id: i.id, label: i.label }))}
                                rules={{ required: "Visa type is required" }}
                            />
                            <SelectField
                                trans
                                title={"Role"}
                                control={control}
                                name="shop_role_id"
                                options={shopRoleOptions.map((i) => ({ id: i.id, label: i.label }))}
                                rules={{ required: "Shop role is required" }}
                            />
                            <InputField label="Email" name="email" variant="outlined" control={control} rules={{ required: "Email is required" }} />
                            <InputField
                                label="PIN"
                                name="clock_in_pin"
                                variant="outlined"
                                control={control}
                                rules={{
                                    required: "PIN is required",
                                    pattern: {
                                        value: /^[0-9]{4}$/,
                                        message: "PIN must be 4 digits",
                                    },
                                }}
                            />

                            <InputField
                                trans
                                label="Full Name (Payslip)"
                                name="payslip_full_name"
                                variant="outlined"
                                control={control}
                                rules={{ required: "Full name is required" }}
                            />
                            <InputField
                                trans
                                label="Prefer Name (Payslip)"
                                name="payslip_prefer_name"
                                variant="outlined"
                                control={control}
                                rules={{ required: "Prefer name is required" }}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction={"column"} flex={1} spacing={"1rem"}>
                        <Stack spacing={".8rem"}>
                            <DotPointHeader
                                trans
                                title="WAGES"
                                titleSx={{
                                    color: colors.primary,
                                    fontSize: "2rem",
                                }}
                            />
                            <SelectField
                                trans
                                title={"Job Title"}
                                control={control}
                                name="job_title_id"
                                options={shopJobTitles.map((i) => ({ id: i.id, label: i.title }))}
                                onChange={() => {
                                    setValue("job_level_id", "")
                                    setValue("job_wage_id", "")
                                    setValue("weekday_pay", "0")
                                    setValue("saturday_pay", "0")
                                    setValue("sunday_pay", "0")
                                    setValue("holiday_pay", "0")
                                }}
                                rules={{ required: "Job title is required" }}
                            />

                            <JobLevelField />
                            <JobWageField />

                            <InputField trans label="Weekday Pay" name="weekday_pay" variant="outlined" control={control} type={"number"} />
                            <InputField trans label="Saturday Pay" name="saturday_pay" variant="outlined" control={control} type={"number"} />
                            <InputField trans label="Sunday Pay" name="sunday_pay" variant="outlined" control={control} type={"number"} />
                            <InputField trans label="Holiday Pay" name="holiday_pay" variant="outlined" control={control} type={"number"} />
                        </Stack>
                    </Stack>
                </Stack>
            </FormProvider>
        </ConfirmModal>
    )
}

const JobLevelField = () => {
    const { hasPerm } = usePermissions()
    const { control, setValue, watch } = useFormContext()
    const selectedJobTitleID = watch("job_title_id")
    const [shopJobLevels, setShopJobLevels] = React.useState<ShopJobLevel[]>([])
    React.useEffect(() => setShopJobLevels([]), [selectedJobTitleID])
    useServerSubscriptionShop<ShopJobLevel[]>(
        {
            URI: `/job_title/${selectedJobTitleID}/job_levels`,
            key: WSKeys.WSKeyShopJobLevels,
            ready: hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobLevels((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                    prev = prev.map((jl) => payload.find((p) => p.id === jl.id) || jl)
                    payload.forEach((p) => (prev.some((jl) => jl.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jl) => !jl.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )
    return (
        <SelectField
            trans
            title={"Job Level"}
            control={control}
            name="job_level_id"
            options={shopJobLevels.map((i) => ({ id: i.id, label: i.label }))}
            onChange={() => {
                setValue("job_wage_id", "")
                setValue("weekday_pay", "0")
                setValue("saturday_pay", "0")
                setValue("sunday_pay", "0")
                setValue("holiday_pay", "0")
            }}
            rules={{ required: "Job level is required" }}
        />
    )
}
const JobWageField = () => {
    const { hasPerm } = usePermissions()
    const { control, setValue, watch } = useFormContext()
    const selectedShopJobLevelID = watch("job_level_id")
    const [shopJobWages, setShopJobWages] = React.useState<ShopJobWage[]>([])
    React.useEffect(() => setShopJobWages([]), [selectedShopJobLevelID])
    useServerSubscriptionShop<ShopJobWage[]>(
        {
            URI: `/job_level/${selectedShopJobLevelID}/job_wages`,
            key: WSKeys.WSKeyShopJobWages,
            ready: !!selectedShopJobLevelID && hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobWages((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                    prev = prev.map((jw) => payload.find((p) => p.id === jw.id) || jw)
                    payload.forEach((p) => (prev.some((jw) => jw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jw) => !jw.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )
    return (
        <SelectField
            trans
            title={"Employment Status"}
            control={control}
            name="job_wage_id"
            options={shopJobWages.map((i) => ({ id: i.id, label: snakeToTitle(i.employment_status) }))}
            rules={{ required: "Employment status is required" }}
            onChange={(e) => {
                if (typeof e === "string") return
                const wage = shopJobWages.find((jw) => jw.id === e.id)
                if (!wage) return
                setValue("weekday_pay", `${wage.weekday_pay}`)
                setValue("saturday_pay", `${wage.saturday_pay}`)
                setValue("sunday_pay", `${wage.sunday_pay}`)
                setValue("holiday_pay", `${wage.holiday_pay}`)
            }}
        />
    )
}
