import { Autocomplete, Box, createFilterOptions, TextField, Typography } from "@mui/material"
import { colors, fonts } from "../../theme/theme"

interface SelectBoxProps {
    options: SelectBoxOption[]
    onChange?: (v: string) => void

    defaultValue?: string
}
export interface SelectBoxOption {
    id: string
    label: string
    description?: string
}
export const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: SelectBoxOption) => `${option.label} ${option.description || ""}`.toLowerCase(),
})

export const AutocompleteBox = ({ options, onChange, defaultValue }: SelectBoxProps) => {
    return (
        <Autocomplete
            id="combo-box-demo"
            freeSolo
            disableClearable
            fullWidth
            options={options}
            defaultValue={defaultValue}
            onChange={(event: any, newValue: SelectBoxOption | string) => {
                if (!onChange) return

                if (typeof newValue === "string") {
                    onChange(newValue)
                    return
                }
                onChange(newValue.id)
            }}
            filterOptions={filterOptions}
            sx={{
                "&& .MuiInputBase-hiddenLabel": {
                    p: 0,
                    backgroundColor: "unset",
                },
                "&& .MuiOutlinedInput-root": {
                    p: 0,
                    backgroundColor: "unset",
                },
                "&& .MuiAutocomplete-input": {
                    height: "3rem",
                    px: "1.5rem",
                    py: ".5rem",
                },
            }}
            componentsProps={{
                paper: {
                    sx: {
                        backgroundColor: colors.background,
                        borderRadius: 0.5,
                    },
                },
                popper: {
                    sx: {
                        borderBottom: `${colors.primary}50 2px solid`,
                        borderLeft: `${colors.primary}50 2px solid`,
                        borderRight: `${colors.primary}50 2px solid`,
                        borderRadius: 0.5,
                    },
                },
            }}
            noOptionsText={
                <Typography sx={{ opacity: 0.6 }}>
                    <i>No options</i>
                </Typography>
            }
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Typography variant="body1" fontFamily={fonts.sourceSansProSemiboldIt}>
                        {option.label}
                    </Typography>
                    {option.description && (
                        <Typography variant="body2" sx={{ ml: "1rem" }}>
                            {option.description}
                        </Typography>
                    )}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    hiddenLabel
                    defaultValue={defaultValue}
                    sx={{
                        ".MuiOutlinedInput-root": {
                            p: 0,
                        },
                        ".MuiOutlinedInput-input": {
                            height: "unset",
                            "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                                WebkitAppearance: "none",
                            },
                            fontSize: "2rem",
                            fontFamily: fonts.sourceSansProSemibold,
                            borderRadius: 0.5,
                            border: `${colors.primary}50 2px solid`,
                            ":hover, :focus, :active": { border: `${colors.primary}99 2px solid` },
                        },
                        ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                    }}
                />
            )}
        />
    )
}
