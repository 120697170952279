import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded"
import PercentRoundedIcon from "@mui/icons-material/PercentRounded"
import { Box, Stack } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useForm } from "react-hook-form"
import { calComponentCost, priceCalculator, roundToDecimalPlaces } from "../../../helpers"
import { useServerCommandsShop } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { fonts } from "../../../theme/theme"
import { Product } from "../../../types/products"
import { InputBox } from "../../common/InputBox"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ConfirmModal } from "../../common/confirmModal"
import { InputField } from "../../common/form/inputField"
import { ComponentCard } from "./componentCard"
import { ComponentSelectionList, ProductComponent } from "./componentSelectionList"

interface ProductInput {
    label: string
    price: string
}

interface Props {
    onClose: () => void
    product?: Product
    initialFirstLevelItems?: ProductComponent[]
}

const ProductModal = ({ product, onClose, initialFirstLevelItems = [] }: Props) => {
    const { send } = useServerCommandsShop()
    const [emptyStackCheck, setEmptyStackCheck] = React.useState(false)
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedStacks, setSelectedStacks] = React.useState<ProductComponent[]>(initialFirstLevelItems)
    const [cost, setCost] = React.useState("0")
    const [profitRate, setProfitRate] = React.useState("0")

    const { control, trigger, getValues, setValue, watch } = useForm<ProductInput>({
        defaultValues: {
            label: product?.label || "",
            price: product?.price || "0",
        },
    })

    const price = watch("price")

    React.useEffect(() => {
        let newTotalCost = 0

        selectedStacks.forEach((stack) => {
            newTotalCost += calComponentCost(stack.cost, stack.volume, stack.unit_type, stack.setVolume, stack.setUnitType)
        })

        const newCost = roundToDecimalPlaces(newTotalCost)

        setCost(newCost)

        const { price: p, profitRate: pr } = priceCalculator({
            cost: newCost,
            price: price,
        })
        setValue("price", roundToDecimalPlaces(p))
        setProfitRate(roundToDecimalPlaces(pr))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStacks])

    const onSubmit = React.useCallback(async () => {
        if (!(await trigger())) return

        if (!selectedStacks.length) {
            setEmptyStackCheck(true)
            return
        }

        const value = getValues()

        setIsLoading(true)
        try {
            const resp = await send(product ? WSKeys.WSKeyProductUpdate : WSKeys.WSKeyProductCreate, {
                id: product?.id,
                label: value.label,
                price: value.price,
                item_stacks: selectedStacks.filter((ss) => ss.type === "item").map((ss) => ({ id: ss.id, volume: ss.setVolume, unit_type: ss.setUnitType })),
                intermediate_product_stacks: selectedStacks
                    .filter((ss) => ss.type === "intermediate_product")
                    .map((ss) => ({ id: ss.id, volume: ss.setVolume, unit_type: ss.setUnitType })),
            })
            if (!resp) return

            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : `Failed to ${product ? "edit" : "create new"} product.`)
        } finally {
            setIsLoading(false)
        }
    }, [trigger, getValues, product, onClose, send, selectedStacks])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {selectedStacks.map((component) => {
                        return (
                            <div key={`component-${component.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ComponentCard
                                    productComponent={component}
                                    onVolumeChange={(v) => {
                                        setSelectedStacks((prev) => {
                                            return prev.map((p) => {
                                                if (p.id !== component.id) return p
                                                return { ...p, setVolume: v }
                                            })
                                        })
                                    }}
                                    onUnitTypeChange={(v) => {
                                        setSelectedStacks((prev) => {
                                            return prev.map((p) => {
                                                if (p.id !== component.id) return p
                                                return { ...p, setUnitType: v }
                                            })
                                        })
                                    }}
                                    onRemove={() => {
                                        setSelectedStacks((prev) => prev.filter((p) => p.id !== component.id))
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [selectedStacks, setSelectedStacks])

    return (
        <ConfirmModal
            title={`${product ? "Edit" : "Create new"} product`}
            onClose={onClose}
            onConfirm={onSubmit}
            width="130rem"
            isLoading={isLoading}
            error={error}
        >
            <Stack flex={1} direction={"row"} justifyContent={"space-between"} spacing={"1rem"}>
                <Stack width={"58rem"} height={"80rem"}>
                    <ComponentSelectionList selectedStacks={selectedStacks} setSelectedStacks={setSelectedStacks} />
                </Stack>
                <Stack flex={1} spacing={"1.5rem"}>
                    <Stack spacing={"1rem"} direction={"row"}>
                        <InputField
                            label={"Label *"}
                            name="label"
                            variant={"outlined"}
                            control={control}
                            rules={{
                                required: "Label is required.",
                                maxLength: { value: 50, message: "Label must be less than 50 characters." },
                            }}
                        />
                    </Stack>
                    <Stack spacing={"1rem"} direction={"row"}>
                        <InputBox
                            label="Cost"
                            variant="outlined"
                            value={cost}
                            setValue={() => {
                                return
                            }}
                            disabled
                            InputProps={{
                                startAdornment: <AttachMoneyRoundedIcon fontSize="large" />,
                            }}
                        />
                        <InputBox
                            label="Profit Rate"
                            variant="outlined"
                            value={profitRate}
                            type="number"
                            setValue={(v) => {
                                setProfitRate(v)
                            }}
                            onChange={(e) => {
                                const v = e.target.value
                                if (v === "") {
                                    setProfitRate("0")
                                }
                                const { price } = priceCalculator({
                                    cost,
                                    profitRate: v,
                                })

                                setValue("price", roundToDecimalPlaces(price))
                            }}
                            InputProps={{
                                endAdornment: <PercentRoundedIcon fontSize="large" />,
                            }}
                        />
                        <InputField
                            label={"Price *"}
                            name="price"
                            variant={"outlined"}
                            control={control}
                            type="number"
                            rules={{
                                required: "Price is required.",
                                validate: {
                                    positive: (v: string) => parseFloat(v) > 0 || "Price must be positive.",
                                },
                            }}
                            onChange={(e) => {
                                const v = e.target.value
                                if (v === "") return

                                const { profitRate } = priceCalculator({
                                    cost,
                                    price: v,
                                })
                                setProfitRate(roundToDecimalPlaces(profitRate))
                            }}
                            InputProps={{
                                startAdornment: <AttachMoneyRoundedIcon fontSize="large" />,
                            }}
                        />
                    </Stack>

                    <Stack flex={1}>
                        <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                            Contains
                        </TypographyTrans>
                        {emptyStackCheck && !selectedStacks.length && (
                            <TypographyTrans color={"error"}>Must contain at least one item or mid-product</TypographyTrans>
                        )}
                        <Stack sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {content}
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}

export default ProductModal
