import { Stack, Typography } from "@mui/material"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { CountUpWageTimer } from "../../components/timer/countUpWageTimer"
import { roundedTo } from "../../helpers"
import { useServerCommandsUser } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts } from "../../theme/theme"
import { UnfinishedWorkRecord, WorkRecordLiveList } from "../../types/Shop"

interface Props {
    recalculate?: boolean
}

export const TotalTodayWageCard = ({ recalculate }: Props) => {
    const { send } = useServerCommandsUser()
    const [totalHistoryAmount, setTotalHistoryAmount] = useState(0)
    const [UnfinishedWorkRecords, setUnfinishedWorkRecords] = useState<UnfinishedWorkRecord[]>([])
    const [renderCount, setRenderCount] = useState(0)

    const getTodayWage = useCallback(async () => {
        const resp = await send<WorkRecordLiveList>(WSKeys.WSKeyUserShopLiveWorkRecord, {
            start_time: moment().startOf("day").toDate(),
            end_time: moment().endOf("day").toDate(),
        })

        if (!resp) return

        setRenderCount((prev) => prev + 1)
        setTotalHistoryAmount(roundedTo(resp.total_history_amount, 2))
        setUnfinishedWorkRecords(resp.unfinished_work_records)
    }, [send])

    useEffect(() => {
        setTimeout(() => getTodayWage(), 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                p: "1rem",
                backgroundColor: colors.primary,
                width: "100%",
                height: "100%",
                borderRadius: 3,
            }}
        >
            <Typography variant="h4" fontWeight={"bolder"} color={colors.offWhite}>
                Day Wage
            </Typography>
            <Stack
                alignItems={"center"}
                sx={{
                    mb: "3rem",
                }}
                spacing={"2rem"}
            >
                {recalculate ? (
                    <Typography sx={{ fontFamily: fonts.sourceSansProBold, fontSize: "2.5rem", color: colors.offWhite }}>Recalculate</Typography>
                ) : (
                    <CountUpWageTimer
                        key={`daily-render-count-${renderCount}`}
                        baseWage={totalHistoryAmount}
                        startedRecords={UnfinishedWorkRecords.map((r) => ({
                            started_at: moment(r.started_at).toDate(),
                            wage: parseFloat(r.wage),
                            withSuperRate: parseFloat(r.with_super_rate),
                        }))}
                        sx={{
                            color: colors.offWhite,
                        }}
                    />
                )}
            </Stack>
        </Stack>
    )
}
