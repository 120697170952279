import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded"
import PaymentsIcon from "@mui/icons-material/Payments"
import TimerIcon from "@mui/icons-material/Timer"
import { Box, Stack, SxProps, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { SendFunc } from "../../../../../containers/ws/useCommands"
import { roundedTo, secondsToDisplayTime } from "../../../../../helpers"
import { colors, fonts } from "../../../../../theme/theme"
import { UserShopWorkRecord } from "../../../../../types/Shop"
import { DateFormat } from "../../../../../types/types"
import { TimeDisplay } from "../../../../common/timeDisplay"
import { WorkRecordRequestModal } from "./WorkRecordRequestModal"
import { WorkRecordDeleteModal } from "./workRecordDeleteModal"

interface WorkRecordCardProps {
    workRecord: UserShopWorkRecord
    weekdayPay: string
    saturdayPay: string
    sundayPay: string
    send: SendFunc

    omitPay?: boolean
    small?: boolean
}

export const WorkRecordCard = ({ workRecord, weekdayPay, saturdayPay, sundayPay, send, omitPay, small }: WorkRecordCardProps) => {
    const { started_at, ended_at, total_seconds, attached_to_payslip_id } = workRecord
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
    const [openEditModal, setOpenEditModal] = React.useState(false)
    const salary = React.useMemo(() => {
        switch (started_at.getDay()) {
            case 0:
                return roundedTo((total_seconds * roundedTo(sundayPay)) / 3600)
            case 6:
                return roundedTo((total_seconds * roundedTo(saturdayPay)) / 3600)
            default:
                return roundedTo((total_seconds * roundedTo(weekdayPay)) / 3600)
        }
    }, [saturdayPay, started_at, sundayPay, total_seconds, weekdayPay])

    const sxOpacity = React.useMemo<SxProps>(() => {
        if (attached_to_payslip_id) return { opacity: 0.5 }
        return { opacity: 1 }
    }, [attached_to_payslip_id])

    const action = React.useMemo(() => {
        if (attached_to_payslip_id)
            return (
                <Box
                    sx={{
                        backgroundColor: colors.darkGrey,

                        height: "fit-content",
                        px: ".5rem",
                        borderRadius: 0.8,
                    }}
                >
                    <Typography variant={"body2"} color="white" fontFamily={fonts.sourceSansProSemibold}>
                        CLAIMED
                    </Typography>
                </Box>
            )

        return (
            <>
                <Box data-testid="work-record-edit-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenEditModal(true)}>
                    <ModeEditRoundedIcon
                        sx={{
                            fontSize: small ? "2rem" : "3.5rem",
                            color: colors.primary,
                        }}
                    />
                </Box>
                <Box data-testid="work-record-delete-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenDeleteModal(true)}>
                    <DeleteForeverOutlinedIcon
                        sx={{
                            fontSize: small ? "2rem" : "3.5rem",
                            color: colors.lightRed,
                        }}
                    />
                </Box>
            </>
        )
    }, [attached_to_payslip_id, small, setOpenDeleteModal, setOpenEditModal])

    return (
        <>
            <Box
                flex={1}
                sx={{
                    display: "grid",
                    gridTemplateColumns: omitPay ? "35% 24% 24% 10%" : "25% 20% 20% 20% 11%",
                    gridTemplateRows: "repeat(auto-fill, 5.5rem) ",
                    gap: "1rem",
                    borderRadius: 0.8,
                    border: `${colors.primary}30 3px solid`,
                    px: small ? "1rem" : "2rem",
                }}
            >
                <Stack direction={"row"} alignItems={"center"} spacing={"1rem"} sx={sxOpacity}>
                    <InsertInvitationIcon
                        sx={{
                            fontSize: small ? "2rem" : "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                        {moment(started_at).format(DateFormat.DateDay)}
                    </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing={small ? ".2rem" : "1rem"} sx={sxOpacity}>
                    <AccessTimeIcon
                        sx={{
                            fontSize: small ? "2rem" : "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <TimeDisplay variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold} date={started_at} />
                    <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                        ~
                    </Typography>
                    <TimeDisplay variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold} date={ended_at} />
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing={small ? ".2rem" : "1rem"} justifyContent={"center"} sx={sxOpacity}>
                    <TimerIcon
                        sx={{
                            fontSize: small ? "2rem" : "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                        {secondsToDisplayTime(total_seconds, true)}
                    </Typography>
                </Stack>

                {!omitPay && (
                    <Stack direction={"row"} alignItems={"center"} spacing={small ? ".2rem" : "1rem"} sx={{ pl: "25%", ...sxOpacity }}>
                        <PaymentsIcon
                            sx={{
                                fontSize: small ? "2rem" : "2.7rem",
                                color: colors.primary,
                            }}
                        />
                        <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                            ${salary}
                        </Typography>
                    </Stack>
                )}

                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={small ? "1rem" : "2rem"}>
                    {action}
                </Stack>
            </Box>

            {openEditModal && (
                <WorkRecordRequestModal
                    data-testid="work-record-request-modal"
                    userID={workRecord.user_id}
                    onClose={() => setOpenEditModal(false)}
                    workRecord={workRecord}
                    send={send}
                />
            )}
            {openDeleteModal && (
                <WorkRecordDeleteModal send={send} data-testid="work-record-delete-modal" workRecord={workRecord} onClose={() => setOpenDeleteModal(false)} />
            )}
        </>
    )
}
