import { Stack } from "@mui/material"
import React from "react"
import { colors, fonts } from "../../../../theme/theme"
import { Shop } from "../../../../types/Shop"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { ShopButton } from "../../../common/shopButton"
import { TwoFactorSetupComponent } from "./twoFactorComponent"

interface ShopPosPasscodeProps {
    shop: Shop
}
export const ShopPosPasscode = ({ shop }: ShopPosPasscodeProps) => {
    const { pos_is_set } = shop
    const [openTwoFactor, setOpenTwoFactor] = React.useState(false)

    if (openTwoFactor) return <TwoFactorSetupComponent exit={() => setOpenTwoFactor(false)} />

    return (
        <Stack flex={1} spacing={"1rem"}>
            <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                {pos_is_set
                    ? "Congratulations! Your POS login secret has been set. If you have lost or changed your device, please click the reset button to update the secret."
                    : "It looks like your POS login secret has not been set. Please click the button below to set your login secret."}
            </TypographyTrans>

            <ShopButton
                sx={{
                    py: 0,
                    px: "1rem",
                    backgroundColor: pos_is_set ? colors.lightRed : `${colors.green}BB`,
                    height: "5rem",
                    ":hover": {
                        backgroundColor: pos_is_set ? colors.lightRed : `${colors.green}BB`,
                    },
                }}
                onClick={() => setOpenTwoFactor(true)}
            >
                {pos_is_set ? "reset passcode" : "Set Passcode"}
            </ShopButton>
        </Stack>
    )
}
