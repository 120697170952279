import { Stack, SxProps, Typography } from "@mui/material"
import React from "react"
import { autoTextColor, snakeToTitle } from "../../helpers"
import { fonts } from "../../theme/theme"

interface TextBoxProps {
    label: string
    backgroundColor: string
    textColor?: string
    boxSX?: SxProps
}

export const TextBox = ({ label, backgroundColor, textColor, boxSX }: TextBoxProps) => {
    const contrastTextColor = React.useMemo(() => autoTextColor(backgroundColor), [backgroundColor])
    return (
        <Stack
            sx={{
                backgroundColor,
                width: "fit-content",
                border: "1px solid " + backgroundColor,
                borderRadius: 0.5,
                px: 1,
                ...boxSX,
            }}
        >
            <Typography
                variant={"h6"}
                sx={{
                    fontFamily: fonts.sourceSansProSemiboldIt,
                    color: textColor || contrastTextColor,
                }}
            >
                {snakeToTitle(label)}
            </Typography>
        </Stack>
    )
}
