import EditIcon from "@mui/icons-material/Edit"
import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { colors, fonts } from "../../theme/theme"

import AddIcon from "@mui/icons-material/Add"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import RemoveIcon from "@mui/icons-material/Remove"
import { typographyMaxLength } from "../../theme/sxProps"

export interface FilterBarOption {
    key: string
    value: string
    stay?: boolean
}

interface FilterOptionProps {
    title: React.ReactNode
    options: FilterBarOption[]
    selectedKeys: string[]
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>

    onKeySelect?: () => void

    openAddOptionModal?: boolean
    setOpenAddOptionModal?: React.Dispatch<React.SetStateAction<boolean>>
    addOptionModalFunc?: () => JSX.Element

    openUpdateOptionModal?: boolean
    setOpenUpdateOptionModal?: React.Dispatch<React.SetStateAction<boolean>>
    updateOptionModalFunc?: (item: FilterBarOption) => JSX.Element

    openDeleteOptionModal?: boolean
    setOpenDeleteOptionModal?: React.Dispatch<React.SetStateAction<boolean>>
    deleteOptionModalFunc?: (item: FilterBarOption) => JSX.Element
}

export const FilterOption = ({
    title,
    options,
    selectedKeys,
    setSelectedKeys,
    onKeySelect,

    openAddOptionModal,
    setOpenAddOptionModal,
    addOptionModalFunc,

    openUpdateOptionModal,
    setOpenUpdateOptionModal,
    updateOptionModalFunc,

    openDeleteOptionModal,
    setOpenDeleteOptionModal,
    deleteOptionModalFunc,
}: FilterOptionProps) => {
    const canAddOption = openAddOptionModal !== undefined && !!setOpenAddOptionModal && !!addOptionModalFunc

    const canUpdateOption = openUpdateOptionModal !== undefined && !!setOpenUpdateOptionModal && !!updateOptionModalFunc
    const [showUpdateButton, setShowUpdateButton] = React.useState(false)
    const [updatedOption, setUpdatedOption] = React.useState<FilterBarOption>()

    const canDeleteOption = openDeleteOptionModal !== undefined && !!setOpenDeleteOptionModal && !!deleteOptionModalFunc
    const [showDeleteButton, setShowDeleteButton] = React.useState(false)
    const [deletedOption, setDeletedOption] = React.useState<FilterBarOption>()

    const header = React.useMemo(() => {
        if (typeof title === "string") {
            return (
                <Typography
                    variant="h5"
                    fontWeight="bolder"
                    sx={{
                        my: "4px",
                    }}
                >
                    {title}:
                </Typography>
            )
        }

        return title
    }, [title])

    return (
        <>
            <Stack direction="column">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {header}
                    <Stack direction="row" alignItems="center">
                        {canAddOption && (
                            <IconButton
                                disableRipple
                                size="small"
                                onClick={() => {
                                    setOpenAddOptionModal((prev) => !prev)
                                    setShowDeleteButton(false)
                                    setShowUpdateButton(false)
                                    setSelectedKeys([])
                                }}
                            >
                                <AddIcon fontSize="large" sx={{ color: colors.primary }} />
                            </IconButton>
                        )}
                        {canUpdateOption && (
                            <IconButton
                                disableRipple
                                size="small"
                                onClick={() => {
                                    setShowUpdateButton((prev) => !prev)
                                    setShowDeleteButton(false)
                                    if (setOpenAddOptionModal) setOpenAddOptionModal(false)
                                    setSelectedKeys([])
                                }}
                            >
                                <EditIcon fontSize="large" sx={{ color: colors.green }} />
                            </IconButton>
                        )}
                        {canDeleteOption && (
                            <IconButton
                                disableRipple
                                size="small"
                                disabled={options.length === 1}
                                onClick={() => {
                                    setShowDeleteButton((prev) => !prev)
                                    setShowUpdateButton(false)
                                    if (setOpenAddOptionModal) setOpenAddOptionModal(false)
                                    setSelectedKeys([])
                                }}
                            >
                                <DeleteForeverIcon fontSize="large" sx={{ color: showDeleteButton && options.length > 1 ? colors.red : colors.primary }} />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>

                {options.map((o) => (
                    <Stack
                        key={o.key}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            width: "100%",
                            minHeight: "5.5rem",
                            backgroundColor: selectedKeys.includes(o.key) ? `${colors.primary}30` : colors.background,
                            pl: "1rem",
                            border: `${colors.primary} 1.5px dotted`,
                            cursor: "pointer",
                            mb: "3px",
                        }}
                        onClick={() => {
                            if (openAddOptionModal || showUpdateButton || showDeleteButton) return

                            if (onKeySelect) onKeySelect()
                            setSelectedKeys((prev) => {
                                if (prev.includes(o.key)) {
                                    return prev.filter((p) => p !== o.key)
                                }

                                return prev.concat(o.key)
                            })
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontFamily: fonts.sourceSansProSemibold,
                                ...typographyMaxLength,
                            }}
                        >
                            {o.value}
                        </Typography>

                        {!o.stay && canUpdateOption && showUpdateButton && (
                            <IconButton
                                disableRipple
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (setOpenUpdateOptionModal) setOpenUpdateOptionModal(true)
                                    setUpdatedOption(o)
                                }}
                            >
                                <EditIcon fontSize="large" sx={{ color: colors.red }} />
                            </IconButton>
                        )}

                        {!o.stay && canDeleteOption && showDeleteButton && (
                            <IconButton
                                disableRipple
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (setOpenDeleteOptionModal) setOpenDeleteOptionModal(true)
                                    setDeletedOption(o)
                                }}
                            >
                                <RemoveIcon fontSize="large" sx={{ color: colors.red }} />
                            </IconButton>
                        )}
                    </Stack>
                ))}
            </Stack>

            {openAddOptionModal && setOpenAddOptionModal && addOptionModalFunc && <>{addOptionModalFunc()}</>}

            {openUpdateOptionModal && updatedOption && updateOptionModalFunc && <>{updateOptionModalFunc(updatedOption)}</>}

            {openDeleteOptionModal && deletedOption && deleteOptionModalFunc && <>{deleteOptionModalFunc(deletedOption)}</>}
        </>
    )
}
