import { Stack, Typography } from "@mui/material"
import moment, { Moment } from "moment"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { SendFunc } from "../../../../../containers/ws"
import { combineDateAndTime } from "../../../../../helpers"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { UserShopWorkRecord } from "../../../../../types/Shop"
import { Permission } from "../../../../../types/permissions"
import { DateFormat } from "../../../../../types/types"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"
import { DateSelector } from "../../../../common/dateSelector"
import { TimeSelector } from "../../../../common/timeSelector"

interface WorkRecordAddModalProps {
    userID: string
    shopID?: string
    onClose: () => void
    workRecord?: UserShopWorkRecord
    send: SendFunc

    strictStartTime?: Moment
    strictEndTime?: Moment
}

export const WorkRecordRequestModal = ({ onClose, userID, workRecord, send, shopID, strictStartTime, strictEndTime }: WorkRecordAddModalProps) => {
    const { hasPerm } = usePermissions()
    const [date, setDate] = React.useState<Moment | null>(workRecord?.started_at ? moment(workRecord.started_at) : moment())
    const [startTime, setStartTime] = React.useState<Moment | null>(workRecord?.ended_at ? moment(workRecord.started_at) : moment())
    const [endTime, setEndTime] = React.useState<Moment | null>(workRecord?.ended_at ? moment(workRecord.ended_at) : moment())
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const addWorkRecord = React.useCallback(async () => {
        if (workRecord) return

        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have permission.")
            return
        }
        if (!startTime || !endTime || !date) {
            setError("Date field is missing.")
            return
        }
        if (strictStartTime && date.isBefore(strictStartTime)) {
            setError(
                `The date is out of range, should be between ${strictStartTime.format(DateFormat.DateOnly)} and ${strictEndTime?.format(DateFormat.DateOnly)}`,
            )
            return
        }
        if (strictEndTime && date.isAfter(strictEndTime)) {
            setError(
                `The date is out of range, should be between ${strictStartTime?.format(DateFormat.DateOnly)} and ${strictEndTime.format(DateFormat.DateOnly)}`,
            )
            return
        }
        if (!startTime.isBefore(endTime)) {
            setError("Start time must be earlier than the end time.")
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopWorkRecordAdd, {
                user_id: userID,
                shop_id: shopID,
                start_time: combineDateAndTime(date, startTime).toDate(),
                end_time: combineDateAndTime(date, endTime).toDate(),
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to clock out.")
        } finally {
            setIsLoading(false)
        }
    }, [date, endTime, hasPerm, onClose, send, startTime, userID, workRecord, shopID, strictStartTime, strictEndTime])

    const editWorkRecord = React.useCallback(async () => {
        if (!workRecord) return

        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have permission.")
            return
        }
        if (!startTime || !endTime || !date) {
            setError("Date field is missing.")
            return
        }

        const startAt = combineDateAndTime(date, startTime)
        const endAt = combineDateAndTime(date, endTime)

        if (!startAt.isBefore(endAt)) {
            setError("Start time must be earlier than the end time.")
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopWorkRecordUpdate, {
                work_record_id: workRecord.id,
                start_time: startAt.toDate(),
                end_time: endAt.toDate(),
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to clock out.")
        } finally {
            setIsLoading(false)
        }
    }, [date, endTime, hasPerm, onClose, send, startTime, workRecord])
    return (
        <ConfirmModal
            trans
            title={`${workRecord ? "Edit" : "Add"} Work Record`}
            onClose={onClose}
            onConfirm={workRecord ? editWorkRecord : addWorkRecord}
            confirmLabel={workRecord ? "EDIT" : "CREATE"}
            omitCancel
            width={"70rem"}
            isLoading={isLoading}
            error={error}
        >
            <Stack flex={1} spacing={"1rem"} sx={{ pb: "2rem" }}>
                <Stack spacing={".25rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Date
                    </TypographyTrans>
                    <DateSelector date={date} setDate={setDate} min={strictStartTime?.format("YYYY-MM-DD")} max={strictEndTime?.format("YYYY-MM-DD")} />
                </Stack>

                <Stack spacing={".25rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Time
                    </TypographyTrans>
                    <Stack direction={"row"} spacing={"1rem"} alignItems={"center"}>
                        <TimeSelector name="work-record-start-time-input" date={startTime} setDate={setStartTime} />
                        <Typography variant={"h4"} fontFamily={fonts.sourceSansProBlack}>
                            ~
                        </Typography>
                        <TimeSelector date={endTime} setDate={setEndTime} />
                    </Stack>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
