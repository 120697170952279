import { useMediaQuery } from "@mui/material"
import React from "react"
import { useDebounce } from "../hooks"

export interface Dimension {
    width: number
    height: number
}

export interface DimensionState {
    remToPxRatio: number
    windowDimensions: Dimension
    triggerReset: () => void
}

const initialState: DimensionState = {
    remToPxRatio: 10,
    windowDimensions: {
        width: 0,
        height: 0,
    },
    triggerReset: () => {
        return
    },
}

export const DimensionContext = React.createContext<DimensionState>(initialState)

export const DimensionProvider = ({ children }: { children: React.ReactNode }) => {
    const [remToPxRatio, setRemToPxRatio] = React.useState(initialState.remToPxRatio)
    const below600 = useMediaQuery("(max-width:600px)")
    const below900 = useMediaQuery("(max-width:900px)")
    const below1300 = useMediaQuery("(max-width:1300px)")
    const below1500 = useMediaQuery("(max-width:1500px)")
    const below1922 = useMediaQuery("(max-width:1922px)")

    const windowContainer = React.useRef<HTMLElement | null>(null)
    const resizeObserver = React.useRef<ResizeObserver>()

    const [windowDimensions, setWindowDimensions] = useDebounce<Dimension>(initialState.windowDimensions, 300)

    // Please refer to `src/theme/global.css`
    React.useEffect(() => {
        if (below600) return setRemToPxRatio(0.37 * 16)
        if (below900) return setRemToPxRatio(0.42 * 16)
        if (below1300) return setRemToPxRatio(0.44 * 16)
        if (below1500) return setRemToPxRatio(0.48 * 16)
        if (below1922) return setRemToPxRatio(0.5 * 16)
        setRemToPxRatio(0.57 * 16)
    }, [below1922, below1500, below900, below600, below1300])

    const setupResizeObserver = React.useCallback(() => {
        windowContainer.current = document.getElementById("game-ui-container")
        if (!windowContainer.current) return

        resizeObserver.current = new ResizeObserver((entries) => {
            const rect = entries[0].contentRect
            setWindowDimensions({
                width: rect.width,
                height: rect.height,
            })
        })
        resizeObserver.current.observe(windowContainer.current)
    }, [setWindowDimensions])

    const recalculateDimensions = React.useCallback(() => {
        windowContainer.current = document.getElementById("game-ui-container")
        if (!windowContainer.current) {
            setWindowDimensions({ width: 0, height: 0 })
            return
        }

        const containerWidth = windowContainer.current.offsetWidth
        const containerHeight = windowContainer.current.offsetHeight

        setWindowDimensions({ width: containerWidth, height: containerHeight })
    }, [setWindowDimensions])

    const triggerReset = React.useCallback(() => {
        recalculateDimensions()
        setupResizeObserver()
    }, [recalculateDimensions, setupResizeObserver])

    React.useEffect(() => {
        recalculateDimensions()
        setupResizeObserver()
    }, [remToPxRatio, recalculateDimensions, setupResizeObserver])

    React.useEffect(() => {
        return () => {
            resizeObserver.current && windowContainer.current && resizeObserver.current.unobserve(windowContainer.current)
        }
    }, [])

    return (
        <DimensionContext.Provider
            value={{
                remToPxRatio,
                windowDimensions,
                triggerReset,
            }}
        >
            {children}
        </DimensionContext.Provider>
    )
}

export const useDimension = () => {
    return React.useContext<DimensionState>(DimensionContext)
}
