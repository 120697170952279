import { Box, Stack } from "@mui/material"
import { DRAWER_MAX_WIDTH, DRAWER_MIN_WIDTH, DRAWER_TRANSITION_DURATION, TOP_BAR_HEIGHT } from "../../constants"
import { useAuth } from "../../containers/auth"
import { useUI } from "../../containers/ui"
import { colors, siteZIndex } from "../../theme/theme"
import { User } from "../../types/users"
import { LanguageSelector } from "./languageSelector"
import { Logo } from "./logo"
import { ProfileCard } from "./profileCard/profileCard"
import { TimeFormatSelector } from "./timeFormatSelector"

export const Bar = () => {
    const { userID, user } = useAuth()
    const { isNavLinksDrawerOpen } = useUI()

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                position: "relative",
                pl: ".8rem",
                pr: "1.6rem",
                ml: isNavLinksDrawerOpen ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH,
                flexShrink: 0,
                height: TOP_BAR_HEIGHT,
                width: `calc(100% - ${isNavLinksDrawerOpen ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH})`,
                color: "#FFFFFF",
                background: colors.primary,
                transition: `all ${DRAWER_TRANSITION_DURATION / 1000}s`,

                zIndex: siteZIndex.Bar,
            }}
        >
            <BarContent userID={userID} user={user} />
        </Stack>
    )
}

const BarContent = ({ userID, user }: { userID?: string; user: User }) => {
    return (
        <>
            <Logo />
            <Box sx={{ flexGrow: 1 }} />
            <TimeFormatSelector />
            <LanguageSelector />
            <ProfileCard />
        </>
    )
}
