import { Action } from "react-fetching-library"
import { SERVER_HOST } from "../constants"
import { User } from "../types/users"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CreateCheckoutSession = (formValues: any): Action<string> => {
    return {
        method: "POST",
        endpoint: `/package-checkout`,
        credentials: "include",
        responseType: "json",
        body: formValues,
    }
}

interface LoginPOSFormValues {
    username: string
    passcode: string
}

const LoginPOS = (formValues: LoginPOSFormValues): Action<User> => {
    return {
        method: "POST",
        endpoint: `${window.location.protocol}//${SERVER_HOST}/api/pos/login`,
        credentials: "include",
        responseType: "json",
        body: formValues,
    }
}

export { LoginPOS }
