import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { Stack } from "@mui/material"
import React from "react"
import { snakeToTitle } from "../../../helpers"
import { colors, fonts } from "../../../theme/theme"
import { TypographyTrans } from "../../common/TypographyTrans"
import { PageHeader } from "../../common/pageHeader"
import { ShopDetail } from "./shopDetail/shopDetail"
import { ShopJobTitleList } from "./shopSettingsJobTitle/shopJobTitleList"

enum SettingTab {
    ShopDetail = "SHOP_DETAIL",
    JobTitle = "JOB_TITLE",
}

export const ShopSettings = () => {
    const [selectShopSetting, setSelectShopSetting] = React.useState<SettingTab>()
    const settingPanel = React.useMemo(() => {
        switch (selectShopSetting) {
            case SettingTab.ShopDetail:
                return <ShopDetail />
            case SettingTab.JobTitle:
                return <ShopJobTitleList />
            default:
                return null
        }
    }, [selectShopSetting])

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "35rem",
                }}
            >
                <PageHeader
                    title={
                        <TypographyTrans variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bolder" }}>
                            SETTINGS
                        </TypographyTrans>
                    }
                />
                <Stack sx={{ px: ".25rem", pt: ".25rem" }}>
                    {Object.values(SettingTab).map((st) => (
                        <Stack
                            key={st}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{
                                borderBottom: `${colors.primary}50 4px solid`,
                                borderRadius: 0.8,
                                cursor: "pointer",
                                px: ".5rem",
                                py: ".75rem",
                                ":hover": {
                                    backgroundColor: `${colors.primary}10`,
                                },
                            }}
                            onClick={() => setSelectShopSetting(st)}
                        >
                            <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBlackIt} flex={1} sx={{ ml: "2rem", my: ".5rem" }}>
                                {snakeToTitle(st)}
                            </TypographyTrans>

                            <ArrowRightIcon sx={{ fontSize: "4em", color: `${colors.primary}CC` }} />
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            <Stack flex={1}>{settingPanel}</Stack>
        </>
    )
}
