import { Stack } from "@mui/material"
import { ItemLists } from "../../components/items/itemLists"
import { ItemProvider } from "../../containers/items"
import ItemUpdater from "../../updaters/itemUpdater"

export const ItemPage = () => {
    return (
        <ItemProvider>
            <ItemUpdater />
            <Stack
                direction="row"
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    height: "100%",
                }}
            >
                <ItemLists />
            </Stack>
        </ItemProvider>
    )
}
