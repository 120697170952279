export enum Permission {
    ItemUpdate = "ITEM_UPDATE",
    ItemView = "ITEM_VIEW",
    CategoryUpdate = "CATEGORY_UPDATE",
    ImportRecordUpdate = "IMPORT_RECORD_UPDATE",
    ImportRecordView = "IMPORT_RECORD_VIEW",
    ManufacturerUpdate = "MANUFACTURER_UPDATE",
    ManufacturerView = "MANUFACTURER_VIEW",
    ShopList = "SHOP_LIST",
    ShopCreate = "SHOP_CREATE",
    ShopRoleView = "SHOP_ROLE_VIEW",
    ShopRoleUpdate = "SHOP_ROLE_UPDATE",

    UserCreate = "USER_CREATE",
    USerRemove = "USER_REMOVE",
    UserList = "USER_LIST",

    OrgPermissionAccountantUpdate = "ACCOUNTANT_UPDATE",

    // shop permissions
    ShopAccountView = "SHOP_ACCOUNT_VIEW",
    ShopAccountUpdate = "SHOP_ACCOUNT_UPDATE",
    ShopMemberView = "SHOP_MEMBER_VIEW",
    ShopMemberUpdate = "SHOP_MEMBER_UPDATE",
    ShopInventoryView = "SHOP_INVENTORY_VIEW",
    ShopInventoryUpdate = "SHOP_INVENTORY_UPDATE",
    ShopJobTitleView = "SHOP_JOB_TITLE_VIEW",
    ShopJobTitleUpdate = "SHOP_JOB_TITLE_UPDATE",
    ShopItemView = "SHOP_ITEM_VIEW",
    ShopItemUpdate = "SHOP_ITEM_UPDATE",
    ShopProductView = "SHOP_PRODUCT_VIEW",
    ShopProductUpdate = "SHOP_PRODUCT_UPDATE",
    ShopDeviceTokenGenerate = "SHOP_DEVICE_TOKEN_GENERATE",
}
