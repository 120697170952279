import { Stack } from "@mui/material"
import { Moment } from "moment"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { SendFunc } from "../../../../../containers/ws"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { UserOrdinaryPyament } from "../../../../../types/Shop"
import { Permission } from "../../../../../types/permissions"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"
import { InputBox } from "../../../../common/InputBox"

interface Props {
    userID: string
    shopID?: string
    onClose: () => void
    ordinaryPayment?: UserOrdinaryPyament
    send: SendFunc

    strictStartTime: Moment
}

export const OrdinaryPaymentRequestModal = ({ onClose, userID, ordinaryPayment, send, shopID, strictStartTime }: Props) => {
    const { hasPerm } = usePermissions()
    const [totalHours, setTotalHours] = React.useState(ordinaryPayment?.total_hours || "38")
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const addOrdinaryPayment = React.useCallback(async () => {
        if (ordinaryPayment) return

        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have permission.")
            return
        }
        if (!totalHours) {
            setError("total hours is missing.")
            return
        }
        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopOrdinaryPaymentAdd, {
                user_id: userID,
                shop_id: shopID,
                start_time: strictStartTime.clone().add(3, "day").toDate(),
                total_hours: parseInt(totalHours),
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to add ordinary payment.")
        } finally {
            setIsLoading(false)
        }
    }, [totalHours, hasPerm, onClose, send, userID, ordinaryPayment, shopID, strictStartTime])

    const editWorkRecord = React.useCallback(async () => {
        if (!ordinaryPayment) return

        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have permission.")
            return
        }
        if (!totalHours) {
            setError("ordianry hours field is missing.")
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopOrdinaryPaymentUpdate, {
                ordinary_payment_id: ordinaryPayment.id,
                start_time: strictStartTime.clone().add(3, "day").toDate(),
                total_hours: totalHours,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to edit edit ordinary payment.")
        } finally {
            setIsLoading(false)
        }
    }, [totalHours, hasPerm, onClose, send, strictStartTime, ordinaryPayment])
    return (
        <ConfirmModal
            trans
            title={`${ordinaryPayment ? "Edit" : "Add"} Ordinary Payment`}
            onClose={onClose}
            onConfirm={ordinaryPayment ? editWorkRecord : addOrdinaryPayment}
            confirmLabel={ordinaryPayment ? "EDIT" : "CREATE"}
            omitCancel
            width={"70rem"}
            isLoading={isLoading}
            error={error}
        >
            <Stack flex={1} spacing={"1rem"} sx={{ pb: "2rem" }}>
                <Stack spacing={".25rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Total Hours
                    </TypographyTrans>
                    <InputBox
                        variant={"outlined"}
                        value={totalHours}
                        setValue={setTotalHours}
                        stackSx={{ flex: 1 }}
                        sx={{
                            border: `${colors.primary}99 2px solid`,
                            borderRadius: 0,
                        }}
                        placeholder={"Enter total hours"}
                    />
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
