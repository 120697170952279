import CategoryIcon from "@mui/icons-material/Category"
import { Stack, Typography } from "@mui/material"
import React from "react"
import { snakeToTitle } from "../../../../helpers"
import { colors, fonts } from "../../../../theme/theme"
import { ShopMember } from "../../../../types/Shop"
import { DotPointHeader } from "../../../common/dotPointHeader"
import { PageHeader } from "../../../common/pageHeader"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { ShopMemberArchiveModal } from "../shopMemberArchiveModal"
import { ShopMemberModal } from "../shopMemberModal"
import { ShopMemberDetailField } from "./shopMemberDetailField"

interface ShopMemberDetailProps {
    shopMember: ShopMember
}

export const ShopMemberDetail = ({ shopMember }: ShopMemberDetailProps) => {
    const {
        prefer_name,
        email,
        job_title,
        employment_status,
        visa_type_label,
        clock_in_pin,
        weekday_pay,
        saturday_pay,
        sunday_pay,
        holiday_pay,
        payslip_prefer_name,
        deleted_at,
    } = shopMember
    const [openShopMemberUpdateModal, setOpenShopMemberUpdateModal] = React.useState(false)
    const [openArchiveMemberModal, setOpenArchiveMemberModal] = React.useState(false)
    return (
        <>
            <Stack
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                }}
            >
                <PageHeader
                    title={
                        <Stack direction={"row"} spacing={".5rem"} alignItems={"center"}>
                            <Typography variant="h5" fontWeight="bolder" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                {`${payslip_prefer_name}'s`}
                            </Typography>
                            <TypographyTrans variant="h5" fontWeight="bolder" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                DETAIL
                            </TypographyTrans>
                        </Stack>
                    }
                    popoverFuncs={[
                        {
                            label: "Edit",
                            trans: true,
                            onClick: () => setOpenShopMemberUpdateModal(true),
                            icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                        {
                            label: deleted_at ? "Unarchive" : "Archive",
                            trans: true,
                            onClick: () => setOpenArchiveMemberModal(true),
                            icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                        },
                    ]}
                />

                <Stack direction="row" spacing="3rem" flex={1} sx={{ p: "1rem" }}>
                    <Stack direction="column" flex={1}>
                        <DotPointHeader trans title="GENERAL" />
                        <ShopMemberDetailField trans label={"Name"} value={prefer_name} />
                        <ShopMemberDetailField label={"Email"} value={email || "None"} />
                        <ShopMemberDetailField trans label={"Job Title"} value={job_title} />
                        <ShopMemberDetailField trans label={"Employment Status"} value={snakeToTitle(employment_status)} />
                        <ShopMemberDetailField trans label={"Visa Type"} value={visa_type_label} />
                        <ShopMemberDetailField label={"PIN"} value={clock_in_pin} />
                    </Stack>
                    <Stack direction="column" flex={1}>
                        <DotPointHeader trans title="WAGE" />
                        <ShopMemberDetailField trans label={"Weekday"} value={`$ ${weekday_pay}`} />
                        <ShopMemberDetailField trans label={"Saturday"} value={`$ ${saturday_pay}`} />
                        <ShopMemberDetailField trans label={"Sunday"} value={`$ ${sunday_pay}`} />
                        <ShopMemberDetailField trans label={"Holiday"} value={`$ ${holiday_pay}`} />
                    </Stack>
                    <Stack direction="column" flex={1}>
                        <DotPointHeader trans title="LEAVE" />
                    </Stack>
                </Stack>
            </Stack>

            {openShopMemberUpdateModal && <ShopMemberModal shopMember={shopMember} onClose={() => setOpenShopMemberUpdateModal(false)} />}
            {openArchiveMemberModal && <ShopMemberArchiveModal targetedMember={shopMember} onClose={() => setOpenArchiveMemberModal(false)} />}
        </>
    )
}
