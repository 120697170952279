import { Stack, Typography } from "@mui/material"
import React from "react"
import { useItem } from "../../containers/items"
import { usePermissions } from "../../containers/permissions"
import { useServerCommandsOrg } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors } from "../../theme/theme"
import { ItemSource } from "../../types/items"
import { Permission } from "../../types/permissions"
import { InputBox } from "../common/InputBox"
import { ConfirmModal } from "../common/confirmModal"
import { SelectBox } from "../common/selectBox"

interface ItemSourceModalProps {
    itemSource?: ItemSource
    onClose: () => void
    deleteMode?: boolean
}

export const ItemSourceModal = ({ itemSource, onClose, deleteMode }: ItemSourceModalProps) => {
    const { hasPerm } = usePermissions()
    const { itemSources } = useItem()
    const { send } = useServerCommandsOrg()
    const [itemSourceLabel, setItemSourceLabel] = React.useState(itemSource?.label || "")
    const [migrateToItemSourceID, setMigrateToItemSourceID] = React.useState("")
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const title = React.useMemo(() => {
        if (deleteMode) return "Delete Item Source"
        return `${itemSource ? "Edit" : "Create"} Item Source`
    }, [deleteMode, itemSource])

    const migrateItemSourceOptions = React.useMemo(() => itemSources.filter((is) => is.id !== itemSource?.id), [itemSource, itemSources])

    const content = React.useMemo(() => {
        if (deleteMode)
            return (
                <Stack direction={"column"} spacing={"1rem"}>
                    <Typography variant="h6">Are you sure, you want to delete this item source?</Typography>
                    <Typography variant="h6">And any non-allocated items will be fallback to</Typography>
                    <SelectBox
                        options={migrateItemSourceOptions.map((is) => ({ value: is.id, label: is.label }))}
                        value={migrateToItemSourceID}
                        placeholder="Select item source"
                        onChange={(e) => {
                            const v = e.target.value
                            console.log(v)
                            if (typeof v !== "string") return

                            setMigrateToItemSourceID(v)
                        }}
                    />
                </Stack>
            )

        return (
            <InputBox
                variant={"outlined"}
                value={itemSourceLabel}
                setValue={(v) => {
                    setItemSourceLabel(v)
                    setError("")
                }}
                stackSx={{ flex: 1 }}
                innerSx={{
                    height: "4.5rem",
                    fontSize: "1.82rem",
                    py: 0,
                    px: "1rem",
                }}
                sx={{
                    border: `${colors.primary}99 2px solid`,
                    borderRadius: 0.5,
                }}
                errMsg={error}
                placeholder={"Enter new item source"}
            />
        )
    }, [deleteMode, itemSourceLabel, error, setMigrateToItemSourceID, migrateItemSourceOptions, migrateToItemSourceID])

    const onCreateUpdateConfirm = React.useCallback(async () => {
        if (!itemSourceLabel) {
            setError("Please enter item source")
            return
        }

        if (itemSourceLabel === itemSource?.label) {
            onClose()
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(itemSource ? WSKeys.WSKeyItemSourceUpdate : WSKeys.WSKeyItemSourceCreate, {
                id: itemSource?.id,
                label: itemSourceLabel,
            })

            if (!resp) return
            onClose()
        } catch (e) {
            if (typeof e === "string") {
                setError(e)
            } else {
                setError(`Failed to ${itemSource ? "edit" : "create new"} item source`)
            }
        } finally {
            setIsLoading(false)
        }
    }, [onClose, itemSource, itemSourceLabel, send])

    const onDeleteConfirm = React.useCallback(async () => {
        if (!migrateToItemSourceID) {
            setError("Please select item source to migrate")
            return
        }

        try {
            setIsLoading(true)

            const resp = await send(WSKeys.WSKeyItemSourceDelete, {
                id: itemSource?.id,
                migrate_to_item_source_id: migrateToItemSourceID,
            })

            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to delete item source")
        } finally {
            setIsLoading(false)
        }
    }, [onClose, itemSource, migrateToItemSourceID, send])

    if (!hasPerm(Permission.ItemUpdate)) return null

    return (
        <ConfirmModal title={title} onClose={onClose} onConfirm={deleteMode ? onDeleteConfirm : onCreateUpdateConfirm} isLoading={isLoading} error={error}>
            {content}
        </ConfirmModal>
    )
}
