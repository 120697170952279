import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import { IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { roundedTo } from "../../../helpers"
import { colors } from "../../../theme/theme"
import { Item } from "../../../types/items"

interface ImportedItemSelectCardProps {
    item: Item
    onSelect: () => void
}

const propsAreEqual = (prevProps: ImportedItemSelectCardProps, nextProps: ImportedItemSelectCardProps) => {
    return prevProps.item.item_carton_variant?.id === nextProps.item.item_carton_variant?.id
}

export const ImportedItemSelectCard = React.memo(function ImportedItemSelectCard({ item, onSelect }: ImportedItemSelectCardProps) {
    const { item_carton_variant, import_label, label } = item

    if (!item_carton_variant) return null

    return (
        <Stack
            spacing={0.6}
            sx={{
                border: `${colors.primary}99 2px solid`,
                py: ".25rem",
                px: ".6rem",
            }}
        >
            <Stack direction="row" alignItems="start" justifyContent="space-between">
                <Stack direction="column">
                    <Typography variant="h5" fontWeight="bold">
                        {label}
                    </Typography>
                    <Typography variant="body2" color={`${colors.primary}CC`}>
                        {import_label}
                    </Typography>
                </Stack>

                <IconButton onClick={onSelect} size="small">
                    <ArrowCircleRightOutlinedIcon fontSize="large" sx={{ color: colors.primary, fontSize: "2.5rem" }} />
                </IconButton>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6">W: {item_carton_variant.carton_width_cm}</Typography>
                    <Typography variant="h6">D: {item_carton_variant.carton_depth_cm}</Typography>
                    <Typography variant="h6">H: {item_carton_variant.carton_height_cm}</Typography>
                </Stack>

                <Typography variant="body1">CBM: {roundedTo(item_carton_variant.cbm, 5)}</Typography>
            </Stack>
        </Stack>
    )
}, propsAreEqual)
