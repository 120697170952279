import AccountTreeIcon from "@mui/icons-material/AccountTree"
import FaceIcon from "@mui/icons-material/Face"
import HandymanIcon from "@mui/icons-material/Handyman"
import InventoryIcon from "@mui/icons-material/Inventory"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import PunchClockIcon from "@mui/icons-material/PunchClock"
import RedeemIcon from "@mui/icons-material/Redeem"
import SetMealIcon from "@mui/icons-material/SetMeal"
import StorefrontIcon from "@mui/icons-material/Storefront"
import WarehouseIcon from "@mui/icons-material/Warehouse"

export interface NavLink {
    icon: JSX.Element
    label: string
    path: string
    isBottom?: boolean
}

export const leftDrawerOrgNavLinks: NavLink[] = [
    {
        label: "Item",
        path: "/item",
        icon: <InventoryIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Import",
        path: "/import",
        icon: <LocalShippingIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Warehouses",
        path: "/warehouse",
        icon: <WarehouseIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Stores",
        path: "/store",
        icon: <StorefrontIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Accountant",
        path: "/accountant",
        icon: <MonetizationOnIcon sx={{ fontSize: "3rem" }} />,
    },
]

export const leftDrawerShopNavLinks = (id: string): NavLink[] => [
    {
        label: "Members",
        path: `/inner_shop/${id}/members`,
        icon: <FaceIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Items",
        path: `/inner_shop/${id}/items`,
        icon: <SetMealIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Mid-Products",
        path: `/inner_shop/${id}/intermediate_products`,
        icon: <AccountTreeIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Products",
        path: `/inner_shop/${id}/products`,
        icon: <RedeemIcon sx={{ fontSize: "3rem" }} />,
    },
    {
        label: "Settings",
        path: `/inner_shop/${id}/settings`,
        icon: <HandymanIcon sx={{ fontSize: "3rem" }} />,
        isBottom: true,
    },
]

export const leftDrawerPOSsystem: NavLink[] = [
    {
        label: "Clock In/Out",
        path: "/clock_in_out",
        icon: <PunchClockIcon sx={{ fontSize: "3rem" }} />,
    },
]
