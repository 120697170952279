import React from "react"
import { useServerSubscriptionOrg, useServerSubscriptionSecureUser } from "../hooks/useServer"
import { WSKeys } from "../key"
import { Organisation } from "../types/organisations"
import { Permission } from "../types/permissions"
import { usePermissions } from "./permissions"

export interface OrganisationState {
    organisation: Organisation | undefined
    setOrganisation: React.Dispatch<React.SetStateAction<Organisation | undefined>>
}

const initialState: OrganisationState = {
    organisation: undefined,
    setOrganisation: () => {
        return
    },
}

export const OrganisationContext = React.createContext<OrganisationState>(initialState)

export const OrganisationProvider = ({ children }: { children: React.ReactNode }) => {
    const [organisation, setOrganisation] = React.useState<Organisation>()

    return (
        <OrganisationContext.Provider
            value={{
                organisation,
                setOrganisation,
            }}
        >
            {children}
        </OrganisationContext.Provider>
    )
}

export const useOrganisation = () => {
    return React.useContext<OrganisationState>(OrganisationContext)
}

export const OrganisationUpdater = () => {
    const { organisation, setOrganisation } = useOrganisation()
    const { setOrgPermissions } = usePermissions()

    // subscribe on organisation permissions
    React.useEffect(() => {
        if (!organisation?.id) setOrgPermissions([])
    }, [organisation, setOrgPermissions])

    useServerSubscriptionSecureUser<Permission[]>(
        {
            URI: `/organisation/${organisation?.id}/permission_list`,
            key: WSKeys.WSKeyUserPermissionsSubscribe,
            ready: !!organisation?.id,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setOrgPermissions(payload)
            },
        },
    )

    // Subscribe on the organisation
    useServerSubscriptionOrg<Organisation>(
        {
            URI: "",
            key: WSKeys.WSKeyOrganisation,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setOrganisation(payload)
            },
        },
    )

    return null
}
