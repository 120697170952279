import { Stack, Typography } from "@mui/material"
import React from "react"
import { areEqual } from "../../../../helpers"
import { useDebounce } from "../../../../hooks"
import { useServerCommandsShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { Shop } from "../../../../types/Shop"
import { InputBox } from "../../../common/InputBox"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { AutoSavingIndicator } from "../../../common/autoSavingIndicator"
import { ShopPosPasscode } from "./shopPosPasscode"

interface ShopPosPanel {
    shop: Shop
}

export const ShopPosPanel = ({ shop }: ShopPosPanel) => {
    const { send } = useServerCommandsShop()
    const { pos_username } = shop
    const [delayValue, setValue, valueInstant] = useDebounce(pos_username, 1000)
    const [isChanging, setIsChanging] = React.useState(false)
    const [error, setError] = React.useState("")

    React.useEffect(() => {
        if (!isChanging || !delayValue) return
        // if nothing changed, return
        if (areEqual(delayValue, pos_username)) {
            setIsChanging(false)
            return
        }

        send(WSKeys.WSKeyShopUpdate, {
            username: delayValue,
        })
            .catch((e) => {
                setError(typeof e === "string" ? e : "Failed to save the field.")
                setValue(pos_username)
            })
            .finally(() => setIsChanging(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayValue, send])

    // reset loading icon
    React.useEffect(() => {
        if (areEqual(valueInstant, pos_username)) setIsChanging(false)
    }, [valueInstant, pos_username])

    return (
        <Stack flex={1} spacing={"1rem"} sx={{ pb: "2rem" }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    POS
                </TypographyTrans>
                {<AutoSavingIndicator isLoading={isChanging} />}
                {!isChanging && error && <Typography color={colors.lightRed}>{error}</Typography>}
            </Stack>
            <Stack flex={1} spacing={"1rem"} sx={{ px: "2rem" }}>
                <InputBox
                    trans
                    label={"Username"}
                    variant="outlined"
                    value={valueInstant}
                    setValue={(e) => setValue((prev) => e)}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setIsChanging(true)}
                />
                <TypographyTrans variant="h6" fontWeight="bold">
                    Passcode
                </TypographyTrans>

                <ShopPosPasscode shop={shop} />
            </Stack>
        </Stack>
    )
}
