import AddIcon from "@mui/icons-material/Add"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useOrganisation } from "../../containers/organisations"
import { useServerCommandsOrg } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors } from "../../theme/theme"
import { InputBox } from "../common/InputBox"
import { AccountantCCEmailCard } from "./accountantCCEmailCard"

export enum ActionMode {
    Add = "ADD",
    Edit = "EDIT",
    Delete = "DELETE",
}

export const AccountantCCEmailSetter = () => {
    const { send } = useServerCommandsOrg()
    const { organisation } = useOrganisation()
    const [actionMode, setActionMode] = React.useState<ActionMode>(ActionMode.Add)
    const [newCCEmail, setNewCCEmail] = React.useState("")
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const addCCEmail = React.useCallback(async () => {
        if (!newCCEmail) return
        setError("")
        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyOrganisationAccountantCCEmailAdd, {
                new_email: newCCEmail,
            })
            if (!resp) return
            setNewCCEmail("")
        } catch (e) {
            setError(typeof e === "string" ? e : "Something went wrong. Please try again later.")
        } finally {
            setIsLoading(false)
        }
    }, [newCCEmail, send])

    const editCCEmail = React.useCallback(
        async (email: string, new_email: string) => {
            setError("")
            try {
                setIsLoading(true)
                const resp = await send(WSKeys.WSKeyOrganisationAccountantCCEmailUpdate, {
                    email,
                    new_email,
                })
                if (!resp) return
                setNewCCEmail("")
            } catch (e) {
                setError(typeof e === "string" ? e : "Something went wrong. Please try again later.")
            } finally {
                setIsLoading(false)
            }
        },
        [send],
    )

    const deleteCCEmail = React.useCallback(
        async (email: string) => {
            setError("")
            try {
                setIsLoading(true)
                const resp = await send(WSKeys.WSKeyOrganisationAccountantCCEmailDelete, {
                    email,
                })
                if (!resp) return
                setNewCCEmail("")
            } catch (e) {
                setError(typeof e === "string" ? e : "Something went wrong. Please try again later.")
            } finally {
                setIsLoading(false)
            }
        },
        [send],
    )

    const content = React.useMemo(() => {
        if (!organisation) return null
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {organisation.payslip_cc_emails.map((ccEmail, index) => {
                        return (
                            <div key={`organisation-cc-email-${index}`} style={{ marginBottom: ".5rem" }}>
                                <AccountantCCEmailCard email={ccEmail} actionMode={actionMode} onEdit={editCCEmail} onDelete={deleteCCEmail} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [organisation, actionMode, editCCEmail, deleteCCEmail])

    return (
        <Stack sx={{ minHeight: "20rem" }}>
            <Typography variant={"h6"} color={colors.lightRed}>
                {error}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={".5rem"} sx={{ height: "4.3rem" }}>
                <Stack flex={1} direction={"row"} alignItems={"center"} sx={{ height: "100%", cursor: "pointer" }}>
                    <InputBox
                        variant={"outlined"}
                        value={newCCEmail}
                        setValue={setNewCCEmail}
                        stackSx={{ flex: 1 }}
                        sx={{
                            border: `${colors.primary}99 2px solid`,
                            borderRadius: 0,
                        }}
                        placeholder={"Enter new email"}
                    />
                    <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%", backgroundColor: `${colors.primary}99` }} onClick={addCCEmail}>
                        <AddIcon sx={{ color: colors.background, fontSize: "4rem" }} />
                    </Stack>
                </Stack>

                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => {
                        if (actionMode !== ActionMode.Edit) {
                            setActionMode(ActionMode.Edit)
                            return
                        }
                        setActionMode(ActionMode.Add)
                    }}
                    sx={{
                        cursor: "pointer",
                        height: "100%",
                        backgroundColor: colors.green,
                        opacity: actionMode === ActionMode.Edit ? 1 : 0.3,
                        px: ".5rem",
                        borderRadius: 0.5,
                    }}
                >
                    <EditIcon sx={{ fontSize: "3rem", color: colors.offWhite }} />
                </Stack>

                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => {
                        if (actionMode !== ActionMode.Delete) {
                            setActionMode(ActionMode.Delete)
                            return
                        }
                        setActionMode(ActionMode.Add)
                    }}
                    sx={{
                        cursor: "pointer",
                        height: "100%",
                        backgroundColor: colors.lightRed,
                        opacity: actionMode === ActionMode.Delete ? 1 : 0.3,
                        px: ".5rem",
                        borderRadius: 0.5,
                    }}
                >
                    <DeleteForeverIcon sx={{ fontSize: "3rem", color: colors.offWhite }} />
                </Stack>
            </Stack>
            <Box
                sx={{
                    my: "1rem",
                    flex: 1,
                    overflowY: "auto",
                    overflowX: "hidden",
                    direction: "ltr",
                    pb: "1rem",
                }}
            >
                {content}
            </Box>
        </Stack>
    )
}
