import { Stack } from "@mui/material"
import React from "react"
import { ShopIntermediateProducts } from "../../components/shop/shopItems/intermediateProducts"
import { ShopProducts } from "../../components/shop/shopItems/products"
import { ShopItems } from "../../components/shop/shopItems/shopItems"
import { ItemProvider } from "../../containers/items"
import { ItemType } from "../../types/items"
import ItemUpdater from "../../updaters/itemUpdater"

interface ShopItemPageProps {
    routeType: ItemType
}

export const ShopItemPage = ({ routeType }: ShopItemPageProps) => {
    const route = React.useMemo(() => {
        switch (routeType) {
            case ItemType.ITEM:
                return <ShopItems />
            case ItemType.INTERMEDIATE_PRODUCT:
                return <ShopIntermediateProducts />
            case ItemType.PRODUCT:
                return <ShopProducts />
        }
    }, [routeType])

    return (
        <ItemProvider>
            <ItemUpdater />
            <Stack
                direction="row"
                spacing={"1rem"}
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    height: "100%",
                }}
            >
                {route}
            </Stack>
        </ItemProvider>
    )
}
