import EditIcon from "@mui/icons-material/Edit"
import { Box, Stack } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../containers/permissions"
import { useDebounce } from "../../../../hooks"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { Permission } from "../../../../types/permissions"
import { ShopJobLevel, ShopJobTitle } from "../../../../types/Shop"
import { AutocompleteBox } from "../../../common/autocompleteBox"
import { AutoSavingIndicator } from "../../../common/autoSavingIndicator"
import { InputBox } from "../../../common/InputBox"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { JobLevelUpdateModal } from "./shopSettingsJobLevel/JobLevelUpdateModal"
import { ShopJobLevelDutyField } from "./shopSettingsJobLevel/shopJobLevelDutyField"
import { ShopJobWageEntitledMemberPanel } from "./shopSettingsJobLevel/shopSettingsShopJobWage/shopJobWageEntitledMemberPanel"
import { ShopJobWagePanel } from "./shopSettingsJobLevel/shopSettingsShopJobWage/shopJobWagePanel"

interface ShopJobTitlePanelProps {
    shopJobTitle: ShopJobTitle
}
const propsAreEqual = (prevProps: ShopJobTitlePanelProps, nextProps: ShopJobTitlePanelProps) => {
    return prevProps.shopJobTitle.id === nextProps.shopJobTitle.id
}

export const ShopJobTitlePanel = React.memo(function ShopJobTitlePanel({ shopJobTitle }: ShopJobTitlePanelProps) {
    const { id, title } = shopJobTitle
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()

    const [openJobLevelModal, setOpenJobLevelModal] = React.useState(false)

    const [jobTitleIsLoading, setJobTitleIsLoading] = React.useState(false)
    const [jobTitle, setJobTitle, jobTitleInstant] = useDebounce(title, 1000)
    React.useEffect(() => {
        if (!jobTitleIsLoading || !hasPerm(Permission.ShopJobTitleUpdate)) return
        send(WSKeys.WSKeyShopJobTitleUpdate, { id, title: jobTitle }).finally(() => setJobTitleIsLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPerm, id, jobTitle, send])

    const [shopJobLevels, setShopJobLevels] = React.useState<ShopJobLevel[]>([])
    const [selectedShopJobLevelID, setSelectedShopJobLevelID] = React.useState("")

    const selectedShopJobLevel = React.useMemo(() => shopJobLevels.find((sjl) => sjl.id === selectedShopJobLevelID), [selectedShopJobLevelID, shopJobLevels])

    useServerSubscriptionShop<ShopJobLevel[]>(
        {
            URI: `/job_title/${id}/job_levels`,
            key: WSKeys.WSKeyShopJobLevels,
            ready: hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobLevels((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                    prev = prev.map((jl) => payload.find((p) => p.id === jl.id) || jl)
                    payload.forEach((p) => (prev.some((jl) => jl.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jl) => !jl.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )

    return (
        <>
            <Stack flex={1}>
                <Stack sx={{ pb: "1.5rem" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                                Title
                            </TypographyTrans>
                            {<AutoSavingIndicator isLoading={jobTitleIsLoading} />}
                        </Stack>
                        <Box
                            sx={{
                                borderRadius: 0.8,
                                backgroundColor: `${colors.green}80`,
                                px: "2rem",
                                py: ".25rem",
                                mr: "1rem",
                            }}
                        >
                            <TypographyTrans variant={"h5"} color={`${colors.text}A0`}>
                                The Page Support Auto Saving
                            </TypographyTrans>
                        </Box>
                    </Stack>

                    <Stack
                        sx={{
                            pt: "1rem",
                            width: "40rem",
                            pl: "2rem",
                        }}
                    >
                        <InputBox
                            variant="outlined"
                            value={jobTitleInstant}
                            setValue={setJobTitle}
                            onChange={() => setJobTitleIsLoading(true)}
                            sx={{ fontFamily: fonts.sourceSansProSemibold }}
                        />
                    </Stack>
                </Stack>

                <Stack sx={{ pb: "1.5rem", width: "40rem" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} spacing={1}>
                        <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                            Level
                        </TypographyTrans>
                        <Box sx={{ cursor: "pointer" }} onClick={() => setOpenJobLevelModal(true)}>
                            <EditIcon fontSize={"large"} sx={{ color: `${colors.primary}DD` }} />
                        </Box>
                    </Stack>

                    <Stack
                        sx={{
                            pt: "1rem",
                            pl: "2rem",
                        }}
                    >
                        <AutocompleteBox options={shopJobLevels.map((sjl) => ({ id: sjl.id, label: sjl.label }))} onChange={setSelectedShopJobLevelID} />
                    </Stack>
                </Stack>

                {selectedShopJobLevel && (
                    <Stack spacing={"2rem"} key={selectedShopJobLevel.id} flex={1}>
                        <ShopJobLevelDutyField shopJobLevel={selectedShopJobLevel} />
                        <Stack direction={"row"} flex={1} sx={{ pr: "2rem", pb: "1rem" }}>
                            <ShopJobWagePanel shopJobLevelID={selectedShopJobLevel.id} />
                            <ShopJobWageEntitledMemberPanel shopJobLevelID={selectedShopJobLevel.id} />
                        </Stack>
                    </Stack>
                )}
            </Stack>

            {openJobLevelModal && <JobLevelUpdateModal jobTitleID={id} onClose={() => setOpenJobLevelModal(false)} />}
        </>
    )
}, propsAreEqual)
