let mockMode = false

export const MockMode = () => {
    mockMode = true
}

export const IsMockMode = () => mockMode

const subscriptionMock: { [key: string]: any } = {}

export const AddSubscriptionMock = (key: string, data: any) => (subscriptionMock[key] = data)

export const GetSubscriptionMock = (key: string) => subscriptionMock[key]

const commandMock: { [key: string]: any } = {}

export const AddCommandMock = (key: string, payload: any, data: any) => (commandMock[key + JSON.stringify(payload)] = data)

export const GetCommandMock = (key: string, payload: any) => commandMock[key + JSON.stringify(payload)]
