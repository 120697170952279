import { Stack } from "@mui/material"
import { ShopSettings } from "../../components/shop/shopSettings/shopSettings"

export const ShopSettingsPage = () => {
    return (
        <Stack
            direction="row"
            spacing={"1rem"}
            sx={{
                flex: 1,
                overflow: "hidden",
                height: "100%",
            }}
        >
            <ShopSettings />
        </Stack>
    )
}
