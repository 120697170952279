import { Stack } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { SendFunc } from "../../../../../containers/ws"
import { snakeToTitle } from "../../../../../helpers"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { LeaveStatus, LeaveType, UserShopLeaveRequest } from "../../../../../types/Shop"
import { InputBox } from "../../../../common/InputBox"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"
import { DateSelector } from "../../../../common/dateSelector"
import { SelectBox } from "../../../../common/selectBox"

interface UserShopLeaveRequestModalProps {
    leaveRequest?: UserShopLeaveRequest
    onClose: () => void
    selectedShopID: string
    selectedMemberID: string
    send: SendFunc
    strictStartDate?: moment.Moment
    strictEndDate?: moment.Moment
}

export const UserShopLeaveRequestModal = ({
    leaveRequest,
    onClose,
    selectedMemberID,
    selectedShopID,
    send,
    strictStartDate,
    strictEndDate,
}: UserShopLeaveRequestModalProps) => {
    const [date, setDate] = React.useState<moment.Moment | null>(leaveRequest?.date ? moment(leaveRequest.date) : strictStartDate || null)
    const [leaveType, setLeaveType] = useState(leaveRequest?.leave_type || "")
    const [leaveStatus, setLeaveStatus] = useState(leaveRequest?.status || "")
    const [hours, setHours] = useState(leaveRequest?.hours || "0")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const addUserShopLeaveRequest = React.useCallback(async () => {
        if (!date) {
            setError("Date field is missing.")
            return
        }

        if (!leaveType) {
            setError("Leave type field is missing.")
            return
        }

        if (!leaveStatus) {
            setError("Leave status field is missing.")
            return
        }

        if (!hours) {
            setError("Hours field is missing.")
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(leaveRequest ? WSKeys.WSKeyUserShopLeaveRequestUpdate : WSKeys.WSKeyUserShopLeaveRequestCreate, {
                id: leaveRequest?.id,
                shop_id: selectedShopID,
                user_id: selectedMemberID,
                leave_type: leaveType,
                status: leaveStatus,
                hours: hours ? parseFloat(hours) : 0,
                date: date,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to add user leave request.")
        } finally {
            setIsLoading(false)
        }
    }, [leaveRequest, date, onClose, selectedMemberID, selectedShopID, send, leaveType, leaveStatus, hours])

    return (
        <ConfirmModal
            title={`${leaveRequest ? "Edit" : "Add"} User Leave`}
            onClose={onClose}
            onConfirm={addUserShopLeaveRequest}
            confirmLabel="CREATE"
            isLoading={isLoading}
            error={error}
            omitCancel
        >
            <Stack flex={1} spacing={"1rem"} sx={{ pb: "2rem" }}>
                <Stack spacing={".25rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Date
                    </TypographyTrans>
                    <DateSelector date={date} setDate={setDate} min={strictStartDate?.format("YYYY-MM-DD")} max={strictEndDate?.format("YYYY-MM-DD")} />
                </Stack>
                <Stack spacing={".5rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Leave Type
                    </TypographyTrans>
                    <SelectBox
                        options={Object.values(LeaveType).map((s) => ({ value: s, label: snakeToTitle(s) }))}
                        value={leaveType}
                        sx={{ border: `${colors.primary} 2px solid` }}
                        onChange={(e) => {
                            const v = e.target.value
                            if (typeof v !== "string") return
                            setLeaveType(v)
                        }}
                    />
                </Stack>
                <Stack spacing={".5rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Leave Status
                    </TypographyTrans>
                    <SelectBox
                        options={Object.values(LeaveStatus)
                            .filter((ls) => ls != LeaveStatus.Cancelled)
                            .map((s) => ({ value: s, label: snakeToTitle(s) }))}
                        value={leaveStatus}
                        sx={{ border: `${colors.primary} 2px solid` }}
                        onChange={(e) => {
                            const v = e.target.value
                            if (typeof v !== "string") return
                            setLeaveStatus(v)
                        }}
                    />
                </Stack>
                <Stack spacing={".5rem"}>
                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold} sx={{ color: colors.primary }}>
                        Hours
                    </TypographyTrans>
                    <InputBox
                        variant={"outlined"}
                        value={hours}
                        setValue={setHours}
                        type="number"
                        sx={{
                            border: `${colors.primary} 2px solid`,
                        }}
                        InputProps={{
                            endAdornment: (
                                <Stack direction="row" spacing="1rem" alignItems="center">
                                    <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                                        hrs
                                    </TypographyTrans>
                                </Stack>
                            ),
                        }}
                    />
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
