import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material"
import React from "react"
import { colors, fonts, siteZIndex } from "../../theme/theme"
import { TypographyTrans } from "./TypographyTrans"
import { ShopButton } from "./shopButton"

interface ConfirmModalProps {
    title: React.ReactNode
    children?: React.ReactNode
    onConfirm?: () => void
    onClose: () => void
    isLoading?: boolean
    width?: string
    error?: string
    confirmPrefix?: React.ReactNode
    confirmSuffix?: React.ReactNode
    disableConfirm?: boolean
    confirmLabel?: string
    cancelLabel?: string
    confirmColor?: string
    cancelColor?: string
    omitCancel?: boolean
    omitButton?: boolean
    trans?: boolean

    rowReverse?: boolean
}

export const ConfirmModal = ({
    title,
    children,
    onConfirm,
    onClose,
    width,
    isLoading,
    error,
    confirmPrefix,
    confirmSuffix,
    disableConfirm,
    confirmLabel,
    cancelLabel,
    confirmColor,
    cancelColor,
    omitCancel,
    omitButton,
    trans,
    rowReverse,
}: ConfirmModalProps) => {
    const ShowTitle = React.useMemo(() => {
        if (typeof title === "string") {
            if (trans)
                return (
                    <TypographyTrans variant="h4" sx={{ lineHeight: 1, fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                        {title}
                    </TypographyTrans>
                )
            return (
                <Typography variant="h4" sx={{ lineHeight: 1, fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                    {title}
                </Typography>
            )
        }
        return title
    }, [title, trans])
    return (
        <Modal open onClose={onClose} sx={{ zIndex: siteZIndex.Modal }}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: width || "50rem",
                    boxShadow: 6,
                    outline: "none",
                    backgroundColor: colors.background,
                    opacity: isLoading ? 0.6 : 1,
                    borderRadius: ".5rem",
                }}
            >
                <Stack
                    spacing="1.2rem"
                    sx={{
                        position: "relative",
                        px: "2.5rem",
                        py: "2.4rem",
                        ".MuiTypography-root span": {
                            color: colors.neonBlue,
                            fontWeight: "fontWeightBold",
                        },
                    }}
                >
                    {ShowTitle}

                    {children && children}

                    {error && (
                        <Typography
                            sx={{
                                mt: ".3rem",
                                color: colors.red,
                            }}
                        >
                            {error}
                        </Typography>
                    )}

                    {!omitButton && (
                        <Stack
                            direction="row"
                            spacing="1rem"
                            justifyContent="space-between"
                            sx={{ pt: ".4rem", flexDirection: rowReverse ? "row-reverse" : undefined }}
                        >
                            <ShopButton
                                sx={{ pt: 0, pb: 0, minWidth: omitCancel ? "1px" : "5rem", opacity: omitCancel ? 0 : 1 }}
                                buttonColor={cancelColor || colors.red}
                                onClick={omitCancel ? undefined : onClose}
                                disabled={isLoading}
                            >
                                {!omitCancel && (
                                    <TypographyTrans variant="h5" fontWeight={"bold"} color={colors.offWhite} fontFamily={fonts.sourceSansProSemibold}>
                                        {cancelLabel || "CANCEL"}
                                    </TypographyTrans>
                                )}
                            </ShopButton>

                            <ShopButton
                                disabled={disableConfirm}
                                buttonColor={confirmColor || colors.primary}
                                loading={isLoading}
                                sx={{ pt: 0, pb: 0, minWidth: "5rem" }}
                                onClick={onConfirm}
                            >
                                <Stack direction="row" justifyContent="center">
                                    {confirmPrefix}
                                    <TypographyTrans variant="h5" fontWeight={"bold"} color={colors.offWhite} fontFamily={fonts.sourceSansProSemibold}>
                                        {confirmLabel || "CONFIRM"}
                                    </TypographyTrans>
                                    {confirmSuffix}
                                </Stack>
                            </ShopButton>
                        </Stack>
                    )}

                    {isLoading && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Stack>
            </Box>
        </Modal>
    )
}
