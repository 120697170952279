import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CategoryIcon from "@mui/icons-material/Category"
import { Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { CLIENT_HOST } from "../../constants"
import { usePermissions } from "../../containers/permissions"
import { useServerSubscriptionOrg } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts } from "../../theme/theme"
import { Shop, ShopType } from "../../types/Shop"
import { Permission } from "../../types/permissions"
import { PageHeader } from "../common/pageHeader"
import { ShopButton } from "../common/shopButton"
import { WarehouseOverview } from "./warehouseOverview/WarehouseOverview"
import { WarehouseInventoryOverview } from "./warehouseOverview/warehouseInventoryOverview"

export const Warehouses = () => {
    const { hasPerm } = usePermissions()

    const [warehouses, setWarehouses] = React.useState<Shop[]>([])
    useServerSubscriptionOrg<Shop[]>(
        {
            URI: "/shops",
            key: WSKeys.WSKeyShops,
            ready: hasPerm(Permission.ShopList),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setWarehouses((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at && p.type === ShopType.Warehouse)
                    prev = prev.map((w) => payload.find((p) => p.id === w.id) || w)
                    payload.forEach((p) => (prev.some((w) => w.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((w) => !w.deleted_at && w.type === ShopType.Warehouse)
                })
            },
        },
    )

    const [selectedWarehouse, setSelectedWarehouse] = React.useState<Shop>()
    React.useEffect(() => {
        if (warehouses.length === 0) {
            setSelectedWarehouse(undefined)
            return
        }

        if (selectedWarehouse) return

        setSelectedWarehouse(warehouses[0])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses])

    if (warehouses.length === 0 || !selectedWarehouse) return null

    return (
        <Stack
            direction="column"
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
                width: "100%",
            }}
        >
            <PageHeader
                title={
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: warehouses.length > 1 ? "pointer" : undefined }}>
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>{selectedWarehouse.label}</strong>
                        </Typography>
                        {warehouses.length > 1 && <ArrowDropDownIcon fontSize="large" sx={{ color: colors.primary }} />}
                    </Stack>
                }
                popoverFuncs={[
                    {
                        label: "Add Warehouse",
                        onClick: () => console.log("test click"),
                        icon: <CategoryIcon fontSize="large" sx={{ color: colors.primary }} />,
                    },
                ]}
            />
            <Stack
                direction="row"
                sx={{
                    p: ".5rem",
                    height: "100%",
                }}
            >
                <WarehouseOverview />
                <Divider sx={{ my: ".35rem !important", border: `${colors.primary}30 1px solid` }} />

                <Stack direction="column" flex={1} sx={{ px: ".5rem" }}>
                    <WarehouseInventoryOverview />
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <ShopButton
                            buttonColor={colors.primary}
                            onClick={() => window.open(`http://${CLIENT_HOST}/inner_warehouse/${selectedWarehouse.id}`, "_blank")}
                        >
                            GO TO SHOP
                        </ShopButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
