import { Stack, Typography } from "@mui/material"
import React from "react"
import { areEqual, snakeToTitle } from "../../../../helpers"
import { useDebounce } from "../../../../hooks"
import { useServerCommandsShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { Shop, ShopType } from "../../../../types/Shop"
import { InputBox } from "../../../common/InputBox"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { AutoSavingIndicator } from "../../../common/autoSavingIndicator"
import { SelectBox } from "../../../common/selectBox"

interface ShopDetailPanel {
    shop: Shop
}

export const ShopDetailPanel = ({ shop }: ShopDetailPanel) => {
    const { send } = useServerCommandsShop()
    const [error, setError] = React.useState("")

    const [delayValue, setValue, valueInstant] = useDebounce(shop, 1000)
    const [isChanging, setIsChanging] = React.useState(false)

    React.useEffect(() => {
        if (!isChanging || !delayValue) return

        // if nothing changed, return
        if (
            areEqual(delayValue.label, shop.label) &&
            areEqual(delayValue.type, shop.type) &&
            areEqual(delayValue.business_name, shop.business_name) &&
            areEqual(delayValue.abn, shop.abn) &&
            areEqual(delayValue.address, shop.address)
        ) {
            setIsChanging(false)
            return
        }

        send(WSKeys.WSKeyShopUpdate, {
            label: delayValue.label,
            type: delayValue.type,
            business_name: delayValue.business_name,
            abn: delayValue.abn,
            address: delayValue.address,
        })
            .catch((e) => setError(typeof e === "string" ? e : "Failed to save the field."))
            .finally(() => setIsChanging(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayValue, send])

    // reset loading icon
    React.useEffect(() => {
        if (
            areEqual(valueInstant.label, shop.label) &&
            areEqual(valueInstant.type, shop.type) &&
            areEqual(valueInstant.business_name, shop.business_name) &&
            areEqual(valueInstant.abn, shop.abn) &&
            areEqual(valueInstant.address, shop.address)
        ) {
            setIsChanging(false)
        }
    }, [valueInstant, shop])

    return (
        <Stack spacing={"1rem"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    DETAIL
                </TypographyTrans>
                {<AutoSavingIndicator isLoading={isChanging} />}
                {!isChanging && error && <Typography color={colors.lightRed}>{error}</Typography>}
            </Stack>
            <Stack spacing={"1rem"} sx={{ px: "2rem" }}>
                <InputBox
                    trans
                    label={"Shop Name"}
                    variant="outlined"
                    value={valueInstant.label}
                    setValue={(e) => setValue((prev) => ({ ...prev, label: e }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setIsChanging(true)}
                />
                <Stack>
                    <TypographyTrans variant="h6" fontWeight="bold">
                        Shop Type
                    </TypographyTrans>
                    <SelectBox
                        options={Object.values(ShopType).map((type) => ({ value: type, label: snakeToTitle(type) }))}
                        value={valueInstant.type}
                        sx={{ border: `${colors.primary}50 2px solid`, height: "5rem" }}
                        fontSize="2.3rem"
                        onChange={(e) => {
                            const v = e.target.value
                            if (typeof v !== "string") return
                            setValue((prev) => ({ ...prev, type: v as ShopType }))
                            setIsChanging(true)
                        }}
                    />
                </Stack>
                <InputBox
                    trans
                    label={"Business Name"}
                    variant="outlined"
                    value={valueInstant.business_name || ""}
                    setValue={(e) => setValue((prev) => ({ ...prev, business_name: e || undefined }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setIsChanging(true)}
                />
                <InputBox
                    trans
                    label={"ABN"}
                    variant="outlined"
                    value={valueInstant.abn || ""}
                    setValue={(e) => setValue((prev) => ({ ...prev, abn: e || undefined }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setIsChanging(true)}
                />
                <InputBox
                    trans
                    label={"Address"}
                    variant="outlined"
                    value={valueInstant.address || ""}
                    setValue={(e) => setValue((prev) => ({ ...prev, address: e || undefined }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setIsChanging(true)}
                />
            </Stack>
        </Stack>
    )
}
