import { Theme, ThemeProvider as MuiThemeProvider } from "@mui/material"
import React from "react"
import { mergeDeep } from "../helpers"
import { colors, theme } from "../theme/theme"

interface FactionThemeColors {
    primary: string
    secondary: string
    background: string
}

export interface ThemeState extends Theme {
    setFactionColors: React.Dispatch<React.SetStateAction<FactionThemeColors>>
}

const initialState: ThemeState = {
    ...theme,
    setFactionColors: () => {
        return
    },
}

export const ThemeContext = React.createContext<ThemeState>(initialState)

// @ts-ignore
export const ThemeProvider = ({ children }) => {
    const [currentTheme, setCurrentTheme] = React.useState<Theme>(theme)
    const [factionColors, setFactionColors] = React.useState<FactionThemeColors>({
        primary: colors.primary,
        secondary: colors.secondary,
        background: colors.background,
    })

    React.useEffect(() => {
        setCurrentTheme((curTheme: Theme) => mergeDeep(curTheme, { storemateTheme: factionColors }))
    }, [factionColors])

    return (
        <ThemeContext.Provider value={{ ...currentTheme, setFactionColors }}>
            <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    return React.useContext(ThemeContext)
}
