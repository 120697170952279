import SyncProblemIcon from "@mui/icons-material/SyncProblem"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../../../containers/permissions"
import { snakeToTitle } from "../../../../../../helpers"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../../../hooks/useServer"
import { WSKeys } from "../../../../../../key"
import { colors, fonts } from "../../../../../../theme/theme"
import { Permission } from "../../../../../../types/permissions"
import { ShopJobWage, ShopMember } from "../../../../../../types/Shop"
import { ShopButton } from "../../../../../common/shopButton"
import { TooltipHelper } from "../../../../../common/tooltipHelper"
import { TypographyTrans } from "../../../../../common/TypographyTrans"

interface ShopJobWageEntitledMemberCardProps {
    shopMember: ShopMember
}

export const ShopJobWageEntitledMemberCard = ({ shopMember }: ShopJobWageEntitledMemberCardProps) => {
    const { id, prefer_name, job_wage_id, weekday_pay, saturday_pay, sunday_pay, holiday_pay, employment_status } = shopMember
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [error, setError] = React.useState("")

    const [shopJobWage, setShopJobWage] = React.useState<ShopJobWage>()
    useServerSubscriptionShop<ShopJobWage>(
        {
            URI: `/job_wage/${job_wage_id}`,
            key: WSKeys.WSKeyShopJobWage,
            ready: hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobWage(payload)
            },
        },
    )

    const syncMemberWage = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopMemberUpdate)) {
            setError("You do not have the permission.")
            return
        }
        try {
            await send(WSKeys.WSKeyShopMembersWageSync, { user_ids: [id] })
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to sync member's wage")
        }
    }, [hasPerm, id, send])

    const wageMatched = React.useMemo(
        () =>
            shopJobWage &&
            shopJobWage.weekday_pay === weekday_pay &&
            shopJobWage.saturday_pay === saturday_pay &&
            shopJobWage.sunday_pay === sunday_pay &&
            shopJobWage.holiday_pay === holiday_pay,

        [holiday_pay, saturday_pay, shopJobWage, sunday_pay, weekday_pay],
    )

    const displayIcon = React.useMemo(() => {
        if (!shopJobWage) return <CircularProgress />
        if (wageMatched) return <TaskAltIcon sx={{ fontSize: "2.5rem", color: colors.green }} />
        return <SyncProblemIcon sx={{ fontSize: "2.5rem", color: colors.orange }} />
    }, [wageMatched, shopJobWage])

    const renderCompare = React.useCallback(
        (currentWage: string, memberWage: string) => (
            <Stack direction={"row"} alignItems={"center"}>
                <Stack flex={1} alignItems={"flex-start"} justifyContent={"center"}>
                    <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        $ {currentWage}
                    </Typography>
                </Stack>

                <Stack flex={1} alignItems={"center"} justifyContent={"center"}>
                    {currentWage === memberWage ? (
                        <TaskAltIcon sx={{ fontSize: "2rem", color: colors.green }} />
                    ) : (
                        <SyncProblemIcon sx={{ fontSize: "2.2rem", color: colors.orange }} />
                    )}
                </Stack>

                <Stack flex={1} alignItems={"flex-end"} justifyContent={"center"}>
                    <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        $ {memberWage}
                    </Typography>
                </Stack>
            </Stack>
        ),
        [],
    )

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
                px: "2rem",
                py: ".5rem",
                border: `${colors.primary}80 3px solid`,
                borderRadius: 0.8,
            }}
        >
            <TooltipHelper
                placement={"left-end"}
                renderNode={
                    <Stack spacing={"1rem"} sx={{ width: "22.5rem", p: "1rem", backgroundColor: colors.background, border: `${colors.primary}99 1px solid` }}>
                        <Stack direction={"row"} spacing={".25rem"}>
                            <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProBoldIt}>
                                Wage
                            </TypographyTrans>
                            <Typography variant={"h6"} fontFamily={fonts.sourceSansProBoldIt}>
                                (
                            </Typography>
                            <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProBoldIt}>
                                {snakeToTitle(employment_status)}
                            </TypographyTrans>
                            <Typography variant={"h6"} fontFamily={fonts.sourceSansProBoldIt}>
                                )
                            </Typography>
                        </Stack>

                        <Stack spacing={".5rem"}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                                    Current
                                </TypographyTrans>
                                <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                                    {prefer_name}
                                </Typography>
                            </Stack>
                            {renderCompare(shopJobWage?.weekday_pay || "", weekday_pay)}
                            {renderCompare(shopJobWage?.saturday_pay || "", saturday_pay)}
                            {renderCompare(shopJobWage?.sunday_pay || "", sunday_pay)}
                            {renderCompare(shopJobWage?.holiday_pay || "", holiday_pay)}
                        </Stack>
                        <ShopButton trans disabled={wageMatched} buttonColor={colors.green} onClick={syncMemberWage}>
                            Sync
                        </ShopButton>
                        {error && (
                            <Typography variant={"h6"} color={colors.lightRed}>
                                {error}
                            </Typography>
                        )}
                    </Stack>
                }
            >
                <Box>{displayIcon}</Box>
            </TooltipHelper>

            <Typography variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                {prefer_name}
            </Typography>
        </Stack>
    )
}
