import { Stack, Typography } from "@mui/material"
import * as React from "react"
import { dateFormatter, DateFormatType } from "../../../helpers"
import { colors, fonts } from "../../../theme/theme"
import { ImportRecord } from "../../../types/importRecords"
import { PageHeader } from "../../common/pageHeader"
import { ShopButton } from "../../common/shopButton"
import { ImportRecordDetailInner } from "./importRecordDetailInner"
import { ImportRecordDetailItems } from "./importRecordDetailItems"

interface ImportRecordDetailProps {
    importRecord: ImportRecord
}

interface GroupButton {
    id: string
    label: string
}

export const ImportRecordDetail = ({ importRecord }: ImportRecordDetailProps) => {
    const { order_placed_at } = importRecord

    const groupButtons: GroupButton[] = [
        {
            id: "detail",
            label: "DETAIL",
        },
        {
            id: "items",
            label: "ITEMS",
        },
    ]

    const [selectedButton, setSelectedButton] = React.useState<GroupButton>(groupButtons[0])

    const content = React.useMemo(() => {
        switch (selectedButton.id) {
            case "detail":
                return <ImportRecordDetailInner importRecord={importRecord} />
            case "items":
                return <ImportRecordDetailItems importRecord={importRecord} />
        }
    }, [importRecord, selectedButton.id])

    return (
        <Stack
            direction="column"
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <PageHeader
                title={
                    <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                        <strong>{`RECORD - ${order_placed_at ? dateFormatter(order_placed_at, DateFormatType.DateMonthYear) : "No Order Date"}`}</strong>
                    </Typography>
                }
            />

            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                    py: ".3rem",
                    px: "1rem",
                }}
            >
                {groupButtons.map((gb) => (
                    <ShopButton
                        key={gb.id}
                        size="small"
                        sx={{
                            border: `${colors.primary} 1px solid`,
                            backgroundColor: selectedButton.id === gb.id ? colors.primary : colors.background,
                            ":hover": {
                                backgroundColor: selectedButton.id === gb.id ? colors.primary : colors.background,
                            },
                        }}
                        onClick={() => setSelectedButton((prev) => (prev.id === gb.id ? prev : gb))}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: selectedButton.id === gb.id ? colors.secondary : colors.primary,
                            }}
                        >
                            <strong>{gb.label}</strong>
                        </Typography>
                    </ShopButton>
                ))}
            </Stack>

            <Stack
                direction="column"
                flex={1}
                sx={{
                    pt: "2rem",
                    pb: "1rem",
                    px: "1.8rem",
                }}
            >
                {content}
            </Stack>
        </Stack>
    )
}
