import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../containers/permissions"
import { useDebounce } from "../../hooks"
import { colors, fonts } from "../../theme/theme"
import { Permission } from "../../types/permissions"
import { InputBox } from "../common/InputBox"
import { AutoSavingIndicator } from "../common/autoSavingIndicator"
import { ActionMode } from "./accountantCCEmailSetter"

interface AccountantCCEmailCardProps {
    email: string
    actionMode: ActionMode
    onEdit: (oldEmail: string, newEmail: string) => Promise<void>
    onDelete: (email: string) => void
}

export const AccountantCCEmailCard = ({ email, actionMode, onEdit, onDelete }: AccountantCCEmailCardProps) => {
    const { hasPerm } = usePermissions()
    const [error, setError] = React.useState("")
    const [changingEmail, setChangingEmail] = React.useState(false)

    const [newEmail, setNewEmail, newEmailInstant] = useDebounce(email, 1000)

    React.useEffect(() => {
        if (!changingEmail || newEmail === "" || newEmail === email) return
        onEdit(email, newEmail)
            .catch((e) => setError(typeof e === "string" ? e : "Failed to process."))
            .finally(() => setChangingEmail(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, onEdit, newEmail])

    if (actionMode === ActionMode.Edit) {
        return (
            <InputBox
                disabled={!hasPerm(Permission.OrgPermissionAccountantUpdate)}
                variant={"outlined"}
                value={newEmailInstant}
                setValue={setNewEmail}
                stackSx={{ flex: 1 }}
                innerSx={{
                    height: "4.5rem",
                    fontSize: "1.82rem",
                    py: 0,
                    px: "1rem",
                }}
                sx={{
                    border: `${colors.primary}99 2px solid`,
                    borderRadius: 0.5,
                }}
                onChange={() => setChangingEmail(true)}
                errMsg={error}
                placeholder={"Enter new email"}
                InputProps={{
                    endAdornment: (
                        <Stack direction="row" spacing="1rem" alignItems="center">
                            <AutoSavingIndicator isLoading={changingEmail} />
                        </Stack>
                    ),
                }}
            />
        )
    }

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
                px: "1rem",
                py: ".5rem",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
                height: "5rem",
            }}
        >
            <Typography variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                {email}
            </Typography>

            {actionMode === ActionMode.Delete && (
                <Box sx={{ cursor: "pointer" }} onClick={() => onDelete(email)}>
                    <DeleteForeverIcon sx={{ color: colors.lightRed, fontSize: "2.4rem" }} />
                </Box>
            )}

            {error && (
                <Typography variant={"h6"} color={colors.lightRed}>
                    {error}
                </Typography>
            )}
        </Stack>
    )
}
