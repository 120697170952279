import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { colors, fonts } from "../../../../theme/theme"
import { ShopJobTitle } from "../../../../types/Shop"
import { ShopJobTitleDeleteModal } from "./shopJobTitleDeleteModal"

interface ShopJobTitleCardProps {
    shopJobTitle: ShopJobTitle
    onSelect: () => void
    selected?: boolean
    deleteMode?: boolean
}

export const ShopJobTitleCard = ({ shopJobTitle, onSelect, selected, deleteMode }: ShopJobTitleCardProps) => {
    const { title } = shopJobTitle
    const [openDeleteModel, setOpenDeleteMode] = React.useState(false)
    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    px: "2.2rem",
                    py: "1rem",
                    border: `${colors.primary}${selected ? "" : "50"} 4px solid`,
                    borderRadius: 0.8,
                    ":hover": {
                        border: `${colors.primary}${deleteMode ? "50" : ""} 4px solid`,
                    },
                }}
                onClick={() => {
                    if (deleteMode) return
                    onSelect()
                }}
            >
                <Typography variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                    {title}
                </Typography>

                {deleteMode && (
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setOpenDeleteMode(true)
                        }}
                    >
                        <DeleteForeverIcon sx={{ color: colors.lightRed, fontSize: "2.5rem" }} />
                    </Box>
                )}
            </Stack>
            {openDeleteModel && <ShopJobTitleDeleteModal shopJobTitle={shopJobTitle} onClose={() => setOpenDeleteMode(false)} />}
        </>
    )
}
