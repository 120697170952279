import AddIcon from "@mui/icons-material/Add"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { usePermissions } from "../../../../../containers/permissions"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../../hooks/useServer"
import { WSKeys } from "../../../../../key"
import { colors } from "../../../../../theme/theme"
import { Permission } from "../../../../../types/permissions"
import { ShopJobLevel } from "../../../../../types/Shop"
import { ConfirmModal } from "../../../../common/confirmModal"
import { InputBox } from "../../../../common/InputBox"
import { ShopJobLevelCard } from "./shopJobLevelCard"

interface JobLevelUpdateModalProps {
    jobTitleID: string
    onClose: () => void
}

export const JobLevelUpdateModal = ({ jobTitleID, onClose }: JobLevelUpdateModalProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()
    const [newJobLevel, setNewJobLevel] = React.useState("")
    const [deletedMode, setDeleteMode] = React.useState(false)
    const [editMode, setEditMode] = React.useState(false)
    const [shopJobLevels, setShopJobLevels] = React.useState<ShopJobLevel[]>([])
    const [error, setError] = React.useState("")

    useServerSubscriptionShop<ShopJobLevel[]>(
        {
            URI: `/job_title/${jobTitleID}/job_levels`,
            key: WSKeys.WSKeyShopJobLevels,
            ready: hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobLevels((prev) => {
                    if (prev.length === 0) return payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                    prev = prev.map((jl) => payload.find((p) => p.id === jl.id) || jl)
                    payload.forEach((p) => (prev.some((jl) => jl.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((jl) => !jl.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1))
                })
            },
        },
    )

    const addJobLevel = React.useCallback(async () => {
        if (!newJobLevel) return
        try {
            await send(WSKeys.WSKeyShopJobLevelCreate, { job_title_id: jobTitleID, label: newJobLevel })
            setNewJobLevel("")
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to process.")
        }
    }, [newJobLevel, send, jobTitleID, setError])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {shopJobLevels.map((jl, index) => {
                        return (
                            <div key={`shop-member-${jl.id}`} style={{ marginBottom: ".5rem" }}>
                                <ShopJobLevelCard
                                    shopJobLevel={jl}
                                    deletedMode={deletedMode}
                                    editMode={editMode}
                                    isFirstElement={index === 0}
                                    isLastElement={index === shopJobLevels.length - 1}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [shopJobLevels, deletedMode, editMode])

    return (
        <ConfirmModal trans title={"UPDATE JOB LEVEL"} onClose={onClose} omitButton>
            <Stack sx={{ height: "40rem" }}>
                <Typography variant={"h5"} color={colors.lightRed}>
                    {error}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={".5rem"} sx={{ pt: ".5rem", pb: "1rem" }}>
                    <Stack flex={1} direction={"row"} alignItems={"center"} sx={{ height: "100%", cursor: "pointer" }}>
                        <InputBox
                            variant={"outlined"}
                            value={newJobLevel}
                            setValue={setNewJobLevel}
                            stackSx={{ flex: 1 }}
                            sx={{
                                border: `${colors.primary}99 2px solid`,
                                borderRadius: 0,
                            }}
                            placeholder={"Enter new level"}
                        />
                        <Stack sx={{ height: "100%", backgroundColor: `${colors.primary}99` }} onClick={addJobLevel}>
                            <AddIcon sx={{ color: colors.background, fontSize: "4rem" }} />
                        </Stack>
                    </Stack>

                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            if (!editMode) {
                                setEditMode(true)
                                setDeleteMode(false)
                                return
                            }
                            setEditMode(false)
                        }}
                        sx={{
                            cursor: "pointer",
                            height: "100%",
                            backgroundColor: colors.green,
                            opacity: editMode ? 1 : 0.3,
                            px: ".5rem",
                            borderRadius: 0.5,
                        }}
                    >
                        <EditIcon sx={{ fontSize: "3rem", color: colors.offWhite }} />
                    </Stack>

                    <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            if (!deletedMode) {
                                setDeleteMode(true)
                                setEditMode(false)
                                return
                            }
                            setDeleteMode(false)
                        }}
                        sx={{
                            cursor: "pointer",
                            height: "100%",
                            backgroundColor: colors.lightRed,
                            opacity: deletedMode ? 1 : 0.3,
                            px: ".5rem",
                            borderRadius: 0.5,
                        }}
                    >
                        <DeleteForeverIcon sx={{ fontSize: "3rem", color: colors.offWhite }} />
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        my: "1rem",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden",
                        direction: "ltr",
                    }}
                >
                    {content}
                </Box>
            </Stack>
        </ConfirmModal>
    )
}
