import { Stack } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../containers/permissions"
import { useServerCommandsShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { Permission } from "../../../../types/permissions"
import { ConfirmModal } from "../../../common/confirmModal"
import { InputBox } from "../../../common/InputBox"

interface ShopJobTitleCreateModalProps {
    onClose: () => void
}

export const ShopJobTitleCreateModal = ({ onClose }: ShopJobTitleCreateModalProps) => {
    const { send } = useServerCommandsShop()
    const { hasPerm } = usePermissions()
    const [jobTitleLabel, setJobTitleLabel] = React.useState("")
    const [error, setError] = React.useState("")
    const createNewJobTitle = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopJobTitleUpdate)) return

        try {
            await send(WSKeys.WSKeyShopJobTitleCreate, { title: jobTitleLabel })
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to create new job title.")
        }
    }, [hasPerm, jobTitleLabel, onClose, send])

    return (
        <ConfirmModal trans title={"Create Job Title"} onClose={onClose} confirmLabel={"CREATE"} onConfirm={createNewJobTitle} error={error}>
            <Stack sx={{ pt: "1.5rem", pb: ".75rem" }}>
                <InputBox variant={"outlined"} value={jobTitleLabel} setValue={setJobTitleLabel} placeholder="New job title" />
            </Stack>
        </ConfirmModal>
    )
}
