import { ImportCartonVariant } from "../../../../types/importRecords"
import { Divider, Stack, Typography } from "@mui/material"
import { LabelValue } from "../../../common/labelValue"
import { colors } from "../../../../theme/theme"
import { DisplayCurrencyAmount } from "../../../common/currency/currency"
import { roundedTo } from "../../../../helpers"
import { TableDisplayMode } from "../importRecordDetailItems"

interface ImportedItemCartonProps {
    importCartonVariant: ImportCartonVariant
    cartonAmount: number
    costAUD: string
    costTWD: string
    displayMode: TableDisplayMode
}

export const ImportedItemGeneralDetail = ({ importCartonVariant, cartonAmount, costTWD, costAUD, displayMode }: ImportedItemCartonProps) => {
    const { cbm, unit_count_per_carton, carton_width_cm, carton_depth_cm, carton_height_cm } = importCartonVariant
    return (
        <Stack
            direction="row"
            spacing={1}
            flex={1}
            sx={{
                mx: "1rem",
            }}
        >
            <LabelValue
                label={
                    <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                        CTN
                    </Typography>
                }
                value={
                    <Typography variant="h6" fontWeight="bold">
                        {cartonAmount}
                    </Typography>
                }
                isVertical
                spacing={0}
            />
            <Divider sx={{ ml: "2rem !important", border: `${colors.primary}50 1px dashed` }} />
            <Stack direction="row">
                <InfoPair>
                    <>
                        <LabelValue
                            sx={{ minWidth: "5.5rem" }}
                            label={
                                <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                    UPC
                                </Typography>
                            }
                            value={
                                <Typography variant="h6" fontWeight="bold">
                                    {unit_count_per_carton}
                                </Typography>
                            }
                            isVertical
                            spacing={0}
                        />
                        <LabelValue
                            sx={{ minWidth: "5.5rem" }}
                            label={
                                <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                    TOTAL
                                </Typography>
                            }
                            value={
                                <Typography variant="h6" fontWeight="bold">
                                    {unit_count_per_carton * cartonAmount}
                                </Typography>
                            }
                            isVertical
                            spacing={0}
                        />
                    </>
                </InfoPair>

                {/* CBM */}
                <Divider sx={{ ml: "1.5rem !important", border: `${colors.primary}50 1px dashed` }} />
                <InfoPair>
                    <>
                        {displayMode === TableDisplayMode.CBM && (
                            <>
                                <LabelValue
                                    sx={{ minWidth: "7rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                            W (cm)
                                        </Typography>
                                    }
                                    value={
                                        <Typography variant="h6" fontWeight="bold">
                                            {carton_width_cm}
                                        </Typography>
                                    }
                                    isVertical
                                    spacing={0}
                                />
                                <LabelValue
                                    sx={{ minWidth: "7rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                            D (cm)
                                        </Typography>
                                    }
                                    value={
                                        <Typography variant="h6" fontWeight="bold">
                                            {carton_depth_cm}
                                        </Typography>
                                    }
                                    isVertical
                                    spacing={0}
                                />
                                <LabelValue
                                    sx={{ minWidth: "7rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                            H (cm)
                                        </Typography>
                                    }
                                    value={
                                        <Typography variant="h6" fontWeight="bold">
                                            {carton_height_cm}
                                        </Typography>
                                    }
                                    isVertical
                                    spacing={0}
                                />
                            </>
                        )}

                        <LabelValue
                            sx={{ minWidth: "7rem" }}
                            label={
                                <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                    CBM
                                </Typography>
                            }
                            value={
                                <Typography variant="h6" fontWeight="bold">
                                    {roundedTo(cbm, 4)}
                                </Typography>
                            }
                            isVertical
                            spacing={0}
                        />
                        <LabelValue
                            sx={{ minWidth: "7rem" }}
                            label={
                                <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`}>
                                    TOTAL
                                </Typography>
                            }
                            value={
                                <Typography variant="h6" fontWeight="bold">
                                    {roundedTo(parseFloat(cbm) * cartonAmount, 3)}
                                </Typography>
                            }
                            isVertical
                            spacing={0}
                        />
                    </>
                </InfoPair>

                {/* COST */}
                {displayMode === TableDisplayMode.General && (
                    <>
                        <Divider sx={{ ml: "1.5rem !important", border: `${colors.primary}50 1px dashed` }} />
                        <InfoPair>
                            <>
                                <LabelValue
                                    sx={{ minWidth: "8.5rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`} align="center">
                                            UNIT
                                        </Typography>
                                    }
                                    value={
                                        <DisplayCurrencyAmount
                                            variant="h6"
                                            fontWeight="bold"
                                            twd={roundedTo(parseFloat(costTWD) / cartonAmount / unit_count_per_carton)}
                                            aud={roundedTo(parseFloat(costAUD) / cartonAmount / unit_count_per_carton)}
                                        />
                                    }
                                    isVertical
                                    spacing={0}
                                />
                                <LabelValue
                                    sx={{ minWidth: "8.5rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`} align="center">
                                            CTN
                                        </Typography>
                                    }
                                    value={
                                        <DisplayCurrencyAmount
                                            variant="h6"
                                            fontWeight="bold"
                                            twd={roundedTo(parseFloat(costTWD) / cartonAmount)}
                                            aud={roundedTo(parseFloat(costAUD) / cartonAmount)}
                                        />
                                    }
                                    isVertical
                                    spacing={0}
                                />
                                <LabelValue
                                    sx={{ minWidth: "11rem" }}
                                    label={
                                        <Typography variant="body1" fontWeight="bold" color={`${colors.primary}90`} align="center">
                                            TOTAL
                                        </Typography>
                                    }
                                    value={<DisplayCurrencyAmount variant="h6" fontWeight="bold" twd={costTWD} aud={costAUD} />}
                                    isVertical
                                    spacing={0}
                                />
                            </>
                        </InfoPair>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

const InfoPair = ({ children }: { children: JSX.Element }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{
                px: "1rem",
            }}
        >
            {children}
        </Stack>
    )
}
