import React from "react"
import { useMutation, useQuery } from "react-fetching-library"
import { SERVER_HOST } from "../constants"
import { LoginCheck, LoginPOS, Logout } from "../fetching"
import { useServerSubscriptionSecureUser } from "../hooks/useServer"
import { WSKeys } from "../key"
import { Organisation } from "../types/organisations"
import { Permission } from "../types/permissions"
import { User } from "../types/users"
import { Language, useLanguages } from "./Languages"
import { useInnerShop } from "./inner_shop"
import { useOrganisation } from "./organisations"
import { usePermissions } from "./permissions"
import { TimeFormatOption, useTimeFormat } from "./timeFormatter"

export interface AuthState {
    isLoggingIn: boolean
    user: User
    userID: string
    isSystemUser: boolean
    setUser: React.Dispatch<React.SetStateAction<User>>
    onLoginClick: () => void
    onLogoutClick: () => void
    onLoginPOS: (username: string, passcode: string) => Promise<User>
    organisations: Organisation[]
    setOrganisations: React.Dispatch<React.SetStateAction<Organisation[]>>
}

const fallbackUser: User = {
    id: "",
    email: "",
    prefer_name: "",
    verified: false,
    address: "",
    is_system_user: false,
    language_preference: Language.English,
    time_format_preference: TimeFormatOption.Time24H,
}

const initialState: AuthState = {
    isLoggingIn: false,
    user: fallbackUser,
    userID: "",
    isSystemUser: false,
    setUser: () => {
        return
    },
    onLoginClick: () => {
        return
    },
    onLogoutClick: () => {
        return
    },
    onLoginPOS: async (username: string, passcode: string) => {
        return fallbackUser
    },
    organisations: [],
    setOrganisations: () => {
        return
    },
}

export const AuthContext = React.createContext<AuthState>(initialState)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [isLoggingIn, setIsLoggingIn] = React.useState(true)
    const [user, setUser] = React.useState<User>(initialState.user)
    const [organisations, setOrganisations] = React.useState<Organisation[]>([])
    const [currentOrganisation, setCurrentOrganisation] = React.useState<Organisation>()
    const userID = user.id
    const [loginPopup, setLoginPopup] = React.useState<Window | null>(null)
    const popupCheckInterval = React.useRef<NodeJS.Timer>()
    const { query: authCheck, payload: loginResponse, error: loginError } = useQuery(LoginCheck())
    const { query: logout } = useQuery(Logout(), false)
    const { mutate: loginPOS } = useMutation(LoginPOS)

    const authCheckCallback = React.useCallback(
        async (event?: MessageEvent) => {
            if (!event || event.data !== "check auth") return

            // Check passport server login
            if (!userID) {
                try {
                    const resp = await authCheck()
                    if (resp.error || !resp.payload) {
                        setUser(fallbackUser)
                        return
                    }
                    setUser(resp.payload)
                } catch (err) {
                    console.error(err)
                }
            }
        },
        [authCheck, userID],
    )

    // login
    React.useEffect(() => {
        setIsLoggingIn(false)
        if (loginError || !loginResponse) {
            return
        }
        setUser(loginResponse)
    }, [loginResponse, loginError])

    // Check if login in the iframe has been successful (window closed), if closed then do clean up
    React.useEffect(() => {
        if (!loginPopup) return

        // Listening for a token coming from the iframe
        window.addEventListener("message", authCheckCallback, false)

        const clearPopupCheckInterval = () => {
            popupCheckInterval.current && clearInterval(popupCheckInterval.current)
        }

        clearPopupCheckInterval()
        popupCheckInterval.current = setInterval(async () => {
            if (!loginPopup) return clearPopupCheckInterval()
            if (loginPopup.closed) {
                clearPopupCheckInterval()
                setLoginPopup(null)
                setIsLoggingIn(false)
                window.removeEventListener("message", authCheckCallback)
            }
        }, 1000)

        return clearPopupCheckInterval
    }, [loginPopup, authCheckCallback])

    // Open iframe to passport web to log in
    const onLoginClick = React.useCallback(async () => {
        if (isLoggingIn) return
        setIsLoggingIn(true)

        const width = 520
        const height = 730
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const left = window.screenX + (window.outerWidth - width) / 2
        const href = `${window.location.protocol}//${SERVER_HOST}/api/auth/google_login`
        const popup = window.open(href, "Google login", `width=${width},height=${height},left=${left},top=${top},popup=1`)

        setLoginPopup(popup)
    }, [isLoggingIn])

    const onLogoutClick = React.useCallback(async () => {
        try {
            const resp = await logout()
            if (!resp) return
            location.reload()
        } catch (e) {
            console.log(e)
        }
    }, [logout])

    const onLoginPOS = React.useCallback(
        (username: string, passcode: string) => {
            return new Promise<User>((resolve, reject) => {
                loginPOS({ username, passcode })
                    .then((resp) => {
                        if (resp.error || !resp.payload) {
                            reject("Failed to login POS system.")
                            setUser(fallbackUser)
                            return
                        }
                        setUser(resp.payload)

                        resolve(resp.payload)
                    })
                    .catch((e) => {
                        console.log(e)
                        reject(e)
                    })
            })
        },
        [loginPOS],
    )

    return (
        <AuthContext.Provider
            value={{
                isLoggingIn,
                user,
                userID,
                isSystemUser: user.is_system_user,
                setUser,
                onLoginClick,
                onLogoutClick,
                onLoginPOS,
                setOrganisations,
                organisations,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return React.useContext<AuthState>(AuthContext)
}

export const UserUpdater = () => {
    const { setUser, setOrganisations } = useAuth()
    const { setNewLanguage } = useLanguages()
    const { setTimeFormatOption } = useTimeFormat()
    const { setOrganisation } = useOrganisation()
    const { shopID } = useInnerShop()
    const { setShopPermissions } = usePermissions()

    // Subscribe on the user
    useServerSubscriptionSecureUser<User>(
        {
            URI: "",
            key: WSKeys.WSKeyUserSubscribe,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setUser(payload)
                setNewLanguage(payload.language_preference)
                setTimeFormatOption(payload.time_format_preference)
            },
        },
    )

    // subscribe on shop permissions
    React.useEffect(() => {
        if (!shopID) setShopPermissions([])
    }, [setShopPermissions, shopID])
    useServerSubscriptionSecureUser<Permission[]>(
        {
            URI: `/shop/${shopID}/permission_list`,
            key: WSKeys.WSKeyUserPermissionsSubscribe,
            ready: !!shopID,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopPermissions(payload)
            },
        },
    )

    // Subscribe on the user
    useServerSubscriptionSecureUser<Organisation[]>(
        {
            URI: "/organisations",
            key: WSKeys.WSKeyUserOrganisations,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setOrganisations(payload)
                setOrganisation((prev) => {
                    // clean up current organisation, if no organisation exists
                    if (payload.length === 0) return undefined

                    // stay the same, if prev organisation exists
                    if (prev) return payload.find((p) => p.id === prev.id) || prev

                    // otherwise, assign the first one
                    return payload[0]
                })
            },
        },
    )

    return null
}
