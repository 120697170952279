import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined"
import { Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { roundedTo } from "../../../../helpers"
import { typographyMaxLength } from "../../../../theme/sxProps"
import { colors } from "../../../../theme/theme"
import { ImportedItem } from "../../../../types/importRecords"
import { ConfirmModal } from "../../../common/confirmModal"
import { TableDisplayMode } from "../importRecordDetailItems"
import { ImportedItemGeneralDetail } from "./importedItemGeneralDetail"
import { ImportedItemModal } from "./importedItemModal"

interface ImportedItemCardProps {
    importedItem: ImportedItem
    audToTwdRate: string
    shouldShrink: boolean
    currentDisplayMode: TableDisplayMode
    isDeleteMode: boolean
    deleteImportedItem: () => void
    totalCBM: number
    operationFeeTWD: number
    operationFeeAUD: number
    extra_charge_rate: number
}

const propsAreEqual = (prevProps: ImportedItemCardProps, nextProps: ImportedItemCardProps) => {
    return (
        prevProps.isDeleteMode === nextProps.isDeleteMode &&
        prevProps.currentDisplayMode === nextProps.currentDisplayMode &&
        prevProps.shouldShrink === nextProps.shouldShrink &&
        prevProps.totalCBM === nextProps.totalCBM &&
        prevProps.operationFeeTWD === nextProps.operationFeeTWD &&
        prevProps.operationFeeAUD === nextProps.operationFeeAUD &&
        prevProps.importedItem === nextProps.importedItem
    )
}

export const ImportedItemCard = React.memo(function ImportedItemCard({
    importedItem,
    audToTwdRate,
    shouldShrink,
    currentDisplayMode,
    isDeleteMode,
    deleteImportedItem,
    totalCBM,
    operationFeeTWD,
    operationFeeAUD,
    extra_charge_rate,
}: ImportedItemCardProps) {
    const { carton_amount, cost_aud, cost_twd, item } = importedItem
    const { label, import_label, manufacturer, item_carton_variant } = item

    const costTWD = React.useMemo(() => {
        if (!item_carton_variant?.cbm) return "0"
        const cost = (parseFloat(cost_twd) * (100 + extra_charge_rate)) / 100
        const cbm = parseFloat(item_carton_variant.cbm) * carton_amount
        return `${roundedTo(cost + (operationFeeTWD * cbm) / totalCBM)}`
    }, [totalCBM, item_carton_variant?.cbm, operationFeeTWD, cost_twd, carton_amount, extra_charge_rate])

    const costAUD = React.useMemo(() => {
        if (!item_carton_variant?.cbm) return "0"
        const cost = (parseFloat(cost_aud) * (100 + extra_charge_rate)) / 100
        const cbm = parseFloat(item_carton_variant.cbm) * carton_amount
        return `${roundedTo(cost + (operationFeeAUD * cbm) / totalCBM)}`
    }, [totalCBM, item_carton_variant?.cbm, operationFeeAUD, cost_aud, carton_amount, extra_charge_rate])

    const [openEditModal, setOpenEditModal] = React.useState(false)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false)
    React.useEffect(() => {
        if (!isDeleteMode) setOpenDeleteConfirmation(false)
    }, [isDeleteMode])
    return (
        <>
            <Stack
                direction="row"
                sx={{
                    border: `${isDeleteMode ? colors.lightRed : `${colors.primary}70`} 2px solid`,
                    borderRadius: 0.8,
                    minHeight: "8rem",
                }}
            >
                <Stack
                    direction="row"
                    flex={1}
                    sx={{
                        textAlign: "start",
                        p: ".8rem",
                    }}
                >
                    <Stack direction="column" flex={1}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h5" sx={{ mb: ".25rem", ...typographyMaxLength }}>
                                <strong>{label}</strong>
                            </Typography>

                            {!shouldShrink && manufacturer && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        ml: "1rem",
                                        ...typographyMaxLength,
                                    }}
                                >
                                    ({manufacturer.label_ch})
                                </Typography>
                            )}
                        </Stack>

                        <Typography
                            variant="body2"
                            color={`${colors.primary}f0`}
                            sx={{
                                ...typographyMaxLength,
                            }}
                        >
                            {import_label}
                        </Typography>
                    </Stack>

                    <Divider sx={{ mx: ".35rem !important", border: `${colors.primary}50 1px dashed` }} />

                    {item_carton_variant && (
                        <Stack
                            direction="row"
                            sx={{
                                px: ".25rem",
                            }}
                        >
                            <ImportedItemGeneralDetail
                                key={item_carton_variant.id}
                                importCartonVariant={item_carton_variant}
                                cartonAmount={carton_amount}
                                costTWD={costTWD}
                                costAUD={costAUD}
                                displayMode={currentDisplayMode}
                            />
                        </Stack>
                    )}
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ backgroundColor: isDeleteMode ? colors.lightRed : `${colors.primary}30`, width: "4rem", cursor: "pointer" }}
                    onClick={() => {
                        if (isDeleteMode) {
                            setOpenDeleteConfirmation((prev) => !prev)
                            return
                        }
                        setOpenEditModal((prev) => !prev)
                    }}
                >
                    {isDeleteMode ? (
                        <DeleteForeverOutlinedIcon fontSize="large" sx={{ color: colors.background }} />
                    ) : (
                        <FitScreenOutlinedIcon fontSize="large" />
                    )}
                </Stack>
            </Stack>
            {openEditModal && (
                <ImportedItemModal
                    setOpen={setOpenEditModal}
                    importedItem={importedItem}
                    audToTwdRate={audToTwdRate}
                    importRecordID={importedItem.import_record_id}
                />
            )}
            {openDeleteConfirmation && (
                <ConfirmModal
                    title={
                        <Stack spacing={1} sx={{ ml: "1rem" }}>
                            <Typography variant="h5">Are you sure you want to delete the item?</Typography>
                            <Typography variant="h6">{label}</Typography>
                        </Stack>
                    }
                    onConfirm={() => {
                        setOpenDeleteConfirmation(false)
                        deleteImportedItem()
                    }}
                    onClose={() => setOpenDeleteConfirmation(false)}
                />
            )}
        </>
    )
},
propsAreEqual)
