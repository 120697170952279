import React from "react"

export enum Language {
    English = "EN",
    Chinese = "CH",
}

export interface LanguageState {
    language: Language
    setNewLanguage: (lang: Language) => void

    translate: (word: React.ReactNode) => React.ReactNode
}

const initialState: LanguageState = {
    language: Language.English,
    setNewLanguage: (lang: Language) => {
        return
    },
    translate: (word: React.ReactNode) => "",
}

export const LanguageContext = React.createContext<LanguageState>(initialState)

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const [language, setLanguage] = React.useState<Language>((localStorage.getItem("web_language") as Language) || Language.English)
    const setNewLanguage = React.useCallback((lang: Language) => {
        localStorage.setItem("web_language", lang)
        setLanguage(lang)
    }, [])
    const translate = React.useCallback(
        (word: React.ReactNode) => {
            if (typeof word !== "string") return word

            if (language === Language.English) return word

            return translateMap.get(word.toLowerCase()) || word
        },
        [language],
    )
    return (
        <LanguageContext.Provider
            value={{
                language,
                setNewLanguage,
                translate,
            }}
        >
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguages = () => {
    return React.useContext<LanguageState>(LanguageContext)
}

/* TRANSLATE MAP */

const translateMap = new Map<string, string>()
translateMap.set("member", "成員")
translateMap.set("members", "成員")
translateMap.set("search", "搜尋")
translateMap.set("settings", "設定")
translateMap.set("shop detail", "店家資訊")
translateMap.set("job title", "工作職稱")
translateMap.set("title", "頭銜")
translateMap.set("level", "等級")
translateMap.set("job level", "職階")
translateMap.set("duty", "職責")
translateMap.set("full time", "全職")
translateMap.set("part time", "兼職")
translateMap.set("casual", "臨時工")
translateMap.set("contract", "合約工")
translateMap.set("weekday", "平日")
translateMap.set("saturday", "週六")
translateMap.set("sunday", "週日")
translateMap.set("holiday", "假日")
translateMap.set("weekday pay", "平日時薪")
translateMap.set("saturday pay", "週六時薪")
translateMap.set("sunday pay", "週日時薪")
translateMap.set("holiday pay", "假日時薪")
translateMap.set("update job level", "職階修改")
translateMap.set("delete job title", "刪除工作職稱")
translateMap.set("confirm", "確認")
translateMap.set("cancel", "取消")
translateMap.set("create job title", "新增工作職稱")
translateMap.set("create", "新增")
translateMap.set("edit", "修改")
translateMap.set("clock in", "打卡")
translateMap.set("clock out", "結束")
translateMap.set("detail", "資料")
translateMap.set("general", "基本")
translateMap.set("wage", "時薪")
translateMap.set("wages", "時薪")
translateMap.set("leave", "假期")
translateMap.set("account panel", "出勤資訊")
translateMap.set("work records", "工作紀錄")
translateMap.set("payslips", "薪資單")
translateMap.set("new member", "新增成員")
translateMap.set("name", "名字")
translateMap.set("employment status", "雇傭狀態")
translateMap.set("visa type", "簽證類別")
translateMap.set("add work record", "新增出勤紀錄")
translateMap.set("edit work record", "修改出勤紀錄")
translateMap.set("delete work record", "刪除出勤紀錄")
translateMap.set("date", "日期")
translateMap.set("time", "時間")
translateMap.set("update member", "更新成員")
translateMap.set("create member", "新增員工")
translateMap.set("prefer name", "名字")
translateMap.set("role", "位階")
translateMap.set("full name (payslip)", "全名 (薪資單)")
translateMap.set("prefer name (payslip)", "名字 (薪資單)")
translateMap.set("archived members", "已封存成員")
translateMap.set("archive", "封存")
translateMap.set("unarchive", "還原")
translateMap.set("archive member", "封存成員")
translateMap.set("unarchive member", "還原成員")
translateMap.set("sync", "同步")
translateMap.set("current", "目前")
translateMap.set("entitled members", "雇傭成員")

translateMap.set("the page support auto saving", "此頁為自動儲存")
translateMap.set("are you sure you want to delete the following job title?", "你確定要刪除以下工作職稱嗎？")
translateMap.set("please set up the starting time", "請設定開始時間")
translateMap.set("are you sure you want to clock out?", "你確定要結束打卡嗎？")
translateMap.set("are you sure you want to delete the work record?", "你確定要刪除出勤紀錄嗎？")
translateMap.set("are you sure you want to archive the following member?", "你確定要封存以下成員嗎？")
translateMap.set("are you sure you want to unarchive the following member?", "你確定要還原以下成員嗎？")
