import React from "react"
import { FindRoot } from "./context"
import { GetSubscriptionMock, IsMockMode } from "./useMock"
import { useWS } from "./useWS"

export interface SubProps {
    URI: string
    key: string
    batchURI?: string
    host?: string
    ready?: boolean
    args?: { [key: string]: any }
}

export interface SubOptions<T = unknown> {
    callback?: (payload: T) => void
    filter?: (payload: T) => T
}

export const useSubscription = <T = unknown>({ URI, key, batchURI, host, ready, args }: SubProps, options?: SubOptions<T>) => {
    const [payload, setPayload] = React.useState<T>()
    const isMock = IsMockMode()
    const { wsURI } = useWS({
        URI,
        key,
        host,
        ready: !!ready && !isMock,
        batchURI,
        jsonStringifiedParams: args ? JSON.stringify(args) : undefined,
        subscriber: (msg) => {
            if (msg.key === key) {
                let payload = msg.payload as unknown as T

                if (!payload) return

                if (options?.filter) payload = options.filter(payload)

                setPayload(payload)
                options?.callback && options.callback(payload)
            }
        },
    })

    const refetch = React.useCallback(() => {
        const fr = FindRoot(wsURI)
        const conn = fr[1]

        if (!conn) return

        conn.socket.send(`REFETCH:${wsURI.path}${wsURI.jsonStringifiedParams ? `?searchParams=${wsURI.jsonStringifiedParams}` : ""}`)
    }, [wsURI])

    React.useEffect(() => {
        if (!isMock || !options?.callback) return
        options.callback(GetSubscriptionMock(key))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMock])

    return { payload, refetch }
}
