import { Stack, Typography } from "@mui/material"
import React from "react"
import { TypographyTrans } from "../../components/common/TypographyTrans"
import { ShopButton } from "../../components/common/shopButton"
import { CountUpTimer } from "../../components/timer/countUpTimer"
import { colors, fonts } from "../../theme/theme"
import { ShopMember } from "../../types/Shop"
import { ClockingModal } from "./clockingModal"

interface ClockInCardProps {
    shopMember: ShopMember
}

export const ClockInCard = ({ shopMember }: ClockInCardProps) => {
    const { prefer_name, ongoing_work_record, deleted_at } = shopMember
    const [openClockingModal, setOpenClockingModal] = React.useState(false)
    const clockInOut = React.useMemo(() => {
        if (ongoing_work_record)
            return (
                <>
                    <CountUpTimer startedAt={ongoing_work_record.started_at} sx={{ opacity: deleted_at ? 0.5 : 1, fontSize: "4rem" }} />
                    <ShopButton
                        trans
                        buttonColor={`${colors.lightRed}CC`}
                        sx={{ px: "2rem", py: ".25rem" }}
                        disabled={!!deleted_at}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            setOpenClockingModal(true)
                        }}
                    >
                        <TypographyTrans fontWeight={"bold"} variant={"h5"} fontFamily={fonts.sourceSansProBlack} color={colors.offWhite}>
                            Clock Out
                        </TypographyTrans>
                    </ShopButton>
                </>
            )

        return (
            <>
                <Typography variant={"h4"} fontFamily={fonts.sourceSansProBold} sx={{ opacity: deleted_at ? 0.5 : 1, fontSize: "4rem" }}>
                    --:--:--
                </Typography>
                <ShopButton
                    trans
                    buttonColor={`${colors.primary}CC`}
                    sx={{ px: "2rem", py: ".25rem" }}
                    disabled={!!deleted_at}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        setOpenClockingModal(true)
                    }}
                >
                    <TypographyTrans fontWeight={"bold"} variant={"h5"} fontFamily={fonts.sourceSansProBlack} color={colors.offWhite}>
                        Clock In
                    </TypographyTrans>
                </ShopButton>
            </>
        )
    }, [deleted_at, ongoing_work_record])
    return (
        <>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    p: "1rem",
                    border: "2px solid " + colors.primary,
                    width: "100%",
                    height: "100%",
                    borderRadius: 3,
                    opacity: ongoing_work_record ? 1 : 0.5,
                }}
            >
                <Typography variant="h4" fontWeight={"bolder"}>
                    {prefer_name}
                </Typography>
                <Stack
                    alignItems={"center"}
                    sx={{
                        mb: "3rem",
                    }}
                    spacing={"2rem"}
                >
                    {clockInOut}
                </Stack>
            </Stack>
            {openClockingModal && <ClockingModal shopMember={shopMember} onClose={() => setOpenClockingModal(false)} />}
        </>
    )
}
