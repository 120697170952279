import { SxProps } from "@mui/system"

export const typographyMaxLength: SxProps = {
    display: "-webkit-box",
    overflow: "hidden",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1, // change to max number of lines
    WebkitBoxOrient: "vertical",
}
