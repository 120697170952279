import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import { Box, IconButton, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useMemo } from "react"
import FlipMove from "react-flip-move"
import { useOrganisation } from "../../containers/organisations"
import { getSalaryPayoutSessionDateRange, roundToDecimalPlaces } from "../../helpers"
import { useServerCommandsOrg } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { colors, fonts } from "../../theme/theme"
import { SalaryPayoutUser } from "../../types/organisations"
import { DateFormat } from "../../types/types"
import { TypographyTrans } from "../common/TypographyTrans"
import { ShopButton } from "../common/shopButton"

interface ShopSalaryPayoutProps {
    shopID: string
}

export enum SalaryPayoutSessionType {
    Week = "WEEK",
    Month = "MONTH",
    Quarter = "QUARTER",
    Year = "YEAR",
}

export const ShopSalaryPayout = ({ shopID }: ShopSalaryPayoutProps) => {
    const { send } = useServerCommandsOrg()
    const { organisation } = useOrganisation()
    const [offset, setOffset] = React.useState(0)
    const [salaryPayoutSessionType, setSalaryPayoutSessionType] = React.useState<SalaryPayoutSessionType>(SalaryPayoutSessionType.Week)
    const [salaryPayout, setSalaryPayout] = React.useState<SalaryPayoutUser[]>([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const { startDate, endDate } = React.useMemo(() => {
        const firstPayDate = organisation ? moment(organisation.salary_pay_date) : moment()
        return getSalaryPayoutSessionDateRange(firstPayDate, offset, salaryPayoutSessionType)
        // check if salary pay date is set
    }, [salaryPayoutSessionType, offset, organisation])

    const fetchShopSalaryPayout = React.useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await send<SalaryPayoutUser[]>(WSKeys.WSKeyOrganisationSalaryPayout, {
                shop_ids: [shopID],
                start_date: startDate,
                end_date: endDate,
            })

            setSalaryPayout(resp)
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to fetch shop salary payout")
        } finally {
            setIsLoading(false)
        }
    }, [send, shopID, startDate, endDate])

    React.useEffect(() => {
        fetchShopSalaryPayout()
    }, [fetchShopSalaryPayout])

    const tableRow = React.useCallback(
        (id: string, children: React.ReactNode, omitBorder?: boolean) => (
            <div
                key={id}
                style={{
                    backgroundColor: colors.background,
                }}
            >
                <Box
                    flex={1}
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "20% 25% 25% 30%",
                        gridTemplateRows: "repeat(auto-fill, 4.5rem) ",
                        gap: "1rem",
                        borderRadius: 0.8,
                        borderBottom: omitBorder ? undefined : `${colors.primary}30 1px dashed`,
                        px: "1rem",
                    }}
                >
                    {children}
                </Box>
            </div>
        ),
        [],
    )

    const content = useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {salaryPayout.map((sp) => {
                        return tableRow(
                            `salary-payout-${sp.user_id}`,
                            <>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                        {sp.preferred_name}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                        {roundToDecimalPlaces(sp.hours)} hrs
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                        $ {roundToDecimalPlaces(sp.paid)}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ pr: "2rem" }}>
                                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                        $ {roundToDecimalPlaces(sp.superannuation)}
                                    </Typography>
                                </Stack>
                            </>,
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [salaryPayout, tableRow])

    const footer = React.useMemo(() => {
        let totalHours = 0.0
        let totalPaid = 0.0
        let totalSuperannuation = 0.0

        salaryPayout.forEach((sp) => {
            totalHours += parseFloat(sp.hours)
            totalPaid += parseFloat(sp.paid)
            totalSuperannuation += parseFloat(sp.superannuation)
        })

        return tableRow(
            "salary-payout-header",
            <>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        Total
                    </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        {roundToDecimalPlaces(totalHours)} hrs
                    </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        $ {roundToDecimalPlaces(totalPaid)}
                    </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ pr: "2rem" }}>
                    <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                        $ {roundToDecimalPlaces(totalSuperannuation)}
                    </Typography>
                </Stack>
            </>,
            true,
        )
    }, [salaryPayout, tableRow])

    const header = React.useMemo(() => {
        return (
            <Box
                sx={{
                    ml: "1rem",
                    mr: ".5rem",
                    pr: "1rem",
                    direction: "ltr",
                }}
            >
                {tableRow(
                    "salary-payout-header",
                    <>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                Name
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                            <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                Hours
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                            <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                Paid
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ pr: "2rem" }}>
                            <Typography variant="h6" fontFamily={fonts.sourceSansProBold}>
                                Superannuation
                            </Typography>
                        </Stack>
                    </>,
                    true,
                )}
            </Box>
        )
    }, [tableRow])

    return (
        <Stack
            direction={"column"}
            spacing={"1rem"}
            sx={{
                border: `2px solid ${colors.primary}80`,
                borderRadius: "0.5rem",
                padding: "1rem",
                height: "50%",
            }}
        >
            {/* header */}
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                }}
            >
                <TypographyTrans variant="h6" sx={{ fontFamily: fonts.sourceSansProBold }}>
                    Salary Payout
                </TypographyTrans>
                <Stack direction={"row"} alignItems={"center"} spacing={"1rem"}>
                    {Object.values(SalaryPayoutSessionType).map((v) => (
                        <ShopButton
                            key={v}
                            data-testid={"week-button"}
                            sx={{
                                py: 0,
                                px: "1rem",
                                backgroundColor: colors.primary,
                                opacity: salaryPayoutSessionType === v ? 1 : 0.3,
                                ":hover": {
                                    backgroundColor: colors.primary,
                                },
                            }}
                            onClick={() => {
                                setSalaryPayoutSessionType(v)
                                setOffset(0)
                            }}
                        >
                            {v}
                        </ShopButton>
                    ))}
                </Stack>
            </Stack>

            {/* time bar */}
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <IconButton size="small" onClick={() => setOffset((prev) => prev - 1)} sx={{ p: 0 }}>
                    <ArrowLeftIcon sx={{ color: colors.primary, fontSize: "4rem" }} />
                </IconButton>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                    {startDate.format(DateFormat.DateDay)} ~ {endDate.format(DateFormat.DateDay)}
                </TypographyTrans>
                <IconButton size="small" onClick={() => setOffset((prev) => prev + 1)} sx={{ p: 0 }}>
                    <ArrowRightIcon sx={{ color: colors.primary, fontSize: "4rem" }} />
                </IconButton>
            </Stack>

            {/* user salary payout */}
            <Stack sx={{ px: ".5rem", py: ".5rem", flex: 1 }}>
                {header}
                <Box
                    sx={{
                        ml: "1rem",
                        mr: ".5rem",
                        pr: "1rem",
                        my: "1rem",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden",
                        direction: "ltr",
                    }}
                >
                    {content}
                </Box>
                <Box
                    sx={{
                        ml: "1rem",
                        mr: ".5rem",
                        pr: "1rem",
                        direction: "ltr",
                    }}
                >
                    {footer}
                </Box>
            </Stack>
        </Stack>
    )
}
