import React from "react"
import { DateFormat } from "../types/types"

export enum TimeFormatOption {
    Time24H = "24H",
    Time12H = "12H",
}

export interface TimeFormatState {
    setTimeFormatOption: (timeFormatOption: TimeFormatOption) => void
    timeFormat: DateFormat
    timeFormatOption: TimeFormatOption
}

const initialState: TimeFormatState = {
    setTimeFormatOption: (timeFormatOption: TimeFormatOption) => {
        return
    },
    timeFormat: DateFormat.Time24H,
    timeFormatOption: TimeFormatOption.Time24H,
}

export const TimeFormatContext = React.createContext<TimeFormatState>(initialState)

export const TimeFormatProvider = ({ children }: { children: React.ReactNode }) => {
    const [timeFormatOption, setTimeFormatOption] = React.useState<TimeFormatOption>(TimeFormatOption.Time24H)
    return (
        <TimeFormatContext.Provider
            value={{
                setTimeFormatOption,
                timeFormat: timeFormatOption === TimeFormatOption.Time24H ? DateFormat.Time24H : DateFormat.Time12H,
                timeFormatOption,
            }}
        >
            {children}
        </TimeFormatContext.Provider>
    )
}

export const useTimeFormat = () => {
    return React.useContext<TimeFormatState>(TimeFormatContext)
}
