import { Stack, TextField, TextFieldProps, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import React from "react"
import { Control, Controller } from "react-hook-form"
import { colors, fonts } from "../../../theme/theme"
import { TypographyTrans } from "../TypographyTrans"

interface InputFieldProps {
    control: Control<any, object>
    rules?: any
    name: string
    trans?: boolean
}

export const InputField = ({
    variant,
    label,
    control,
    sx,
    name,
    rules,
    helperText,
    onChange,
    multiline,
    trans,
    ...textFieldProps
}: InputFieldProps & TextFieldProps) => {
    const inputLabel = React.useMemo(() => {
        if (!label) return null
        if (typeof label === "string") {
            if (trans)
                return (
                    <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                        {label}
                    </TypographyTrans>
                )

            return (
                <Typography variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                    {label}
                </Typography>
            )
        }
        return label
    }, [label, trans])

    const sxProps: SxProps = React.useMemo(() => {
        const primaryColor = colors.primary
        switch (variant) {
            case "filled":
                return {
                    backgroundColor: "unset",
                    ".MuiFilledInput-input": {
                        px: "1.5rem",
                        py: ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.5rem",
                    },
                    ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                }
            case "outlined":
                return {
                    backgroundColor: "unset",
                    ".MuiOutlinedInput-input": {
                        px: "1.5rem",
                        py: multiline ? "1.5rem" : ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.3rem",
                        fontFamily: fonts.sourceSansProSemibold,
                    },
                    ".MuiOutlinedInput-notchedOutline": { border: "unset" },
                }
            default:
                return {
                    backgroundColor: "unset",
                    ".MuiInput-input": {
                        px: "1.5rem",
                        py: ".662rem",
                        height: "unset",
                        "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                            WebkitAppearance: "none",
                        },
                        fontSize: "2.5rem",
                    },
                }
        }
    }, [multiline, variant])

    return (
        <Stack>
            {inputLabel}
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue=""
                render={({ field, fieldState }) => (
                    <TextField
                        autoComplete="off"
                        hiddenLabel
                        fullWidth
                        {...textFieldProps}
                        variant={variant}
                        value={field.value || ""}
                        onChange={(e) => {
                            field.onChange(e)
                            if (onChange) onChange(e)
                        }}
                        multiline={multiline}
                        inputRef={field.ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : helperText}
                        sx={{
                            ".MuiFormHelperText-root": {
                                margin: "1px 0 0 3px",
                                color: `${colors.lightRed} !important`,
                                fontSize: "1.35rem",
                                fontFamily: fonts.sourceSansProSemibold,
                            },
                            borderRadius: 0.5,
                            border: `${fieldState.error ? colors.lightRed : colors.primary}50 2px solid`,
                            ...sxProps,
                            ...sx,
                        }}
                    />
                )}
            />
        </Stack>
    )
}
