import React from "react"
import { roundedTo } from "../helpers"
import { ImportRecord } from "../types/importRecords"

interface ImportRecordPriceProps {
    importRecord: ImportRecord
}

export const useImportRecordPrice = ({ importRecord }: ImportRecordPriceProps) => {
    const {
        shipping_fee_twd,
        shipping_fee_aud,
        custom_fee_twd,
        custom_fee_aud,
        pallet_cost_twd,
        pallet_cost_aud,
        surcharge_twd,
        surcharge_aud,
        cargo_packing_fee_twd,
        cargo_packing_fee_aud,
        item_extra_charge_percentage,
        cost_twd,
        cost_aud,
    } = importRecord

    const totalCostTWD = React.useMemo(
        () =>
            ((roundedTo(cost_twd) + roundedTo(pallet_cost_twd)) * (100 + roundedTo(item_extra_charge_percentage))) / 100 +
            roundedTo(shipping_fee_twd) +
            roundedTo(custom_fee_twd) +
            roundedTo(surcharge_twd) +
            roundedTo(cargo_packing_fee_twd),
        [cargo_packing_fee_twd, cost_twd, custom_fee_twd, item_extra_charge_percentage, pallet_cost_twd, shipping_fee_twd, surcharge_twd],
    )

    const extraChargeTWD = React.useMemo(
        () => ((roundedTo(cost_twd) + roundedTo(pallet_cost_twd)) * roundedTo(item_extra_charge_percentage)) / 100,
        [cost_twd, pallet_cost_twd, item_extra_charge_percentage],
    )

    const totalCostAUD = React.useMemo(
        () =>
            ((roundedTo(cost_aud) + roundedTo(pallet_cost_aud)) * (100 + roundedTo(item_extra_charge_percentage))) / 100 +
            roundedTo(shipping_fee_aud) +
            roundedTo(custom_fee_aud) +
            roundedTo(surcharge_aud) +
            roundedTo(cargo_packing_fee_aud),
        [cargo_packing_fee_aud, cost_aud, custom_fee_aud, item_extra_charge_percentage, pallet_cost_aud, shipping_fee_aud, surcharge_aud],
    )

    const extraChargeAUD = React.useMemo(
        () => ((roundedTo(cost_aud) + roundedTo(pallet_cost_aud)) * roundedTo(item_extra_charge_percentage)) / 100,
        [cost_aud, pallet_cost_aud, item_extra_charge_percentage],
    )

    const operationFeeTWD = React.useMemo(() => {
        const shippingFee = parseFloat(shipping_fee_twd)
        const customFee = parseFloat(custom_fee_twd)
        const palletFee = (parseFloat(pallet_cost_twd) * (1 + parseFloat(item_extra_charge_percentage))) / 100
        const surcharge = parseFloat(surcharge_twd)
        const cargoPackingFee = parseFloat(cargo_packing_fee_twd)
        return roundedTo(shippingFee + customFee + palletFee + surcharge + cargoPackingFee)
    }, [cargo_packing_fee_twd, custom_fee_twd, item_extra_charge_percentage, pallet_cost_twd, shipping_fee_twd, surcharge_twd])

    const operationFeeAUD = React.useMemo(() => {
        const shippingFee = parseFloat(shipping_fee_aud)
        const customFee = parseFloat(custom_fee_aud)
        const palletFee = (parseFloat(pallet_cost_aud) * (1 + parseFloat(item_extra_charge_percentage))) / 100
        const surcharge = parseFloat(surcharge_aud)
        const cargoPackingFee = parseFloat(cargo_packing_fee_aud)
        return roundedTo(shippingFee + customFee + palletFee + surcharge + cargoPackingFee)
    }, [cargo_packing_fee_aud, custom_fee_aud, item_extra_charge_percentage, pallet_cost_aud, shipping_fee_aud, surcharge_aud])

    return {
        totalCostTWD,
        extraChargeTWD,
        totalCostAUD,
        extraChargeAUD,
        operationFeeTWD,
        operationFeeAUD,
    }
}
