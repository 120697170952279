import { Control } from "react-hook-form"
import { Stack, Typography } from "@mui/material"
import { InputField } from "../form/inputField"
import { roundedTo } from "../../../helpers"
import React from "react"
import { ImportRecordUpdateForm } from "../../importRecords/detail/importRecordDetailUpdateModal"

interface FeeCurrencyFieldsProps {
    title: string
    audToTwdRate: string
    control: Control<ImportRecordUpdateForm, any>
    twdName: string
    audName: string
    setValue: any

    isVertical?: boolean
}

export const FeeCurrencyFields = ({ title, twdName, audName, control, audToTwdRate, setValue, isVertical }: FeeCurrencyFieldsProps) => {
    return (
        <Stack direction="column" spacing={1}>
            <Typography variant="h6" fontWeight="bold">
                {title}:
            </Typography>
            <Stack direction={isVertical ? "column" : "row"} alignItems="center" spacing={1} sx={{ pb: "1rem" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        TWD:
                    </Typography>
                    <InputField
                        name={twdName}
                        variant="outlined"
                        control={control}
                        onChange={(e) => setValue(audName, `${roundedTo(parseFloat(e.currentTarget.value) / parseFloat(audToTwdRate))}`)}
                        type="number"
                    />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        AUD:
                    </Typography>
                    <InputField
                        name={audName}
                        variant="outlined"
                        control={control}
                        onChange={(e) => setValue(twdName, `${roundedTo(parseFloat(e.currentTarget.value) * parseFloat(audToTwdRate))}`)}
                        type="number"
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}
