import { Item } from "./items"

export enum PalletType {
    Wooden = "WOODEN",
    Plastic = "PLASTIC",
}

export enum CurrencyType {
    AUD = "AUD",
    TWD = "TWD",
}

export interface CurrencyOption {
    key: CurrencyType
    label_en: string
    label_ch: string
}

export const currencyOptionTWD: CurrencyOption = {
    key: CurrencyType.TWD,
    label_en: "TWD",
    label_ch: "",
}

export const currencyOptionAUD: CurrencyOption = {
    key: CurrencyType.AUD,
    label_en: "AUD",
    label_ch: "",
}

export interface ImportRecord {
    id: string
    organisation_id: string
    shipping_fee_twd: string
    shipping_fee_aud: string
    custom_fee_twd: string
    custom_fee_aud: string

    order_placed_at?: Date
    payment_transfer_at?: Date
    ship_arrived_at?: Date
    ship_left_at?: Date

    pallet_count: string
    pallet_type: string
    pallet_cost_twd: string
    pallet_cost_aud: string

    surcharge_twd: string
    surcharge_aud: string

    cargo_packing_fee_twd: string
    cargo_packing_fee_aud: string

    item_extra_charge_percentage: string
    aud_to_twd_rate: string
    deleted_at?: Date

    cost_twd: string
    cost_aud: string
    cbm: string
    total_item_amount: number
}

export interface ImportCartonVariant {
    id: string
    item_id: string
    carton_width_cm: string
    carton_depth_cm: string
    carton_height_cm: string
    cbm: string
    unit_count_per_carton: number
    deleted_at?: Date
}

export interface ImportedItem {
    id: string
    import_record_id: string
    carton_amount: number
    cost_twd: string
    cost_aud: string
    deleted_at?: Date

    item: Item
}
