import { Box, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useNavigate } from "react-router-dom"
import { useInnerShop } from "../../../containers/inner_shop"
import { useDebounce } from "../../../hooks/useDebounce"
import { usePagination } from "../../../hooks/usePagination"
import { colors, fonts } from "../../../theme/theme"
import { IntermediateProduct } from "../../../types/intermediateProducts"
import { InputBox } from "../../common/InputBox"
import { TotalAndPageSizeOptions } from "../../common/totalAndPageSizeOptions"
import { ItemCard } from "../../items/itemCard"

interface Props {
    intermediateProducts: IntermediateProduct[]
}

const IntermediateProductStackList = ({ intermediateProducts }: Props) => {
    const { shopID } = useInnerShop()
    const [list, setList] = React.useState<IntermediateProduct[]>([])
    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 5,
        page: 1,
    })
    const navigate = useNavigate()

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...intermediateProducts]

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => s.label.toLowerCase().includes(searchValue.toLowerCase()))
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, intermediateProducts, searchValue, page, pageSize, setTotalItems])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((intermediateProduct) => {
                        return (
                            <div key={`intermediateProduct-${intermediateProduct.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ItemCard
                                    item={intermediateProduct}
                                    linkTo={() => {
                                        navigate(`/inner_shop/${shopID}/intermediate_products?intermediate_product_id=${intermediateProduct.id}`)
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, shopID, navigate])

    return (
        <Stack direction="column" flex={1}>
            <TotalAndPageSizeOptions
                countItems={list.length}
                pageSizeOptions={[5, 10]}
                pageSize={pageSize}
                changePageSize={changePageSize}
                changePage={changePage}
            >
                {/* Search */}
                <Stack spacing="1rem" direction="row" alignItems="center">
                    <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                        <strong>SEARCH:</strong>
                    </Typography>
                    <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                </Stack>
            </TotalAndPageSizeOptions>

            <Stack sx={{ px: ".5rem", py: "1rem", flex: 1 }}>
                <Box
                    sx={{
                        my: "1rem",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden",
                        direction: "ltr",
                    }}
                >
                    {content}
                </Box>
            </Stack>

            {totalPages > 1 && (
                <Box
                    sx={{
                        mt: "auto",
                        px: "1rem",
                        py: ".7rem",
                        backgroundColor: colors.background,
                    }}
                >
                    <Pagination
                        size="medium"
                        count={totalPages}
                        page={page}
                        sx={{
                            ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                            ".Mui-selected": {
                                color: colors.primary,
                                backgroundColor: `${colors.background} !important`,
                            },
                        }}
                        onChange={(e, p) => changePage(p)}
                    />
                </Box>
            )}
        </Stack>
    )
}

export default IntermediateProductStackList
