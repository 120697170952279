import { Stack } from "@mui/material"
import { ImportRecordCreate } from "../../components/importRecords/importRecordCreate/importRecordCreate"

export const ImportRecordCreatePage = () => {
    return (
        <Stack
            direction="row"
            sx={{
                flex: 1,
                overflow: "hidden",
                height: "100%",
            }}
        >
            <ImportRecordCreate />
        </Stack>
    )
}
