import React from "react"

interface Props {
    pageSize: number
    page: number
}

export const usePagination = ({ pageSize: _pageSize = 10, page: _page = 1 }: Props) => {
    const [pageSize, setPageSize] = React.useState<number>(_pageSize)
    const [page, setPage] = React.useState<number>(_page)
    const [totalItems, setTotalItems] = React.useState<number>(0)
    const [totalPages, setTotalPages] = React.useState<number>(0)

    React.useEffect(() => {
        setTotalPages(Math.ceil(totalItems / pageSize))
    }, [pageSize, totalItems])

    const hasNext = React.useMemo(() => page + 1 <= totalPages, [page, totalPages])

    const hasPrev = React.useMemo(() => page - 1 > 0, [page])

    const nextPage = React.useCallback(() => {
        if (hasNext) setPage((prev) => prev + 1)
    }, [hasNext])

    const prevPage = React.useCallback(() => {
        if (hasPrev) setPage((prev) => prev - 1)
    }, [hasPrev])

    const changePageSize = React.useCallback((newPageSize: number) => {
        setPageSize((curPageSize) => (newPageSize !== curPageSize ? newPageSize : curPageSize))
    }, [])

    const changePage = React.useCallback((newPage: number) => {
        setPage((curPage) => (newPage !== curPage ? newPage : curPage))
    }, [])

    return {
        page,
        nextPage,
        prevPage,
        hasNext,
        hasPrev,
        changePage,
        totalPages,
        totalItems,
        pageSize,
        setTotalItems,
        changePageSize,
    }
}
