import React from "react"

export const useToggle = (initialValue: boolean = false): [boolean, (value?: boolean) => void] => {
    const [value, setValue] = React.useState(initialValue)

    const toggleValue = React.useCallback((value?: boolean) => {
        setValue((currentValue: boolean) => (typeof value === "boolean" ? value : !currentValue))
    }, [])

    return [value, toggleValue]
}
