import { Box, Stack } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useServerSubscriptionOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { Item } from "../../../types/items"
import { ImportedItemSelectCard } from "./importedItemSelectCard"

interface ImportItemSelectorProps {
    selectedImportedItems: Item[]
    onAddItem: (item: Item) => void
    searchValue: string
}

export const ImportItemSelector = ({ selectedImportedItems, onAddItem, searchValue }: ImportItemSelectorProps) => {
    const [list, setList] = React.useState<Item[]>([])
    const [items, setItems] = React.useState<Item[]>([])
    useServerSubscriptionOrg<Item[]>(
        {
            URI: `/imported_item_list`,
            key: WSKeys.WSKeyImportedItemList,
        },
        {
            callback: (payload) => {
                setItems(payload)
            },
        },
    )

    React.useEffect(() => {
        let list = [...items]
        if (searchValue !== "") {
            list = list.filter((l) => `${l.label} ${l.import_label}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        setList(
            list
                .filter((l) => !selectedImportedItems.some((si) => si.item_carton_variant?.id === l.item_carton_variant?.id))
                .sort((a, b) => (a.order_number > b.order_number ? 1 : -1)),
        )
    }, [items, searchValue, selectedImportedItems])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((item) => {
                        return (
                            <div key={`imported-item-${item.id}-${item.item_carton_variant?.id}`} style={{ marginBottom: ".3rem" }}>
                                <ImportedItemSelectCard item={item} onSelect={() => onAddItem(item)} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [list, onAddItem])

    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                width: "100%",
            }}
        >
            <Stack flex={1} sx={{ px: "1rem", py: "1rem" }}>
                <Stack
                    sx={{
                        flex: 1,
                        pr: "0.2rem",
                        overflowY: "auto",
                        overflowX: "hidden",
                        direction: "ltr",
                    }}
                >
                    {content}
                </Stack>
            </Stack>
        </Stack>
    )
}
