export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST || "storemate.services"
export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST || "storemate.services"

export const TOP_BAR_HEIGHT = "6rem"
export const DRAWER_TRANSITION_DURATION = 250

export const DRAWER_MAX_WIDTH = "25rem"
export const DRAWER_MIN_WIDTH = "7rem"

export const VERSION = process.env.REACT_APP_COMMIT_REF || "development"
