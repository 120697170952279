import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import { Stack, Typography } from "@mui/material"
import React from "react"
import { FcGoogle } from "react-icons/fc"
import { ShopButton } from "../../components/common/shopButton"
import { useAuth } from "../../containers/auth"
import { colors, fonts } from "../../theme/theme"
import { PosLoginModal } from "./PosLoginModal"

export const LoginPage = () => {
    const { onLoginClick } = useAuth()

    const [openPOSLogin, setOpenPOSLogin] = React.useState(false)

    return (
        <Stack
            spacing="3rem"
            alignItems="center"
            justifyContent="center"
            sx={{
                position: "fixed",
                width: "100vw",
                height: "100%",
                backgroundColor: colors.primary,
            }}
        >
            <Typography variant={"h2"} color={colors.offWhite} sx={{ mb: "1rem" }}>
                StoreMate
            </Typography>
            <ShopButton onClick={onLoginClick} buttonColor={`${colors.background}DD`} innerSx={{ minWidth: "30rem" }}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={"1rem"}>
                    <FcGoogle size={25} />
                    <Typography variant={"h5"} color={colors.primary} sx={{ fontFamily: fonts.sourceSansProBoldIt }}>
                        Login with Google
                    </Typography>
                </Stack>
            </ShopButton>
            <ShopButton buttonColor={`${colors.background}DD`} innerSx={{ minWidth: "30rem" }} onClick={() => setOpenPOSLogin(true)}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={"1rem"}>
                    <PointOfSaleIcon sx={{ fontSize: "2.8rem", color: colors.darkerNeonBlue }} />
                    <Typography variant={"h5"} color={colors.primary} sx={{ fontFamily: fonts.sourceSansProBoldIt }}>
                        POS SYSTEM
                    </Typography>
                </Stack>
            </ShopButton>
            {openPOSLogin && <PosLoginModal onClose={() => setOpenPOSLogin(false)} />}
        </Stack>
    )
}
