import { Box, Stack, StackProps, Typography } from "@mui/material"
import { colors } from "../../theme/theme"
import { StoreTooltip } from "./storeTooltip"

interface Props {
    label: string | React.ReactNode
    value: string
    tooltipText: string
}

const TooltipLabelValue = ({ label, value, tooltipText, ...stackProps }: Props & StackProps) => {
    const labelValue =
        typeof label === "string" ? (
            <Typography
                variant="h5"
                sx={{
                    color: colors.primary,
                }}
            >
                {label}
            </Typography>
        ) : (
            <Box>{label}</Box>
        )
    return (
        <Stack direction={"row"} spacing={"1.3rem"} alignItems={"center"} justifyContent={"flex-start"} minWidth={"13rem"} {...stackProps}>
            <StoreTooltip text={tooltipText}>{labelValue}</StoreTooltip>
            <Typography variant="h5">
                <strong>{value}</strong>
            </Typography>
        </Stack>
    )
}

export default TooltipLabelValue
