import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Connect, DefaultHost, RemoveConnections, Socket, Subscribe, Subscriber, Urify } from "./context"
import { IsMockMode } from "./useMock"
import { useMounted } from "./useMounted"

interface WSProps {
    URI: string
    key?: string
    batchURI?: string
    host?: string
    ready?: boolean
    subscriber?: Subscriber
    isCommander?: boolean
    jsonStringifiedParams?: string
}

const MAX_RECONNECT_ATTEMPTS = 6

export const useWS = ({ URI, key, host, batchURI, ready = true, subscriber, isCommander, jsonStringifiedParams }: WSProps) => {
    const isMock = IsMockMode()
    const wsURI = React.useMemo(
        () => Urify({ URI, host: host || DefaultHost(), batchURI, jsonStringifiedParams }),
        [URI, host, batchURI, jsonStringifiedParams],
    )
    const isBrowser = typeof window !== "undefined"
    const socket = Socket(wsURI)
    const ident = React.useRef<string>(uuidv4())
    const cleanupConn = React.useRef<() => void>(() => {
        return
    })

    const isMounted = useMounted()
    const [socketReady, setSocketReady] = React.useState<boolean>(false)
    const [socketState, setSocketState] = React.useState<number>(isBrowser ? WebSocket.CONNECTING : 0)
    const [isReconnecting, setIsReconnecting] = React.useState(false)
    const [isServerDown, setIsServerDown] = React.useState(true)
    const timeUntilRetry = React.useRef(6)
    const reconnectAttempts = React.useRef(0)

    const makeConnection = React.useCallback(() => {
        if (isMock || !ready) return
        if (subscriber) {
            Subscribe({
                uri: wsURI,
                key: key,
                sub: (msg) => {
                    if (!isMounted.current) return
                    subscriber(msg)
                },
                observer: setSocketState,
                isCommander,
            }).then((cleanup) => {
                cleanupConn.current = cleanup
                if (isMounted.current) setSocketReady(true)
            })
        } else {
            Connect({
                uri: wsURI,
                ident: ident.current,
                observer: setSocketState,
                isCommander,
            }).then((cleanup) => {
                cleanupConn.current = cleanup
                if (isMounted.current) setSocketReady(true)
            })
        }

        // Return a clean up function
        return () => {
            cleanupConn.current()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [URI, host, ready, wsURI, isMock])

    React.useEffect(() => {
        return makeConnection()
    }, [makeConnection])

    // Reconnect
    React.useEffect(() => {
        if (isMock || !isMounted.current) return

        // Will attempt to reconnect if ws closed
        if (socketState === WebSocket.CLOSED && reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
            setIsReconnecting(true)
            RemoveConnections(wsURI)

            setTimeout(() => {
                reconnectAttempts.current += 1
                console.log(`Reconnect attempt: ${reconnectAttempts.current}/${MAX_RECONNECT_ATTEMPTS}`)
                setSocketState(isBrowser ? WebSocket.CONNECTING : 0)
                makeConnection()
                timeUntilRetry.current += 6
            }, timeUntilRetry.current * 1000)
        } else if (socketState === WebSocket.CLOSED) {
            setIsServerDown(true)
            setIsReconnecting(false)
        } else if (socketState === WebSocket.OPEN) {
            timeUntilRetry.current = 6
            reconnectAttempts.current = 0
            setIsReconnecting(false)
            setIsServerDown(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketState, isMock])

    return {
        state: !isBrowser ? 0 : !socketReady ? WebSocket.CONNECTING : socketState,
        isReconnecting,
        isServerDown,
        wsURI,
        socket,
    }
}
