import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded"
import { Stack } from "@mui/material"
import React from "react"
import { useForm } from "react-hook-form"
import { useItem } from "../../../containers/items"
import { usePermissions } from "../../../containers/permissions"
import { snakeToTitle } from "../../../helpers"
import { useServerCommandsOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { Item } from "../../../types/items"
import { Permission } from "../../../types/permissions"
import { ConfirmModal } from "../../common/confirmModal"
import { InputField } from "../../common/form/inputField"
import { MultiSelectField } from "../../common/form/multiSelectField"

interface ItemInput {
    label: string
    volume: string
    unit_type: string
    cost: string
    item_source_ids: string[]
}

interface ShopItemModalProps {
    onClose: () => void
    item?: Item
}

export const ShopItemModal = ({ item, onClose }: ShopItemModalProps) => {
    const { itemSources } = useItem()
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsOrg()
    const [error, setError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const { control, getValues, trigger } = useForm<ItemInput>({
        defaultValues: {
            label: item?.label || "",
            volume: item?.volume || "",
            unit_type: item?.unit_type || "",
            cost: item?.cost || "",
            item_source_ids: item?.item_sources?.map((is) => is.id) || [],
        },
    })

    const onSubmit = React.useCallback(async () => {
        if (!(await trigger())) return

        const values = getValues()

        setIsLoading(true)
        try {
            const resp = await send(item ? WSKeys.WSKeyItemUpdate : WSKeys.WSKeyItemCreate, { ...values, id: item?.id })
            if (!resp) return

            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : `Failed to ${item ? "edit" : "create new"} item.`)
        } finally {
            setIsLoading(false)
        }
    }, [getValues, trigger, item, onClose, send])

    if (!hasPerm(Permission.ItemUpdate)) return null
    return (
        <ConfirmModal title={`${item ? "Edit" : "Create new"} item`} onClose={onClose} onConfirm={onSubmit} width="60rem" isLoading={isLoading} error={error}>
            <Stack spacing={"1.5rem"}>
                <InputField
                    label={"Label *"}
                    name="label"
                    variant={"outlined"}
                    control={control}
                    rules={{
                        required: "Label is required.",
                        maxLength: { value: 50, message: "Label must be less than 50 characters." },
                    }}
                />
                <Stack
                    spacing={"1rem"}
                    direction={"row"}
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <InputField
                        label={"Volume *"}
                        name="volume"
                        variant={"outlined"}
                        control={control}
                        type="number"
                        rules={{
                            required: "Unit type is required.",
                            validate: {
                                positive: (v: string) => parseFloat(v) > 0 || "Volume must be positive.",
                            },
                        }}
                    />
                    <InputField
                        label={"Unit Type *"}
                        name="unit_type"
                        variant={"outlined"}
                        placeholder="g, kg, ml, l ...etc"
                        control={control}
                        rules={{
                            required: "Unit type is required.",
                        }}
                    />
                </Stack>
                <InputField
                    label={"Cost *"}
                    name="cost"
                    variant={"outlined"}
                    control={control}
                    type="number"
                    rules={{
                        required: "Unit type is required.",
                        validate: {
                            positive: (v: string) => parseFloat(v) > 0 || "Cost must be positive.",
                        },
                    }}
                    InputProps={{
                        startAdornment: <AttachMoneyRoundedIcon fontSize="large" />,
                    }}
                />
                <MultiSelectField
                    label="Item Sources *"
                    name="item_source_ids"
                    control={control}
                    options={itemSources.map((is) => ({ id: is.id, label: snakeToTitle(is.label) }))}
                    rules={{
                        validate: {
                            required: (v: string[]) => (v && v.length > 0) || "Item source is required.",
                        },
                    }}
                />
            </Stack>
        </ConfirmModal>
    )
}
