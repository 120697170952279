import { Box, Stack, SxProps, Typography } from "@mui/material"
import { SendFunc } from "../../../../../containers/ws/useCommands"
import { UserOrdinaryPyament } from "../../../../../types/Shop"
import { colors, fonts } from "../../../../../theme/theme"
import React from "react"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import TimerIcon from "@mui/icons-material/Timer"
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded"
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { OrdinaryPaymentRequestModal } from "./OrdinaryPaymentRequestModal"
import { Moment } from "moment"
import { OrdinaryPaymentDeleteModal } from "./ordinaryPaymentDeleteModal"

interface Props {
    ordinaryPayment: UserOrdinaryPyament
    weekdayPay: string
    send: SendFunc
    strictStartTime: Moment

    omitPay?: boolean
    small?: boolean
}

export const OrdinaryPaymentCard = ({ ordinaryPayment, weekdayPay, send, strictStartTime, omitPay, small }: Props) => {
    const { total_hours, attached_to_payslip_id } = ordinaryPayment

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
    const [openEditModal, setOpenEditModal] = React.useState(false)

    const sxOpacity = React.useMemo<SxProps>(() => {
        if (attached_to_payslip_id) return { opacity: 0.5 }
        return { opacity: 1 }
    }, [attached_to_payslip_id])

    const action = React.useMemo(() => {
        if (attached_to_payslip_id)
            return (
                <Box
                    sx={{
                        backgroundColor: colors.darkGrey,

                        height: "fit-content",
                        px: ".5rem",
                        borderRadius: 0.8,
                    }}
                >
                    <Typography variant={"body2"} color="white" fontFamily={fonts.sourceSansProSemibold}>
                        CLAIMED
                    </Typography>
                </Box>
            )

        return (
            <>
                <Box data-testid="work-record-edit-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenEditModal(true)}>
                    <ModeEditRoundedIcon
                        sx={{
                            fontSize: small ? "2rem" : "3.5rem",
                            color: colors.primary,
                        }}
                    />
                </Box>
                <Box data-testid="work-record-delete-modal-button" sx={{ cursor: "pointer" }} onClick={() => setOpenDeleteModal(true)}>
                    <DeleteForeverOutlinedIcon
                        sx={{
                            fontSize: small ? "2rem" : "3.5rem",
                            color: colors.lightRed,
                        }}
                    />
                </Box>
            </>
        )
    }, [attached_to_payslip_id, small, setOpenDeleteModal, setOpenEditModal])

    return (
        <>
            <Box
                flex={1}
                sx={{
                    display: "grid",
                    gridTemplateColumns: omitPay ? "35% 24% 24% 10%" : "25% 20% 20% 20% 11%",
                    gridTemplateRows: "repeat(auto-fill, 5.5rem) ",
                    gap: "1rem",
                    borderRadius: 0.8,
                    border: `${colors.primary}30 3px solid`,
                    px: small ? "1rem" : "2rem",
                }}
            >
                <Stack direction={"row"} alignItems={"center"} spacing={"1rem"} sx={sxOpacity}>
                    <InsertInvitationIcon
                        sx={{
                            fontSize: small ? "2rem" : "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                        Ordinary Payments
                    </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} spacing={small ? ".2rem" : "1rem"} sx={sxOpacity}></Stack>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={small ? ".2rem" : "1rem"} sx={sxOpacity}>
                    <TimerIcon
                        sx={{
                            fontSize: small ? "2rem" : "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <Typography variant={small ? "h6" : "h4"} fontFamily={fonts.sourceSansProSemibold}>
                        {total_hours} hrs
                    </Typography>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={small ? "1rem" : "2rem"}>
                    {action}
                </Stack>
            </Box>

            {openEditModal && (
                <OrdinaryPaymentRequestModal
                    data-testid="ordinary-payment-request-modal"
                    userID={ordinaryPayment.user_id}
                    onClose={() => setOpenEditModal(false)}
                    ordinaryPayment={ordinaryPayment}
                    send={send}
                    strictStartTime={strictStartTime}
                />
            )}
            {openDeleteModal && <OrdinaryPaymentDeleteModal ordinaryPayment={ordinaryPayment} onClose={() => setOpenDeleteModal(false)} send={send} />}
        </>
    )
}
