import { Box, Divider, SxProps, Typography, TypographyProps } from "@mui/material"
import React from "react"
import { colors } from "../../theme/theme"

interface Props {
    icon: JSX.Element
    label: React.ReactNode
    buttonSx?: SxProps
    onClick?: () => void
}

const IconLabelButton = ({ icon, label, onClick, buttonSx, ...typographyProps }: Props & TypographyProps) => {
    const text = React.useMemo(() => {
        if (typeof label === "string") {
            return (
                <Typography variant="h6" color={colors.text} sx={{ pl: "3px" }} {...typographyProps}>
                    {label}
                </Typography>
            )
        }

        return label
    }, [label, typographyProps])

    return (
        <Box onClick={onClick}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    px: "1rem",
                    py: ".5rem",
                    backgroundColor: `${colors.primary}20`,
                    borderRadius: 0.8,
                    width: "fit-content",
                    cursor: "pointer",
                    ...buttonSx,
                }}
            >
                {icon}
                <Divider flexItem sx={{ mx: ".3rem" }} />
                {text}
            </Box>
        </Box>
    )
}

export default IconLabelButton
