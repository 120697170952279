import { Box, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { useItem } from "../../../containers/items"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks/useDebounce"
import { usePagination } from "../../../hooks/usePagination"
import { colors, fonts } from "../../../theme/theme"
import { InputBox } from "../../common/InputBox"
import { TotalAndPageSizeOptions } from "../../common/totalAndPageSizeOptions"
import { ComponentCard } from "./componentCard"

export interface ProductComponent {
    id: string
    volume: string
    type: string
    label: string
    cost: string
    unit_type: string
    setVolume: string
    setUnitType: string
}

interface ComponentSelectionListProps {
    selectedStacks: ProductComponent[]
    setSelectedStacks: React.Dispatch<React.SetStateAction<ProductComponent[]>>
    invalidSelectionIDs?: string[]
}

export const ComponentSelectionList = ({ selectedStacks, setSelectedStacks, invalidSelectionIDs }: ComponentSelectionListProps) => {
    const { items, intermediateProducts } = useItem()
    const { hasPerm } = usePermissions()

    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 8,
        page: 1,
    })

    const [selectionList, setSelectionList] = React.useState<ProductComponent[]>([])
    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [
            ...items.map((t) => ({ ...t, type: "item", setVolume: "0", setUnitType: t.unit_type })),
            ...intermediateProducts.map((ip) => ({ ...ip, type: "intermediate_product", setVolume: "0", setUnitType: ip.unit_type })),
        ]

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => s.label.toLowerCase().includes(searchValue.toLowerCase()))
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setSelectionList(sorted.sort((a, b) => a.label.localeCompare(b.label)))
    }, [setSelectionList, items, intermediateProducts, searchValue, page, pageSize, setTotalItems])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {selectionList.map((component) => {
                        return (
                            <div key={`component-${component.id}`} style={{ marginBottom: ".5rem", cursor: "pointer" }}>
                                <ComponentCard
                                    productComponent={component}
                                    selectedComponents={selectedStacks}
                                    disabled={invalidSelectionIDs?.includes(component.id)}
                                    onSelect={() => {
                                        setSelectedStacks((prev) => {
                                            if (prev.some((p) => p.id === component.id)) return prev
                                            return [...prev].concat(component)
                                        })
                                    }}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [selectionList, selectedStacks, setSelectedStacks, invalidSelectionIDs])

    return (
        <Stack direction="column" height={"100%"}>
            <TotalAndPageSizeOptions
                countItems={selectionList.length}
                pageSizeOptions={[]}
                pageSize={pageSize}
                changePageSize={changePageSize}
                changePage={changePage}
            >
                {/* Search */}
                <Stack spacing="1rem" direction="row" alignItems="center">
                    <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                        <strong>SEARCH:</strong>
                    </Typography>
                    <InputBox
                        variant="standard"
                        value={searchValueInstant}
                        setValue={(v) => {
                            changePage(1)
                            setSearchValue(v)
                        }}
                    />
                </Stack>
            </TotalAndPageSizeOptions>

            <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                <Box
                    sx={{
                        ml: "1rem",
                        mr: ".5rem",
                        pr: "1.4rem",
                        my: "1rem",
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden",
                        direction: "ltr",
                    }}
                >
                    {content}
                </Box>
            </Stack>

            {totalPages > 1 && (
                <Box
                    sx={{
                        mt: "auto",
                        px: "1rem",
                        py: ".7rem",
                        backgroundColor: colors.background,
                    }}
                >
                    <Pagination
                        size="medium"
                        count={totalPages}
                        page={page}
                        sx={{
                            ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                            ".Mui-selected": {
                                color: colors.primary,
                                backgroundColor: `${colors.background} !important`,
                            },
                        }}
                        onChange={(e, p) => changePage(p)}
                    />
                </Box>
            )}
        </Stack>
    )
}
