import { Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { SendFunc } from "../../../../../containers/ws"
import { WSKeys } from "../../../../../key"
import { fonts } from "../../../../../theme/theme"
import { UserShopWorkRecord } from "../../../../../types/Shop"
import { Permission } from "../../../../../types/permissions"
import { DateFormat } from "../../../../../types/types"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"
import { TimeDisplay } from "../../../../common/timeDisplay"

interface WorkRecordDeleteModalProps {
    workRecord: UserShopWorkRecord
    onClose: () => void
    send: SendFunc
}

export const WorkRecordDeleteModal = ({ workRecord, onClose, send }: WorkRecordDeleteModalProps) => {
    const { hasPerm } = usePermissions()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const deleteWorkRecord = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopMemberUpdate)) return

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopWorkRecordDelete, {
                work_record_id: workRecord.id,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to delete work record.")
        } finally {
            setIsLoading(false)
        }
    }, [hasPerm, onClose, send, workRecord.id])

    return (
        <ConfirmModal
            trans
            title={"Delete Work Record"}
            onClose={onClose}
            width={"fit-content"}
            onConfirm={deleteWorkRecord}
            error={error}
            isLoading={isLoading}
        >
            <Stack spacing={"1rem"} sx={{ pb: "2rem" }} width={"50rem"}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    Are you sure you want to delete the work record?
                </TypographyTrans>
                <Stack direction={"row"} alignItems={"center"} spacing={"2rem"}>
                    <Typography variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                        {moment(workRecord.started_at).format(DateFormat.DateDay)}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={".5rem"}>
                        <TimeDisplay variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt} date={workRecord.started_at} />
                        <Typography variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                            ~
                        </Typography>
                        <TimeDisplay variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt} date={workRecord.ended_at} />
                    </Stack>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
