import { Box, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import FlipMove from "react-flip-move"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks"
import { useImportRecordPrice } from "../../../hooks/useImportRecord"
import { usePagination } from "../../../hooks/usePagination"
import { useServerCommandsOrg, useServerSubscriptionOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import { ImportRecord, ImportedItem } from "../../../types/importRecords"
import { Manufacturer } from "../../../types/items"
import { Permission } from "../../../types/permissions"
import { InputBox } from "../../common/InputBox"
import { FilterBar } from "../../common/filterBar"
import { FilterOption } from "../../common/filterOption"
import { ShopButton } from "../../common/shopButton"
import { TotalAndPageSizeOptions } from "../../common/totalAndPageSizeOptions"
import { ImportedItemCard } from "./importedItem/importedItemCard"
import { ImportedItemModal } from "./importedItem/importedItemModal"

export enum TableDisplayMode {
    General = "GENERAL",
    CBM = "CBM",
}

interface ImportRecordDetailItemsProps {
    importRecord: ImportRecord
}

export const ImportRecordDetailItems = ({ importRecord }: ImportRecordDetailItemsProps) => {
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsOrg()
    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)

    const { operationFeeTWD, operationFeeAUD } = useImportRecordPrice({ importRecord })

    const { page, changePage, changePageSize, setTotalItems, totalPages, pageSize } = usePagination({
        pageSize: 10,
        page: 1,
    })

    const [filterBarExpended, setFilterBarExpended] = React.useState(false)
    const [list, setList] = React.useState<ImportedItem[]>([])
    const [selectedManufacturerKeys, setSelectedManufacturerKeys] = React.useState<string[]>([])

    const [importedItems, setImportedItems] = React.useState<ImportedItem[]>([])
    const [includedManufacturers, setIncludedManufacturers] = React.useState<Manufacturer[]>([])
    useServerSubscriptionOrg<ImportedItem[]>(
        {
            URI: `/import_record/${importRecord.id}/item_list`,
            key: WSKeys.WSKeyImportRecordItemList,
            ready: hasPerm(Permission.ImportRecordView) && hasPerm(Permission.ItemView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setImportedItems((prev) => {
                    if (prev.length === 0) {
                        return payload
                    }
                    prev = prev.map((ii) => payload.find((p) => p.id === ii.id) || ii)

                    payload.forEach((p) => {
                        if (prev.some((ii) => ii.id === p.id)) return
                        prev.push(p)
                    })

                    return prev.filter((ii) => !ii.deleted_at).sort((a, b) => (a.item.order_number > b.item.order_number ? 1 : -1))
                })

                setIncludedManufacturers((prev) => {
                    prev.map((m) => payload.find((p) => p.item.manufacturer_id === m.id)?.item.manufacturer || m)

                    payload.forEach((p) => {
                        if (!p.item.manufacturer_id || !p.item.manufacturer || prev.some((m) => m.id === p.item.manufacturer_id)) return
                        prev.push(p.item.manufacturer)
                    })

                    return prev
                })
            },
        },
    )

    const onDeleteImportedItem = React.useCallback(
        async (importedItemID: string) => {
            try {
                await send<null, { imported_item_id: string }>(WSKeys.WSKeyImportRecordItemArchive, {
                    imported_item_id: importedItemID,
                })
            } catch (e) {
                console.log(e)
            }
        },
        [send],
    )

    const [currentDisplayMode, setCurrentDisplayMode] = React.useState<TableDisplayMode>(TableDisplayMode.General)
    const [isDeleteMode, setIsDeleteMode] = React.useState(false)
    const [isAddMode, setIsAddMode] = React.useState(false)

    // Apply filter, sorting and pagination
    React.useEffect(() => {
        let sorted = [...importedItems]

        // filter
        if (searchValue !== "") {
            sorted = sorted.filter((s) => `${s.item.label} ${s.item.import_label}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        if (selectedManufacturerKeys.length > 0) {
            sorted = sorted.filter((s) => !!s.item.manufacturer_id && selectedManufacturerKeys.includes(s.item.manufacturer_id))
        }

        // set total after filtered
        setTotalItems(sorted.length)

        // pagination
        sorted = sorted.slice((page - 1) * pageSize, page * pageSize)

        setList(sorted)
    }, [setList, searchValue, page, pageSize, setTotalItems, importedItems, selectedManufacturerKeys])

    const content = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((importedItem) => {
                        return (
                            <div key={`imported-item-${importedItem.id}`} style={{ marginBottom: "1.3rem" }}>
                                <ImportedItemCard
                                    importedItem={importedItem}
                                    audToTwdRate={importRecord.aud_to_twd_rate}
                                    shouldShrink={filterBarExpended}
                                    currentDisplayMode={currentDisplayMode}
                                    isDeleteMode={isDeleteMode}
                                    deleteImportedItem={() => onDeleteImportedItem(importedItem.id)}
                                    operationFeeAUD={operationFeeAUD}
                                    operationFeeTWD={operationFeeTWD}
                                    totalCBM={parseFloat(importRecord.cbm)}
                                    extra_charge_rate={parseFloat(importRecord.item_extra_charge_percentage)}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [
        list,
        importRecord.aud_to_twd_rate,
        importRecord.cbm,
        importRecord.item_extra_charge_percentage,
        filterBarExpended,
        currentDisplayMode,
        isDeleteMode,
        operationFeeAUD,
        operationFeeTWD,
        onDeleteImportedItem,
    ])

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    height: "100%",
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                }}
            >
                <FilterBar
                    setFilterBarExpended={setFilterBarExpended}
                    resetFilter={() => {
                        setSelectedManufacturerKeys([])
                    }}
                >
                    <FilterOption
                        title="Manufacturer"
                        options={includedManufacturers.map((c) => ({ key: c.id, value: c.label_ch }))}
                        selectedKeys={selectedManufacturerKeys}
                        setSelectedKeys={setSelectedManufacturerKeys}
                    />
                </FilterBar>
                <Stack direction="column" flex={1} sx={{ p: ".5rem" }}>
                    <TotalAndPageSizeOptions
                        countItems={list.length}
                        pageSizeOptions={[10, 20, 40]}
                        pageSize={pageSize}
                        changePageSize={changePageSize}
                        changePage={changePage}
                    >
                        {/* Search */}
                        <Stack spacing="1rem" direction="row" alignItems="center">
                            <Typography variant="body1" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                                <strong>SEARCH:</strong>
                            </Typography>
                            <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                        </Stack>
                    </TotalAndPageSizeOptions>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            pl: "2rem",
                            pr: "1.5rem",
                            mt: ".2rem",
                            pt: "1rem",
                            height: "4.5rem",
                            backgroundColor: colors.background,
                            borderTop: `${colors.primary}70 1.5px solid`,
                        }}
                    >
                        <TableModeButtonGroup
                            outerPrimaryColor={colors.primary}
                            outerSecondaryColor={colors.secondary}
                            outerBackgroundColor={colors.background}
                            tableModeButtons={[
                                {
                                    label: TableDisplayMode.General,
                                    onClick: () => setCurrentDisplayMode(TableDisplayMode.General),
                                    isHighlighted: currentDisplayMode === TableDisplayMode.General,
                                },
                                {
                                    label: TableDisplayMode.CBM,
                                    onClick: () => setCurrentDisplayMode(TableDisplayMode.CBM),
                                    isHighlighted: currentDisplayMode === TableDisplayMode.CBM,
                                },
                            ]}
                        />
                        <TableModeButtonGroup
                            outerPrimaryColor={colors.primary}
                            outerSecondaryColor={colors.secondary}
                            outerBackgroundColor={colors.background}
                            tableModeButtons={[
                                {
                                    label: "ADD",
                                    onClick: () => {
                                        setIsAddMode((prev) => !prev)
                                        setIsDeleteMode(false)
                                    },
                                    isHighlighted: false,
                                },
                                {
                                    label: "REMOVE",
                                    onClick: () => {
                                        setIsDeleteMode((prev) => !prev)
                                        setIsAddMode(false)
                                    },
                                    isHighlighted: isDeleteMode,
                                    innerPrimaryColor: colors.red,
                                },
                            ]}
                        />
                    </Stack>

                    <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                        <Box
                            sx={{
                                ml: "1rem",
                                mr: ".5rem",
                                pr: "1rem",
                                my: "1rem",
                                flex: 1,
                                overflowY: "auto",
                                overflowX: "hidden",
                                direction: "ltr",
                            }}
                        >
                            {content}
                        </Box>
                    </Stack>

                    {totalPages > 1 && (
                        <Box
                            sx={{
                                mt: "auto",
                                px: "1rem",
                                py: ".7rem",
                                backgroundColor: colors.background,
                            }}
                        >
                            <Pagination
                                size="medium"
                                count={totalPages}
                                page={page}
                                sx={{
                                    ".MuiButtonBase-root": { borderRadius: 0.8, fontFamily: fonts.sourceSansProBold, fontSize: "1.8rem" },
                                    ".Mui-selected": {
                                        color: colors.primary,
                                        backgroundColor: `${colors.background} !important`,
                                    },
                                }}
                                onChange={(e, p) => changePage(p)}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>
            {isAddMode && <ImportedItemModal setOpen={setIsAddMode} audToTwdRate={importRecord.aud_to_twd_rate} importRecordID={importRecord.id} />}
        </>
    )
}

interface TableModeButton {
    label: React.ReactNode
    onClick: () => void
    isHighlighted: boolean

    innerPrimaryColor?: string
    innerSecondaryColor?: string
    innerBackgroundColor?: string
}

const TableModeButtonGroup = ({
    tableModeButtons,
    outerPrimaryColor,
    outerSecondaryColor,
    outerBackgroundColor,
}: {
    tableModeButtons: TableModeButton[]
    outerPrimaryColor: string
    outerSecondaryColor: string
    outerBackgroundColor: string
}) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {tableModeButtons.map((tmb, i) => {
                const { label, onClick, isHighlighted, innerPrimaryColor, innerSecondaryColor, innerBackgroundColor } = tmb
                const primaryColor = innerPrimaryColor || outerPrimaryColor
                const secondaryColor = innerSecondaryColor || outerSecondaryColor
                const backgroundColor = innerBackgroundColor || outerBackgroundColor

                const showLabel = () => {
                    if (typeof label === "string")
                        return (
                            <Typography
                                variant="h6"
                                sx={{ color: isHighlighted ? secondaryColor : `${primaryColor}dd`, fontWeight: "fontWeightBold" }}
                                onClick={onClick}
                            >
                                {label}
                            </Typography>
                        )

                    return label
                }
                return (
                    <ShopButton
                        key={i}
                        size="small"
                        sx={{
                            border: `${primaryColor}80 2px solid`,
                            py: 0,
                            px: "1rem",
                            backgroundColor: isHighlighted ? primaryColor : backgroundColor,
                            "&:hover": {
                                backgroundColor: isHighlighted ? primaryColor : backgroundColor,
                            },
                        }}
                        innerSx={{ p: 0 }}
                    >
                        {showLabel()}
                    </ShopButton>
                )
            })}
        </Stack>
    )
}
