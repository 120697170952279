import { Stack, Typography } from "@mui/material"
import React from "react"
import { usePermissions } from "../../../../../containers/permissions"
import { SendFunc } from "../../../../../containers/ws"
import { WSKeys } from "../../../../../key"
import { colors, fonts } from "../../../../../theme/theme"
import { UserOrdinaryPyament } from "../../../../../types/Shop"
import { Permission } from "../../../../../types/permissions"
import { TypographyTrans } from "../../../../common/TypographyTrans"
import { ConfirmModal } from "../../../../common/confirmModal"
import TimerIcon from "@mui/icons-material/Timer"

interface Props {
    ordinaryPayment: UserOrdinaryPyament
    onClose: () => void
    send: SendFunc
}

export const OrdinaryPaymentDeleteModal = ({ ordinaryPayment, onClose, send }: Props) => {
    const { hasPerm } = usePermissions()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const deleteOrdinaryPayment = React.useCallback(async () => {
        if (!hasPerm(Permission.ShopMemberUpdate)) return

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyUserShopOrdinaryPaymentDelete, {
                ordinary_payment_id: ordinaryPayment.id,
            })
            if (!resp) return
            onClose()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to delete ordinary payment.")
        } finally {
            setIsLoading(false)
        }
    }, [hasPerm, onClose, send, ordinaryPayment.id])

    return (
        <ConfirmModal
            trans
            title={"Delete Ordinary Payment"}
            onClose={onClose}
            width={"fit-content"}
            onConfirm={deleteOrdinaryPayment}
            error={error}
            isLoading={isLoading}
        >
            <Stack spacing={"1rem"} sx={{ pb: "2rem" }} width={"50rem"}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProBold}>
                    Are you sure you want to delete the ordinary payment?
                </TypographyTrans>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={"1rem"}>
                    <TimerIcon
                        sx={{
                            fontSize: "2.7rem",
                            color: colors.primary,
                        }}
                    />
                    <Typography variant={"h4"} fontFamily={fonts.sourceSansProSemibold}>
                        {ordinaryPayment.total_hours} hrs
                    </Typography>
                </Stack>
            </Stack>
        </ConfirmModal>
    )
}
