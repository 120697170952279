import { Stack, Typography } from "@mui/material"
import React from "react"
import { colors, fonts } from "../../theme/theme"
import { PageHeader } from "../common/pageHeader"
import { AccountantSettingModal } from "./accountantSettingsModal"
import { PayStaffModal } from "./payStaffs/payStaffModal"

export const AccountantPanel = () => {
    const [openPayStaffModal, setOpenPayStaffModal] = React.useState(false)
    const [openSettingsModal, setOpenSettingsModal] = React.useState(false)

    const optionButton = React.useCallback((title: string, callback: () => void) => {
        return (
            <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{
                    width: "100%",
                    px: "1rem",
                    py: "1rem",
                    borderBottom: `${colors.primary}50 3px solid`,
                    cursor: "pointer",
                }}
                onClick={callback}
            >
                <Typography variant={"h5"} fontFamily={fonts.sourceSansProBoldIt}>
                    {title}
                </Typography>
            </Stack>
        )
    }, [])

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    border: `${colors.primary}70 2px solid`,
                    borderRadius: 0.8,
                    width: "40rem",
                }}
            >
                <PageHeader
                    title={
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bolder" }}>
                                ACCOUNTANT PANEL
                            </Typography>
                        </Stack>
                    }
                />

                <Stack
                    flex={1}
                    spacing={"1rem"}
                    sx={{
                        p: "1rem",
                    }}
                >
                    {optionButton("View Payslips", () => console.log("click"))}
                    {optionButton("Pay Staffs", () => setOpenPayStaffModal(true))}
                    {optionButton("Settings", () => setOpenSettingsModal(true))}
                </Stack>
            </Stack>

            {openPayStaffModal && <PayStaffModal onClose={() => setOpenPayStaffModal(false)} />}
            {openSettingsModal && <AccountantSettingModal onClose={() => setOpenSettingsModal(false)} />}
        </>
    )
}
