import { Box, Stack, Typography } from "@mui/material"
import moment, { Moment } from "moment"
import React, { useEffect } from "react"
import FlipMove from "react-flip-move"
import { roundToDecimalPlaces, snakeToTitle } from "../../../helpers"
import { useServerCommandsOrg, useServerSubscription } from "../../../hooks/useServer"
import { EmploymentStatus, WSKeys } from "../../../key"
import { colors, fonts } from "../../../theme/theme"
import {
    LeaveStatus,
    Shop,
    ShopMember,
    StaffPayslipSessionStatus,
    UserOrdinaryPyament,
    UserShopLeaveRequest,
    UserShopPayslipBrief,
    UserShopSpecialPayslipRequirement,
    UserShopWorkRecord,
    UserShopYTDPayment,
    payslipStatusColorBox,
} from "../../../types/Shop"
import { TaxRateType } from "../../../types/organisations"
import { TypographyTrans } from "../../common/TypographyTrans"
import { ConfirmModal } from "../../common/confirmModal"
import { DotPointHeader } from "../../common/dotPointHeader"
import { ShopButton } from "../../common/shopButton"
import { LeaveRequestCard } from "../../shop/shopMember/accountPanel/leaveRequest/LeaveRequestCard"
import { UserShopLeaveRequestModal } from "../../shop/shopMember/accountPanel/leaveRequest/LeaveRequestModal"
import { WorkRecordRequestModal } from "../../shop/shopMember/accountPanel/workRecord/WorkRecordRequestModal"
import { WorkRecordCard } from "../../shop/shopMember/accountPanel/workRecord/workRecordCard"
import { PayslipTemplate } from "./payslipTemplate"
import { OrdinaryPaymentCard } from "../../shop/shopMember/accountPanel/ordinaryPayment/ordianryPaymentCard"
import { OrdinaryPaymentRequestModal } from "../../shop/shopMember/accountPanel/ordinaryPayment/OrdinaryPaymentRequestModal"

interface PayStaffPanelMemberWorkRecordsProps {
    selectedShopID: string
    selectedMemberID: string
    startTime: Moment
    endTime: Moment
    selectedShop?: Shop
    taxRateTypes: TaxRateType[]
    superannuationRate: string
}

export const PayStaffPanelMemberWorkRecords = ({
    selectedShopID,
    selectedMemberID,
    startTime,
    endTime,
    selectedShop,
    taxRateTypes,
    superannuationRate,
}: PayStaffPanelMemberWorkRecordsProps) => {
    const { send } = useServerCommandsOrg()
    const [openAddRecordModal, setOpenAddRecordModal] = React.useState(false)
    const [openAddOrdinaryHoursModal, setOpenAddOrdinaryHoursModal] = React.useState(false)
    const [userShopYTDPayment, setUserShopYTDPayment] = React.useState<UserShopYTDPayment>()
    const [userShopSpecialPayslipRequirement, setUserShopSpecialPayslipRequirement] = React.useState<UserShopSpecialPayslipRequirement>()
    const [userShopPayslips, setUserShopPayslips] = React.useState<UserShopPayslipBrief[]>([])
    const [currentPayslipID, setCurrentPayslipID] = React.useState("")
    const [openEditDateRangeModal, setOpenEditDateRangeModal] = React.useState(false)
    const [openResetPayslipModal, setOpenResetPayslipModal] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const labelValue = React.useCallback(
        (title: string, value: string | number, justifyContent?: string) => (
            <Stack direction={"row"} alignItems={"center"} justifyContent={justifyContent} spacing={".5rem"}>
                <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: "12rem" }}>
                    <Typography variant={"h6"}>{title}</Typography>
                    <Typography variant={"h6"}>:</Typography>
                </Stack>
                <Typography variant={"h6"} fontFamily={fonts.sourceSansProBoldIt} sx={{ pl: "1rem" }}>
                    {value}
                </Typography>
            </Stack>
        ),
        [],
    )

    // subscribe data
    const [shopMember, setShopMember] = React.useState<ShopMember>()
    const showLeaveOption = React.useMemo(() => {
        if (!shopMember) return false
        return [EmploymentStatus.FullTime, EmploymentStatus.PartTime].includes(shopMember.employment_status)
    }, [shopMember])
    useServerSubscription<ShopMember>(
        {
            URI: `/shop/${selectedShopID}/member/${selectedMemberID}`,
            key: WSKeys.WSKeyShopMember,
            ready: !!selectedShopID && !!selectedMemberID,
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopMember(payload)
            },
        },
    )

    useEffect(() => {
        if (!selectedShopID || !selectedMemberID) return
        send<UserShopYTDPayment>(WSKeys.WSKeyUserShopYTDPayment, {
            user_id: selectedMemberID,
            shop_id: selectedShopID,
            before_date: endTime.toDate(),
        }).then((resp) => {
            if (!resp) return
            setUserShopYTDPayment(resp)
        })

        send<UserShopSpecialPayslipRequirement>(WSKeys.WSKeyUserShopSpecialPayslipRequirement, {
            user_id: selectedMemberID,
            shop_id: selectedShopID,
        }).then((resp) => {
            if (!resp) return
            setUserShopSpecialPayslipRequirement(resp)
        })
    }, [selectedShopID, selectedMemberID, endTime, send])

    useServerSubscription<UserShopPayslipBrief[]>(
        {
            URI: `/shop/${selectedShopID}/member/${selectedMemberID}/payslips`,
            key: WSKeys.WSKeyUserShopPayslips,
            ready: !!selectedShopID && !!selectedMemberID,
            args: {
                start_date: startTime.toDate(),
                end_date: endTime.toDate(),
            },
        },
        {
            callback: (payload) => {
                if (!payload) return
                const targetPayslip = payload.find(
                    (p) => !p.deleted_at && p.start_date.toISOString() === startTime.toISOString() && p.end_date.toISOString() === endTime.toISOString(),
                )

                if (targetPayslip) {
                    setCurrentPayslipID(targetPayslip.id)
                }

                setUserShopPayslips((prev) => {
                    if (prev.length === 0) payload.filter((p) => moment(p.start_date).isBetween(startTime, endTime, undefined, "[]") && !p.deleted_at)
                    prev = prev.map((usw) => payload.find((p) => p.id === usw.id) || usw)
                    payload.forEach((p) => (prev.some((usw) => usw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usw) => moment(usw.start_date).isBetween(startTime, endTime, undefined, "[]") && !usw.deleted_at)
                })
            },
            filter: (payload) => {
                return payload.filter((wr) => moment(wr.start_date).isBetween(startTime, endTime, undefined, "[]"))
            },
        },
    )

    const [workRecords, setWorkRecords] = React.useState<UserShopWorkRecord[]>([])
    useServerSubscription<UserShopWorkRecord[]>(
        {
            URI: `/shop/${selectedShopID}/member/${selectedMemberID}/work_records`,
            key: WSKeys.WSKeyShopMemberWorkRecords,
            ready: !!selectedShopID && !!selectedMemberID,
            args: {
                started_at: startTime.toDate(),
                ended_at: endTime.toDate(),
                bonus_reward_key: localStorage.getItem("BONUS_REWARD_KEY"),
            },
        },
        {
            callback: (payload) => {
                if (!payload) return
                payload = payload
                    .filter((wr) => wr.started_at > startTime.toDate() && wr.started_at < endTime.toDate())
                    .sort((a, b) => (a.started_at > b.started_at ? -1 : 1))

                setWorkRecords((prev) => {
                    if (prev.length === 0) payload.filter((p) => !p.is_deleted)
                    prev = prev.map((usw) => payload.find((p) => p.id === usw.id) || usw)
                    payload.forEach((p) => (prev.some((usw) => usw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usw) => !usw.is_deleted)
                })
            },
        },
    )

    const [ordinaryPayments, setOrdinaryPayments] = React.useState<UserOrdinaryPyament[]>([])
    useServerSubscription<UserOrdinaryPyament[]>(
        {
            URI: `/shop/${selectedShopID}/member/${selectedMemberID}/ordinary_payments`,
            key: WSKeys.WSKeyUserShopOrdinaryPaymentRecords,
            ready: !!selectedShopID && !!selectedMemberID,
            args: {
                started_at: startTime.toDate(),
                ended_at: endTime.toDate(),
            },
        },
        {
            callback: (payload) => {
                if (!payload) return

                payload = payload
                    .filter((wr) => wr.started_at > startTime.toDate() && wr.started_at < endTime.toDate())
                    .sort((a, b) => (a.started_at > b.started_at ? -1 : 1))

                setOrdinaryPayments((prev) => {
                    if (prev.length === 0) payload.filter((p) => !p.deleted_at)
                    prev = prev.map((usw) => payload.find((p) => p.id === usw.id) || usw)
                    payload.forEach((p) => (prev.some((usw) => usw.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usw) => !usw.deleted_at)
                })
            },
        },
    )

    const payslipStatus = React.useMemo(() => {
        // show no work record if there is nothing return
        if (workRecords.length === 0) {
            return StaffPayslipSessionStatus.NO_RECORDS
        }

        let status = ""
        let hasUnconfirmedRecord = false
        let hasConfirmedRecord = false

        workRecords.forEach((wr) => {
            // skip if the status is set
            if (status !== "") return
            // check if the record is overlapped
            if (wr.attached_to_payslip) {
                hasConfirmedRecord = true

                if (wr.attached_to_payslip.start_date < startTime.toDate() || wr.attached_to_payslip.end_date > endTime.toDate()) {
                    status = StaffPayslipSessionStatus.OVERLAPPED
                }
                return
            }

            hasUnconfirmedRecord = true
        })

        if (status !== "") {
            return StaffPayslipSessionStatus.OVERLAPPED
        }
        if (hasConfirmedRecord && hasUnconfirmedRecord) {
            return StaffPayslipSessionStatus.INCOMPLETE
        }

        if (hasConfirmedRecord) {
            return StaffPayslipSessionStatus.SENT
        }

        return StaffPayslipSessionStatus.READY
    }, [workRecords, endTime, startTime])

    const workRecordContent = React.useMemo(() => {
        if (!shopMember) return null
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {workRecords.map((wr) => {
                        return (
                            <div data-testid={`work-record-${wr.id}`} key={`work-record-${wr.id}`} style={{ marginBottom: ".5rem" }}>
                                <WorkRecordCard
                                    workRecord={wr}
                                    weekdayPay={shopMember.weekday_pay}
                                    saturdayPay={shopMember.saturday_pay}
                                    sundayPay={shopMember.sunday_pay}
                                    send={send}
                                    omitPay
                                    small
                                />
                            </div>
                        )
                    })}
                    {ordinaryPayments.map((op) => {
                        return (
                            <div data-testid={`ordinary-payment-${op.id}`} key={`ordinary-payment-${op.id}`} style={{ marginBottom: ".5rem" }}>
                                <OrdinaryPaymentCard
                                    ordinaryPayment={op}
                                    weekdayPay={shopMember.weekday_pay}
                                    send={send}
                                    strictStartTime={startTime}
                                    omitPay
                                    small
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [workRecords, ordinaryPayments, shopMember, send, startTime])

    const resetPayslip = React.useCallback(async () => {
        if (!!currentPayslipID && payslipStatus !== StaffPayslipSessionStatus.SENT) return
        try {
            setIsLoading(true)
            await send(WSKeys.WSKeyUserShopPayslipReset, { payslip_id: currentPayslipID })
            setOpenResetPayslipModal(false)
            setCurrentPayslipID("")
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to reset payslip")
        } finally {
            setIsLoading(false)
        }
    }, [send, payslipStatus, currentPayslipID, setCurrentPayslipID])

    const leaveRequestActionButton = React.useMemo(() => {
        switch (payslipStatus) {
            case StaffPayslipSessionStatus.NO_RECORDS:
            case StaffPayslipSessionStatus.READY:
                return (
                    <ShopButton
                        data-testid={"add-leave-button"}
                        sx={{
                            py: 0,
                            px: "1rem",
                            backgroundColor: colors.primary,
                            ":hover": {
                                backgroundColor: colors.primary,
                            },
                        }}
                        onClick={() => setOpenAddLeaveModal(true)}
                    >
                        Add Leave
                    </ShopButton>
                )
            case StaffPayslipSessionStatus.INCOMPLETE:
            case StaffPayslipSessionStatus.OVERLAPPED:
                return null
        }
    }, [payslipStatus])

    const workRecordActionButton = React.useMemo(() => {
        switch (payslipStatus) {
            case StaffPayslipSessionStatus.NO_RECORDS:
            case StaffPayslipSessionStatus.READY:
                return (
                    <Stack direction="row" gap={1}>
                        <ShopButton
                            data-testid={"add-record-button"}
                            sx={{
                                py: 0,
                                px: "1rem",
                                backgroundColor: colors.primary,
                                ":hover": {
                                    backgroundColor: colors.primary,
                                },
                            }}
                            onClick={() => setOpenAddRecordModal(true)}
                        >
                            Work Record
                        </ShopButton>
                        <ShopButton
                            data-testid={"add-record-button"}
                            sx={{
                                py: 0,
                                px: "1rem",
                                backgroundColor: colors.primary,
                                ":hover": {
                                    backgroundColor: colors.primary,
                                },
                            }}
                            onClick={() => setOpenAddOrdinaryHoursModal(true)}
                        >
                            Ordinary Hours
                        </ShopButton>
                    </Stack>
                )
            case StaffPayslipSessionStatus.INCOMPLETE:
                return (
                    <ShopButton
                        data-testid={"reset-payslip-button"}
                        sx={{
                            py: 0,
                            px: "1rem",
                            backgroundColor: colors.lightRed,
                            ":hover": {
                                backgroundColor: colors.lightRed,
                            },
                        }}
                        onClick={() => setOpenResetPayslipModal(true)}
                    >
                        Reset Payslip
                    </ShopButton>
                )
            case StaffPayslipSessionStatus.OVERLAPPED:
                return (
                    <ShopButton
                        data-testid={"change-date-button"}
                        sx={{
                            py: 0,
                            px: "1rem",
                            backgroundColor: colors.yellow,
                            ":hover": {
                                backgroundColor: colors.yellow,
                            },
                        }}
                        onClick={() => setOpenEditDateRangeModal(true)}
                    >
                        Change Date
                    </ShopButton>
                )
            case StaffPayslipSessionStatus.SENT:
                return (
                    <ShopButton
                        data-testid={"change-date-button"}
                        sx={{
                            py: 0,
                            px: "1rem",
                            backgroundColor: colors.orange,
                            ":hover": {
                                backgroundColor: colors.orange,
                            },
                        }}
                        onClick={() => setOpenResetPayslipModal(true)}
                    >
                        Reset Payslip
                    </ShopButton>
                )
        }
    }, [payslipStatus])

    // leave requests
    const [openAddLeaveModal, setOpenAddLeaveModal] = React.useState(false)
    const [leaveRequests, setLeaveRequests] = React.useState<UserShopLeaveRequest[]>([])
    useServerSubscription<UserShopLeaveRequest[]>(
        {
            URI: `/shop/${selectedShopID}/member/${selectedMemberID}/leave_requests`,
            key: WSKeys.WSKeyUserShopLeaveRequests,
            ready: !!selectedShopID && !!selectedMemberID && showLeaveOption,
            args: {
                started_at: startTime.toDate(),
                ended_at: endTime.toDate(),
            },
        },
        {
            callback: (payload) => {
                if (!payload || !payload.length) return
                payload = payload.filter((wr) => wr.date >= startTime.toDate() && wr.date <= endTime.toDate()).sort((a, b) => (a.date > b.date ? -1 : 1))
                setLeaveRequests((prev) => {
                    if (prev.length === 0) payload.filter((p) => p.status !== LeaveStatus.Cancelled)
                    prev = prev.map((usl) => payload.find((p) => p.id === usl.id) || usl)
                    payload.forEach((p) => (prev.some((usl) => usl.id === p.id) ? undefined : prev.push(p)))
                    return prev.filter((usl) => usl.status !== LeaveStatus.Cancelled)
                })
            },
            filter: (payload) => {
                if (!payload || !payload.length) return []
                return payload.filter((wr) => wr.date >= startTime.toDate() && wr.date <= endTime.toDate())
            },
        },
    )

    const leaveRequestContent = React.useMemo(() => {
        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {leaveRequests.map((lr) => {
                        return (
                            <div data-testid={`leave-request-${lr.id}`} key={`leave-request-${lr.id}`} style={{ marginBottom: ".5rem" }}>
                                <LeaveRequestCard leaveRequest={lr} />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [leaveRequests])

    if (!shopMember) return <Stack data-testid="empty-component" flex={1} />

    const { prefer_name, payslip_prefer_name, job_title, employment_status, visa_type_label, user_shop_leave } = shopMember

    return (
        <Stack direction={"row"} spacing={"3rem"} flex={1}>
            <Stack spacing={"2rem"} flex={1}>
                {/* Member Detail */}
                <Stack spacing={"1rem"}>
                    <DotPointHeader title={"Member Detail"} />

                    <Stack direction={"row"} spacing={".5rem"}>
                        <Stack spacing={".5rem"} flex={1}>
                            {labelValue("Name", `${prefer_name} ${prefer_name !== payslip_prefer_name ? `(${payslip_prefer_name})` : ""}`)}
                            {labelValue("Job Title", snakeToTitle(job_title))}
                            {labelValue("Employment", snakeToTitle(employment_status))}
                            {labelValue("Visa", visa_type_label)}
                        </Stack>
                        {showLeaveOption && user_shop_leave && (
                            <Stack spacing={".5rem"} minWidth={"35%"}>
                                {labelValue("Annual leave", `${roundToDecimalPlaces(user_shop_leave.annual_leave_hours, 4)} hrs`, "space-between")}
                                {labelValue("Personal care", `${roundToDecimalPlaces(user_shop_leave.personal_care_leave_hours, 4)} hrs`, "space-between")}
                                {labelValue("Time in lieu", `${roundToDecimalPlaces(user_shop_leave.time_in_lieu_hours, 4)} hrs`, "space-between")}
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                {/* Work Records*/}
                <Stack spacing={"1rem"} flex={1}>
                    <DotPointHeader
                        title={"Work Record"}
                        titleSx={{
                            fontSize: "2.25rem",
                        }}
                        appendedComponent={payslipStatusColorBox[payslipStatus]}
                        suffixComponent={workRecordActionButton}
                    />
                    <Stack sx={{ py: ".5rem", flex: 1 }}>
                        <Box
                            sx={{
                                ml: ".2rem",
                                mr: ".2rem",
                                pr: ".2rem",
                                flex: 1,
                                overflowY: "auto",
                                overflowX: "hidden",
                                direction: "ltr",
                            }}
                        >
                            {workRecordContent}
                        </Box>
                    </Stack>
                </Stack>

                {/* NOTE: hide leave section for now, till figuring out the correct leave calculation */}
                {/* {showLeaveOption && (
                    <Stack height={"30%"}>
                        <DotPointHeader
                            title="Leaves"
                            titleSx={{
                                fontSize: "2.25rem",
                            }}
                            suffixComponent={leaveRequestActionButton}
                        />
                        <Stack sx={{ py: ".5rem", flex: 1 }}>
                            <Box
                                sx={{
                                    ml: ".2rem",
                                    mr: ".2rem",
                                    pr: ".2rem",
                                    flex: 1,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    direction: "ltr",
                                }}
                            >
                                {leaveRequestContent}
                            </Box>
                        </Stack>
                    </Stack>
                )} */}
            </Stack>

            <Stack minWidth={"770px"}>
                {selectedShop && (
                    <PayslipTemplate
                        userShopSpecialPayslipRequirement={userShopSpecialPayslipRequirement}
                        payslips={userShopPayslips}
                        payslipID={currentPayslipID}
                        shop={selectedShop}
                        shopMember={shopMember}
                        startDate={startTime}
                        endDate={endTime}
                        workRecords={workRecords}
                        ordinaryPayments={ordinaryPayments}
                        leaveRequests={leaveRequests}
                        taxRateTypes={taxRateTypes}
                        superannuationRate={superannuationRate}
                        userShopYTDPayment={userShopYTDPayment}
                        send={send}
                    />
                )}
            </Stack>

            {openAddRecordModal && (
                <WorkRecordRequestModal
                    data-testid="work-record-request-modal"
                    userID={selectedMemberID}
                    shopID={selectedShopID}
                    onClose={() => setOpenAddRecordModal(false)}
                    send={send}
                    strictStartTime={startTime}
                    strictEndTime={endTime}
                />
            )}
            {openAddOrdinaryHoursModal && (
                <OrdinaryPaymentRequestModal
                    userID={selectedMemberID}
                    shopID={selectedShopID}
                    onClose={() => setOpenAddOrdinaryHoursModal(false)}
                    send={send}
                    strictStartTime={startTime}
                />
            )}
            {openAddLeaveModal && (
                <UserShopLeaveRequestModal
                    onClose={() => setOpenAddLeaveModal(false)}
                    selectedMemberID={selectedMemberID}
                    selectedShopID={selectedShopID}
                    send={send}
                    strictStartDate={startTime}
                    strictEndDate={endTime}
                />
            )}
            {openResetPayslipModal && (
                <ConfirmModal title="Reset Payslip" onClose={() => setOpenResetPayslipModal(false)} onConfirm={resetPayslip}>
                    <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemibold}>
                        {`Are you sure you want to reset current payslip?`}
                    </TypographyTrans>
                </ConfirmModal>
            )}
        </Stack>
    )
}
