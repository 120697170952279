import { Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { useOrganisation } from "../../containers/organisations"
import { snakeToTitle } from "../../helpers"
import { useServerCommandsOrg } from "../../hooks/useServer"
import { WSKeys } from "../../key"
import { fadeInAndOutEffect } from "../../theme/keyframes"
import { colors, fonts } from "../../theme/theme"
import { SalaryPayCycle } from "../../types/organisations"
import { TypographyTrans } from "../common/TypographyTrans"
import { ConfirmModal } from "../common/confirmModal"
import { DateSelector } from "../common/dateSelector"
import { SelectBox } from "../common/selectBox"
import { AccountantCCEmailSetter } from "./accountantCCEmailSetter"

interface AccountantSettingModalProps {
    onClose: () => void
}

export const AccountantSettingModal = ({ onClose }: AccountantSettingModalProps) => {
    const { organisation } = useOrganisation()
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [salaryPayDate, setSalaryPayDate] = React.useState<moment.Moment | null>(organisation?.salary_pay_date ? moment(organisation?.salary_pay_date) : null)
    const [selectedPayCycle, setSelectedPayCycle] = React.useState<string>(organisation?.salary_pay_cycle || "")
    const { send } = useServerCommandsOrg()
    const [showSuccess, setShowSuccess] = React.useState(false)

    const onConfirm = React.useCallback(async () => {
        if (!salaryPayDate) {
            setError("Start date is required.")
            return
        }
        if (!selectedPayCycle) {
            setError("Pay cycle is required.")
            return
        }

        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyOrganisationSalarySettingsUpdate, {
                salary_pay_date: salaryPayDate.startOf("day"),
                salary_pay_cycle: selectedPayCycle,
            })

            if (!resp) return
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
                onClose()
            }, 2000)
        } catch (e) {
            setError(typeof e === "string" ? e : "Something went wrong. Please try again later.")
        } finally {
            setIsLoading(false)
        }
    }, [onClose, setShowSuccess, salaryPayDate, selectedPayCycle, send])

    return (
        <ConfirmModal
            title={
                <Stack direction="row" alignItems="center" sx={{ height: "3.5rem" }}>
                    <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack, fontWeight: "bold" }}>
                        Settings
                    </Typography>
                    {showSuccess && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: "1rem",
                                px: "1rem",
                                py: "0.3rem",
                                lineHeight: 0,
                                borderRadius: 2,
                                backgroundColor: colors.green,
                                animation: `${fadeInAndOutEffect} 2s`,
                            }}
                        >
                            <Typography
                                variant="body1"
                                textAlign={"center"}
                                sx={{
                                    fontFamily: fonts.sourceSansProBlack,
                                    fontWeight: "bold",
                                    color: colors.offWhite,
                                }}
                            >
                                UPDATED
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            }
            onClose={onClose}
            onConfirm={onConfirm}
            isLoading={isLoading}
        >
            <Stack spacing={"1rem"} sx={{ pb: "2rem" }}>
                {error && (
                    <Typography variant={"body1"} color={colors.lightRed}>
                        {error}
                    </Typography>
                )}

                <DateSelector label={"Starting Pay Date:"} date={salaryPayDate} setDate={setSalaryPayDate} />
                <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                    Pay Cycle:
                </TypographyTrans>
                <SelectBox
                    options={Object.values(SalaryPayCycle).map((cycle) => ({ value: cycle, label: snakeToTitle(cycle) }))}
                    value={selectedPayCycle}
                    sx={{ border: `${colors.primary} 2px solid`, height: "5rem" }}
                    onChange={(e) => {
                        const v = e.target.value
                        if (typeof v !== "string") return
                        setSelectedPayCycle(v)
                    }}
                />

                <TypographyTrans variant={"h6"} fontFamily={fonts.sourceSansProSemiboldIt}>
                    CC Emails:
                </TypographyTrans>
                <AccountantCCEmailSetter />
            </Stack>
        </ConfirmModal>
    )
}
