import { Stack } from "@mui/material"
import React from "react"
import { snakeToTitle } from "../../../../helpers"
import { colors, fonts } from "../../../../theme/theme"
import { ShopMember } from "../../../../types/Shop"
import { PageHeader } from "../../../common/pageHeader"
import { ShopButton } from "../../../common/shopButton"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { AccountPanelWorkRecords } from "./workRecord/AccountPanelWorkRecords"

enum AccountPanelDisplay {
    WorkRecords = "WORK_RECORDS",
    Payslips = "PAYSLIPS",
    Leave = "LEAVE",
}

interface ShopMemberAccountPanelProps {
    shopMember: ShopMember
}

export const ShopMemberAccountPanel = ({ shopMember }: ShopMemberAccountPanelProps) => {
    const [selectedDisplay, setSelectedDisplay] = React.useState<AccountPanelDisplay>(AccountPanelDisplay.WorkRecords)

    const panel = React.useMemo(() => {
        switch (selectedDisplay) {
            case AccountPanelDisplay.WorkRecords:
                return <AccountPanelWorkRecords key={shopMember.id} shopMember={shopMember} />
            case AccountPanelDisplay.Payslips:
                return null
            case AccountPanelDisplay.Leave:
                return null
        }
    }, [shopMember, selectedDisplay])

    return (
        <Stack
            direction={"column"}
            flex={1}
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
            }}
        >
            <PageHeader
                title={
                    <TypographyTrans variant="h5" sx={{ fontFamily: fonts.sourceSansProBlackIt }}>
                        ACCOUNT PANEL
                    </TypographyTrans>
                }
            />

            <Stack direction="row" flex={1}>
                {/* Panel Bar */}
                <Stack
                    direction={"column"}
                    spacing={".5rem"}
                    sx={{
                        borderRight: `${colors.primary} 1px dashed`,
                        p: ".5rem",
                    }}
                >
                    {Object.values(AccountPanelDisplay).map((apd) => (
                        <ShopButton
                            trans
                            buttonColor={selectedDisplay === apd ? colors.primary : `${colors.lightGrey}99`}
                            key={apd}
                            onClick={() => setSelectedDisplay(apd)}
                            innerSx={{
                                width: "15rem",
                                opacity: selectedDisplay === apd ? 1 : 0.3,
                            }}
                        >
                            {snakeToTitle(apd)}
                        </ShopButton>
                    ))}
                </Stack>
                {/* Select Panel */}
                <Stack flex={1}>{panel}</Stack>
            </Stack>
        </Stack>
    )
}
