import { Stack } from "@mui/material"
import { useQRCode } from "next-qrcode"
import React from "react"
import { usePinInput } from "react-pin-input-hook"
import { useServerCommandsShop } from "../../../../hooks/useServer"
import { WSKeys } from "../../../../key"
import { colors, fonts } from "../../../../theme/theme"
import { TypographyTrans } from "../../../common/TypographyTrans"
import { ShopButton } from "../../../common/shopButton"

interface TwoFactorSetupComponentProps {
    exit: () => void
}

export const TwoFactorSetupComponent = ({ exit }: TwoFactorSetupComponentProps) => {
    const { send } = useServerCommandsShop()
    const [values, setValues] = React.useState<string[]>(Array(6).fill(""))
    const [totpUrl, setTotpUrl] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const { fields } = usePinInput({
        values,
        onChange: (values) => {
            setValues(values)
        },
    })
    const { Canvas } = useQRCode()

    React.useEffect(() => {
        setIsLoading(true)
        send<string>(WSKeys.WSKeyShopPosTotpKey)
            .then((resp) => {
                setTotpUrl(resp)
            })
            .catch((e) => setError(typeof e === "string" ? e : "Failed to retrieve TOTP URL"))
            .finally(() => setIsLoading(false))
    }, [send])

    const VerifyTotpKey = React.useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await send(WSKeys.WSKeyShopPosTotpKeySetup, {
                passcode: values.join(""),
            })
            if (!resp) return
            exit()
        } catch (e) {
            setError(typeof e === "string" ? e : "Failed to retrieve TOTP URL")
        } finally {
            setIsLoading(false)
        }
    }, [send, setError, setIsLoading, values, exit])

    return (
        <Stack flex={1}>
            <TypographyTrans variant="h6" fontFamily={fonts.sourceSansProSemibold}>
                Please Scan the following QR code with any app authenticator and enter the PIN
            </TypographyTrans>

            {/* QR code*/}
            <Stack flex={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {totpUrl && (
                    <Canvas
                        text={totpUrl}
                        options={{
                            level: "H",
                            margin: 4,
                            scale: 200,
                            width: 200,
                            color: {
                                dark: colors.darkNavyBlue,
                                light: `${colors.primary}20`,
                            },
                        }}
                    />
                )}
            </Stack>

            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={"3rem"}>
                <Stack direction={"row"} alignItems={"center"} spacing={".8rem"}>
                    {fields.map((fieldProps, index) => (
                        <input
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "4.5rem",
                                height: "4.5rem",
                                textAlign: "center",
                                border: `3px solid ${colors.primary}DD`,
                                borderRadius: 7,
                                fontSize: "2.2rem",
                                fontFamily: fonts.sourceSansProBlack,
                            }}
                            key={index}
                            {...fieldProps}
                        />
                    ))}
                </Stack>
                <ShopButton
                    disabled={values.length < 6}
                    sx={{
                        py: 0,
                        px: "1rem",
                        backgroundColor: `${colors.primary}BB`,
                        height: "5rem",
                        ":hover": {
                            backgroundColor: `${colors.primary}BB`,
                        },
                    }}
                    onClick={VerifyTotpKey}
                >
                    confirm
                </ShopButton>
            </Stack>
        </Stack>
    )
}
