import { Stack } from "@mui/material"
import { Warehouses } from "../../components/warehouses/warehouses"

export const WarehousePage = () => {
    return (
        <Stack
            direction="row"
            sx={{
                flex: 1,
                overflow: "hidden",
                height: "100%",
            }}
        >
            <Warehouses />
        </Stack>
    )
}
