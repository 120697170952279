import { TextField } from "@mui/material"
import moment from "moment"
import React from "react"
import { colors, fonts } from "../../theme/theme"
import { DateFormat } from "../../types/types"

export const TimeSelector = ({
    date,
    setDate,
    name,
}: {
    date: moment.Moment | null
    setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>
    name?: string
}) => {
    return (
        <TextField
            id="time"
            name={name}
            hiddenLabel
            role="textbox"
            type="time"
            fullWidth
            variant="outlined"
            defaultValue={date ? date.format(DateFormat.Time24H) : undefined}
            onChange={(e) => {
                const time = e.currentTarget.value
                if (!time) {
                    setDate(null)
                    return
                }
                setDate(moment(time, DateFormat.Time24H))
            }}
            inputProps={{
                step: 300,
            }}
            sx={{
                width: "100%",
                ".MuiOutlinedInput-input": {
                    p: ".37rem 1.6rem",
                    pt: ".55rem",
                    fontSize: "2.2rem",
                    height: `4.3rem`,
                    fontFamily: fonts.sourceSansProBold,
                    "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                    },
                    appearance: "textfield",
                },
                ".MuiOutlinedInput-notchedOutline": {
                    border: `${colors.primary} 2px solid`,
                    borderRadius: 0.8,
                },
            }}
        />
    )
}
