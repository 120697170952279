import React from "react"

export const useMounted = () => {
    const isMounted = React.useRef(true)

    React.useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    return isMounted
}
