import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { CircularProgress } from "@mui/material"
import React from "react"
import { fadeInAndOutEffect } from "../../theme/keyframes"
import { colors } from "../../theme/theme"

interface AutoSavingIndicatorProps {
    isLoading: boolean
    isError?: boolean
}
export const AutoSavingIndicator = ({ isLoading, isError }: AutoSavingIndicatorProps) => {
    const [isSaving, setIsSaving] = React.useState(false)
    const [isSavingCompleted, setIsSavingCompleted] = React.useState(false)

    React.useEffect(() => {
        if (isError) {
            setIsSaving(false)
            return
        } else if (isLoading) {
            setIsSaving(true)
            return
        } else if (isSaving) {
            setIsSaving(false)
            setIsSavingCompleted(true)

            setTimeout(() => setIsSavingCompleted(false), 1000)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isError])

    if (!isSaving && !isSavingCompleted) return null
    else if (isSaving) return <CircularProgress />
    else return <TaskAltIcon sx={{ color: colors.green, fontSize: "3.25rem", animation: `${fadeInAndOutEffect} 1.2s` }} />
}
