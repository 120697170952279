import AddBoxIcon from "@mui/icons-material/AddBox"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow"
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat"
import { Box, IconButton, Stack, Typography } from "@mui/material"
import moment from "moment/moment"
import React from "react"
import FlipMove from "react-flip-move"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { DRAWER_TRANSITION_DURATION } from "../../../constants"
import { usePermissions } from "../../../containers/permissions"
import { useDebounce } from "../../../hooks"
import { useServerCommandsOrg } from "../../../hooks/useServer"
import { WSKeys } from "../../../key"
import { fadeInAndOutEffect } from "../../../theme/keyframes"
import { colors, fonts } from "../../../theme/theme"
import { ImportedItem, PalletType } from "../../../types/importRecords"
import { Item } from "../../../types/items"
import { Permission } from "../../../types/permissions"
import { InputBox } from "../../common/InputBox"
import { FeeCurrencyFields } from "../../common/currency/feeCurrencyFields"
import { DateField } from "../../common/form/dateField"
import { InputField } from "../../common/form/inputField"
import { SelectField } from "../../common/form/selectField"
import { PageHeader } from "../../common/pageHeader"
import { ShopButton } from "../../common/shopButton"
import { ImportRecordRequest, ImportRecordUpdateForm } from "../detail/importRecordDetailUpdateModal"
import { ImportItemSelector } from "./importItemSelector"
import { ImportRecordSelector } from "./importRecordSelector"
import { ImportedItemFieldCard } from "./importedItemFieldCard"

export interface ImportItemSelectField {
    item_id: string
    item_carton_variant_id: string
    cost_twd: string
    cost_aud: string
    carton_amount: string
}

export const ImportRecordCreate = () => {
    const navigate = useNavigate()
    const { send } = useServerCommandsOrg()
    const [openImportRecordSelector, setOpenImportRecordSelector] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const { hasPerm } = usePermissions()
    const [addMode, setAddMode] = React.useState(false)
    const [searchValue, setSearchValue, searchValueInstant] = useDebounce("", 200)
    const [selectedItems, setSelectedItems] = React.useState<Item[]>([])
    const { control, watch, setValue, getValues, trigger } = useForm<ImportRecordUpdateForm>({
        defaultValues: {
            shipping_fee_twd: "0",
            shipping_fee_aud: "0",
            custom_fee_twd: "0",
            custom_fee_aud: "0",

            order_placed_at: new Date(),
            payment_transfer_at: null,
            ship_left_at: null,
            ship_arrived_at: null,

            pallet_count: "0",
            pallet_type: PalletType.Wooden,
            pallet_cost_twd: "0",
            pallet_cost_aud: "0",
            surcharge_twd: "0",
            surcharge_aud: "0",
            cargo_packing_fee_twd: "0",
            cargo_packing_fee_aud: "0",
            item_extra_charge_percentage: "0",
            aud_to_twd_rate: "20",
        },
    })

    const audToTwdRate = watch("aud_to_twd_rate")

    const itemsSelectField = React.useRef<ImportItemSelectField[]>([])
    const content = React.useMemo(() => {
        let list = [...selectedItems]
        if (searchValue !== "") {
            list = list.filter((l) => `${l.import_label} ${l.label}`.toLowerCase().includes(searchValue.toLowerCase()))
        }

        return (
            <Box sx={{ direction: "ltr", height: 0 }}>
                {/*@ts-ignore*/}
                <FlipMove>
                    {list.map((item) => {
                        return (
                            <div key={`imported-item-${item.item_carton_variant?.id}`} style={{ marginBottom: ".5rem" }}>
                                <ImportedItemFieldCard
                                    item={item}
                                    onDelete={() => {
                                        setSelectedItems((prev) => prev.filter((p) => p.item_carton_variant?.id !== item.item_carton_variant?.id))
                                        itemsSelectField.current = itemsSelectField.current.filter(
                                            (is) => is.item_carton_variant_id !== item.item_carton_variant?.id,
                                        )
                                    }}
                                    audToTwdRate={audToTwdRate}
                                    itemsSelectField={itemsSelectField}
                                />
                            </div>
                        )
                    })}
                </FlipMove>
            </Box>
        )
    }, [selectedItems, searchValue, audToTwdRate])

    const onSelectImportRecord = React.useCallback(
        async (importRecordID: string) => {
            try {
                const selectedRecord = await send<ImportRecordUpdateForm & { imported_items: ImportedItem[] }>(WSKeys.WSKeyImportRecordGet, {
                    import_record_id: importRecordID,
                })

                setValue("shipping_fee_twd", selectedRecord.shipping_fee_twd)
                setValue("shipping_fee_aud", selectedRecord.shipping_fee_aud)
                setValue("custom_fee_twd", selectedRecord.custom_fee_twd)
                setValue("custom_fee_aud", selectedRecord.custom_fee_aud)
                setValue("order_placed_at", selectedRecord.order_placed_at || null)
                setValue("payment_transfer_at", selectedRecord.payment_transfer_at || null)
                setValue("ship_left_at", selectedRecord.ship_left_at || null)
                setValue("ship_arrived_at", selectedRecord.ship_arrived_at || null)
                setValue("pallet_count", `${selectedRecord.pallet_count}`)
                setValue("pallet_type", selectedRecord.pallet_type)
                setValue("pallet_cost_twd", selectedRecord.pallet_cost_twd)
                setValue("pallet_cost_aud", selectedRecord.pallet_cost_aud)
                setValue("surcharge_twd", selectedRecord.surcharge_twd)
                setValue("surcharge_aud", selectedRecord.surcharge_aud)
                setValue("cargo_packing_fee_twd", selectedRecord.cargo_packing_fee_twd)
                setValue("cargo_packing_fee_aud", selectedRecord.cargo_packing_fee_aud)
                setValue("item_extra_charge_percentage", `${selectedRecord.item_extra_charge_percentage}`)
                setValue("aud_to_twd_rate", `${selectedRecord.aud_to_twd_rate}`)
                setSelectedItems(selectedRecord.imported_items.map<Item>((ii) => ii.item))
                itemsSelectField.current = selectedRecord.imported_items.map<ImportItemSelectField>((ii) => ({
                    item_id: ii.item.id,
                    item_carton_variant_id: ii.item.item_carton_variant?.id || "",
                    cost_twd: ii.cost_twd,
                    cost_aud: ii.cost_aud,
                    carton_amount: `${ii.carton_amount}`,
                }))

                setOpenImportRecordSelector(false)
            } catch (e) {
                console.log(e)
            }
        },
        [send, setValue],
    )

    const onSubmit = React.useCallback(async () => {
        if (!hasPerm(Permission.ImportRecordUpdate)) return

        // valid
        if (!(await trigger())) return

        const data: ImportRecordUpdateForm = getValues()

        try {
            await send<null, ImportRecordRequest>(WSKeys.WSKeyImportRecordCreate, {
                ...data,
                id: "",
                order_placed_at: moment(data.order_placed_at).isValid() ? moment(data.order_placed_at).toDate() : undefined,
                payment_transfer_at: moment(data.payment_transfer_at).isValid() ? moment(data.payment_transfer_at).toDate() : undefined,
                ship_arrived_at: moment(data.ship_arrived_at).isValid() ? moment(data.ship_arrived_at).toDate() : undefined,
                ship_left_at: moment(data.ship_left_at).isValid() ? moment(data.ship_left_at).toDate() : undefined,
                pallet_count: !isNaN(parseInt(data.pallet_count)) ? parseInt(data.pallet_count) : 0,
                item_extra_charge_percentage: !isNaN(parseFloat(data.item_extra_charge_percentage)) ? parseFloat(data.item_extra_charge_percentage) : 0,
                aud_to_twd_rate: !isNaN(parseFloat(data.aud_to_twd_rate)) ? parseFloat(data.aud_to_twd_rate) : 0,
                imported_items: itemsSelectField.current.map((si) => ({
                    item_id: si.item_id,
                    item_carton_variant_id: si.item_carton_variant_id,
                    carton_amount: parseInt(si.carton_amount),
                    cost_twd: si.cost_twd,
                    cost_aud: si.cost_aud,
                })),
            })

            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
                navigate("/import")
            }, 2000)
        } catch (e) {
            console.log(e)
        }
    }, [getValues, hasPerm, navigate, send, trigger, itemsSelectField])

    return (
        <Stack
            flex={1}
            sx={{
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.8,
            }}
        >
            <PageHeader
                title={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton size="small" disableRipple onClick={() => navigate("/import")} sx={{ p: 0 }}>
                            <ArrowBackIcon sx={{ fontSize: "2.5rem", color: colors.primary, fontWeight: "bold" }} />
                        </IconButton>
                        <Typography variant="h5" sx={{ fontFamily: fonts.sourceSansProBlack }}>
                            <strong>CREATE IMPORT RECORDS</strong>
                        </Typography>
                        {showSuccess && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    ml: "1rem",
                                    px: "1rem",
                                    borderRadius: 2,
                                    backgroundColor: colors.green,
                                    animation: `${fadeInAndOutEffect} 2s`,
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: fonts.sourceSansProBlack,
                                        fontWeight: "bold",
                                        color: colors.offWhite,
                                    }}
                                >
                                    SUCCESS
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                }
            />

            <Stack direction="column" flex={1} spacing={1}>
                <Stack direction="row" spacing={1} sx={{ width: "100%", px: "1rem", pt: "1rem" }}>
                    <ShopButton
                        sx={{
                            border: `${colors.primary} 1px solid`,
                            backgroundColor: colors.primary,
                            ":hover": {
                                backgroundColor: colors.primary,
                            },
                        }}
                        size="small"
                        onClick={() => setOpenImportRecordSelector(true)}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bold", color: `${colors.secondary}CC` }}>
                            <strong>Import Record</strong>
                        </Typography>
                    </ShopButton>
                </Stack>
                <Stack direction="row" flex={1} spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                    <Stack direction="row" spacing={1}>
                        <Stack direction="column" sx={{ height: "100%", p: "1rem", minWidth: "fix-content" }}>
                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                Time:
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                                <DateField label="Order At:" control={control} name="order_placed_at" />
                                <DateField label="Payment transfer:" control={control} name="payment_transfer_at" />
                                <DateField label="Ship left:" control={control} name="ship_left_at" />
                                <DateField label="Ship Arrive:" control={control} name="ship_arrived_at" />
                            </Stack>

                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                General:
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{ px: "1rem", pb: "1rem" }}>
                                <InputField
                                    label={
                                        <Stack direction="row" spacing={1}>
                                            <Typography variant="h6" fontWeight="bold">
                                                AUD
                                            </Typography>
                                            <TrendingFlatIcon fontSize="large" fontWeight="bold" sx={{ color: colors.primary }} />
                                            <Typography variant="h6" fontWeight="bold">
                                                TWD
                                            </Typography>
                                        </Stack>
                                    }
                                    name="aud_to_twd_rate"
                                    variant="outlined"
                                    control={control}
                                    type="number"
                                />
                                <InputField label="Extra charge (%)" name="item_extra_charge_percentage" variant="outlined" control={control} type="number" />
                            </Stack>

                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                Pallet:
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{ px: "1rem" }}>
                                <InputField label="Count" name="pallet_count" variant="outlined" control={control} type="number" />
                                <SelectField
                                    title="Type"
                                    name="pallet_type"
                                    control={control}
                                    options={[
                                        { id: PalletType.Wooden, label: PalletType.Wooden },
                                        { id: PalletType.Plastic, label: PalletType.Plastic },
                                    ]}
                                />
                            </Stack>
                        </Stack>

                        <Stack direction="column">
                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                Fee:
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{ px: "1rem" }}>
                                <FeeCurrencyFields
                                    title="Shipping Fee"
                                    control={control}
                                    audToTwdRate={audToTwdRate}
                                    twdName="shipping_fee_twd"
                                    audName="shipping_fee_aud"
                                    setValue={setValue}
                                    isVertical
                                />
                                <FeeCurrencyFields
                                    title="Custom fee"
                                    control={control}
                                    audToTwdRate={audToTwdRate}
                                    twdName="custom_fee_twd"
                                    audName="custom_fee_aud"
                                    setValue={setValue}
                                    isVertical
                                />
                                <FeeCurrencyFields
                                    title="Surcharge"
                                    control={control}
                                    audToTwdRate={audToTwdRate}
                                    twdName="surcharge_twd"
                                    audName="surcharge_aud"
                                    setValue={setValue}
                                    isVertical
                                />
                                <FeeCurrencyFields
                                    title="Cargo Packing fee"
                                    control={control}
                                    audToTwdRate={audToTwdRate}
                                    twdName="cargo_packing_fee_twd"
                                    audName="cargo_packing_fee_aud"
                                    setValue={setValue}
                                    isVertical
                                />
                                <FeeCurrencyFields
                                    title="Pallet Cost"
                                    control={control}
                                    audToTwdRate={audToTwdRate}
                                    twdName="pallet_cost_twd"
                                    audName="pallet_cost_aud"
                                    setValue={setValue}
                                    isVertical
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack direction="column" flex={1} spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h5" fontWeight="bold" color={colors.primary}>
                                Items:
                            </Typography>
                            <IconButton
                                disableRipple
                                size="small"
                                sx={{
                                    p: 0,
                                    opacity: addMode ? 0.5 : 1,
                                }}
                                onClick={() => setAddMode((prev) => !prev)}
                            >
                                <AddBoxIcon sx={{ color: colors.primary, fontSize: "2.5rem" }} />
                            </IconButton>
                        </Stack>

                        <Stack
                            direction="column"
                            flex={1}
                            sx={{
                                border: `${colors.primary}99 2px solid`,
                                borderRadius: 0.8,
                            }}
                        >
                            <Stack
                                sx={{
                                    px: "1rem",
                                    pt: "1rem",
                                }}
                            >
                                <InputBox variant="standard" value={searchValueInstant} setValue={setSearchValue} />
                            </Stack>
                            <Stack direction="row" flex={1} spacing={1}>
                                <Stack
                                    direction="row"
                                    sx={{
                                        height: "100%",
                                        width: addMode ? "32%" : 0,
                                        transition: `all ${DRAWER_TRANSITION_DURATION / 750}s`,
                                        visibility: !addMode ? "hidden" : undefined,
                                    }}
                                >
                                    <ImportItemSelector
                                        searchValue={searchValue}
                                        selectedImportedItems={selectedItems}
                                        onAddItem={(item) => {
                                            setSelectedItems((prev) => prev.concat(item))
                                            itemsSelectField.current = itemsSelectField.current.concat({
                                                item_id: item.id,
                                                item_carton_variant_id: item.item_carton_variant?.id || "",
                                                cost_twd: "0",
                                                cost_aud: "0",
                                                carton_amount: "0",
                                            })
                                        }}
                                    />

                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: "100%", minWidth: "7rem", display: !addMode ? "none" : undefined }}
                                    >
                                        <DoubleArrowIcon
                                            sx={{
                                                fontSize: "4.5rem",
                                                color: colors.secondary,
                                                backgroundImage: `linear-gradient(to right, ${colors.primary}10 0%, ${colors.primary}80)`,
                                            }}
                                        />
                                    </Stack>
                                </Stack>

                                <Stack flex={1}>
                                    <Stack sx={{ px: "1rem", py: "1rem", flex: 1 }}>
                                        <Box
                                            sx={{
                                                pr: "1rem",
                                                flex: 1,
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                                direction: "ltr",
                                            }}
                                        >
                                            {content}
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ px: "1rem", pb: "1rem" }}>
                    <ShopButton
                        sx={{
                            border: `${colors.primary} 1px solid`,
                            backgroundColor: colors.primary,
                            ":hover": {
                                backgroundColor: colors.primary,
                            },
                        }}
                        size="small"
                        onClick={onSubmit}
                    >
                        <Typography color={colors.secondary}>
                            <strong>Create</strong>
                        </Typography>
                    </ShopButton>
                </Stack>
            </Stack>
            {openImportRecordSelector && <ImportRecordSelector setOpen={setOpenImportRecordSelector} onSelectedImportRecordID={onSelectImportRecord} />}
        </Stack>
    )
}
