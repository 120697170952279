import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import { Stack } from "@mui/material"
import React, { useCallback } from "react"
import { usePermissions } from "../../../../../../containers/permissions"
import { snakeToTitle } from "../../../../../../helpers"
import { useDebounce } from "../../../../../../hooks"
import { useServerCommandsShop, useServerSubscriptionShop } from "../../../../../../hooks/useServer"
import { EmploymentStatus, WSKeys } from "../../../../../../key"
import { colors, fonts } from "../../../../../../theme/theme"
import { ShopJobWage } from "../../../../../../types/Shop"
import { Permission } from "../../../../../../types/permissions"
import { InputBox } from "../../../../../common/InputBox"
import { TypographyTrans } from "../../../../../common/TypographyTrans"
import { AutoSavingIndicator } from "../../../../../common/autoSavingIndicator"

interface ShopJobWagePanelProps {
    shopJobLevelID: string
}
export const ShopJobWagePanel = ({ shopJobLevelID }: ShopJobWagePanelProps) => {
    const { hasPerm } = usePermissions()

    const sortShopJobWagesOrder = useCallback((sjws: ShopJobWage[]): ShopJobWage[] => {
        const newList: ShopJobWage[] = []
        const fullTimeWage = sjws.find((sjw) => sjw.employment_status === EmploymentStatus.FullTime)
        if (fullTimeWage) newList.push(fullTimeWage)
        const partTimeWages = sjws.find((sjw) => sjw.employment_status === EmploymentStatus.PartTime)
        if (partTimeWages) newList.push(partTimeWages)
        const casualWages = sjws.find((sjw) => sjw.employment_status === EmploymentStatus.Casual)
        if (casualWages) newList.push(casualWages)
        const contractWages = sjws.find((sjw) => sjw.employment_status === EmploymentStatus.Contract)
        if (contractWages) newList.push(contractWages)
        return newList
    }, [])

    const [shopJobWages, setShopJobWages] = React.useState<ShopJobWage[]>([])
    useServerSubscriptionShop<ShopJobWage[]>(
        {
            URI: `/job_level/${shopJobLevelID}/job_wages`,
            key: WSKeys.WSKeyShopJobWages,
            ready: !!shopJobLevelID && hasPerm(Permission.ShopJobTitleView),
        },
        {
            callback: (payload) => {
                if (!payload) return
                setShopJobWages((prev) => {
                    if (prev.length === 0)
                        return sortShopJobWagesOrder(payload.filter((p) => !p.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1)))
                    prev = prev.map((jw) => payload.find((p) => p.id === jw.id) || jw)
                    payload.forEach((p) => (prev.some((jw) => jw.id === p.id) ? undefined : prev.push(p)))
                    return sortShopJobWagesOrder(prev.filter((jw) => !jw.deleted_at).sort((a, b) => (a.order_number > b.order_number ? 1 : -1)))
                })
            },
        },
    )

    return (
        <Stack direction={"row"} flex={1} spacing={"2rem"} sx={{ px: "1.5rem" }}>
            {shopJobWages.map((sjw) => (
                <PaymentFields key={sjw.id} shopJobWage={sjw} />
            ))}
        </Stack>
    )
}

interface PaymentFieldsProps {
    shopJobWage: ShopJobWage
}
const PaymentFields = ({ shopJobWage }: PaymentFieldsProps) => {
    const { id } = shopJobWage
    const { hasPerm } = usePermissions()
    const { send } = useServerCommandsShop()

    const [dayPayment, setDayPayment, dayPaymentInstant] = useDebounce(
        {
            weekday_pay: shopJobWage.weekday_pay,
            saturday_pay: shopJobWage.saturday_pay,
            sunday_pay: shopJobWage.sunday_pay,
            holiday_pay: shopJobWage.holiday_pay,
        },
        1500,
    )

    const [jobWageIsLoading, setJobWageIsLoading] = React.useState(false)
    React.useEffect(() => {
        if (!jobWageIsLoading || !hasPerm(Permission.ShopJobTitleUpdate)) return
        send(WSKeys.WSKeyShopJobWageUpdate, { ...dayPayment, id }).finally(() => setJobWageIsLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPerm, id, dayPayment, send])

    return (
        <Stack spacing={"1rem"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TypographyTrans variant={"h5"} fontFamily={fonts.sourceSansProSemiboldIt}>
                    {snakeToTitle(shopJobWage.employment_status)}
                </TypographyTrans>
                {<AutoSavingIndicator isLoading={jobWageIsLoading} />}
            </Stack>

            <Stack spacing={"1.5rem"}>
                <InputBox
                    trans
                    label={"Weekday Pay"}
                    variant="outlined"
                    type={"number"}
                    value={dayPaymentInstant.weekday_pay}
                    setValue={(e) => setDayPayment((prev) => ({ ...prev, weekday_pay: e }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setJobWageIsLoading(true)}
                    InputProps={{
                        startAdornment: <AttachMoneyIcon sx={{ fontSize: "2.3rem", color: colors.primary }} />,
                    }}
                />
                <InputBox
                    trans
                    label={"Saturday Pay"}
                    variant="outlined"
                    type={"number"}
                    value={dayPaymentInstant.saturday_pay}
                    setValue={(e) => setDayPayment((prev) => ({ ...prev, saturday_pay: e }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setJobWageIsLoading(true)}
                    InputProps={{
                        startAdornment: <AttachMoneyIcon sx={{ fontSize: "2.3rem", color: colors.primary }} />,
                    }}
                />
                <InputBox
                    trans
                    label={"Sunday Pay"}
                    variant="outlined"
                    type={"number"}
                    value={dayPaymentInstant.sunday_pay}
                    setValue={(e) => setDayPayment((prev) => ({ ...prev, sunday_pay: e }))}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    onChange={() => setJobWageIsLoading(true)}
                    InputProps={{
                        startAdornment: <AttachMoneyIcon sx={{ fontSize: "2.3rem", color: colors.primary }} />,
                    }}
                />
                <InputBox
                    trans
                    label={"Holiday Pay"}
                    variant="outlined"
                    type={"number"}
                    value={dayPaymentInstant.holiday_pay}
                    setValue={(e) => {
                        console.log(e)
                        setDayPayment((prev) => ({ ...prev, holiday_pay: e }))
                    }}
                    onChange={() => setJobWageIsLoading(true)}
                    sx={{ fontFamily: fonts.sourceSansProSemibold }}
                    InputProps={{
                        startAdornment: <AttachMoneyIcon sx={{ fontSize: "2.3rem", color: colors.primary }} />,
                    }}
                />
            </Stack>
        </Stack>
    )
}
