import AddBoxIcon from "@mui/icons-material/AddBox"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { Box, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { calCostRatio } from "../../../helpers"
import { colors } from "../../../theme/theme"
import { InputBox } from "../../common/InputBox"
import { SelectBox } from "../../common/selectBox"
import { ProductComponent } from "./componentSelectionList"

interface ComponentCardProps {
    productComponent: ProductComponent
    selectedComponents?: ProductComponent[]
    disabled?: boolean
    onSelect?: () => void
    onVolumeChange?: (volume: string) => void
    onUnitTypeChange?: (unitType: string) => void
    onRemove?: () => void
}

export const ComponentCard = ({ productComponent, onSelect, onVolumeChange, onUnitTypeChange, selectedComponents, onRemove, disabled }: ComponentCardProps) => {
    const { id, label, cost, volume, unit_type, setVolume } = productComponent
    const isDisabled = React.useMemo(
        () => disabled || (selectedComponents && selectedComponents.some((sc) => sc.id === id)),
        [disabled, selectedComponents, id],
    )
    const [containedVolume, setContainedVolume] = React.useState(setVolume || "0")
    const [unitType, setUnitType] = React.useState(productComponent.setUnitType ? productComponent.setUnitType.toLowerCase() : unit_type.toLowerCase())
    const unitTypeOptions = React.useMemo(() => {
        switch (unit_type.toLowerCase()) {
            case "g":
            case "kg":
                return [
                    { value: "g", label: "G" },
                    { value: "kg", label: "KG" },
                ]
            case "ml":
            case "l":
                return [
                    { value: "ml", label: "ML" },
                    { value: "l", label: "L" },
                ]
            default:
                return [{ value: unit_type.toLowerCase(), label: unit_type }]
        }
    }, [unit_type])
    return (
        <Box
            sx={{
                color: colors.primary,
                textAlign: "start",
                border: `${colors.primary}70 2px solid`,
                borderRadius: 0.6,
                p: ".8rem",
                opacity: isDisabled ? 0.6 : 1,
            }}
        >
            <Stack flex={1} direction={"row"} spacing={"2rem"} alignItems={"center"}>
                <Stack direction="row" flex={1} alignItems={"center"} justifyContent="space-between">
                    <Stack direction="column">
                        <Typography variant="h5">
                            <strong>{label}</strong>
                        </Typography>
                    </Stack>
                    <Typography variant="h5">
                        <strong>{calCostRatio(cost, volume, unit_type)}</strong>
                    </Typography>
                </Stack>
                {onSelect && (
                    <IconButton size="small" onClick={onSelect} sx={{ p: 0 }} disabled={isDisabled}>
                        <AddBoxIcon sx={{ color: colors.primary, fontSize: "4rem" }} />
                    </IconButton>
                )}
                {onVolumeChange && onUnitTypeChange && (
                    <Stack direction={"row"} spacing={".8rem"}>
                        <Stack maxWidth={"80px"}>
                            <InputBox
                                value={containedVolume}
                                setValue={(v) => {
                                    setContainedVolume(v)
                                    onVolumeChange(v)
                                }}
                                type="number"
                                placeholder=" "
                            />
                        </Stack>
                        <Stack maxWidth={"40px"}>
                            <SelectBox
                                options={unitTypeOptions}
                                value={unitType}
                                sx={{
                                    border: `${colors.primary}80 1px solid`,
                                    "& .MuiSelect-outlined": {
                                        pt: ".2rem",
                                        pb: 0,
                                        px: "0 !important",
                                        width: "40px",
                                    },
                                }}
                                onChange={(e) => {
                                    const v = e.target.value
                                    if (typeof v !== "string") return
                                    setUnitType(v)
                                    onUnitTypeChange(v)
                                }}
                                placeholder=" "
                            />
                        </Stack>
                        {onRemove && (
                            <IconButton size="small" onClick={onRemove} sx={{ p: 0 }} disabled={isDisabled}>
                                <DeleteForeverIcon sx={{ color: colors.lightRed, fontSize: "2.5rem" }} />
                            </IconButton>
                        )}
                    </Stack>
                )}
            </Stack>
        </Box>
    )
}
