import React from "react"

export interface UIState {
    isNavLinksDrawerOpen: boolean
    toggleIsNavLinksDrawerOpen: (value: boolean) => void
}

const initialState: UIState = {
    isNavLinksDrawerOpen: true,
    toggleIsNavLinksDrawerOpen: () => {
        return
    },
}

export const UIContext = React.createContext<UIState>(initialState)

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
    const [isNavLinksDrawerOpen, setIsNavLinksDrawerOpen] = React.useState(localStorage.getItem("nav-links-drawer-open") === "true")

    const toggleIsNavLinksDrawerOpen = React.useCallback(
        (val: boolean) => {
            localStorage.setItem("nav-links-drawer-open", val ? "true" : "false")
            setIsNavLinksDrawerOpen(val)
        },
        [setIsNavLinksDrawerOpen],
    )

    return (
        <UIContext.Provider
            value={{
                isNavLinksDrawerOpen,
                toggleIsNavLinksDrawerOpen,
            }}
        >
            {children}
        </UIContext.Provider>
    )
}

export const useUI = () => {
    return React.useContext<UIState>(UIContext)
}
